import Vue from 'vue';
import { Dialog } from 'buefy/dist/components/dialog'

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

export default {
    install: (Vue) => {
        Vue.prototype.$utils = {
            test(msg) {
               alert(msg || 'test');
            },

            scrollToTop() {
                window.scrollTo(0,0);
            },

            getHumanErrorMessage(err) {
                /** return first error message only */
                const data  = err.response.data.data;

                if(typeof data.error !== 'undefined') {
                    return data.error;
                } else if(typeof data.err !== 'undefined') {
                    return data.err;
                } else {
                    const errorObj = data.errors;
                    return errorObj[Object.keys(errorObj)[0]].toString();
                }

            },

            getHumanErrorMessages(err) {
                /** return all error messages as an array */
                return err.response.data.data.errors;
            },

            showChannelOfflineInfo() {

                const msg = `
                    <b>If the channel is offline or unavailable, please check the following:</b><br>
                    <br>
                    - Mobile phone is connected to Wi-Fi<br>
                    - Mobile phone with Whatsapp is switched properly installed and running<br>
                    - Mobile phone is connected to a charger<br>
                    <br>
                    Please wait for 1 minute if the channel is back online. If the problem persists, please contact a ${this.$global.product_name} representative by chat, email or phone.
                `;

                Dialog.alert({
                    title: 'Channel offline',
                    message: msg,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa'
                })
            },

            getCurrentUri: function () {
                return window.location.protocol + '//' + window.location.host + '';
            },

            open: function(url, target) {
                window.open(url, target || null);
            },

            parseWAMessage: function(b) {

                if(b) {
                    //font styles
                    b = b.replace(/\*{1}(.+?)\*{1}/g, '<b>$1</b>');
                    b = b.replace(/\_{1}(.+?)\_{1}/g, '<i>$1</i>');
                    b = b.replace(/\~{1}(.+?)\~{1}/g, '<del>$1</del>');
                    b = b.replace(/(https?:\/\/[^\s]+)/g, "<a href='$1' target='_blank'>$1</a>");
                }

                return b;
            },

            getForeignPaymentUrl: function(id) {
                return 'https://www.mollie.com/dashboard/payments/' + id;
            },

            getForeignCustomerUrl: function(id) {
                return 'https://www.mollie.com/dashboard/customers/' + id;
            },

            getForeignInvoiceUrl: function(id) {
                return 'https://moneybird.com/244764053818312298/sales_invoices/' + id;
            },

            getMimeTypeOfUrl: function(url) {

                if(! url) return 'unknown';

                switch(url.split('.').pop()) {
                    case 'gif':
                        return 'image/gif';
                    case 'png':
                        return 'image/png';
                    case 'jpg':
                    case 'jpeg':
                        return 'image/jpeg';
                    case 'mpeg':
                        return 'audio/mpeg';
                    case 'mp3':
                        return 'audio/mp3';
                    case 'mp4':
                        return 'video/mp4';
                    case 'pdf':
                        return 'application/pdf';
                    default:
                        return 'unknown';
                }
            }
        };
    }
};

const CreateTemplateForm = {
    data() {
        return {
            data: {},
            form: {
                name: 'My template'
            },

            submitting: false
        }
    },
    mounted() {
        this.$refs.templateName.focus();
    },
    props: [
        'campaignName'
    ],
    template: `
            <form @submit.prevent="handleSubmit">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Create a template</p>
                        <!--<button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>-->
                    </header>
                    <section class="modal-card-body">
                        <div class="columns">
                        
                            <div class="column is-1">
                                <b-icon size="is-medium" icon="comment-alt"></b-icon>
                            </div>
                            <div class="column is-10">
                                <p>
                                Create new template to be used as a starting point for a template
                                </p>
                                <hr>
                                <b-field label="Template Name">
                                    <b-input
                                        size="is-medium"
                                        type="text"
                                        :value="form.name"
                                        v-model.trim="form.name"
                                        ref="templateName"
                                        placeholder="eg. My template"
                                        @focus="$event.target.select()"
                                        required
                                    >
                                    </b-input>
                                </b-field>
                                <br>
                            </div>
                            <div class="column is-1"></div>
                            
                        </div>
                    </section>
                    <footer class="modal-card-foot">
                        <div class="columns" style="width: 100%">
                            <div class="column is-12" style="text-align: right">
                                <button class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}">Create template</button>
                                <button class="button is-medium" type="button" @click="$parent.close()">Cancel</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </form>
        `,
    methods: {
        handleSubmit() {
            // Send data to the server or update your stores and such.

            this.submitting = true;

            const params = {
                name: this.form.name,
                is_public: true,
                message_body: 'Your message',
                message_body_generic: 'Your message'
            }

            this.$store.dispatch('createTemplate', params).then((data) => {

                if(data.success) {
                    this.$buefy.toast.open({
                        message: 'Your new template is created!',
                        type: 'is-success'
                    });

                    this.$router.push(`/templates/edit/${data.result.id}`);
                } else {

                    if(typeof data.errors.limit !== 'undefined') {
                        this.$buefy.toast.open({
                            message: 'Your account reached limit of total allowed templates for your subscription plan',
                            type: 'is-danger'
                        });
                    }
                }

                this.$parent.close();


            }).catch((err) => {
                this.$buefy.toast.open({
                    message: 'Error occurred while creating template: '+err,
                    type: 'is-danger'
                });
                // handle error
            })
            .finally(() => {
                this.submitting = false;
            });
        }
    }
}

export default CreateTemplateForm;

<template>
    <section>
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <h1 class="is-pulled-left">{{ template.name }}
                        <a v-show="template.name" href="#" @click="renameTemplate">
                            <b-icon icon="pencil-alt" size="is-small" style="margin-left:14px"></b-icon>
                        </a>
                    </h1>
                </div>
            </div>

            <!-- Right side -->
            <div class="level-right">
            </div>

        </nav>
        <hr>

        <form @submit.prevent="">

            <b-loading :active.sync="loading"></b-loading>

            <div class="columns is-mobile">
                <div class="column is-1"></div>
                <div class="column is-10">
                    <section>
                        <b-tabs v-show="!loading" type="is-centered" size="is-medium" class="block" v-model="tabIndex" expanded>
<!--                            <div v-show="!loading">-->
                                <b-tab-item>
                                    <template slot="header">
                                        <b-icon icon="pencil-alt"></b-icon>
                                        <span> Compose message </span>
                                    </template>

                                    <div class="box">
                                        <div class="has-text-centered">
                                            <b-switch v-model="template.enabled"></b-switch><b>Send welcome message on subscribe</b>
                                        </div>
                                    </div>

                                    <div v-show="template.enabled">
                                        <template-editor v-model="template"></template-editor>
                                    </div>

                                    <hr>

                                    <div class="field is-grouped is-pulled-left">
                                        <button class="button is-light is-medium" @click="$router.go(-1)">Back</button>
                                    </div>

                                    <div class="field is-grouped is-pulled-right">
                                        <button class="button is-light is-medium" type="button" @click="tabIndex = 1">Preview</button>&nbsp;
                                        <button class="button is-light is-medium" @click="sendTestMessage">Send test message</button>&nbsp;
                                        <button class="button is-primary is-medium" @click="update" type="button" v-bind:class="{'is-loading': submitting}">Save</button>
                                    </div>

                                </b-tab-item>

                                <b-tab-item>
                                    <template slot="header">
                                        <b-icon icon="dove"></b-icon>
                                        <span> Preview </span>
                                    </template>

                                    <div class="box">
                                        <div class="has-text-centered">
                                            <b>Preview</b>
                                        </div>
                                        <br>
                                        <div class="columns">
                                            <div class="column is-2"></div>
                                            <div class="column is-8">
                                                <div class="has-text-centered">
                                                    <div class="field">
                                                        <b-field>
                                                            <b-radio-button v-model="previewMode"
                                                                            native-value="PERSONALISED"
                                                                            type="is-info">
                                                                <b-icon icon="user-alt"></b-icon>
                                                                <span>Personalised</span>
                                                            </b-radio-button>

                                                            <b-radio-button v-model="previewMode"
                                                                            native-value="ANONYMOUS"
                                                                            type="is-light">
                                                                <b-icon icon="question"></b-icon>
                                                                <span>Fallback</span>
                                                            </b-radio-button>

                                                        </b-field>
                                                    </div>

                                                    <template v-if="previewMode === 'PERSONALISED'">
                                                        <message-preview :attachment="template.uploadFileContent"><span v-html="$utils.parseWAMessage(replace(this.template.message_body,true))"></span></message-preview>
                                                    </template>

                                                    <template v-else>
                                                        <message-preview :attachment="template.uploadFileContent"><span v-html="$utils.parseWAMessage(replace(this.template.message_body_generic,false))"></span></message-preview>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <hr>

                                    <div class="field is-grouped is-pulled-right">
                                        <button class="button is-light is-medium" @click="tabIndex = 0" type="button">Back</button>&nbsp;
                                        <button class="button is-light is-medium" @click="sendTestMessage">Send test message</button>&nbsp;
                                        <button class="button is-primary is-medium" @click="update" type="button" v-bind:class="{'is-loading': submitting}">Save & exit</button>
                                    </div>

                                </b-tab-item>
<!--                            </div>-->

                        </b-tabs>

                    </section>
                </div>
            </div>

            <br>

        </form>

    </section>
</template>

<script>

    import TemplateGallery from '../helpers/templateGallery';
    import TemplateEditor from './common/template-editor.vue';

    import CreateSendTestMessageForm from '../helpers/createSendTestMessageForm';

    import replacePlaceholders from '../helpers/replace';

    export default {
        components: {
            TemplateEditor
        },
        data() {
            return {
                data: {},

                template: {
                    id: this.$route.params.templateId,
                    enabled: null,
                    name: '',
                    type: null,
                    message_body: 'Hi [[FIRST_NAME]], How are you today? Check out our new website! https://bit.ly/2NdFkSi Cheers [[*SENDER_FIRST_NAME]]',
                    message_body_generic: 'Hi there, How are you today? Check out our new website! https://bit.ly/2NdFkSi Cheers [[*SENDER_FIRST_NAME]]',
                    uploadFileContent: null
                },

                previewMode: 'PERSONALISED',
                search: '',


                tabIndex: 0,

                submitting: false,
                loading: true,
            }
        },
        methods: {
            /*
             * Load async data
             */
            loadAsyncData() {

                this.loading = true;

                const params = {
                    id: this.template.id
                };

                this.$store.dispatch('fetchTemplate', params).then((data) => {

                    // update view with current campaign settings
                    let result = data.result;

                    // store locally (mapping)
                    this.template.id = result.id;
                    this.template.name = result.name;
                    this.template.enabled = result.enabled ? true : false;
                    this.template.type = result.type;
                    this.template.message_body = result.message_body;
                    this.template.message_body_generic = result.message_body_generic;

                    if(result.attachment) {

                        this.getBase64ImageFromUrl(result.attachment)
                            .then(result => {
                                this.template.uploadFileContent = result;

                            })
                            .catch(err => console.error(err));
                    } else {
                        this.template.uploadFileContent = null;
                    }

                    this.contacts_count = result.contacts_count;

                })
                .catch((error) => {

                    this.$buefy.toast.open({
                        message: 'The requested template could not be found!',
                        type: 'is-danger'
                    });

                    this.$router.push({ name: 'templates' });

                    this.data = [];
                    this.total = 0;
                    throw error
                })
                .finally(() => {
                    this.loading = false;
                });

            },

            async getBase64ImageFromUrl(imageUrl) {
                var res = await fetch(imageUrl);
                var blob = await res.blob();

                return new Promise((resolve, reject) => {
                    var reader  = new FileReader();
                    reader.addEventListener("load", function () {
                        resolve(reader.result);
                    }, false);

                    reader.onerror = () => {
                        return reject(this);
                    };
                    reader.readAsDataURL(blob);
                })
            },

            update() {

                this.submitting = true;

                const params = this.template;

                this.$store.dispatch('updateTemplate', params).then((data) => {

                // push to view
                // this.$router.push('/templates');
                    this.$router.go(-1);

                })
                .catch((err) => {

                    this.$buefy.toast.open({
                        message: 'Error saving template details: '+this.$utils.getHumanErrorMessage(err),
                        type: 'is-danger'
                    });
                })
                .finally(() => {
                    this.submitting = false;
                });

            },
            showTemplateGallery() {
                this.$buefy.modal.open({
                    parent: this,
                    component: TemplateGallery,
                    hasModalCard: true,
                    width: '900px'
                })
            },
            setTemplsetTemplateFieldsateFields(fields) {
                this.template.message_body = fields.message_body;
                this.template.message_body_generic = fields.message_body_generic;
            },
            renameTemplate() {
                this.$buefy.dialog.prompt({
                    title: 'Rename template',
                    inputAttrs: {
                        placeholder: 'e.g. Special offer template',
                        maxlength: 64,
                        value: this.template.name
                    },
                    size: 'is-medium',
                    onConfirm: (value) => {
                        this.template.name = value;
                    }
                })
            },
            previewFile(file) {

                if (file) {

                    const reader = new FileReader();

                    reader.onload = (e) => {
                        this.template.uploadFileContent = e.target.result;

                        return true;
                    };

                    reader.readAsDataURL(file);
                }

                // required to set files to null otherwise uploading another file will not work
                file = null;

            },
            setTemplateFields(fields) {
                setTimeout( () => {
                    this.template.message_body = fields.message_body;
                    this.template.message_body_generic = fields.message_body_generic;
                    this.template.uploadFileContent = fields.attachment;
                }, 300);

            },

            replace(msg, personalised) {
                return replacePlaceholders(msg, personalised);
            },

            sendTestMessage() {

                this.$buefy.modal.open({
                    parent: this,
                    component: CreateSendTestMessageForm,
                    props: {
                        message: this.template.message_body,
                        phone: this.$store.getters.userProfile.phone,
                        uploadFileContent: this.template.uploadFileContent
                    },
                    hasModalCard: true
                })
            },

        },
        computed: {
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>

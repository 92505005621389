
const TocConsentModal = {
    components: {
    },
    data() {
        return {
            tocAccepted: false,
        }
    },
    props: [
        'phone',
        'origin'
    ],
    template: `
        <div>

            <form @submit.prevent="handleSubmit">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Welcome to your Trial Account</p>
                    </header>
                    <section class="modal-card-body">
                        <template slot="header"  style="visibility: hidden">
                            <!--<b-icon icon="information-outline"></b-icon>-->
                            <!--<span> Issues <b-tag rounded> 3 </b-tag> </span>-->
                        </template>
                        <div class="columns">

                            <div class="column is-1">
                                <brand-logo custom-class=""></brand-logo>
                            </div>

                            <div class="column is-1">
                            </div>

                            <div class="column is-10">
                                <p>
                                    Please review and agree to the Terms and Privacy policy to continue.
                                </p>
                                <br>
                                <section>
                                    <div class="field">
                                        <b-checkbox v-model="tocAccepted">I agree to {{$global.product_name}}'s <a target="_blank" :href="$global.product_homepage+'/terms-and-conditions/'">Terms and privacy</a> policy.</b-checkbox>
                                    </div>
                                </section>
                            </div>
<!--                            <div class="column is-1">-->
<!--                            </div>-->
                        </div>
                        <hr>

                        <div class="columns" style="width: 100%">
                            <div class="column is-12 has-text-centered">
                                <button :disabled="! tocAccepted" type="submit" class="button is-primary is-medium" @click="done">Continue</button>
                            </div>
                        </div>

                    </section>
                    <footer class="modal-card-foot">
                    </footer>
                </div>
            </form>
        </div>
        `,
    methods: {

        done() {
            this.$emit('accepted');
            this.$parent.close();
        }
    },

    mounted() {
    }
}

export default TocConsentModal;

<template>
    <div>
        <b-input
                size="is-medium"
                :type="type"
                :value="password"
                v-model.trim="password"
                ref="password"
                style="width: 500px"
                :placeholder="placeholder"
                @input="changePassword()"
                required
        ></b-input>
        <br>
        <button type="button" class="button is-info is-medium" @click="generate">Regenerate Password</button>
    </div>

</template>

<script>

    export default {
        props : {
            type: {
                type: String,
                default: 'text'
            },
            size: {
                type: String,
                default: '32'
            },
            characters: {
                type: String,
                default: 'a-z,A-Z,0-9,#'
            },
            placeholder: {
                type: String,
                default: 'Password'
            },
            auto: [String, Boolean],
            value: ''
        },
        data: function() {
            return {
                password: this.value
            }
        },
        mounted: function() {
            if(this.auto == 'true' || this.auto == 1) {
                this.generate();
            }
        },
        methods: {

            generate: function() {
                let charactersArray = this.characters.split(',');
                let CharacterSet = '';
                let password = '';

                if( charactersArray.indexOf('a-z') >= 0) {
                    CharacterSet += 'abcdefghijklmnopqrstuvwxyz';
                }
                if( charactersArray.indexOf('A-Z') >= 0) {
                    CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                }
                if( charactersArray.indexOf('0-9') >= 0) {
                    CharacterSet += '0123456789';
                }
                if( charactersArray.indexOf('#') >= 0) {
                    CharacterSet += '![]{}()%&*$#^<>~@|';
                }

                for(let i=0; i < this.size; i++) {
                    password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
                }
                this.password = password;
            },

            changePassword: function() {
                this.$emit('input', this.$refs.password.value)
            }

        }
    }
</script>

<style scoped>

</style>
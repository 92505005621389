<template>
    <section v-show="! hideView">
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <h1 class="is-pulled-left">Edit user</h1>
                </div>
            </div>

            <!-- Right side -->
            <div class="level-right">
            </div>

        </nav>
        <hr>

        <form @submit.prevent="">

            <div class="columns is-mobile">
                <div class="column is-1"></div>
                <div class="column is-10">
                    <section>
                        <b-tabs type="is-centered" size="is-medium" class="block" expanded>
                            <b-tab-item>

                                <template slot="header">
                                    <b-icon icon="wrench"></b-icon>
                                    <span> Basic</span>
                                </template>

                                <div class="box has-text-centered">

                                    <div class="columns is-mobile">
                                        <div class="column is-2"></div>
                                        <div class="column is-8">

                                            <b-field :disabled="!form.state" label="User state">
                                                <state-tag v-model="form.state" :changeable="true"></state-tag>
                                            </b-field>
                                            <br>

                                            <b-field label="First name">
                                                <b-input size="is-medium" maxlength="64" v-model="form.first_name" required></b-input>
                                            </b-field>

                                            <b-field label="Last name">
                                                <b-input size="is-medium" maxlength="64" v-model="form.last_name" required></b-input>
                                            </b-field>

                                            <b-field label="Mobile phone number">
                                                <b-input size="is-medium" maxlength="32" v-model="form.phone" required></b-input>
                                            </b-field>

                                            <b-field label="Email">
                                                <b-input type="email" maxlength="64" size="is-large" v-model="form.email" required></b-input>
                                            </b-field>

                                            <hr>

                                            <!--<label class="label-field">-->
                                                <!--Is superadmin user-->
                                            <!--</label>-->

                                            <!--<b-switch v-model="form.role" true-value="SUPER" false-value="USER"></b-switch>-->

                                        </div>
                                        <div class="column is-2"></div>
                                    </div>

                                    <hr>

                                    <div class="has-text-centered">
                                        <button class="button is-primary is-medium" @click="update" type="button">Update user</button>
                                    </div>
                                </div>

                            </b-tab-item>

                        </b-tabs>
                    </section>
                </div>
                <div class="column is-1"></div>
            </div>

            <br>

        </form>

    </section>
</template>

<script>
    export default {
        data() {
            return {
                data: {},
                form: {
                    user_id: this.$route.params.userId,
                    first_name: '',
                    last_name: '',
                    state: '',
                    role: 'USER',
                },
                submitting: false,

                hideView: true
            }
        },
        methods: {
            /*
             * Load async data
             */
            loadAsyncData() {

                this.loading = true;

                const params = {
                    id: this.form.user_id
                };

                this.$store.dispatch('fetchUser', params).then((data) => {

                    // // update view with current campaign settings
                    const result = data.result;

                    this.form.first_name = result.first_name;
                    this.form.last_name = result.last_name;
                    this.form.phone = result.phone;
                    this.form.email = result.email;
                    this.form.state = result.state;

                    this.form.role = result.role;

                    this.hideView = false;

                })
                .catch((error) => {
                    this.data = [];
                    this.total = 0;
                    this.loading = false;
                    throw error
                });

            },

            update() {

                this.submitting = true;

                const params = {
                    id: this.form.user_id,
                    first_name: this.form.first_name,
                    last_name: this.form.last_name,
                    phone: this.form.phone,
                    email: this.form.email,
                    state: this.form.state,
                    role: this.form.role
                };

                this.$store.dispatch('updateUser', params).then((data) => {

                    // push to view
                    if(data.success) {
                        this.$router.push({ name: 'sa-accounts' });

                        this.$buefy.toast.open({
                            message: 'User details updated!',
                            type: 'is-success'
                        });
                    }

                })
                    .catch((err) => {

                        this.$buefy.toast.open({
                            message: 'Error saving user details: '+err.custom.message,
                            type: 'is-danger'
                        });
                    });
            }

        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>

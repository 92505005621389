<template>
    <section>
        <nav class="level">
            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <h1 v-if="!loading">
                        <template v-if="total">
                            Campaigns ({{ total }})
                        </template>
                        <template v-else>
                            Campaigns
                        </template>
                    </h1>
                    <h1 v-else>
                        &nbsp;
                    </h1>
                </div>
            </div>

            <!-- Right side -->
            <div class="level-right">

                <button id="tour-campaigns-create-button" class="button is-medium is-info is-pulled-right is-clearfix"
                        @click="createCampaignModal()" variant="primary">
                    <b-icon icon="plus"></b-icon><span>Create campaign</span>
                </button>

            </div>

        </nav>

        <hr>

        <!--- FILTERS -->

        <b-dropdown class="is-fullwidth-mobile">
            <button class="button is-light is-medium is-fullwidth-mobile" v-bind:class="{ 'is-primary': filterState.length > 0 }" slot="trigger">
                <span>Filter: State</span>
                <b-icon icon="caret-down"></b-icon>
            </button>

            <b-dropdown-item custom paddingless>

                <b-checkbox v-model="filterState" native-value="FINISHED" @input="applyFilter">
                    Finished
                </b-checkbox>
                <b-checkbox v-model="filterState" native-value="DRAFT" @input="applyFilter">
                    Draft
                </b-checkbox>
                <b-checkbox v-model="filterState" native-value="SCHEDULED" @input="applyFilter">
                    Scheduled
                </b-checkbox>
                <b-checkbox v-model="filterState" native-value="IN_PROGRESS" @input="applyFilter">
                    In Progress
                </b-checkbox>
                <b-checkbox v-model="filterState" native-value="FAILED" @input="applyFilter">
                    Failed
                </b-checkbox>
                <b-checkbox v-model="filterState" native-value="ARCHIVED" @input="applyFilter">
                    Archived
                </b-checkbox>

            </b-dropdown-item>

        </b-dropdown>

        <!--- BULK ACTION -->

        <b-dropdown :disabled="checkedRows.length === 0" class="is-hidden-mobile">
            <button class="button is-light is-medium" slot="trigger">
                <span>Bulk action</span>
                <b-icon icon="caret-down"></b-icon>
            </button>

            <b-dropdown-item value="delete" @click="doBulkAction('delete')">
                Delete
            </b-dropdown-item>

        </b-dropdown>

        <b-field class="search-input-field">
            <b-input v-model="search" type="search" v-bind:class="{ 'has-value': search}" icon="search" ref="search" @input="searchFilter" placeholder="Search campaign..." size="is-medium"></b-input>
        </b-field>

        <hr id="tour-campaigns-overview">

        <!--- TABLE -->

        <!--<b-select class="is-pulled-right" size="is-medium" v-model="perPage">-->
            <!--<option value="10">10 per page</option>-->
            <!--<option value="20">20 per page</option>-->
            <!--<option value="50">50 per page</option>-->
            <!--<option value="100">100 per page</option>-->
        <!--</b-select>-->

        <b-table
                :data="data"
                :loading="loading"

                paginated
                backend-pagination
                :total="total"
                :per-page="perPage"
                @page-change="onPageChange"

                :row-class="setRowClass"

                detailed
                detail-key="id"

                backend-sorting
                :default-sort-direction="defaultSortOrder"
                :default-sort="[sortField, sortOrder]"

                :is-row-checkable="(row) => row.state !== 'IN_PROGRESS'"
                :checked-rows.sync="checkedRows"
                checkable

                @sort="onSort">

            <template slot-scope="props">

                <b-table-column field="warning" label="" width="40" sortable numeric>
                    <span v-if="(props.row.state !== 'FINISHED' && props.row.state !== 'ARCHIVED' && props.row.state !== 'FAILED') && ! props.row.list_id">
                        <b-tooltip label="No contact list has been selected for this campaign."
                                   position="is-right" type="is-warning" :multilined="true">
                            <b-icon icon="exclamation-triangle" position="is-top" type="is-warning"></b-icon>
                        </b-tooltip>
                    </span>

                    <span v-else-if="(props.row.state !== 'FINISHED' && props.row.state !== 'ARCHIVED' && props.row.state !== 'FAILED') && props.row.channel && ! props.row.channel.enabled">
                        <b-tooltip :label="`The channel ${props.row.channel.name} (${props.row.channel.origin}) of the selected contact list is disabled. It is required to enable channel first before this campaign can be sent`"
                                   position="is-right" type="is-black" :multilined="true">
                            <b-icon icon="exclamation-triangle" position="is-top" type="is-black"></b-icon>
                        </b-tooltip>
                    </span>

                    <span v-else-if="(props.row.state !== 'FINISHED' && props.row.state !== 'ARCHIVED' && props.row.state !== 'FAILED') && props.row.channel && ! props.row.channel.is_online">
                        <b-tooltip :label="`The channel ${props.row.channel.name} (${props.row.channel.origin}) of the selected contact list is currently unavailable.`"
                                   position="is-right" type="is-danger" :multilined="true">
                            <b-icon icon="exclamation-triangle" position="is-top" type="is-danger"></b-icon>
                        </b-tooltip>
                    </span>

                </b-table-column>

                <!--<b-table-column field="id" label="ID" width="100" sortable numeric>-->
                    <!--<span v-if="props.row.state === 'DRAFT' || props.row.state === 'SCHEDULED'">-->
                        <!--<router-link :to="{ name: 'campaign-edit', params: {campaignId: props.row.id, tabIndex: 0} }">CA-{{ props.row.id }}</router-link>-->
                    <!--</span>-->
                    <!--<span v-else>-->
                        <!--CA-{{ props.row.id }}-->
                    <!--</span>-->
                <!--</b-table-column>-->

                <b-table-column field="origin" label="Type" width="20" sortable>
                    <b-tooltip v-if="props.row.type === 'WHATSAPP'" label="WhatsApp">
                        <b-icon pack="fab" icon="whatsapp"></b-icon>
                    </b-tooltip>

                    <b-tooltip v-if="props.row.type === 'SMS'" label="SMS">
                        <b-icon icon="sms"></b-icon>
                    </b-tooltip>
                </b-table-column>

                <b-table-column field="name" label="Campaign" sortable>
                    <span v-if="props.row.state === 'DRAFT' || props.row.state === 'SCHEDULED'">
                        <router-link :to="{ name: 'campaign-edit', params: {campaignId: props.row.id, tabIndex: 0} }">{{ props.row.name }}</router-link>
                    </span>
                    <span v-else>
                        {{ props.row.name }}
                    </span>
                    <attachment-icon v-if="props.row.template.attachment" :attachment-type="$utils.getMimeTypeOfUrl(props.row.template.attachment)"></attachment-icon>

                    <template v-if="Object.keys(props.row.contact_list).length > 0">
                        <br>

                        <router-link :to="{ name: 'campaign-edit', params: {campaignId: props.row.id, tabIndex: 1} }">
                            <b-tag type="is-light" rounded>{{ props.row.contact_list.name }} ({{ props.row.contacts_count }})</b-tag>
                            <!--<b-tag size="is-large" type="is-light">-->
<!--                            <span class="text-dark">{{ props.row.contact_list.name }} ({{ props.row.contacts_count }})</span>-->
                            <!--</b-tag>-->
                        </router-link>
                    </template>

                    <template v-if="props.row.state === 'DRAFT' && Object.keys(props.row.contact_list).length === 0">
                        <br>

                        <router-link :to="{ name: 'campaign-edit', params: {campaignId: props.row.id, tabIndex: 1} }">
                            <b-tag size="is-small" type="is-warning">Select contact list...</b-tag>
                        </router-link>
                    </template>

                    <template v-if="props.row.state === 'FINISHED' || props.row.state === 'ABORTED'">

                        <b-field style="margin-top: 20px; margin-bottom: 10px" grouped>

                            <div class="control">
                                <b-taglist attached>
                                    <b-tooltip type="is-white" label="Sent">
                                        <b-tag size="is-small" class="tag-metric" type="is-grey"><b-icon class="grey" icon="check"></b-icon></b-tag>
                                        <b-tag size="is-small" class="tag-metric" type="is-warning">{{ props.row.msg_sent || 0 }}</b-tag>
                                    </b-tooltip>
                                </b-taglist>
                            </div>

                            <div class="control" v-if="props.row.msg_delivered > 0">
                                <b-taglist attached>
                                    <b-tooltip type="is-white" label="Delivered">
                                        <b-tag size="is-small" class="tag-metric" type="is-grey"><b-icon class="grey" icon="check"></b-icon><b-icon class="grey-darker" style="margin-left:-20px" icon="check"></b-icon></b-tag>
                                        <b-tag size="is-small" class="tag-metric" type="is-success">{{ props.row.msg_delivered || 0 }}</b-tag>
                                    </b-tooltip>
                                </b-taglist>
                            </div>

                            <div class="control" v-if="props.row.msg_read > 0">
                                <b-taglist attached>
                                    <b-tooltip type="is-white" label="Read">
                                        <b-tag size="is-small" class="tag-metric" type="is-grey"><b-icon class="blue" icon="check"></b-icon><b-icon class="blue-darker" style="margin-left:-20px" icon="check"></b-icon></b-tag>
                                        <b-tag size="is-small" class="tag-metric" type="is-primary">{{ props.row.msg_read || 0 }}</b-tag>
                                    </b-tooltip>
                                </b-taglist>
                            </div>

                            <div v-if="props.row.state === 'ABORTED' && props.row.msg_queued > 0" class="control">
                                <b-taglist attached>
                                    <b-tooltip type="is-white" label="Not sent">
                                        <b-tag size="is-small" class="tag-metric" type="is-grey"><b-icon class="grey" icon="times"></b-icon></b-tag>
                                        <b-tag size="is-small" class="tag-metric" type="is-danger">{{ props.row.msg_queued || 0 }}</b-tag>
                                    </b-tooltip>
                                </b-taglist>
                            </div>

                            <!--                                <div class="control" v-if="props.row.msg_sent_ignored && props.row.msg_sent_ignored > 0">-->
                            <!--                                    <b-taglist attached>-->
                            <!--                                        <b-tooltip type="is-white" label="Ignored">-->
                            <!--                                            <b-tag size="is-small" class="tag-metric" type="is-grey"><b-icon class="grey" icon="times"></b-icon></b-tag>-->
                            <!--                                            <b-tag size="is-small" class="tag-metric" type="is-dark">{{ props.row.msg_sent_ignored || 0 }}</b-tag>-->
                            <!--                                        </b-tooltip>-->
                            <!--                                    </b-taglist>-->
                            <!--                                </div>-->
                        </b-field>

                        <!--                                <template v-if="props.row.msg_sent_ignored && props.row.msg_sent_ignored > 0">&nbsp;({{ props.row.msg_sent_ignored }} ignored)</template>-->
                    </template>

                </b-table-column>


                <!--<b-table-column field="scheduled" label=" ">-->
                    <!--<template v-if="props.row.scheduled_at">-->
                        <!--<b-tag size="is-large" type="is-white">-->
                            <!--<b-icon style="color: #bbb;" icon="clock"></b-icon>-->
                        <!--</b-tag>-->
                    <!--</template>-->
                <!--</b-table-column>-->

                <b-table-column field="updated_at" label="Updated / Scheduled" width="200" sortable>
                    <template v-if="props.row.state === 'SCHEDULED'">
                        <b-icon icon="clock" type="is-grey"></b-icon>
                        <b-tooltip type="is-light" :label="props.row.scheduled_at | moment('timezone', $global.current_tz) | moment('dddd, MMMM Do YYYY, h:mm:ss a')">
                            <b>{{  props.row.scheduled_at | moment('timezone', $global.current_tz) | moment("from", "now") }}</b>
                        </b-tooltip>
                    </template>
                    <template v-else-if="props.row.state === 'FINISHED'">
                        <b-icon icon="paper-plane" size="is-small" type="is-grey"></b-icon>
                        <b-tooltip style="display: inline" type="is-light" :label="props.row.finished_at | moment('timezone', $global.current_tz) | moment('dddd, MMMM Do YYYY, h:mm:ss a')">
                            {{  props.row.finished_at | moment('timezone', $global.current_tz) | moment("from", "now") || 'unknown' }}
                        </b-tooltip>
                    </template>
                    <template v-else>
                        <b-tooltip type="is-light" :label="props.row.updated_at | moment('timezone', $global.current_tz) | moment('dddd, MMMM Do YYYY, h:mm:ss a')">
                            {{props.row.updated_at | moment('timezone', $global.current_tz) | moment("from", "now") }}
                        </b-tooltip>
                    </template>
                    <br>
<!--                    <span class="text-lightgrey" v-if="props.row.last_updated_by_user">-->
<!--                        by {{ props.row.last_updated_by_user.first_name }} {{ props.row.last_updated_by_user.last_name }}-->
<!--                    </span>-->
                </b-table-column>

                <b-table-column field="state" label="State" width="60" sortable centered>
                    <template v-if="props.row.state === 'IN_PROGRESS'">
                        <!--<b-tooltip size="is-large" label="Campaign is currently being processed, hang on!">-->
                        <b-tag size="is-large" type="is-info"><div style="display:inline-block; padding-right: 4px;" class="loader"></div> In Progress ({{ props.row.msg_sent || 0 }}/{{ props.row.msg_total }})</b-tag>
                        <!--</b-tooltip>-->
                    </template>

                    <template v-if="props.row.state === 'FINISHED'">
                        <!--<b-tooltip size="is-large" label="Campaign has been sent">-->
                        <b-tag size="is-large" type="is-primary" class="campaign-state">Finished</b-tag>
                        <!--</b-tooltip>-->
                    </template>

                    <template v-else-if="props.row.state === 'SCHEDULED'">
                        <b-tag size="is-large" type="is-warning" class="campaign-state">Scheduled</b-tag>
                        <!--                        <div>-->
                        <!--                            <sub v-if="props.row.sub_state === 'channel_is_offline'" class="error-color">Scheduled campaign could not start! (whatsapp channel not online)</sub>-->
                        <!--                        </div>-->

                    </template>

                    <template v-else-if="props.row.state === 'DRAFT'">
                        <!--<b-tooltip size="is-large" label="Campaign is saved as draft">-->
                        <b-tag size="is-large" type="is-info" class="campaign-state">Draft</b-tag>
                        <!--</b-tooltip>-->
                    </template>

                    <template v-else-if="props.row.state === 'PAUSED'">
                        <!--<b-tooltip size="is-large" label="Campaign is paused during processing">-->
                        <b-tag size="is-large" type="is-grey" class="campaign-state">Paused</b-tag>
                        <!--</b-tooltip>-->
                    </template>

                    <template v-else-if="props.row.state === 'ABORTED'">
                        <!--<b-tooltip size="is-large" label="Campaign is aborted">-->
                        <b-tag size="is-large" type="is-danger" class="campaign-state">Canceled</b-tag>
                        <!--</b-tooltip>-->
                    </template>

                    <template v-else-if="props.row.state === 'FAILED'">
                        <b-tooltip size="is-large"type="is-danger"  :label="'Reason: '+props.row.sub_state">
                            <b-tag size="is-large" type="is-danger" class="campaign-state">Failed</b-tag>
                        </b-tooltip>
                    </template>

                    <template v-else-if="props.row.state === 'ARCHIVED'">
                        <!--<b-tooltip size="is-large" label="Campaign processing failed or couldn't start">-->
                        <b-tag size="is-large" type="is-error" class="campaign-state">Archived</b-tag>
                        <!--</b-tooltip>-->
                    </template>

                </b-table-column>

                <b-table-column field="action" label="Actions" width="200" centered>

                    <template v-if="props.row.state === 'DRAFT' && Object.keys(props.row.contact_list).length > 0">
                        <router-link v-if="Object.keys(props.row.contact_list).length > 0" :to="{ name: 'campaign-edit', params: {campaignId: props.row.id, tabIndex: 2} }" title="Start campaign" class="action-button">
                            <b-icon icon="paper-plane"></b-icon>
                        </router-link>
                    </template>

                    <template v-if="props.row.state === 'DRAFT' || props.row.state === 'SCHEDULED' || props.row.state === 'FAILED'">
                        <router-link :to="{ name: 'campaign-edit', params: {campaignId: props.row.id} }" class="action-button"><b-icon icon="edit"></b-icon></router-link>
                    </template>

                    <template v-else>
                        <b-icon icon="edit" class="action-button disabled-text invisible"></b-icon>
                    </template>

                    <a v-if="props.row.state === 'IN_PROGRESS'" href="#" @click="cancelCampaign(props.row.id)" class="action-button" title="Cancel campaign">
                        <b-icon icon="times"></b-icon>
                    </a>

                    <a href="#" @click="duplicateCampaign(props.row.id)" class="action-button" title="Duplicate campaign">
                        <b-icon icon="copy"></b-icon>
                    </a>
                    <a v-if="props.row.state === 'FINISHED' || props.row.state === 'ABORTED' " href="#" @click="archiveCampaign(props.row.id)" class="action-button" title="Archive campaign">
                        <b-icon icon="archive"></b-icon>
                    </a>
                    <a v-if="props.row.state !== 'FINISHED' && props.row.state !== 'IN_PROGRESS'" href="#" @click="deleteCampaign(props.row.id)" class="action-button" title="Delete campaign">
                        <b-icon icon="trash-alt"></b-icon>
                    </a>
                </b-table-column>

            </template>

            <!--- TABLE EMPTY -->

            <template slot="empty">
                <transition name="fade">
                    <section class="section" v-show="! loading">
                        <div class="content has-text-grey has-text-centered">
                            <p class="is-large"><b>No campaign found.</b></p>
                            <p>Get in touch with your contacts and <a href="#" @click="createCampaignModal()"><b>start a campaign</b></a> now.</p>
                        </div>
                    </section>
                </transition>
            </template>

            <!--- TABLE ROW DETAILS -->

            <template slot="detail" slot-scope="props">
                <article class="media">
                    <figure class="media-left">
                        <!--<p class="image is-64x64">-->
                            <!--<img src="https://buefy.github.io/static/img/placeholder-128x128.png">-->
                        <!--</p>-->
                    </figure>
                    <div class="media-content">
                        <div class="content">
                            <div style="padding-top:2px">
                                <small v-if="props.row.finished_at"><b>{{ props.row.type === 'WHATSAPP' ? 'WhatsApp' : 'SMS' }} Campaign finished at {{  props.row.finished_at }}</b></small>
                                <small v-else-if="props.row.state === 'IN_PROGRESS'">
                                    <b>{{ props.row.type === 'WHATSAPP' ? 'WhatsApp' : 'SMS' }} Campaign is currently in progress.</b>
                                </small>
                                <small v-else-if="props.row.state === 'DRAFT'">
                                    <b>{{ props.row.type === 'WHATSAPP' ? 'WhatsApp' : 'SMS' }} Campaign is currently in draft mode</b>
                                </small>
                                <small v-else-if="props.row.state === 'ABORTED'">
                                    <b>{{ props.row.type === 'WHATSAPP' ? 'WhatsApp' : 'SMS' }} Campaign has been canceled</b>
                                </small>

                                <p style="margin-top:16px">

                                    <span v-if="props.row.state === 'FINISHED' || props.row.state === 'SCHEDULED' || props.row.state === 'IN_PROGRESS' || props.row.state === 'ABORTED'">

                                        <b-field grouped group-multiline>

                                            <div class="control">
                                                <b-taglist attached>
                                                    <b-tooltip type="is-info" label="Shows how many campaign messages have been sent out to recipients" :multilined="true">
                                                        <b-tag size="is-medium" class="tag-metric" type="is-grey">Sent</b-tag>
                                                        <b-tag size="is-medium" class="tag-metric" type="is-warning">{{ props.row.msg_sent || 0 }}</b-tag>
                                                    </b-tooltip>
                                                </b-taglist>
                                            </div>

                                            <div v-if="props.row.msg_delivered > 0" class="control">
                                                <b-taglist attached>
                                                    <b-tooltip type="is-info" :label="getLabel('delivered')" :multilined="true">
                                                        <b-tag size="is-medium" class="tag-metric" type="is-grey">Delivered <sub>(in beta)</sub> <b-icon size="is-small" icon="info-circle" style="margin-left:4px; opacity: 0.3"></b-icon></b-tag>
                                                        <b-tag size="is-medium" class="tag-metric" type="is-success">{{ props.row.msg_delivered || 0 }}</b-tag>
                                                    </b-tooltip>
                                                </b-taglist>
                                            </div>

                                            <div v-if="props.row.msg_read > 0" class="control">
                                                <b-taglist attached>
                                                    <b-tooltip type="is-info" :label="getLabel('read')" :multilined="true">
                                                        <b-tag size="is-medium" class="tag-metric" type="is-grey">Read <sub>(in beta)</sub> <b-icon size="is-small" icon="info-circle" style="margin-left:4px; opacity: 0.3"></b-icon></b-tag>
                                                        <b-tag size="is-medium" class="tag-metric" type="is-primary">{{ props.row.msg_read || 0 }}</b-tag>
                                                    </b-tooltip>
                                                </b-taglist>
                                            </div>

                                            <div class="control" v-if="props.row.state === 'IN_PROGRESS'">
                                                <b-taglist attached>
                                                    <b-tooltip type="is-info" label="Shows how many messages are queued for sending" :multilined="true">
                                                        <b-tag size="is-medium" class="tag-metric" type="is-grey">Queued</b-tag>
                                                        <b-tag size="is-medium" class="tag-metric" type="is-info">{{ props.row.msg_queued || 0 }}</b-tag>
                                                    </b-tooltip>
                                                </b-taglist>
                                            </div>

                                            <div class="control" v-if="props.row.msg_sent_ignored">
                                                <b-taglist attached>
                                                    <b-tooltip type="is-info" label="Displays how many messages have been sent and have been ignored (contact not marked as opt in)" :multilined="true">
                                                        <b-tag size="is-medium" class="tag-metric" type="is-grey">Sent ignored</b-tag>
                                                        <b-tag size="is-medium" class="tag-metric" type="is-dark">{{ props.row.msg_sent_ignored || 0 }}</b-tag>
                                                    </b-tooltip>
                                                </b-taglist>
                                            </div>

                                            <div class="control" v-if="props.row.state === 'SCHEDULED'">
                                                <b-taglist attached>
                                                    <b-tooltip type="is-info" label="Shows how many messages are scheduled" :multilined="true">
                                                        <b-tag size="is-medium" class="tag-metric" type="is-grey">Scheduled</b-tag>
                                                        <b-tag size="is-medium" class="tag-metric" type="is-warning">{{ props.row.msg_scheduled || 0 }}</b-tag>
                                                    </b-tooltip>
                                                </b-taglist>
                                            </div>

                                            <div class="control" v-if="props.row.state === 'FAILED'">
                                                <b-taglist attached>
                                                    <b-tooltip type="is-info" label="Shows how many messages could not be sent" :multilined="true">
                                                        <b-tag size="is-medium" class="tag-metric" type="is-grey">Failed</b-tag>
                                                        <b-tag size="is-medium" class="tag-metric" type="is-danger">{{ props.row.msg_failed || 0 }}</b-tag>
                                                    </b-tooltip>
                                                </b-taglist>
                                            </div>
                                        </b-field>
                                    </span>
                                </p>

                            </div>
                            <hr>
                            <div>
                                <div class="columns">
                                    <div class="column is-half">
                                        <router-link :to="{ name: 'campaign-edit', params: {campaignId: props.row.id, tabIndex: 1} }">
                                            <message-preview :attachment="props.row.template.attachment || null"><span v-html="$utils.parseWAMessage(replace(props.row.template.message_body,true))"></span></message-preview>
                                        </router-link>
                                    </div>
                                </div>

                                <template v-if="props.row.channel || false">
                                    <channel-display-tag :channel="props.row.channel" :showName="true"></channel-display-tag>
                                </template>
                            </div>
                        </div>
                    </div>
                </article>
            </template>

        </b-table>
    </section>
</template>

<script>

    // import CampaignEditView from './CampaignEditView';
    import CreateCampaignForm from '../helpers/forms';
    import replacePlaceholders from '../helpers/replace';

    export default {
        data() {
            return {
                data: [],
                checkedRows: [],
                total: 0,
                loading: true,
                sortField: 'updated_at',
                sortOrder: 'desc',
                defaultSortOrder: 'asc',
                page: 1,
                perPage: 10,
                filterState: [],
                search: '',
                globalTimeout: null,
                refreshTimeoutHandler: null,

                freshDataInterval: 29 * 1000,
                silentRefreshData: false
            }
        },
        computed: {
        },
        methods: {
            getLabel(txt) {
                switch(txt) {
                    case 'read':
                        return 'Shows how many campaign messages have been read by the recipients. Note: Due to technical limitations, some messages might be read by user, but not be included.';
                    case 'delivered':
                        return 'Shows how many campaign messages have been delivered to the recipient\'s device. Note: Due to technical limitations, some messages might be delivered to user, but not be included.';
                }
            },
            delay(fn, waitDelay) {
                if (this.globalTimeout != null) {
                    clearTimeout(this.globalTimeout);
                }
                this.globalTimeout = setTimeout(function() {
                    this.globalTimeout = null;

                    fn();

                }, waitDelay);
            },
            refreshData() {

                this.refreshTimeoutHandler = setTimeout(() => {
                    this.silentRefreshData = true;

                    if(this.$store.state.view.name === 'campaigns') {
                        // disabled since it will load recursively on every view load (3,4,5 requests stacking!)
                        this.loadAsyncData();
                        this.refreshData();
                    } else {
                        this.refreshTimeoutHandler = null;
                    }
                }, this.freshDataInterval);

            },

            replace(msg, personalised) {
                return replacePlaceholders(msg, personalised);
            },
            searchFilter() {

                var that = this;
                this.delay(function() { that.loadAsyncData() }, this.$global.search_delay);
            },
            createCampaignModal() {
                this.$buefy.modal.open({
                    parent: this,
                    component: CreateCampaignForm,
                    hasModalCard: true
                })
            },
            doBulkAction(action) {

                let campaign_ids = [];
                for(let i=0; i<this.checkedRows.length; i++) {
                    campaign_ids.push(this.checkedRows[i].id);
                }

                if(action === 'delete') {
                    this.deleteCampaigns(campaign_ids);
                }
            },
            /*
             * Load async data
             */
            loadAsyncData() {

                this.loading = ! this.silentRefreshData;

                const params = [
                    `orderBy=${this.sortField}`,
                    `sort=${this.sortOrder}`,
                    `page=${this.page}`,
                    `state=${this.filterState}`,
                    `search=${this.search}`
                ].join('&');

                this.$store.dispatch('fetchCampaigns', params).then((data) => {

                    this.silentRefreshData = false;

                    let result = data.result;

                    this.data = [];
                    let currentTotal = result.total;
                    if (result.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000
                    }

                    this.total = currentTotal;

                    result.data.forEach((item) => {

                        // check for empty values, create one if relation is missing
                        if(! item.contact_list) {
                            item.contact_list = {}
                        }

                        if(! item.template) {
                            item.template = {}
                        }

                        this.data.push(item)
                    })
                    this.loading = false
                })
                    .catch((error) => {
                        this.data = [];
                        this.total = 0;
                        this.loading = false;
                        throw error
                    });

            },

            /*
             * Handle remove a contact list
            */
            startCampaign(id) {

                const params = {
                    id: id
                };
// alert(id);
                this.$root.$emit('component1');
            },

            /*
             * Duplicate a campaign
            */
            duplicateCampaign(id) {

                this.$buefy.dialog.confirm({
                    title: 'Duplicate',
                    message: 'Are you sure you want to <b>duplicate</b> this campaign?',
                    confirmText: 'Duplicate',
                    type: 'is-info',
                    hasIcon: true,
                    onConfirm: () => {

                        const params = {
                            id: id
                        };

                        this.$store.dispatch('duplicateCampaign', params).then((data) => {
                            let result = data.result;
                            this.loadAsyncData();
                        })
                            .catch((error) => {
                                throw error
                            });
                    }
                })
            },

            /*
             * Cancel a campaign
            */
            cancelCampaign(id) {

                this.$buefy.dialog.confirm({
                    title: 'Cancel',
                    message: 'Are you sure you want to <b>cancel</b> this campaign? This action cannot be undone.',
                    confirmText: 'ABORT',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {

                        const params = {
                            id: id
                        };

                        this.$store.dispatch('cancelCampaign', params).then((data) => {
                            let result = data.result;
                            this.loadAsyncData();
                        })
                            .catch((error) => {
                                throw error
                            });
                    }
                })
            },

            /*
             * Archive a campaign
            */
            archiveCampaign(id) {

                const params = {
                    id: id
                };

                this.$buefy.dialog.confirm({
                    title: 'Archive',
                    message: 'Are you sure you want to <b>archive</b> this campaign?',
                    confirmText: 'Archive campaign',
                    type: 'is-primary',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('archiveCampaign', params).then((data) => {

                            let result = data.result;

                            this.loadAsyncData();
                        })
                            .catch((error) => {
                                throw error
                            });
                    }
                })
            },

            /*
             * Remove a campaign
            */
            deleteCampaign(id) {

                const params = {
                    id: id
                };

                this.$buefy.dialog.confirm({
                    title: 'Delete',
                    message: 'Are you sure you want to <b>delete</b> this campaign? This action cannot be undone.',
                    confirmText: 'Delete campaign',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('deleteCampaign', params).then((data) => {

                            let result = data.result;

                            this.loadAsyncData();
                        })
                            .catch((error) => {
                                throw error
                            });
                    }
                })
            },

            /*
             * Remove a campaign
            */
            deleteCampaigns(ids) {

                const params = {
                    ids: ids
                };

                this.$buefy.dialog.confirm({
                    title: 'Delete',
                    message: 'Are you sure you want to <b>delete</b> these campaigns? This action cannot be undone.',
                    confirmText: 'Delete campaigns',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('deleteCampaigns', params).then((data) => {

                            this.checkedRows = [];
                            let result = data.result;

                            this.loadAsyncData();

                        })
                        .catch((error) => {
                            throw error
                        });
                    },
                    onCancel: () => {
                    }
                })
            },

            /*
             * Handle page-change event
             */
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            /*
             * Handle sort event
             */
            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
            },
            /*
             * Type style in relation to the value
             */
            type(value) {
                const number = parseFloat(value)
                if (number < 6) {
                    return 'is-danger'
                } else if (number >= 6 && number < 8) {
                    return 'is-warning'
                } else if (number >= 8) {
                    return 'is-success'
                }
            },
            setRowClass(row, index) {
                switch(row.state) {
                    case 'FINISHED':
                        return 'finished';
                    case 'DRAFT':
                        return 'draft';
                    case 'SCHEDULED':
                        return 'scheduled';
                    default:
                        return '';
                }
            },
            applyFilter() {
                this.loadAsyncData();
                this.$refs.search.focus();
            }
        },
        filters: {
            /**
             * Filter to truncate string, accepts a length parameter
             */
            truncate(value, length) {
                return value.length > length
                    ? value.substr(0, length) + '...'
                    : value
            }
        },

        mounted() {
            this.loadAsyncData();
            this.refreshData();

            this.$refs.search.focus();
        }
    }
</script>

<template>
    <div class="login-view">
        <transition name="fade">
            <div v-if="show" class="columns" style="margin-top:36px">
                <div class="column is-half is-offset-one-quarter">
                    <div class="box login">
                        <!--<img src="/storage/images/logo_login.png">-->
                        <div class="is-fullwidth has-text-centered">
                            <a :href="$global.product_homepage" target="_blank">
                                <brand-logo custom-class="pname-login-logo"></brand-logo>
                            </a>
                        </div>

                        <h1 class="is-hidden-mobile">{{ $global.product_name }} Login</h1>
                        <br>
                        <form v-on:submit.prevent="login({ email, password })">
                            <b-field label="Username or Email" message="Please fill in your username or email address" type="is-info" required>
                                <b-input :disabled="submitting" v-model="email" size="is-medium" ref="email" type="email" icon="envelope" :value="email" required></b-input>
                            </b-field>

                            <b-field label="Password">
                                <b-input :disabled="submitting" v-model="password" size="is-medium" ref="password" type="password" icon="key" value="" password-reveal required>
                                </b-input>
                            </b-field>

                            <div class="has-text-centered" style="margin-top:40px">
                                <button :disabled="submitting" class="button is-info is-medium" v-bind:class="{'is-loading': submitting}" type="submit">Log in</button>
                            </div>
                        </form>
                    </div>

                    <hr>

                    <sub class="small has-text-grey is-pulled-left" style="margin-top:2px">
                        <router-link :to="{ name: 'forgot-password', query: { email: this.email } }">Forgot password
                        </router-link>
                    </sub>
                    <sub class="small has-text-grey is-pulled-right" style="margin-top:2px">
                        <p>
                            <span class="small has-text-grey is-pulled-right" style="margin-top:2px">No {{ $global.product_name }} account yet?</span>
                        </p>

                        <router-link :to="{ name: 'signup', query: { email: this.email } }" style="font-weight: 600">
                            Sign up now!
                        </router-link>
                    </sub>
                    <!--<div class="clearfix"></div>-->
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'LoginView',
        data() {
            return {
                email: this.$route.query.email || this.$route.params.email,
                password: this.$route.query.password || this.$route.params.password,
                params: this.$route.params,
                submitting: false,
                show: false
            }
        },
        methods: {
            login() {

                this.submitting = true;

                this.$store.dispatch("login", {
                    email: this.email,
                    password: this.password
                }).then((data) => {
                    this.submitting = false;

                    // redirect user to account overview if user has role superadmin

                  this.$store.dispatch('getUserProfile', {}).then(() => {

                    if(this.params.redir_path) {

                      // push notification
                      const toast = {
                        message: `Redirecting to page...`,
                        type: 'is-info',
                        duration: 1500
                      };
                      this.$buefy.toast.open(toast);


                      this.$router.push({ path: this.params.redir_path, query: this.params.redir_query });
                    } else {

                      if(data.result.role === 'SUPER') {

                        this.$router.push({ name: 'sa-accounts' });

                      } else {
                        this.$router.push({ name: 'dashboard' });
                      }
                    }

                  }).catch((err) => {
                    console.log(err);
                  });




                }).catch((err) => {

                    this.password = '';

                    let message = 'Unknown error';

                    switch(err.response.status) {
                        case 401:
                            message = 'Sorry, the password is incorrect or we couldn\'t find an account with username "' + this.email + '"';
                            break;
                        case 403:
                            switch(err.response.data.data.error) {
                                case 'account_expired':
                                    message = 'This account has expired. Please contact your sales representative.';

                                    break;
                                default:

                                    message = 'This account or user account has been disabled.';
                                    break;
                            }
                            break
                    }

                    this.submitting = false;
                    this.$buefy.toast.open({
                        message: message,
                        type: 'is-danger',
                        duration: 2900
                    });

                    this.$refs.password.focus();
                });
            }
        },
        mounted() {
            this.show = true;

            const el = document.body.parentElement;
            el.classList.remove('register-view');

            if(this.$route.params.auto_login) {
                this.login();
            } else {

                setTimeout(() => {
                    if (this.email) {
                        this.$refs.password.focus();
                    } else {
                        this.$refs.email.focus();
                    }

                }, 400);

            }

        }
    }
</script>

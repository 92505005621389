<template>
    <section>
        <h1>Send test message<br><b-tag type="is-danger" size="is-large">superadmin only</b-tag></h1>
        <hr>

        <!--<b-loading :active.sync="loading"></b-loading>-->

        <transition name="fade">
            <div v-if="!loading">

                    <div class="column is-half is-offset-one-quarter">

                        <div class="box">
                            <b-field label="Message body">
                                <b-input type="textarea"
                                         minlength="1"
                                         maxlength="1000"
                                         size="is-medium"
                                         rows="10"
                                         v-model="message_body"
                                         ref="contentField"
                                         placeholder=""
                                        autofocus>
                                </b-input>
                            </b-field>

                            <emoji-selector style="margin-top:-10px" picker-style="margin-top:-200px; margin-left: 30px" @click="addEmoji"></emoji-selector>

                            <br>

                            <b-field label="Recipient phone">
                                <b-input type="text"
                                         minlength="7"
                                         maxlength="32"
                                         size="is-medium"
                                         rows="10"
                                         v-model="phone"
                                         placeholder="Phone Number (e.g. 31612345678)"
                                         >
                                </b-input>
                            </b-field>

                            <div class="label-field">Channel origin</div>
                            <channel-selector v-model="channel_id"></channel-selector>

                            <hr>

                            <button class="button is-primary" :disabled="! channel_id || message_body.trim().length < 1 || phone.trim().length < 8" @click="add">Send message</button>
                        </div>
                    </div>
            </div>
        </transition>
    </section>
</template>

<script>

    import EmojiSelector from './common/emoji-selector.vue';

    export default {
        components: {
            EmojiSelector
        },
        name: 'SendSingleMessage',

        data() {
          return {
              message_body: '',
              phone: '',
              origin: '',
              channel_id: null,
              search: null,
              loading: true
          }
        },
        mounted() {
            this.loading = false;
            // this.$refs.message.focus();
        },
        methods: {
            setOrigin(obj) {
                this.origin = obj.origin;
                this.channel_id = obj.id;
            },
            addEmoji(emoji) {
                this.message_body += emoji;
                this.$refs.contentField.focus();
            },
            add() {

                // this.$buefy.toast.open({
                //     message: 'Sending message...',
                //     type: 'is-warning'
                // });

                const params = { body: this.message_body, phone: this.phone, channel_id: this.channel_id };

                this.$store.dispatch('sendTestMessage', params).then((data) => {
                    // console.log(data);

                    this.$buefy.toast.open({
                        message: 'Message successfully sent!',
                        type: 'is-success'
                    });

                    // this.flash({message: 'hi', variant: 'success'});
                }).catch((err) => {
                    this.$buefy.toast.open({
                        message: 'Error occurred while sending message: '+this.$utils.getHumanErrorMessage(err),
                        type: 'is-danger'
                    });
                    // handle error
                });

            }
        }
    }
</script>

<template>
<!--    <div v-if="daystoExpiration <= 1000">-->
    <div class="container">
        <div class="navbar-brand navbar-start">
            <a class="navbar-item" href="#">
                <b-icon icon="exclamation-triangle" style="margin-right: 12px"></b-icon>
                You currently have only&nbsp;<b>{{ value.sms_credits_left }} SMS {{ value.sms_credits_left | pluralize('credit') }} left</b>&nbsp;into your account.
                <button class="button is-light is-small" style="margin-left: 20px" @click="requestUpgradeSMSLimitModal"><b>Request SMS upgrade</b></button>
            </a>
        </div>
    </div>
</template>

<script>

  import RequestUpgradeSMSLimitModal from "../../helpers/requestUpgradeSMSLimitModal";

  export default {
    components: [
      RequestUpgradeSMSLimitModal
    ],
        props: {
            value: null
        },
        data: function () {
            return {
            }
        },
        computed: {
        },
        methods: {
          requestUpgradeSMSLimitModal() {

            this.$buefy.modal.open({
              parent: this,
              component: RequestUpgradeSMSLimitModal,
              hasModalCard: true
            })
          }
        },

        watch: {
            // whenever question changes, this function will run
            // value: function (newValue, oldValue) {
            //     this.update(newValue);
            //
            // }
        },
        mounted() {
            // this.update(this.value);
        }
    }
</script>

<style scoped>

</style>

<template>
    <div>

        <a>
        <svg class="emoji-picker" @click="togglePicker = !togglePicker" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0m0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10"></path><path d="M8 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 8 7M16 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 7M15.232 15c-.693 1.195-1.87 2-3.349 2-1.477 0-2.655-.805-3.347-2H15m3-2H6a6 6 0 1 0 12 0"></path></svg>
        </a>
        <picker style="position:absolute; z-index: 9999" v-show="togglePicker" title="Pick your emoji…" emoji="point_up" @select="addEmoji" />
    </div>
</template>

<script>

    import { Picker, Emoji } from 'emoji-mart-vue';

    export default {
        components: {
            Picker, Emoji
        },
        props: {
            pickerStyle: {
                type: String,
                default: ''
            }
        },
        data: function () {
            return {
                togglePicker: false

            }
        },
        computed: {
        },
        methods: {
            addEmoji(emoji) {
                this.togglePicker = false;
                this.$emit('click',emoji.native);
            },
        },
        mounted() {
            // this.$refs.search.focus();
        }
    }
</script>

<style scoped>

</style>
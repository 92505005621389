<template>
<!--    <div v-if="daystoExpiration <= 1000">-->
    <div class="container">
        <div class="navbar-brand navbar-start">
            <a class="navbar-item" href="#">
                <b-icon icon="exclamation-triangle" style="margin-right: 12px"></b-icon>
                <template v-if=" value.wa_credits_left > 0">
                    You currently have only&nbsp;<b>{{ value.wa_credits_left }} WhatsApp {{ value.wa_credits_left | pluralize('credit') }} left</b>&nbsp;into your account.
                </template>
                <template v-else>
                    You currently have&nbsp;<b>no WhatsApp {{ value.wa_credits_left | pluralize('credit') }} left</b>&nbsp;into your account.
                </template>
<!--                <button class="button is-light is-small" style="margin-left: 20px" @click="requestUpgradeSMSLimitModal"><b>Request SMS upgrade</b></button>-->
            </a>
        </div>
    </div>
</template>

<script>

  export default {
    components: [
    ],
        props: {
            value: null
        },
        data: function () {
            return {
            }
        },
        computed: {
        },
        methods: {

        },

        watch: {
            // whenever question changes, this function will run
            // value: function (newValue, oldValue) {
            //     this.update(newValue);
            //
            // }
        },
        mounted() {
            // this.update(this.value);
        }
    }
</script>

<style scoped>

</style>

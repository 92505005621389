const RegisterSuccessModal = {
    components: {
    },
    data() {
        return {
            mobileActivationCode: '',
            submitting: false,
        }
    },
    props: [
        'email', 'accountId'
    ],
    template: `
        <div>
               
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Registration complete</p>
                        <!--<button class="delete" aria-label="close" role="button" type="button" @click="cancel"></button>-->
                    </header>
                    <section class="modal-card-body">
                       
                        <h1 class="has-text-centered" style="text-transform: none; color: #333 !important;">Thanks for signing up 👋</h1>
                        <h1 class="has-text-centered" style="text-transform: none; font-size: 19px !important;">We've sent you an SMS message.</h1>
                        
                        <h2 class="has-text-centered">
                            To activate your account, enter the mobile activation code below sent to you by SMS:
                            <br><br>
                            <div class="has-text-centered">
                                <b-field grouped class="is-inline-flex">
                                    <b-input placeholder="Enter activation code" size="is-large" v-model="mobileActivationCode" ref="activation_code"></b-input>
                                    <p class="control">
                                        <button @click="submitMobileActivationCode" class="button is-primary" v-bind:class="{'is-loading': submitting}">Activate</button>
                                    </p>
                                </b-field>
                            </div>
                            <br>
                            Alternatively, your account can be activated by clicking the activation link in the email that has been sent to <strong>{{ email }}</strong>.<br><br>
                        </h2>
                    </section>
                    <footer class="modal-card-foot">
                    
                    </footer>
                </div>
        </div>
        `,
    methods: {
        cancel() {
            // this.$emit('start-tour');
            // this.$store.dispatch('finishOnboarding', {}).then((data) => { });

            this.$parent.close();
        },
        submitMobileActivationCode() {

            this.submitting = true;

            const params = {
                account_id: this.accountId,
                mobile_activation_code: this.mobileActivationCode
            };

            this.$store.dispatch('activateAccount', params).then((data) => {
                if(data.success) {

                    this.$parent.close();

                    this.$buefy.toast.open({
                        message: 'Your account is almost ready. Set your password to log in for the first time...',
                        type: 'is-success'
                    });

                    this.$router.push({ name: 'login' });

                    // hard redirect to password reset
                    setTimeout(() => {
                        location.href='/password/reset/'+data.result.token+'?email='+data.result.email;
                    },1100);
                } else { }

                this.show = true;
            })
                .catch((error) => {
                    const data = error.response.data.data;

                    // console.log(error.response.data);
                    this.$buefy.toast.open({
                        message: `${data.error}.<br>For questions, contact us using the chat widget below.`,
                        type: 'is-danger'
                    });

                    setTimeout(() => {
                        location.href='/password/reset/'+data.result.token+'?email='+data.result.email;
                    },1000);

                    throw error
                })
                .finally(() => {
                    this.submitting = false;
                });

        }

    },
    mounted() {
        this.$refs.activation_code.focus();
    }
}

export default RegisterSuccessModal;

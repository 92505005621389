<template>
    <div>
        <b-tag size="is-large" class="state" :type="tagType"> {{ label }}</b-tag>
    </div>
</template>

<script>
    export default {
        props: {
            value: { type: String }
        },
        data: function () {
            return {
                label: '',
                tagType: 'is-info',
            }
        },
        methods: {
            setState() {

                let state = this.value;

                switch(state) {
                    case 'USER':

                        this.tagType = 'is-default';
                        this.label = 'Regular User';
                        break;
                    case 'SUPER':

                        this.tagType = 'is-warning';
                        this.label = 'Super Admin';
                        break;
                }

                if(this.value)
                    this.$emit('input', this.value);
            }
        },
        watch: {
            value() {
                this.setState();
            }
        },
        mounted() {
            this.setState();
        }
    }
</script>

<style scoped>

</style>
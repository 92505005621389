const api = {
    admin: {
        plan:                           '/api/v1/admin/plan',
        billingUsageRates:              '/api/v1/admin/billing-usage-rates',

        billingInfo:                    '/api/v1/admin/billing-info',
        billingOrder:                   '/api/v1/admin/billing-order',
        billingDetails:                 '/api/v1/admin/billing-details',
        billingAddress:                 '/api/v1/admin/billing-address',
        billingSubscription:            '/api/v1/admin/billing-subscription',

        billingSaveUpgradeSettings:     '/api/v1/admin/billing-save-upgrade-settings',
        billingCouponCode:              '/api/v1/admin/billing-coupon-code',

        billingOrderExport:             '/billing/invoice/export',


    },
    activate:                           '/api/v1/activate',
    billing:                            '/api/v1/billing',
    campaign:                           '/api/v1/campaign',
    channel:                            '/api/v1/channel',
    contact:                            '/api/v1/contact',
    contactList:                        '/api/v1/contact-list',
    dashboard:                          '/api/v1/dashboard',
    event:                              '/api/v1/event',
    eventLog:                           '/api/v1/event-log',
    login:                              '/api/v1/login',
    message:                            '/api/v1/message',

    register:                           '/api/v1/register',
    forgotPassword:                     '/api/v1/sendResetLinkEmail',
    resetPassword:                      '/api/v1/resetPassword',
    refreshToken:                       '/api/v1/refreshToken',
    stats:                              '/api/v1/stats',
    template:                           '/api/v1/template',
    user:                               '/api/v1/user',

    exportContacts:                     '/contacts/export',

    sa: {
        account:                        '/api/v1/account',
        system:                         '/api/v1/system',
        createPaymentFromInvoiceId:     '/api/v1/admin/billing-create-payment-from-invoice-id',
    }
}

export default api;

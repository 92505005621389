
const UpdateBillingSubscriptionForm = {
    data() {
        return {
            data: [],
            form: {
                // phone: '',
                // channel_id: null,
                // origin: null,
            },
            submitting: false,
        }
    },
    mounted() {
        // this.$refs.phone.focus();
        // this.form.channel_id = this.channel_id;
        //
        // this.form.phone = this.phone;
    },
    props: [
        'account_id', 'description','amount','foreign_subscription_id'
    ],
    template: `
        <div>
            <form @submit.prevent="">
            
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Change subscription</p>
                        <button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>
                    </header>
                    <section class="modal-card-body">
                   
                       <hr>  
                          
                        <form @submit.prevent="handleSubmit">                
                            <b-field label="Description">
                                <b-input
                                    size="is-medium"
                                    type="text"
                                    :value="description"
                                    v-model.trim="description"
                                    ref="description"
                                    style="width: 400px"
                                    required
                                >
                                </b-input>
                            </b-field>
                            
                            <b-field label="Amount">
                                <b-input
                                    size="is-medium"
                                    type="text"
                                    :value="amount"
                                    v-model.trim="amount"
                                    ref="description"
                                    style="width: 400px"
                                    required
                                >
                                </b-input>
                            </b-field>
                        </form>

                    </section>
                    
                    
                    
                    <footer class="modal-card-foot">
                    
                        <div class="columns" style="width: 100%">
                            <div class="column is-12" style="text-align: right">
                               <button class="button is-primary is-medium" :disabled="! description" @click="handleSubmit" v-bind:class="{'is-loading': submitting}">Update subscription</button>
                                <button class="button is-medium" type="button" @click="$parent.close()">Cancel</button>
                            </div>
                        </div>
                    
                    </footer>
                </div>
            </form>
        </div>
        `,
    methods: {

        handleSubmit() {
            // Send data to the server or update your stores and such.

            this.submitting = true;

            const params = {
                cus_account_id: this.account_id, // account_id name would be overridden so use cus_account_id
                description: this.description,
                amount: this.amount,
                foreign_subscription_id: this.foreign_subscription_id
            };
            console.log(params);

            this.$store.dispatch('updateSubscription', params).then((data) => {
                // console.log(data);

                this.$parent.close();

                if(data.success) {
                    this.$buefy.toast.open({
                        message: 'Message successfully sent!',
                        type: 'is-success'
                    });
                } else {
                    this.$buefy.toast.open({
                        message: 'Error occurred while sending message',
                        type: 'is-danger'
                    });
                }

            }).catch((err) => {
                this.$buefy.toast.open({
                    message: 'Error occurred while sending message: '+this.$utils.getHumanErrorMessage(err),
                    type: 'is-danger'
                });

                // handle error
            }).finally(() => {
                this.submitting = false;

            });

        }
    }
}

export default UpdateBillingSubscriptionForm;

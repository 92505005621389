<template>
    <section>

        <transition name="fade">
            <h1 id="tour-dashboard"><span id="tour-welcome" v-if="$store.getters.userProfile">Welcome {{ $store.getters.userProfile.first_name }}!</span></h1>
        </transition>
        <br>

        <!--<b-loading :active.sync="loading" :is-full-page="false"></b-loading>-->

        <transition name="fade">
            <div class="tile is-ancestor" v-if="!loading">
                <div class="tile is-vertical is-6">
                    <div class="tile">
                        <div class="tile is-parent is-vertical">
                            <article class="tile is-child notification is-light">
                                <p class="title">Dashboard Actions
                                </p>
                                <br>
                                <p class="subtitle">
                                    <button id="tour-dashboard-create-contact-list" class="button is-medium is-info"
                                        @click="createContactListModal()">
                                        <b-icon icon="list-ol" style="margin-right:10px"></b-icon>
                                    Create contact list
                                    </button>

                                    <button id="tour-dashboard-create-campaign" class="button is-medium is-info"
                                        @click="createCampaignModal()">
                                        <b-icon icon="bullhorn" style="margin-right:10px"></b-icon>

                                        Create campaign
                                    </button>
                                </p>
                                <br>
                            </article>
                        </div>
                    </div>
                </div>

                <div class="tile is-parent">
                    <article class="tile is-child notification is-success">
                        <div class="content">
                            <p class="title">{{ stats.campaigns.sent }}</p>
                            <p class="subtitle">{{ stats.campaigns.sent | pluralize('campaign') }} sent</p>
                            <div class="content">
                                <!-- Content -->
                            </div>
                        </div>
                    </article>
                </div>

                <div class="tile is-parent">
                    <article class="tile is-child notification is-info">
                        <div class="content">
                            <p class="title">{{ stats.campaigns.in_draft }}</p>
                            <p class="subtitle">Draft {{ stats.campaigns.in_draft | pluralize('campaign') }}</p>
                            <div class="content">
                                <!-- Content -->
                            </div>
                        </div>
                    </article>
                </div>

                <div class="tile is-parent">
                    <article class="tile is-child notification is-warning">
                        <div class="content">
                            <p class="title">{{ stats.campaigns.scheduled }}</p>
                            <p class="subtitle">{{ stats.campaigns.scheduled | pluralize('campaign') }} scheduled</p>
                            <div class="content">
                                <!-- Content -->
                            </div>
                        </div>
                    </article>
                </div>
            </div>

        </transition>

        <div class="tile is-ancestor" v-if="!loading">
            <subscribe-panel></subscribe-panel>
        </div>

    </section>
</template>

<script>

    import CreateCampaignForm from '../helpers/forms';
    import CreateContactListForm from '../helpers/createContactListForm';

    import SubscribePanel from './common/subscribe-panel';

    import { isMobile } from 'mobile-device-detect';

    export default {
        components: {
          SubscribePanel, isMobile
        },
        data: function() {
            return {
                stats : {
                    campaigns: {},
                    messages: {}
                },
                isMobile: isMobile,
                loading: true
            }
        },
        methods: {
            /*
            * Load async data
            */
            loadAsyncData() {

                const params = {};

                this.$store.dispatch('fetchStats', params).then((data) => {

                    // update view with current campaign settings
                    let result = data.result;
                    this.stats.campaigns = result.campaigns;
                    this.stats.messages = result.messages;

                })
                .catch((error) => {
                    throw error
                })
                .finally(() => {
                    this.loading = false;
                });

            },
            createCampaignModal() {
                this.$buefy.modal.open({
                    parent: this,
                    component: CreateCampaignForm,
                    hasModalCard: true

                })
            },
            createContactListModal() {
                this.$buefy.modal.open({
                    parent: this,
                    component: CreateContactListForm,
                    hasModalCard: true

                })
            }

        },

        mounted() {
            this.loadAsyncData();

            if(this.isMobile) {
                this.$buefy.snackbar.open({
                    message: this.$global.product_name + ' is currently not fully functional on mobile devices. Use a desktop or laptop to get the best experience possible.',
                    position: 'is-top-left',
                    type: 'is-danger',
                    duration: 100000,
                } )
            }
        }
    }
</script>

const CreateContactSubscribeCodeForm = {
    data: function () {
        return {
            data: {},

            tabIndex: 0,
            qrcode_image_url: null,
            destination_url: null,

            loading: false,
            submitting: false,

            invite_text: this.inviteText || '',
            timeOutHandler: null,

            linkType: 'text-link',
            submitLabelText: 'Create text link'
        }
    },
    props: [
        'accountUuid', 'listId', 'listUuid', 'listName', 'inviteText'
    ],
    template: `
                <div class="modal-card" style="width: auto">
                                
                    <header class="modal-card-head">
                        <p class="modal-card-title">Create subscribe link for list: "{{ listName }}"</p>
                        <button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>
                    </header>
                    
                    
                    <section class="modal-card-body" v-show="! loading">
                   <b-loading :active.sync="loading"></b-loading>

                   <div class="columns">
                        <div class="column is-12">

                            <b-tabs class="hide-tab-items" type="is-centered" v-model="tabIndex" @change="switchTab" :animated="false" expanded>
    
                                <b-tab-item label="Start">
                                    <p>
                                        Create a text link or QR code to allow your audience to easily subscribe to your contact lists.
                                    </p>
                                    <br>
                                
                                    <label class="label-field">
                                        Invite confirmation message
                                    </label>
        
                                    <b-field>
                                        <b-input rows="3" size="is-medium" maxlength="250" ref="invite_text" v-model="invite_text" type="textarea"></b-input>
                                    </b-field>

                                    <b-dropdown class="is-pulled-right">
                                        <button class="button is-light" slot="trigger">
                                            <span>Insert merge tag</span>
                                            <b-icon icon="caret-down"></b-icon>
                                        </button>
    
                                        <b-dropdown-item @click="invite_text += ' [[*INVITATION]]'">Invitation code (*)</b-dropdown-item>
                                        <b-dropdown-item @click="invite_text += ' [[*LIST_NAME]]'">List name</b-dropdown-item>
                                    </b-dropdown>

                                    <label class="label-field">Link type</label>
                                    
                                    <div class="field">
                                        <b-radio v-model="linkType" native-value="text-link">
                                            Text link <span class="text-lightgrey">(https://)</span>
                                        </b-radio>
                                    </div>
                                        
                                    <div class="field">
                                        <b-radio v-model="linkType" native-value="qr-code">
                                            QR code <span class="text-lightgrey">(image)</span>
                                        </b-radio>
                                    </div>
                                    
                                </b-tab-item>
                                
                                <b-tab-item label="Generate">

                                    <div v-show="linkType === 'qr-code'" class="has-text-centered">
                                        <p>
                                            <b>Scan the following QR code with a mobile phone:</b>
                                        </p>
                                        <img v-if="qrcode_image_url" style="height:400px; margin: 30px 0" :src="qrcode_image_url" :onload="loading = false">
                                    </div>
                                    
                                    <!--<div class="has-text-centered">-->
                                        <!--<button class="button is-primary is-medium" v-clipboard:copy="qrcode_image_url" @click="$dialog.alert({ title: 'Info', message: 'Successfully copied Qr code url to clipboard.',confirmText: 'OK'})" type="button">Copy url to clipboard</button>-->
                                    <!--</div>-->
                                   
                                   <div v-show="linkType === 'qr-code'">
                                   
                                       <label class="label-field">
                                            QR code image url 
                                            <b-tooltip multilined size="is-medium" type="is-light" label="This url will show the qr code image which can be used to share on social media or to print out">
                                                <b-icon icon="info-circle" size="is-small" type="is-info"></b-icon>
                                            </b-tooltip>
                                        </label v-if="linkType='qr-code'">
                                        
                                        <b-field grouped>
                                            <b-input
                                                size="is-medium"
                                                type="text"
                                                v-model="qrcode_image_url" disabled expanded>
                                            </b-input>
                                            <p class="control">
                                                <button class="button is-primary" v-clipboard:copy="qrcode_image_url" @click="$dialog.alert({ title: 'Info', message: 'Successfully copied Qr code url to clipboard.',confirmText: 'OK'})">Copy to clipboard</button>
                                            </p>
                                        </b-field>
                                   </div>
                                   
                                   
                                   <label class="label-field">
                                        Destination url (mobile link)
                                        <b-tooltip multilined size="is-medium" type="is-light" label="Use this url to let users directly open Whatsapp on their mobile device with the invitation code pre-filled in">
                                            <b-icon icon="info-circle" size="is-small" type="is-info"></b-icon>
                                        </b-tooltip>
                                    </label>
                                    
                                    <b-field grouped>
                                        <b-input
                                            size="is-medium"
                                            type="text"
                                            v-model="destination_url" disabled expanded>
                                        </b-input>
                                        <p class="control">
                                            <button class="button is-primary" v-clipboard:copy="destination_url" @click="$dialog.alert({ title: 'Info', message: 'Successfully copied destination url to clipboard.',confirmText: 'OK'})">Copy to clipboard</button>
                                        </p>
                                    </b-field>
                                    
                                </b-tab-item>
                            </b-tabs>
                        </div>
                        <!--<div class="column is-1">-->
                                  <!---->
                        <!--</div>-->
                </div>
            </section>
                    <footer class="modal-card-foot">
                        
                        <div class="columns" style="width: 100%">
                            <div class="column is-12">
                                <div class="field is-grouped is-pulled-left">
                                    <button v-if="tabIndex > 0" class="button is-medium" @click="tabIndex=0">Back</button>
                                </div>
                                
                                <div class="field is-grouped is-pulled-right">
                                    <button v-if="tabIndex === 0" class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}" @click="generate">{{ submitLabelText }}</button>
                                    <button v-if="tabIndex === 0" class="button is-medium" type="button" @click="$parent.close()">Cancel</button>
                                    <button v-if="tabIndex === 1" class="button is-medium" type="button" @click="$parent.close()">Done</button>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>

        `,
    watch: {
        linkType: function (val) {
            // check first time login, show onboarding modal
            if(val === 'text-link') {
                this.submitLabelText = 'Create text link';
            } else if(val === 'qr-code') {
                this.submitLabelText = 'Create QR code';
            }
        }
    },
    methods: {

        switchTab(idx) {
            if(idx === 1) {
                this.generate();
            }
        },
        generate() {

            this.qrcode_image_url = null;

            // INVITATION CODE is required, so check for that
            if(this.invite_text.indexOf('[[*INVITATION]]') === -1) {

                this.$buefy.toast.open({
                    message: 'Make sure to include the invitation tag [[*INVITATION]] to your invitation message.',
                    type: 'is-danger',
                    duration: 4000
                });

            } else {
                this.loading = true;
                this.tabIndex = 1;

                this.destination_url = this.$utils.getCurrentUri() + '/invite/list/' + this.accountUuid + '/' + this.listUuid + '?text=' + encodeURIComponent(this.invite_text);
                this.qrcode_image_url = this.$utils.getCurrentUri() + '/qr-code/list/subscribe/' + this.accountUuid + '/' + this.listUuid + '?text=' + encodeURIComponent(this.invite_text);
            }
        },
        loadAsyncData() {
        }

    },
    mounted() {
        this.loadAsyncData();
        this.$refs.invite_text.focus();
    }
}

export default CreateContactSubscribeCodeForm;

<template>
    <section>
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <h1 v-bind:class="{'is-invisible': loading}">
<!--                        <b-icon icon="address-book" size="is-large" style="padding-right:20px"></b-icon>-->
                        {{ name }} ({{ total }})
                    </h1>
                </div>
            </div>

            <!-- Right side -->
            <div class="level-right">
            </div>

        </nav>
        <hr>

        <b-dropdown size="is-medium">
            <button class="button is-light is-medium" slot="trigger">
                <span>{{ perPage }} per page</span>
                <b-icon icon="caret-down"></b-icon>
            </button>

            <b-dropdown-item value="10" @click="onLimitChange(10)">10</b-dropdown-item>
            <b-dropdown-item value="25" @click="onLimitChange(25)">25</b-dropdown-item>
            <b-dropdown-item value="50" @click="onLimitChange(50)">50</b-dropdown-item>
        </b-dropdown>

        <hr>

        <!--- TABLE -->

        <b-pagination
                v-show="data > 0"
                :total="total"
                :current.sync="page"
                order="is-right"
                :per-page="perPage"
                @change="onPageChange">
        </b-pagination>


        <b-table
                v-show="data"

                :data="data"
                :loading="loading"

                paginated
                backend-pagination
                :total="total"
                :per-page="perPage"
                @page-change="onPageChange"

                detailed
                detail-key="id"

                backend-sorting
                :default-sort-direction="defaultSortOrder"
                :default-sort="[sortField, sortOrder]"
                @sort="onSort">

            <template slot-scope="props">

                <b-table-column field="key" label="Api">
                    {{ props.row.key }}<br>
                    <b-tag size="is-medium" type="is-grey" rounded>{{ props.row.api_url }}</b-tag>
                </b-table-column>

                <b-table-column field="key" label="Status">
                    <b-field>
                        <div style="display: inline-block" class="control">
                            <b-taglist attached>
                                <b-tag size="is-medium" type="is-white">http</b-tag>
                                <b-tag v-if="props.row.api_http_status === 200" size="is-medium" type="is-primary"><b-tooltip type="is-dark" label="Success">{{ props.row.api_http_status }}</b-tooltip></b-tag>
                                <b-tag v-else-if="props.row.api_http_status > 200 && props.row.api_http_status < 500" size="is-medium" type="is-warning"><b-tooltip type="is-dark" label="Client Error">{{ props.row.api_http_status }}</b-tooltip></b-tag>
                                <b-tag v-else type="is-danger" size="is-medium"><b-tooltip type="is-dark" label="Server Error">{{ props.row.api_http_status }}</b-tooltip></b-tag>
                            </b-taglist>
                        </div>
                    </b-field>
                </b-table-column>

                <b-table-column field="key" label="Requested at">
                    <b-tooltip type="is-light" :label="props.row.created_at | moment('timezone', $global.current_tz) | moment('dddd, MMMM Do YYYY, h:mm:ss a')">
                        {{ props.row.created_at | moment('timezone', $global.current_tz) | moment("from", "now") }}
                    </b-tooltip>
                </b-table-column>

            </template>

            <template slot="empty">
                <transition name="fade">
                    <section class="section" v-show="!loading">
                        <div class="content has-text-grey has-text-centered">
                            <p>
                            </p>
                            <p><b>No event log found.</b></p>
                        </div>
                    </section>
                </transition>
            </template>

            <!-- details -->

            <template slot="detail" slot-scope="props">
                <div class="container is-fluid">
                        <div class="columns">
                            <div class="column is-6">
                                <h5>Request</h5>
                                <vue-json-pretty
                                        :data="JSON.parse(props.row.api_request)"
                                >
                                </vue-json-pretty>
                            </div>
                            <div class="column is-6">
                                <h5>Response</h5>
                                <vue-json-pretty
                                        :data="JSON.parse(props.row.api_response)"
                                >
                                </vue-json-pretty>
                            </div>
                        </div>
                </div>

            </template>

        </b-table>
    </section>
</template>

<script>

    import VueJsonPretty from 'vue-json-pretty';

    export default {
        components: {
            VueJsonPretty
        },
        data() {
            return {
                data: [],
                checkedRows: [],
                listId: this.$route.params.listId,
                name: 'Api Event logs',
                total: null,
                loading: true,
                sortField: 'created_at',
                sortOrder: 'desc',
                defaultSortOrder: 'asc',
                page: 1,
                perPage: 25,
                search: '',
                globalTimeout: null,

                preventReload: false
            }
        },

        methods: {

            onLimitChange(val) {

                this.perPage = val;
                this.loadAsyncData();
            },
            /*
             * Load async data
             */
            loadAsyncData() {

                this.checkedRows = [];
                this.loading = true;

                // Fetch contacts

                let params = [
                    `orderBy=${this.sortField}`,
                    `perPage=${this.perPage}`,
                    `sort=${this.sortOrder}`,
                    `page=${this.page}`,
                    `type=API`,
                    `search=${this.search}`
                ];

                this.$store.dispatch('fetchEventLogs', params.join('&')).then((data) => {

                    let result = data.result;

                    this.data = [];
                    let currentTotal = result.total;
                    if (result.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000
                    }

                    this.total = currentTotal;

                    result.data.forEach((item) => {
                        this.data.push(item)
                    });

                    this.loading = false;
                })
                .catch((error) => {

                    this.$buefy.toast.open({
                        message: this.$utils.getHumanErrorMessage(error),
                        type: 'is-danger'
                    });

                    this.data = [];
                    this.total = 0;
                    this.loading = false;
                    throw error
                });

            },
            /*
             * Handle page-change event
             */
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            /*
             * Handle sort event
             */
            onSort(field, order) {
                // if(field === 'opt_state') {
                //     this.sortField = 'contactentity.opt_state'
                // } else {
                    this.sortField = field
                // }

                this.sortOrder = order
                this.loadAsyncData()
            },
        },
        filters: {
            /**
             * Filter to truncate string, accepts a length parameter
             */
            truncate(value, length) {
                return value.length > length
                    ? value.substr(0, length) + '...'
                    : value
            }
        },
        mounted() {
            this.loadAsyncData();
        }
    }
</script>

<template>

    <div>
        <!--<p>Billing table</p>-->

        <!-- Right side -->
        <div v-if="$store.getters.impersonateUserId" class="level-right" style="margin-bottom:20px">

            <button class="button is-medium is-warning is-pulled-right is-clearfix"
                    @click="createManualOrderFormModal" variant="primary">
                <b-icon icon="plus"></b-icon><span>Create manual order (superadmin)</span>
            </button>

        </div>

        <b-table
                :data="data"
                :loading="loading"

                paginated
                backend-pagination
                :total="total"
                :per-page="perPage"
                @page-change="onPageChange"

                backend-sorting
                :default-sort-direction="defaultSortOrder"
                :default-sort="[sortField, sortOrder]"

                @sort="onSort">

            <template slot-scope="props">
                <!--<b-table-column field="id" label="ID" width="120" sortable numeric>-->
                <!--<router-link :to="{ name: 'template-edit', params: {templateId: props.row.id} }">-->
                <!--TM-{{ props.row.id }}-->
                <!--</router-link>-->
                <!--</b-table-column>-->

                <b-table-column field="date" label="Date" width="120">
                    {{ props.row.created_at | moment('timezone', $global.current_tz) | moment('MMM DD YYYY') }}
                </b-table-column>
                <b-table-column field="description" label="Description" width="340">
                    {{ props.row.description }}
                </b-table-column>
                <b-table-column field="name" label="Amount" width="120">
                    <span v-if="props.row.is_refund">-</span>
                    {{ props.row.charge_amount | currency('€' ) }}
                </b-table-column>
                <b-table-column field="name" label="Status" width="100" centered>
                    <b-tag v-if="! props.row.is_refund" type="is-large" v-bind:class="{ 'is-success': props.row.status === 'PAID', 'is-warning': props.row.status === 'PENDING', 'is-danger': props.row.status === 'FAILED' }">{{ props.row.status | capitalize }}</b-tag>
                    <b-tag v-else type="is-large" v-bind:class="{ 'is-light': props.row.status === 'REFUNDED', 'is-warning': props.row.status === 'REFUND_OPEN', 'is-danger': props.row.status === 'REFUND_FAILED' }">
                        {{ props.row.status | capitalize }}
                    </b-tag>
                    <template v-if="props.row.method === 'CREDITCARD' && props.row.method_details">
                        <p style="margin-top:10px">
                            <b-icon icon="credit-card"></b-icon>
                            <sub>
                                <!--{{ props.row.method_details.cardLabel }}-->
                                **** *** **** {{ props.row.method_details.cardNumber }}
                            </sub>
                        </p>

                    </template>
                    <template v-else-if="props.row.method === 'PAYPAL'">
                        <p style="margin-top:10px">
                            <sub>
                                <b>Paypal</b><br>
                                ref: {{ props.row.method_details.paypalReference }}
                                <!--{{ props.row.method_details.cardLabel }}-->

                            </sub>
                        </p>

                    </template>
                    <template v-else>
                        <p style="margin-top:10px">
                            <sub>
                                <b>{{ props.row.method | capitalize }}</b><br>
                            </sub>
                        </p>

                    </template>
                </b-table-column>
                <b-table-column field="actions" label="Actions" width="250" numeric>
<!--                    <button v-if="props.row.status === 'OPEN'" href="#" class="button is-info" @click="openCheckout(props.row.checkout_url)">Pay now</button>-->
                    <button v-if="props.row.status === 'OPEN' || props.row.status === 'FAILED' || props.row.status === 'EXPIRED' || props.row.status === 'CANCELED'" href="#" class="button is-info" @click="makePayment(props.row.id)">Make payment</button>

                    <a v-if="$store.getters.impersonateUserId" :href="$utils.getForeignPaymentUrl(props.row.foreign_payment_id)" target="mollie" class="action-button">
                        <b-icon icon="wallet"></b-icon>
                    </a>

                    <a v-if="$store.getters.impersonateUserId" :href="$utils.getForeignInvoiceUrl(props.row.foreign_sales_invoice_id)" target="moneybird" class="action-button">
                        <b-icon icon="dove"></b-icon>
                    </a>

                    <a href="#" v-if="$store.getters.impersonateUserId" class="action-button" @click="deleteBillingOrder(props.row.id)">
                        <b-icon icon="trash-alt"></b-icon>
                    </a>

                    <a v-if="props.row.foreign_sales_invoice_id && (props.row.status === 'PAID' || props.row.status === 'OPEN')" class="button is-light" @click="downloadPdf(props.row.id)">download PDF</a>
<!--                    <a v-if="props.row.foreign_sales_invoice_id && (props.row.status === 'PAID' || props.row.status === 'OPEN')" class="button is-light" :href="downloadPdfLink(props.row.foreign_sales_invoice_id)">download PDF</a>-->
                </b-table-column>
            </template>

            <template slot="empty">
                <transition name="fade">
                    <section class="section" v-show="! loading">
                        <div class="content has-text-grey has-text-centered">
                            <p>
                            </p>
                            <p class="is-large"><b>No billing history found.</b></p>
                        </div>
                    </section>
                </transition>
            </template>

        </b-table>
    </div>

</template>

<script>

    import CreateManualOrderForm from '../../helpers/createManualOrderForm';

    export default {
        components: {
            CreateManualOrderForm
        },
        props: {
        },
        data: function () {
            return {
                data: [],
                checkedRows: [],
                total: 0,
                loading: false,
                sortField: 'date',
                sortOrder: 'desc',
                defaultSortOrder: 'asc',
                page: 1,
                perPage: 100,
            }
        },

        methods: {
            downloadPdfLink(id) {
                return `/admin/billing/invoice/${this.$store.state.account.uuid}/${id}.pdf`;
            },
            downloadPdf(id) {

                let params = {
                    id: id
                };

                this.$store.dispatch('downloadInvoice', params).then((data) => {

                    this.$buefy.toast.open({
                        message: 'Downloading file completed!',
                        type: 'is-info',
                        duration: 4000
                    });

                }).catch((error) => {
                    this.data = [];
                    this.total = 0;
                    this.loading = false;
                    throw error
                });

            },

            createManualOrderFormModal() {

                var self = this;

                this.$buefy.modal.open({
                    parent: this,
                    component: CreateManualOrderForm,
                    hasModalCard: true,
                    events: {
                        success: function() {
                            self.loadAsyncData();
                        },
                        failed: function() {
                        }

                    }
                })
            },

            deleteBillingOrder(id) {

                this.$buefy.dialog.confirm({
                    title: 'Delete',
                    message: 'Are you sure you want to <b>delete</b> this order? This action cannot be undone.',
                    confirmText: 'Delete billing order',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                      this.$store.dispatch('deleteBillingOrder', { id: id } ).then(() => {
                        this.loadAsyncData();
                      })
                        .catch((error) => {
                          throw error
                        });
                    }
                  });
            },

            createManualOrder() {

                // this.createManualOrderFormModal()

                this.$buefy.dialog.prompt({
                    title: 'Create payment from (moneybird) invoice id',
                    message: `<b>Note. make sure to set and "save upgrade settings" on user's checkout page first before continuing!</b><br><br>Enter Moneybird Sales invoice id. This id can be found in Moneybird on invoice detail page`,
                    inputAttrs: {
                        placeholder: 'e.g. 249558490686687125',
                        maxlength: 32
                    },
                    onConfirm: (value) => {

                        this.loading = true;

                        const params = {
                            id: value
                        };

                        this.$store.dispatch('createPaymentFromInvoiceId', params).then((data) => {

                            this.$buefy.toast.open({
                                message: 'Order payment created',
                                type: 'is-success'
                            });
                            this.loadAsyncData();

                        })
                        .catch((err) => {

                            this.$buefy.toast.open({
                                message: 'Error occurred while saving campaign: '+this.$utils.getHumanErrorMessage(err),
                                type: 'is-danger'
                            });
                            // throw error
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                    }
                })
            },

            loadAsyncData() {

                this.loading = true;

                const params = {
                    page: this.page
                };

                this.$store.dispatch('fetchbillingOrder', params).then((data) => {

                    let result = data.result;

                    this.data = [];
                    let currentTotal = result.total;
                    if (result.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000
                    }

                    this.total = currentTotal;

                    // transform data and push to table array
                    result.data.forEach((item) => {
                        item.method_details = JSON.parse(item.method_details) || null;

                        this.data.push(item)
                    })
                    this.loading = false
                    this.show = true;
                })
                    .catch((error) => {
                        this.data = [];
                        this.total = 0;
                        this.loading = false;
                        throw error
                    })
                    .finally(() => {
                        this.loading = false;
                    });

            },
            openCheckout(url) {
                window.open(url);
            },

            makePayment(orderId) {

                const params = {
                    id: orderId
                };

                //
                this.$store.dispatch('makePayment', params).then((data) => {

                    // this.$buefy.toast.open({
                    //     message: 'Campaign updated successfully!',
                    //     type: 'is-success'
                    // });

                    if(data.success) {
                        location.href = data.result.checkout_url;
                    }

                }).catch((err) => {
                    this.$buefy.toast.open({
                        message: 'Error occurred while retry to register payment: '+this.$utils.getHumanErrorMessage(err),
                        type: 'is-danger'
                    });
                }).finally(() => {
                });
            },

            /*
             * Handle page-change event
             */
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            /*
             * Handle sort event
             */
            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
            },

        },

        mounted() {
            this.loadAsyncData();
        }
    }
</script>

<style scoped>
</style>

<template>
    <section>
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item" id="tour-templates">
                    <h1 v-if="!loading">
                        <template v-if="total">
                            Templates ({{ total}})
                        </template>
                        <template v-else>
                            Templates
                        </template>
                    </h1>
                    <h1 v-else>
                        &nbsp;
                    </h1>
                </div>
            </div>

            <!-- Right side -->
            <div class="level-right">
                <button class="button is-medium is-info"
                        @click="createTemplateModal()" variant="primary">
                    <b-icon icon="plus"></b-icon><span>Create template</span>
                </button>
            </div>

        </nav>
        <hr id="tour-templates-overview">

        <!--<b-dropdown-->
                <!--size="is-medium"-->
            <!--&gt;-->
            <!--<button class="button is-light is-medium" slot="trigger">-->
                <!--<span>{{ filter.label }}</span>-->
                <!--<b-icon icon="caret-down"></b-icon>-->
            <!--</button>-->

            <!--<b-dropdown-item value="Template messages" @click="onFilterChange('MAIN')">Template messages</b-dropdown-item>-->
            <!--<b-dropdown-item value="Opt-in" @click="onFilterChange('OPT-IN')">Opt-in</b-dropdown-item>-->
            <!--<b-dropdown-item value="Opt-out" @click="onFilterChange('OPT-OUT')">Opt-out</b-dropdown-item>-->
        <!--</b-dropdown>-->

        <!--- BULK ACTION -->

        <b-dropdown :disabled="checkedRows.length === 0" class="is-hidden-mobile">
            <button class="button is-light is-medium" slot="trigger">
                <span>Bulk action</span>
                <b-icon icon="caret-down"></b-icon>
            </button>

            <b-dropdown-item value="delete" @click="doBulkAction('delete')">
                Delete
            </b-dropdown-item>

        </b-dropdown>

        <b-field class="search-input-field">
            <b-input v-model="search" icon="search" ref="search" type="search" v-bind:class="{ 'has-value': search}" @input="searchFilter" placeholder="Search template..." size="is-medium"></b-input>
        </b-field>

        <br><br>

        <b-table
                :data="data"
                :loading="loading"

                paginated
                backend-pagination
                :total="total"
                :per-page="perPage"
                @page-change="onPageChange"

                backend-sorting
                :default-sort-direction="defaultSortOrder"
                :default-sort="[sortField, sortOrder]"

                :is-row-checkable="(row) => row.id !== 3"
                :checked-rows.sync="checkedRows"
                checkable

                @sort="onSort">

            <template slot-scope="props">
                <!--<b-table-column field="id" label="ID" width="120" sortable numeric>-->
                    <!--<router-link :to="{ name: 'template-edit', params: {templateId: props.row.id} }">-->
                        <!--TM-{{ props.row.id }}-->
                    <!--</router-link>-->
                <!--</b-table-column>-->

                <b-table-column field="name" label="Name" width="200" sortable>
                    <router-link :to="{ name: 'template-edit', params: {templateId: props.row.id} }">
                        {{ props.row.name }}
                    </router-link>
                    <attachment-icon style-name="vertical-align: -webkit-baseline-middle;" v-if="props.row.attachment" :attachment-type="props.row.mime_type"></attachment-icon>
                </b-table-column>

                <b-table-column field="message_body" label="Message" sortable>
                    <router-link :to="{ name: 'template-edit', params: {templateId: props.row.id} }">
                        <message-preview :attachment="props.row.attachment || null"><span v-html="$utils.parseWAMessage(replace(props.row.message_body,true))"></span></message-preview>
                    </router-link>
                </b-table-column>

                <b-table-column field="updated_at" width="180" label="Updated" sortable>
                    {{ props.row.updated_at | moment('timezone', $global.current_tz) | moment("from", "now") }}
                    <br>
                    <span class="text-lightgrey" v-if="props.row.last_updated_by_user">
                        by {{ props.row.last_updated_by_user.first_name }} {{ props.row.last_updated_by_user.last_name }}
                    </span>
                </b-table-column>

                <b-table-column field="action" label="" centered>
                    <router-link :to="{ name: 'template-edit', params: {templateId: props.row.id} }" class="action-button" title="Edit template">
                        <b-icon icon="edit"></b-icon>
                    </router-link>

                    <a href="#" @click="deleteTemplate(props.row.id)" class="action-button" title="Delete template">
                        <b-icon icon="trash-alt"></b-icon>
                    </a>
                </b-table-column>
            </template>

            <template slot="empty">
                <transition name="fade">
                    <section class="section" v-show="! loading">
                        <div class="content has-text-grey has-text-centered">
                            <p>
                            </p>
                            <p class="is-large"><b>No template found.</b></p>
                            <p><a href="#" @click="createTemplateModal()">Create a template now</a></p>
                        </div>
                    </section>
                </transition>
            </template>

        </b-table>
    </section>
</template>

<script>

    import CreateTemplateView from '../helpers/createTemplateForm';
    import replacePlaceholders from '../helpers/replace';

    export default {
        data() {
            return {
                data: [],
                checkedRows: [],
                total: 0,
                loading: true,
                sortField: 'updated_at',
                sortOrder: 'desc',
                defaultSortOrder: 'asc',
                page: 1,
                perPage: 20,
                filter: {
                    type: 'MAIN',
                    label: 'Template messages'
                },
                search: '',
                globalTimeout: null
            }
        },
        methods: {
            delay(fn, waitDelay) {
                if (this.globalTimeout != null) {
                    clearTimeout(this.globalTimeout);
                }
                this.globalTimeout = setTimeout(function() {
                    this.globalTimeout = null;

                    fn();

                }, waitDelay);
            },
            replace(msg, personalised) {
                return replacePlaceholders(msg, personalised);
            },
            createTemplateModal() {
                this.$buefy.modal.open({
                    parent: this,
                    component: CreateTemplateView,
                    hasModalCard: true

                })
            },
            doBulkAction(action) {

                let campaign_ids = [];
                for(let i=0; i<this.checkedRows.length; i++) {
                    campaign_ids.push(this.checkedRows[i].id);
                }

                if(action === 'delete') {
                    this.deleteTemplates(campaign_ids);
                }
            },
            /*
            * Handle remove a contact list
            */
            deleteTemplate(id) {

                const params = {
                    id: id
                };

                this.$buefy.dialog.confirm({
                    title: 'Delete',
                    message: 'Are you sure you want to <b>delete</b> this template? This action cannot be undone.',
                    confirmText: 'Delete template',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('deleteTemplate', params).then((data) => {

                            let result = data.result;
                            this.loadAsyncData();
                        })
                            .catch((error) => {
                                throw error
                            });
                    }
                })
            },
            /*
             * Remove templates
            */
            deleteTemplates(ids) {

                const params = {
                    ids: ids
                };

                this.$buefy.dialog.confirm({
                    title: 'Delete',
                    message: 'Are you sure you want to <b>delete</b> these templates? This action cannot be undone.',
                    confirmText: 'Delete templates',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('deleteTemplates', params).then((data) => {

                            this.checkedRows = [];
                            let result = data.result;

                            this.loadAsyncData();

                        })
                            .catch((error) => {
                                throw error
                            });
                    },
                    onCancel: () => {
                    }
                })
            },
            /*
             * Load async data
             */
            loadAsyncData() {

                const params = [
                    'is_public=true',
                    `orderBy=${this.sortField}`,
                    `sort=${this.sortOrder}`,
                    `page=${this.page}`,
                    `type=${this.filter.type}`,
                    `search=${this.search}`
                ].join('&');

                this.loading = true;

                this.$store.dispatch('fetchTemplates', params).then((data) => {

                    let result = data.result;

                    this.data = [];
                    let currentTotal = result.total;
                    if (result.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000
                    }

                    this.total = currentTotal;

                    result.data.forEach((item) => {
                        this.data.push(item)
                    })
                    this.loading = false
                })
                .catch((error) => {
                    this.data = [];
                    this.total = 0;
                    this.loading = false;
                    throw error
                });

            },
            /*
             * Handle dropdown filter change event
             */
            onFilterChange(filter) {

                let filterTypeLabel = '';

                if(filter === 'MAIN') {
                    filterTypeLabel = 'Template messages'
                } else if(filter === 'OPT-IN') {
                    filterTypeLabel = 'Opt-in';
                } else if(filter === 'OPT-OUT') {
                    filterTypeLabel = 'Opt-out';
                } else {
                    filterTypeLabel = 'Unknown';
                }

                this.filter.label = filterTypeLabel;

                this.filter.type = filter;
                this.loadAsyncData()
            },
            /*
             * Handle page-change event
             */
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            /*
             * Handle sort event
             */
            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
            },
            searchFilter() {
                // this.loadAsyncData();
                var that = this;
                this.delay(function() { that.loadAsyncData() }, this.$global.search_delay);
            }
        },
        filters: {
            /**
             * Filter to truncate string, accepts a length parameter
             */
            truncate(value, length) {
                return value.length > length
                    ? value.substr(0, length) + '...'
                    : value
            }
        },
        mounted() {
            this.loadAsyncData();
            this.$refs.search.focus();
        }
    }
</script>

<template>
    <section>

        <transition name="fade">
            <h1>Order</h1>
        </transition>
        <br>

        <!--<b-loading :active.sync="loading" :is-full-page="false"></b-loading>-->

        <div v-if="!loading" class="columns">
            <div class="column is-1">

            </div>
            <div class="column is-10">
                <div class="has-text-centered">

                    <b-message :title="getStatusTitle" size="is-medium" :type="getStatusType" :closable="false" has-icon>
                        <b>{{ getStatusLabel }}</b><br><br>
                        <router-link :to="{name: 'admin-billing', params: { tabIndex: 1 } }" >Click here to view the your payment history</router-link>
                    </b-message>

                </div>
            </div>
            <div class="column is-1"></div>
        </div>
    </section>
</template>

<script>

    export default {
        components: {
        },
        data: function() {
            return {
                data: {},
                id: this.$route.params.orderId,
                loading: true
            }
        },
        computed: {
            getStatusType() {
                switch(this.data.status) {
                    case 'PAID':
                        return 'is-success';
                    case 'EXPIRED':
                        return 'is-danger';
                    default:
                        return 'is-danger';
                }
            },

            getStatusLabel() {
                switch(this.data.status) {
                    case 'PAID':
                        return 'Your payment [#'+this.data.id+'] has been successfully processed.';
                    case 'EXPIRED':
                        return 'Your payment has expired.';
                    case 'FAILED':
                        return 'Your payment could not be processed [#'+this.data.status+']. Please click the link below to try again or choose a different payment method';
                    default:
                        return 'Your payment could not be processed [#'+this.data.status+']. Please contact us to assist you on how to proceed';
                }
            },

            getStatusTitle() {
                switch(this.data.status) {
                    case 'PAID':
                        return 'Payment successful';
                    case 'EXPIRED':
                        return 'Payment expired';
                    case 'FAILED':
                        return 'Payment failed';
                    default:
                        return 'Payment failed';
                }
            }
        },
        methods: {
            /*
            * Load async data
            */
            loadAsyncData() {

                const params = { id: this.id };

                this.$store.dispatch('fetchOrder', params).then((data) => {

                    // update view with current campaign settings
                    let result = data.result;
                    this.data = result;

                    if(result.status === 'EXPIRED') {

                        this.$buefy.toast.open({
                            message: `Redirecting to checkout page...`,
                            type: 'is-info'
                        });

                        setTimeout(() => {
                            this.$router.push({ name: 'admin-billing', params: { tabIndex: 1 } })
                        },2000)
                    }

                })
                .catch((error) => {
                    throw error
                })
                .finally(() => {
                    this.loading = false;
                });

            },
        },

        mounted() {
            this.loadAsyncData();
        }
    }
</script>

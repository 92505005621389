<template>
    <section>
        <nav class="level">
            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <div>
                        <h1>Checkout</h1>
                    </div>
                </div>
            </div>
        </nav>
        <b-notification type="is-warning" expanded :closable="false"><div class="has-text-centered" style="padding: 7px">The total price of a {{ $global.product_name }} subscription is a <u>combination of the plan, credits and the volume of contacts</u> that exist in your account</div></b-notification>
        <!-- preload components, for some reason buefy tabs are no longer loaded beforehand..... -->
        <billing-details-form
                v-if="!loading"
                v-show="false"
                v-model="billing.settings"
                @charge-vat="chargeVat = $event"
                @valid="orderValid = $event"
                @submitClicked="tabIndex++"
        ></billing-details-form>

        <keep-alive>
        <form @submit.prevent="" v-if="$store.getters.userProfile">
            <b-loading :active.sync="loading"></b-loading>

            <transition name="fade">
                <div v-show="show" class="columns is-desktop">
                    <div class="column is-8">

                        <b-steps @change="tabChanged" size="is-small" class="block" v-model="tabIndex" :has-navigation="false" :animated="false">
                            <b-step-item label="Choose Plan">
                                <section>
                                    <div class="box">
                                        <b-message v-show="$store.getters.userProfile.account.plan !== 'TRIAL'" type="is-danger">
                                            Currently it is not possible to change your plan through the site. Please contact us via the widget (bottom right) if you would like to switch your plan.
                                        </b-message>
                                        <h3>Select a plan</h3>
                                        <br>
                                        <p>Compare plans and features on our <router-link :to="{name: 'admin-plans'}" target="_blank">Pricing page</router-link></p>
                                        <br>
                                        <ul>
                                            <li v-for="plan in availablePlans">
                                                <div class="field" v-show="plan.name !== 'CUSTOM'">
                                                    <b-radio v-model="billing.plan.type"
                                                             :native-value="plan.name" :disabled="$store.getters.userProfile.account.plan !== 'TRIAL'">
                                                        <template v-if="plan.name === 'PAY AS YOU GO'">
                                                            {{ plan.name | lowercase | capitalize }} (<u>No recurring fee</u>)
                                                        </template>
                                                        <template v-else>
                                                            {{ plan.name | lowercase | capitalize }} plan
<!--                                                            {{ plan.name | lowercase | capitalize }} ({{ calculateCurrentPlan | currency('€',0) }} / month)-->
                                                        </template>
                                                            <b-tooltip multilined type="is-info" :label="plan.short_desc" style="vertical-align: middle;" position="is-right" >
                                                                <b-icon size="is-small" type="is-dark" icon="info-circle" style="margin-left: 6px"></b-icon>
                                                            </b-tooltip>
                                                    </b-radio>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="box" v-show="! isPayAsYouGo">
                                        <h3>Choose a payment schedule</h3>
                                        <br>
                                        <ul>
                                            <li>
                                                <div class="field">
                                                    <b-radio v-model="billing.schedule.type"
                                                             native-value="MONTHLY" @input="changeSchedule">
                                                        Monthly
                                                    </b-radio>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="field">
                                                    <b-radio v-model="billing.schedule.type"
                                                             native-value="YEARLY" @input="changeSchedule">
                                                        Yearly (<u>Save {{ calculateDiscountFactor | currency('€',0) }}</u>)
                                                    </b-radio>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <hr>

                                    <div class="is-pulled-right">
                                        <button class="button is-info" @click="tabIndex++">Next step - Usage</button>
                                    </div>
                                </section>
                            </b-step-item>

                            <b-step-item :clickable="true" :label="'Usage'">
                                <section>
                                    <div class="box">
                                        <billing-usage-form v-if="!loading"
                                                            :plan="billing.plan.type"
                                                            :schedule="billing.schedule.type"
                                                            v-on:update:whatsappCountTierLimit="whatsapp_tier_limit = $event"
                                                            v-on:update:whatsappCredits="usage.credits.wa = $event"
                                                            :showContactUsageForm="!inPaidPlan"
                                                            v-model="usage"></billing-usage-form>

<!--                                        DEPRECATED -> v-on:update:contactsCountTierLimit="contacts_count_tier_limit = $event"-->

                                    </div>
                                    <hr>
                                    <div class="is-pulled-right">
                                        <button class="button is-info" @click="tabIndex++">Next step - Billing address</button>
                                    </div>
                                </section>
                            </b-step-item>

                            <b-step-item :clickable="true" :icon="orderValid === 0 ? 'exclamation-triangle': ''" label="Billing Address">

                                <template v-if="! orderValid">
                                    <b-message type="is-danger" has-icon :closable="false">
                                        To proceed to checkout, please fill out the billing details below and click the 'Update' button to submit.
                                    </b-message>
                                </template>

                                <div class="box">
                                    <keep-alive>
                                        <billing-details-form
                                                v-model="billing.settings"
                                                @charge-vat="chargeVat = $event"
                                                @valid="orderValid = $event"
                                                @submitClicked="tabIndex++"
                                        >
                                        </billing-details-form>
                                    </keep-alive>
                                </div>

                            </b-step-item>
                            <!--<b-tab-item label="Payment method">-->
                                <!--<billing-payment-method-form v-model="billing.paymentMethod.type"></billing-payment-method-form>-->
                            <!--</b-tab-item>-->
                            <b-step-item :clickable="true" label="Finish">

                                <div class="box">
                                    <div class="columns">
                                        <div class="column is-1">
                                            <b-icon icon="thumbs-up" style="opacity: 0.3"></b-icon>
                                        </div>
                                        <div class="column is-11">
                                            <h3 style="font-size: 22px;">You are all set!</h3>
                                            <br>
                                            <div class="box">
                                                <ul style="list-style-type: square; color: #666">
                                                    <li>
                                                        <sub>
                                                            All Plans are paid upfront, recurring, non-refundable and can be terminated at any time. Your cancellation stops the renewal of your account by the end of that period;
                                                        </sub>
                                                    </li>
                                                    <li>
                                                        <sub>
                                                            Exceeding the Contacts quota of each Volume Tier shall automatically upgrade your account to the next Volume Tier;
                                                        </sub>
                                                    </li>
                                                    <li>
                                                        <sub>
                                                            After your payment is received your account will be set up and connected within one working day,
                                                        </sub>
                                                    </li>
                                                    <li>
                                                        <sub>
                                                            SMS opt-in messages, to initiate a contact, are optional, pre-paid additionally and based on regular operator prices;
                                                        </sub>
                                                    </li>
                                                    <li>
                                                        <sub>
                                                            All Credits purchased expire after 12 months;
                                                        </sub>
                                                    </li>
                                                    <li>
                                                        <sub>
                                                            All prices are excluding phone, phone number, (e)SIM card or WhatsApp account;
                                                        </sub>
                                                    </li>
                                                    <li>
                                                        <sub>
                                                            Our <a :href="$global.product_homepage + '/terms-and-conditions/'" target="_tp-website">Terms & Conditions</a> apply.
                                                        </sub>
                                                    </li>
                                                    <!--<li>-->
                                                        <!--<sub>-->
                                                            <!--Please follow our <a href="" target="_tp-website">WA-Connector Guidelines</a> for a proper operation;-->
                                                        <!--</sub>-->
                                                    <!--</li>-->
                                                </ul>
                                            </div>
                                            <br>
                                            <div class="field">

<!--                                                <b-field grouped>-->
<!--                                                    <b-input placeholder="Search..." expanded></b-input>-->
<!--                                                    <p class="control">-->
<!--                                                        <button class="button is-primary">Search</button>-->
<!--                                                    </p>-->
<!--                                                </b-field>-->

                                                <b-field :type="couponCodeFieldType" :message="couponCodeFieldMessage" grouped>
                                                    <b-input size="is-medium" maxlength="32" expanded v-model="couponCode" @input="v => { couponCode = v.toUpperCase()}" placeholder="Enter coupon code"></b-input>
                                                    <p class="control">
                                                        <button class="button is-primary" @click.prevent="checkCouponCode">Redeem</button>
                                                    </p>
                                                </b-field>

                                            </div>
                                            <div class="field" style="margin-bottom: 16px">
                                                <b-checkbox v-model="recurringConsent"
                                                            type="is-info">
                                                    <b style="font-weight:500">
                                                        <template v-if="isPayAsYouGo">
                                                            I understand that {{ $global.product_name }} will charge my credit card or bank account after completing the payment.
                                                        </template>
                                                        <template v-else>
                                                            I understand that with this payment includes a {{ billing.schedule.type | lowercase }} recurring payment and that {{ $global.product_name }} will charge my credit card or bank account.
                                                        </template>
                                                    </b>
                                                </b-checkbox>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="is-fullwidth">
                                        <button :disabled="!orderValid || !recurringConsent || calculateTotalPriceIncludingTax === 0" class="button is-medium is-info is-pulled-right" @click="submitOrder">
                                            <b-icon icon="shopping-cart"></b-icon>
                                            <span style="margin-left: 10px">Go to checkout</span>
                                        </button>
                                        <button v-if="$store.getters.impersonateUserId" :disabled="!orderValid" class="button is-medium is-warning is-pulled-right" style="margin: 0 8px" @click="saveUpgradeSettings">
                                            <b-icon icon="save"></b-icon>
                                            <span style="margin-left: 10px">Save upgrade settings (superadmin)</span>
                                        </button>
                                        <br>
                                    </div>

                                    <br><br>
                                </div>

                                <template v-if="billing.settings">
                                    <!--You have selected plan: {{ billing.plan.type }} <a href="#" @click="tabIndex=tabs.PLAN">change</a>-->

                                    <!--Monthly period: {{ billing.schedule.type }} <a href="#" @click="tabIndex=tabs.PLAN">change</a><br>-->
                                    <!--Billing details to : {{ billing.settings.company_name }}, {{ billingAddress }} <a href="#" @click="tabIndex=tabs.settings">change</a>-->
                                </template>

                            </b-step-item>
                        </b-steps>
                    </div>
                    <div class="column is-4" style="padding: 28px; background-color: #d6e6f1; border-radius: 7px;">
                        <template v-if="!loading">
                            <h3><b-icon icon="shopping-cart"></b-icon> <b style="vertical-align: text-bottom; margin-left:12px"> Order information:</b></h3><br>
                            <table class="table is-fullwidth is-rounded">
                                <tr>
                                    <td>
                                        <a href="#" @click="tabIndex=tabs.PLAN">
                                            {{ billing.plan.type | lowercase | capitalize }} plan
                                            <template v-if="! isPayAsYouGo && whatsapp_tier_limit && whatsapp_tier_limit.amount > 0">
                                                <br>
                                                ({{ whatsapp_tier_limit.amount | currency('', 0, { thousandsSeparator: ',' }) }} msgs /mo)
                                            </template>
                                            <template v-else-if="! isPayAsYouGo && whatsapp_tier_limit && whatsapp_tier_limit.amount === -1">
                                                <br>
                                                (unlimited msgs /mo)
                                            </template>
                                        </a>
                                    </td>
                                    <td class="has-text-right">{{ calculateCurrentPlan | currency('€', 2) }}</td>
                                </tr>
                                <tr v-if="isPayAsYouGo">
                                    <td>
                                        <a href="#" @click="tabIndex=tabs.USAGE">
                                            WhatsApp credits ({{ usage.credits.wa.amount || 0 }})
                                        </a>
                                    </td>
                                    <td class="has-text-right"><div class="button is-white"></div>{{ usage.credits.wa.price || 0 | currency('€', 2, { thousandsSeparator: ',' }) }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <a href="#" @click="tabIndex=tabs.USAGE">
                                            SMS credits ({{ usage.credits.sms.amount || 0 }})
                                        </a>
                                    </td>
                                    <td class="has-text-right"><div class="button is-white"></div>{{ usage.credits.sms.price || 0 | currency('€', 2, { thousandsSeparator: ',' }) }}</td>
                                </tr>

<!--                                <tr v-if="! isPayAsYouGo && ! inPaidPlan">-->
<!--                                    <td>-->
<!--                                        <a href="#" @click="tabIndex=tabs.USAGE">-->
<!--                                            WhatsApp contact volume <template v-if="contacts_count_tier_limit">({{ contacts_count_tier_limit | currency('', 0, { thousandsSeparator: ',' }) }})</template>-->
<!--                                        </a>-->
<!--                                    </td>-->
<!--                                    <td class="has-text-right"><div v-show="!contacts_count_tier_limit" class="button is-white"></div>{{ calculateUsage | currency('€', 2, { thousandsSeparator: ',' }) }}</td>-->
<!--                                </tr>-->
<!--                                <tr v-if="hide">-->
<!--                                    <td>-->
<!--                                        Setup fee-->
<!--                                    </td>-->
<!--                                    <td class="has-text-right">{{ calculateSetupFee | currency('€', 2, { thousandsSeparator: ',' }) }}</td>-->
<!--                                </tr>-->
                                <tr v-if="couponDiscount > 0">
                                    <td></td>
                                    <td class="has-text-right"><b>Discount (<template v-if="couponDiscountType === 'PCT'">
                                            {{ couponDiscount + '%' }}
                                        </template>
                                        <template v-else-if="couponDiscountType === 'AMOUNT'">
                                            &euro; {{ couponDiscount }}
                                        </template>
                                        ): -
                                        {{ calculateDiscount() | currency('€',2) }}</b></td>
                                </tr>
                                <tr v-if="chargeVat">
                                    <td></td>
                                    <td class="has-text-right"><b>Sub total: {{ calculateTotalPriceExcludingTax() | currency('€',2) }}</b></td>
                                </tr>
                                <tr v-if="chargeVat">
                                    <td></td>
                                    <td class="has-text-right">Vat 21%: {{ calculateVat | currency('€',2) }}</td>
                                </tr>

                                <tr v-if="isPayAsYouGo">
                                    <td></td>
                                    <td class="has-text-right" style="font-size:24px"><b>Total: {{ calculateTotalPriceIncludingTax | currency('€',2) }}</b></td>
                                </tr>

                                <tr v-else-if="billing.schedule.type === 'MONTHLY'">
                                    <td></td>
                                    <td class="has-text-right" style="font-size:24px"><b>Total: {{ calculateTotalPriceIncludingTax | currency('€',2) }}</b></td>
                                </tr>

                                <tr v-else-if="billing.schedule.type === 'YEARLY'">
                                    <td colspan="2" class="has-text-right">

                                        <b style="font-size:24px">Total: {{ Math.ceil(calculateTotalPriceIncludingTax / 12) | currency('€',0) }} /mo</b><br>
                                        <b>{{ calculateTotalPriceIncludingTax | currency('€',2) }} /yr</b>
                                    </td>
                                </tr>
                            </table>

                            <table class="table is-fullwidth">
                                <tr>
                                    <td>
                                        <p><b><a href="#" @click="tabIndex=tabs.SETTINGS">Billing address:</a></b></p>
                                        <br>
                                        <span v-if="this.billing.settings" v-html="billingAddress"></span>

                                        <template v-if="! orderValid">
                                            <br><br>
                                            <b-notification type="is-warning" :closable="false" has-icon>
                                                <b class="has-text-centered">Your billing address is invalid or incomplete. <a href="#" class="color-white" @click="tabIndex=tabs.SETTINGS">Click here</a> to update</b>
                                            </b-notification>
                                        </template>
                                    </td>
                                </tr>
                            </table>
                            <div class="field">
                                <b-checkbox v-model="recurringConsent"
                                            type="is-info">
                                    <template v-if="isPayAsYouGo">
                                        I understand that {{ $global.product_name }} will charge my credit card or bank account after completing the payment.
                                    </template>
                                    <template v-else>
                                        I understand that with this payment includes a {{ billing.schedule.type | lowercase }} recurring payment and that {{ $global.product_name }} will charge my credit card or bank account.
                                    </template>
                                </b-checkbox>
                            </div>
                            <br>
                            <button v-show="orderValid" :disabled="!recurringConsent || calculateTotalPriceIncludingTax === 0" class="button is-info is-fullwidth" @click="submitOrder">Go to checkout</button>
                            <!--Payment method: {{ billing.paymentMethod.type ?  billing.paymentMethod.type : 'Not specified' }} <a href="#" @click="tabIndex=tabs.METHOD">change</a> <dynamic-icon success-icon="check" failed-icon="times" v-model="billing.paymentMethod.type"></dynamic-icon><br><br>-->
                        </template>

                    </div>
                </div>
            </transition>

            <br>

        </form>
        </keep-alive>

    </section>
</template>

<script>

    import billingOrderTable from './common/billing-order-table';
    import BillingDetailsForm from './common/billing-details-form';
    // import BillingPaymentMethodForm from './common/billing-payment-method-form';
    import BillingUsageForm from './common/billing-usage-form';

    const MIN_WA_AMOUNT = 200;
    const MIN_SMS_AMOUNT = 200;

    export default {
        components: {
            billingOrderTable, BillingDetailsForm, BillingUsageForm //, BillingPaymentMethodForm
        },
        data() {
            return {
                data: [],
                tabs: {
                    PLAN: 0,
                    USAGE: 1,
                    SETTINGS: 2,
                    FINISH: 3
                },
                paymentSchedule: '',

                billing: {
                    plan: {
                        type: this.$route.query.plan || this.$route.params.plan || null
                    },
                    schedule: {
                        type: this.$route.query.schedule || this.$route.params.schedule || 'MONTHLY'
                    },
                    // paymentMethod: {
                    //     type: 'CREDITCARD',
                    //     isValid: false
                    // },
                    settings: null
                },

                usage: {
                    credits: {
                        wa: {
                            amount: 0,
                            price: 0
                        },
                        sms: {
                            amount: 0,
                            price: 0
                        }
                    },
                    contacts: {
                        amount: this.$route.query.usageContactsAmount || this.$route.params.usageContactsAmount || null,
                        price: null,
                        discountYearly: null
                    },
                    schedule: this.$route.query.schedule || this.$route.params.schedule || null,
                    pricingTable: null
                },

                contacts_count: this.$route.query.usageContactsAmount || this.$route.params.usageContactsAmount || 200,
                current_contact_count: this.$route.query.usageContactsAmount || this.$route.params.usageContactsAmount || null,
                contacts_count_tier_limit: null,
                whatsapp_tier_limit: null,

                waAmountInput: null,
                smsAmountInput: null,

                availablePlans: [],

                inPaidPlan: false,

                chargeVat: null,

                couponDiscount: null,
                couponDiscountType: 'PCT',

                orderValid: false,

                recurringConsent: false,

                tabIndex: this.$route.query.tabIndex || this.$route.params.tabIndex || 0,

                couponCode: null,
                couponCodeFieldType: '',
                couponCodeFieldMessage: '',

                show: true,
                loading: true
            }
        },

        watch: {
            // usage: {
            //     handler(val) {
            //         this.calculateTotalPriceExcludingTax();
            //     },
            //     deep: true
            // }
        },

        computed: {

            isPayAsYouGo() {
                return this.billing.plan.type === 'PAY AS YOU GO';
            },

            calculatePlanPerMonth() {

                for(let index in this.availablePlans) {
                    if(this.availablePlans[index].name === this.billing.plan.type) {

                        if(this.billing.schedule.type === 'MONTHLY') {
                            return parseFloat(this.availablePlans[index].payment_amount_monthly_in_eur);
                        } else if(this.billing.schedule.type === 'YEARLY') {
                            return parseFloat(this.availablePlans[index].payment_amount_yearly_in_eur / 12);
                        }
                    }
                }
            },

            calculateCurrentPlan() {

                //is upgrade of credits only
                // if(this.inPaidPlan) {
                //     return 0;
                // }

                if(this.isPayAsYouGo) return 0;

                if(! this.whatsapp_tier_limit) return;

                if(this.billing.schedule.type === 'MONTHLY') {
                    return this.whatsapp_tier_limit.monthly_plan_price

                } else if(this.billing.schedule.type === 'YEARLY') {
                    // return plan.payment_amount_yearly_in_eur / 12
                    return this.whatsapp_tier_limit.yearly_plan_price

                }
            },

            calculateUsagePerMonth() {

                return parseFloat(this.usage.contacts.price);

                // return parseFloat(this.usage.contacts.price +
                //     (this.usage.credits.wa.amount * this.usage.pricingChannelCredits.wa.price ) +
                //     (this.usage.credits.sms.amount * this.usage.pricingChannelCredits.sms.price ));

            },

            calculateUsage() {

                // if(this.inPaidPlan) return 0;

                let ret = 0;

                if(this.billing.schedule.type === 'MONTHLY') {
                    ret += this.calculateUsagePerMonth;
                } else if(this.billing.schedule.type === 'YEARLY') {
                    ret += this.calculateUsagePerMonth * 12;
                }

                return ret;
            },

            calculateTotalPriceIncludingTax() {

                if(this.chargeVat) {
                    return this.calculateTotalPriceExcludingTax() * 1.21;
                } else {
                    return this.calculateTotalPriceExcludingTax();
                }
            },

            calculateVat() {
                if(this.chargeVat) {
                    return Math.round( (this.calculateTotalPriceExcludingTax() * 0.21) * 100 ) / 100;
                } else {
                    return 0;
                }
            },

            calculateDiscountFactor() {

                let discount = 0;

                // plan
                // for(let index in this.availablePlans) {
                //     if(this.availablePlans[index].name === this.billing.plan.type) {
                if(! this.whatsapp_tier_limit) return false;

                        const priceYearly = this.whatsapp_tier_limit.yearly_plan_price * 12;
                        discount = priceYearly - this.whatsapp_tier_limit.monthly_plan_price * 12;

                        // deprecated
                        // const priceYearly = this.availablePlans[index].payment_amount_monthly_in_eur * 12;
                        // discount = priceYearly - this.availablePlans[index].payment_amount_yearly_in_eur;

                    // }
                // }

                // add usage discount
                discount += (this.usage.contacts.discountYearly * 12);
                return discount;

            },

            calculateSetupFee() {
                // plan
                for(let index in this.availablePlans) {
                    if(this.availablePlans[index].name === this.billing.plan.type) {

                        return parseFloat(this.availablePlans[index].setup_fee_in_eur);
                    }
                }
                return 0;
            },

            billingAddress() {
                const arr = [ 'street1', 'street2', 'zipcode', 'city', 'state', 'country' ];

                let new_arr = [];
                for(let i=0; i < arr.length; i++) {
                    if(this.billing.settings[arr[i]] !== '') {
                        new_arr.push( this.billing.settings[arr[i]] );
                    }
                }

                // return ' test';
                return new_arr.join('<br>');

            },
        },
        methods: {

            calculateDiscount() {

                if(this.couponDiscount === null) return 0;

                if(this.couponDiscountType === 'PCT') {
                    return  (this.couponDiscount / 100) * this.calculateTotalPriceExcludingTax(true);
                } else if(this.couponDiscountType === 'AMOUNT') {
                    return this.couponDiscount;
                }

                return 0;
            },

            tabChanged(idx) {
                if(idx === this.tabs.FINISH && !this.orderValid) {

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: '<b>Your billing details are incomplete or invalid.</b><br><br>Please correct in order to proceed to checkout!',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        onConfirm: () => this.tabIndex = this.tabs.SETTINGS
                    })

                }
            },

            changeSchedule(schedule) {
                this.usage.schedule = schedule

            },

            // calculatePlan() {
            //
            //     if(! this.whatsapp_tier_limit) return;
            //     if(this.billing.schedule.type === 'MONTHLY') {
            //         return this.whatsapp_tier_limit.monthly_plan_price
            //
            //     } else if(this.billing.schedule.type === 'YEARLY') {
            //         // return plan.payment_amount_yearly_in_eur / 12
            //         return this.whatsapp_tier_limit.yearly_plan_price
            //
            //     }
            // },

            calculateTotalPriceExcludingTax(excludeDiscount) {

                excludeDiscount = excludeDiscount || false;

                let price = 0;

                // customer is not yet in a paid plan
                // if(! this.inPaidPlan) {
                    price += this.calculateSetupFee;

                    if(! this.isPayAsYouGo) {
                        // starter or pro

                        if (this.billing.schedule.type === 'YEARLY') {
                            price +=this.calculateCurrentPlan * 12;
                        } else {
                            price += this.calculateCurrentPlan;
                        }

                    }
                // }

                price += this.usage.credits.sms.price || 0;

                if(this.isPayAsYouGo) {
                    price += this.usage.credits.wa.price || 0;
                }

                // apply coupon discount

                if(! excludeDiscount) {
                    if(this.couponDiscountType === 'PCT') {
                        price -= (this.couponDiscount / 100) * price;

                    } else if(this.couponDiscountType === 'AMOUNT') {
                        price -= this.couponDiscount;
                    }
                }


                return price;
            },

            validateOrder() {
                // check if total amount for pay as you go is not too low.

                const waAmount = this.usage.credits.wa.amount ? parseInt(this.usage.credits.wa.amount) : 0;
                const smsAmount = this.usage.credits.sms.amount ? parseInt(this.usage.credits.sms.amount) : 0;

                if(this.isPayAsYouGo &&
                    ( waAmount < MIN_WA_AMOUNT && smsAmount < MIN_SMS_AMOUNT )
                ) {
                    this.tabIndex = this.tabs.USAGE;
                    return false;
                }

                return true;

            },
            submitOrder() {

                if(! this.validateOrder()) {
                    return
                }

                this.$buefy.dialog.confirm({
                    title: 'Checkout',
                    message: `Click the checkout button below to redirect to the payment method page to finalize the payment.`,
                    cancelText: 'Cancel',
                    confirmText: 'Checkout',
                    type: 'is-info',
                    onConfirm: () => {

                        const params = {
                            plan: this.billing.plan.type,
                            schedule: this.billing.schedule.type,
                            usage: this.usage,
                            tier: { wa: this.whatsapp_tier_limit || 0 },
                            coupon_code: this.couponCode
                            // method: this.billing.paymentMethod.type
                        };

                        // const rnd = Math.random();
                        // window.open('about:blank', 'tp_popup_'+rnd);

                        this.loading = true;

                        this.$store.dispatch('submitOrder', params).then((data) => {

                            this.$buefy.toast.open({
                                message: `Redirecting to checkout page...`,
                                type: 'is-info'
                            });

                            setTimeout(() => {
                                location.href=data.result.checkout_url;
                            },1000);
                            // window.open(data.result.checkout_url, 'tp_popup_'+rnd);

                            // make to timeout of redirect fails
                            setTimeout(() => {
                                this.loading = false;

                                this.$buefy.toast.open({
                                    message: `Redirecting to checkout page failed. Please contact us by clicking the chat button below.`,
                                    type: 'is-danger'
                                });
                            },10000)

                        })
                            .catch((error) => {
                                throw error
                            })
                            .finally(() => {
                                // this.loading = false;
                            });
                    }
                })
            },

            saveUpgradeSettings() {

                this.loading = true;

                const params = {
                    plan: this.billing.plan.type,
                    schedule: this.billing.schedule.type,
                    usage: this.usage
                    // method: this.billing.paymentMethod.type
                };

                this.$store.dispatch('saveUpgradeSettings', params).then((data) => {

                    this.$buefy.toast.open({
                        message: `Saved upgrade settings`,
                        type: 'is-success'
                    });
                })
                .catch((error) => {
                    throw error
                })
                .finally(() => {
                    this.loading = false;
                });

            },

            checkCouponCode() {

                const params = {
                    code: this.couponCode,
                    plan: this.billing.plan.type
                };

                this.$store.dispatch('checkCouponCode', params).then((data) => {

                    if(data.success && data.result !== null) {
                        this.couponDiscount = data.result.val;
                        this.couponDiscountType = data.result.type;

                        this.couponCodeFieldType = 'is-success';
                        this.couponCodeFieldMessage = 'Coupon code is valid';
                    } else {
                        this.couponDiscount = null;

                        this.couponCodeFieldType = 'is-danger';
                        this.couponCodeFieldMessage = 'The coupon code provided is invalid';
                    }


                    // this.$buefy.toast.open({
                    //     message: `Saved upgrade settings`,
                    //     type: 'is-success'
                    // });
                })
                    .catch((error) => {
                        throw error
                    })
                    .finally(() => {
                        // this.loading = false;
                    });

            },

            showCannotUpgradeModal() {

                this.$buefy.dialog.alert({
                    title: 'Cannot upgrade',
                    message: `You cannot upgrade your account since your already in a paid plan!,<br><br>For questions, please contact us via chat.`,
                    conformText: 'Okay',
                    type: 'is-success',
                    onConfirm: () => {
                        this.$router.push( {name: 'admin-billing' } );
                    }
                })

            },

            loadAsyncData() {

                const params = {};

                this.$store.dispatch('fetchPlans', params).then((data) => {
                    this.availablePlans = data.result;

                })
                .catch((error) => {
                    throw error
                })
                .finally(() => {
                    this.loading = false;
                });

            }

        },
        mounted() {


                // disabled code below on 20 sep 2019, since a customer on mobile wants to check pricing but it fails (loader spinner forever)
                // if(this.$store.state.account.plan !== 'TRIAL') {
                //     this.showCannotUpgradeModal()
                // };

            this.usage.schedule = this.billing.schedule.type;

            setTimeout(() => {

                // automatically select the current plan (if set)
                if(this.$store.getters.userProfile && this.$store.getters.userProfile.account.plan !== 'TRIAL') {
                    this.inPaidPlan = true;
                    this.billing.plan.type = this.$store.getters.userProfile.account.plan;
                } else {
                    // already in paid plan
                }

              this.usage.contacts.amount = this.$store.getters.userProfile.account.contacts_count;
              this.current_contact_count = this.$store.getters.userProfile.account.contacts_count;

          }, 2000);

            this.loadAsyncData()
        }
    }
</script>


const CreateSendTestMessageForm = {
    data() {
        return {
            data: [],
            form: {
                phone: '',
                channel_id: null,
                origin: null,
            },
            submitting: false,
        }
    },
    mounted() {
        this.$refs.phone.focus();
        this.form.channel_id = this.channel_id;

        this.form.phone = this.phone;
    },
    props: [
        'message','channel_id','uploadFileContent','phone'
    ],
    template: `
        <div>
            <form @submit.prevent="">
            
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Send test message</p>
                        <button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>
                    </header>
                    <section class="modal-card-body">
                    
                        <b-message type="is-warning">
                            Note: attachments will not be sent in test mode.
                        </b-message>
                    
                        <channel-selector v-model="channel_id" v-on:change="setOrigin"></channel-selector>

                       <hr>  
                                          
                        <b-field label="Phone">
                            <b-input
                                size="is-medium"
                                type="text"
                                :value="form.phone"
                                v-model.trim="form.phone"
                                ref="phone"
                                placeholder="eg. 31612345678"
                                style="width: 400px"
                                minlength="7"
                                maxlength="32"
                                required
                            >
                        </b-input>
                        </b-field>

                    </section>
                    
                    <footer class="modal-card-foot">
                    
                        <div class="columns" style="width: 100%">
                            <div class="column is-12" style="text-align: right">
                               <button class="button is-primary is-medium" :disabled="! form.phone || ! form.channel_id" @click="handleSubmit" v-bind:class="{'is-loading': submitting}">Send test message</button>
                                <button class="button is-medium" type="button" @click="$parent.close()">Cancel</button>
                            </div>
                        </div>
                    
                    </footer>
                </div>
            </form>
        </div>
        `,
    methods: {

        setOrigin(obj) {
            this.form.origin = obj.origin;
            this.form.channel_id = obj.id;
        },
        handleSubmit() {
            // Send data to the server or update your stores and such.

            this.submitting = true;

            const params = {
                body: this.message,
                phone: this.form.phone,
                channel_id: this.form.channel_id,
                uploadFileContent: this.uploadFileContent,
                personalise: true
            };

            this.$store.dispatch('sendTestMessage', params).then((data) => {
                // console.log(data);

                this.$parent.close();

                if(data.success) {
                    this.$buefy.toast.open({
                        message: 'Message successfully sent!',
                        type: 'is-success'
                    });
                } else {
                    this.$buefy.toast.open({
                        message: 'Error occurred while sending message',
                        type: 'is-danger'
                    });
                }

            }).catch((err) => {
                this.$buefy.toast.open({
                    message: 'Error occurred while sending message: '+this.$utils.getHumanErrorMessage(err),
                    type: 'is-danger'
                });

                // handle error
            }).finally(() => {
                this.submitting = false;

            });

        }
    }
}

export default CreateSendTestMessageForm;

const UpdateContactForm = {
    data() {
        return {
            data: {},
            form: {
                // name: `My ${this.$store.getters.userProfile.account.name} Channel`,
                // origin: '',
                send_sms_on_unknown: false
            },
            contacts_count: 0,

            submitting: false

        }
    },
    mounted() {
        for(var i=0;i<this.contacts.length;i++) {
            this.contacts_count++;
        }
    },
    props: [
        'contacts','optUnknownCount','optState'
    ],
    template: `
            <form @submit.prevent="handleSubmit">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Update contacts</p>
                        <button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>
                    </header>
                    <section class="modal-card-body">
                       <div class="columns">
                        
                            <div class="column is-1">
                                <b-icon size="is-medium" icon="exclamation"></b-icon>
                            </div>
                            <div class="column is-10">
                                <p>
                                Are you sure you want to <b>update</b> these contacts?
                                </p>
                                <div v-if="this.optState === 'OPT-IN'">
                                    <hr>
                                    <b-field label="">
                                        <b-checkbox v-model="form.send_sms_on_unknown">
                                            Send SMS message to request opt-unknown contacts to opt-in.<br><u>{{ this.optUnknownCount }} SMS {{ this.optUnknownCount | pluralize('message') }}</u> will be sent. <i>(recommended)</i>
                                        </b-checkbox>
                                    </b-field>
                                    <section>
                                        <b-notification v-show="! form.send_sms_on_unknown" type="is-danger" :closable="false">
                                        <i>Warning: </i> All selected contacts will forcefully be marked as opt-in. <b>Make sure that the selected contacts have given their consent!</b>
                                        </b-notification>
                                    </section>
                                </div>
                            </div>
                            <div class="column is-1">
                              
                            </div>
                        </div>
                    </section>
                    
                    <footer class="modal-card-foot">
                        <div class="columns" style="width: 100%">
                            <div class="column is-12" style="text-align: right">
                                <button class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}">Submit</button>
                                <button class="button is-medium" type="button" @click="$parent.close()">Cancel</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </form>
        `,
    methods: {
        handleSubmit() {

            this.submitting = true;

            // Send data to the server or update your stores and such.
            const params = {
                contact_ids: this.contacts,
                opt_state: this.optState,
                skip_optin_sms: ! this.form.send_sms_on_unknown
            }

            this.$store.dispatch('updateContacts', params).then((data) => {

                this.$parent.close();

                this.checkedRows = [];
                let result = data.result;

                if(data.result.updated) {
                    this.$buefy.toast.open({
                        message: data.result.updated + ' ' + this.$options.filters.pluralize(data.result.updated, 'contact') + ' updated.',
                        type: 'is-info'
                    });

                    this.$emit('success');
                } else if(data.result.sms_sent) {
                    this.$buefy.toast.open({
                        message: data.result.sms_sent + ' sms ' + this.$options.filters.pluralize(data.result.updated, 'message') + ' sent to opt-in.',
                        type: 'is-info'
                    });
                } else {
                    this.$buefy.toast.open({
                        message: 'No contact updated.',
                        type: 'is-warning'
                    });
                }

            })
            .catch((err) => {
                this.$buefy.toast.open({
                    message: 'An error has occurred! '+this.$utils.getHumanErrorMessage(err),
                    type: 'is-danger',
                    duration: 5000
                });
            });
        }
    }
}

export default UpdateContactForm;

import mainStore from '../store/mainStore';

import multiReplaceAll from 'multi-replaceall';

const replacePlaceholders = function (msg, personalised) {

    if (typeof msg === 'undefined' || msg === '') return '';

    let output = msg;
    const userProfile = mainStore.getters.userProfile;

    if(!userProfile) {
        // not loaded yet
        return false;
    }

    const replacementListDefault = [
        {
            substr: '[[*SENDER_FIRST_NAME]]',
            to: '<span class="placeholder-text" title="[[*SENDER_FIRST_NAME]]">'+userProfile.first_name+'</span>'
        },
        {
            substr: '[[*SENDER_LAST_NAME]]',
            to: '<span class="placeholder-text" title="[[*SENDER_LAST_NAME]]">'+userProfile.last_name+'</span>'
        },
        {
            substr: '[[*SENDER_COMPANY]]',
            to: '<span class="placeholder-text" title="[[*SENDER_COMPANY]]">'+userProfile.account.name+'</span>'
        },
        {
            substr: '[[*OPTOUT_KEYWORD]]',
            to: '<span class="placeholder-text" title="[[*OPTOUT_KEYWORD]]">'+userProfile.account.name.toUpperCase()+'</span>'
        }
    ];

    output = multiReplaceAll(output, replacementListDefault);

    let cls = '';
    if(personalised) {
        cls = 'placeholder-text';

        const replacementListPersonalised = [
            {
                substr: '[[FIRST_NAME]]',
                to: '<span class="'+cls+'" title="[[FIRST_NAME]]">John</span>'
            },
            {
                substr: '[[LAST_NAME]]',
                to: '<span class="'+cls+'" title="[[LAST_NAME]]">Doe</span>'
            },
            {
                substr: '[[COMPANY]]',
                to: '<span class="'+cls+'" title="[[COMPANY]]">Acme</span>'
            },
            {
                substr: '[[PHONE]]',
                to: '<span class="'+cls+'" title="[[PHONE]]">+31612345678</span>'
            },
            {
                substr: '[[LINK]]',
                to: '<span class="'+cls+'" title="[[LINK]]">https://acme.org</span>'
            },
            {
                substr: '[[ATTR1]]',
                to: '<span class="'+cls+'" title="[[ATTR1]]">Attribute 1</span>'
            },
            {
                substr: '[[ATTR2]]',
                to: '<span class="'+cls+'" title="[[ATTR2]]">Attribute 2</span>'
            },
            {
                substr: '[[ATTR3]]',
                to: '<span class="'+cls+'" title="[[ATTR3]]">Attribute 3</span>'
            }
        ];

        output = multiReplaceAll(output, replacementListPersonalised);

    } else {
        cls = 'placeholder-error';

        const replacementListPersonalised = [
            {
                substr: '[[FIRST_NAME]]',
                to: '<span class="'+cls+'" title="[[FIRST_NAME]]">[[FIRST_NAME]]</span>'
            },
            {
                substr: '[[LAST_NAME]]',
                to: '<span class="'+cls+'" title="[[LAST_NAME]]">[[LAST_NAME]]</span>'
            },
            {
                substr: '[[COMPANY]]',
                to: '<span class="'+cls+'" title="[[COMPANY]]">[[COMPANY]]</span>'
            }
        ];

        output = multiReplaceAll(output, replacementListPersonalised);

    }


    return output;

}

export default replacePlaceholders;

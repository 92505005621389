const RequestUpgradeSMSLimitModal = {
    data() {
        return {
            data: {},
            form: {
                amount: 5000
            },

            submitting: false

        }
    },
    mounted() {
        this.$refs.creditAmount.focus();
    },
    props: [
        'channelName'
    ],
    template: `
            <form @submit.prevent="handleSubmit">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Request: upgrade SMS credits</p>
                        <button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>
                    </header>
                    <section class="modal-card-body">
                       <div class="columns">
                        
                            <div class="column is-1">
                                <b-icon size="is-medium" icon="list-ol"></b-icon>
                            </div>
                            <div class="column is-10">
                                <p>
                                Submit your SMS upgrade request below and we will get back to you shortly.
                                </p>
                                <hr>
<!--                                <b-dropdown v-model="form.amount" :inline="true" aria-role="list">-->
<!--                                    <button class="button is-primary" slot="trigger">-->
<!--                                        <span>{{ form.amount }}</span>-->
<!--                                        <b-icon icon="caret-down"></b-icon>-->
<!--                                    </button>-->
<!--                        -->
<!--                                    <b-dropdown-item aria-role="listitem">50</b-dropdown-item>-->
<!--                                    <b-dropdown-item aria-role="listitem">100</b-dropdown-item>-->
<!--                                    <b-dropdown-item aria-role="listitem">200</b-dropdown-item>-->
<!--                                    <b-dropdown-item aria-role="listitem">500</b-dropdown-item>-->
<!--                                    <b-dropdown-item aria-role="listitem">1000</b-dropdown-item>-->
<!--                                    <b-dropdown-item aria-role="listitem">2000</b-dropdown-item>-->
<!--                                    <b-dropdown-item aria-role="listitem">4000</b-dropdown-item>-->
<!--                                    <b-dropdown-item aria-role="listitem">8000</b-dropdown-item>-->
<!--                                </b-dropdown>-->
                                
<!--                                <div style="padding-bottom: 100px"></div>-->
                                
                                <b-field horizontal label="Credits amount">
                                    <b-input
                                        size="is-medium"
                                        type="text"
                                        :value="form.amount"
                                        v-model.trim="form.amount"
                                        ref="creditAmount"
                                        placeholder="eg. 5000"
                                        style="width: 200px"
                                        required
                                    >
                                    </b-input>
                                </b-field>
                                <br>
                               
                            </div>
                            <div class="column is-1">
                              
                            </div>
                        </div>
                    </section>
                    
                    <footer class="modal-card-foot">
                        <div class="columns" style="width: 100%">
                            <div class="column is-12" style="text-align: right">
                                <button class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}">Request upgrade</button>
                                <button class="button is-medium" type="button" @click="$parent.close()">Cancel</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </form>
        `,
    methods: {
        handleSubmit() {

            this.submitting = true;

            // Send data to the server or update your stores and such.
            const params = {
                amount: this.form.amount,
            }

            this.$store.dispatch('requestUpgradeSMSLimit', params).then((data) => {

                this.$parent.close();

                this.$buefy.dialog.alert({
                    title: 'Request',
                    message: `<b>Thank you for your request</b><br><br>We will send you an offer to increase. Once your payment is received, your sms credit will be upgraded.`,
                    type: 'is-info',
                    hasIcon: true,
                    icon: 'thumbs-up',
                    iconPack: 'fa'
                })

            }).catch((err) => {
                this.$buefy.toast.open({
                    message: 'Error occurred while rquesting upgraade of SMS balance: '+this.$utils.getHumanErrorMessage(err),
                    type: 'is-danger'
                });
                // handle error
            })
                .finally(() => {
                    this.submitting = false;
                });
        }
    }
}

export default RequestUpgradeSMSLimitModal;

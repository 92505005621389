const SubscribeContactToLists = {
    data() {
        return {
            data: {},
            form: {
                // name: 'My onboarding campaign'
            },

            list: null,
            submitting: false
        }
    },
    mounted() {
        // this.$refs.campaignName.focus();
    },
    props: [
        'contacts'
    ],
    template: `
            <form @submit.prevent="handleSubmit">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Subscribe contact to multiple lists</p>
                        <button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>
                    </header>
                    <section class="modal-card-body">
                    <div class="columns">
                    
                        <div class="column is-1">
                            <b-icon size="is-medium" icon="bullhorn"></b-icon>
                        </div>
                        <div class="column is-10">

                            <p>
                            Subscribe selected contacts to other list
                            </p>
                            <hr>
                            <contactlist-selector v-model="list"></contactlist-selector>
                            <br>
                        </div>
                        <div class="column is-1">
                        </div>
                    </div>
                    </section>
                    <footer class="modal-card-foot">
                        <div class="columns" style="width: 100%">
                            <div class="column is-12" style="text-align: right">
                                <button class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}">Subscribe</button>
                                <button class="button is-medium" type="button" @click="$parent.close()">Cancel</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </form>
        `,
    methods: {
        handleSubmit() {
            // Send data to the server or update your stores and such.

            // this.submitting = true;
            let ids = [];

            for(var i=0;i<this.contacts.length;i++) {
                ids.push(this.contacts[i].id);
            }

            const params = {
                list_id: this.list.id,
                contact_id: ids
            }

            this.$store.dispatch('subscribeContacts', params).then((data) => {

                if(data.result.added > 0) {

                    const msg = data.result.added + ' ' + this.$options.filters.pluralize(data.result.added, 'contact') + ' added to "' + this.list.name + '"';

                    this.$buefy.toast.open({
                        message: msg,
                        type: 'is-success'
                    });
                } else {
                    this.$buefy.toast.open({
                        message: 'No contacts added',
                        type: 'is-warning'
                    });
                }


                this.$parent.close();

            }).catch((err) => {
                this.$buefy.toast.open({
                    message: 'Error occurred while subscribing contacts list: '+err,
                    type: 'is-danger'
                });
                // handle error
            });
        }
    }
}

export default SubscribeContactToLists;

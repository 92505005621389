<template>
    <div>
        hello!!!f
        <b-field label="Name">
            <b-input value="Kevin Garvey"></b-input>
        </b-field>
    </div>
</template>

<script>
    export default {
        methods: {
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>
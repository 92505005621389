<template>
    <transition name="fade">
            <div v-if="! loading && $store.getters.userProfile.phone" class="tile is-horizontal is-12">
                <div class="tile">
                    <div class="tile is-parent is-horizontal">
                        <article class="tile is-child notification is-12 is-light">
                            <p class="title" style="display: inline-block" id="tour-subscriptions">Subscriptions</p>
                            <ul>
                                <li v-for="(listObj, index) in lists" style="margin: 8px 8px;">
                                    <div class="field">
                                        <b-checkbox type="is-success" @input="handleClick(index)" v-model="listObj.is_subscribed" :disabled="listObj.updating">
                                            <span>{{ listObj.list_name }}</span>
                                        </b-checkbox>
                                    </div>
                                </li>
                            </ul>
                            <br>
                        </article>
                    </div>
                </div>
            </div>
    </transition>
</template>

<script>
    export default {
        props: [],
        data: function() {
            return {
                loading: true,
                lists: [],
                total: null
            }
        },

        methods: {
            loadAsyncData() {

                const params = [
                ].join('&');

                this.$store.dispatch('getSubscribeLists', params ).then((data) => {

                    if(data.success) {

                        this.lists = data.result;

                        for (var i = 0; i < this.lists; i++) {
                            this.lists[i].updating = false;
                        }

                        this.loading = false;

                    }
                })
                .catch((error) => {
                    throw error
                })
                .finally(() => {
                });
            },

            handleClick: function (index) {

                const list = this.lists[index];

                // check inverted value of is_subscribed. Property is not yet updated in object
                const params = {
                    action: list.is_subscribed ? 'subscribe' : 'unsubscribe',
                    list: list.list
                };

                const self = this;

                this.$store.dispatch('setSubscribeList', params ).then((data) => {

                    self.lists[index].updating = false;

                    // this.$buefy.toast.open({
                    //     message: 'Subscription success',
                    //     type: 'is-success'
                    // });

                })
                .catch((err) => {

                    this.$buefy.toast.open({
                        message: 'An error has occurred: '+this.$utils.getHumanErrorMessage(err),
                        type: 'is-danger'
                    });

                    throw error
                })
                .finally(() => {
                    this.loading = false;
                });

            }
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>

<style scoped>

</style>

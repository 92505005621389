<template>
    <section v-show="!hideView">
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <h1>
                        System
                    </h1>
                </div>
            </div>
        </nav>
        <hr>

        <div class="columns is-mobile">
            <div class="column is-1"></div>
            <div class="column is-10">
                <section>
                    <b-tabs @change="onChangeTab" size="is-medium" class="block" expanded>
                        <b-tab-item>

                            <template slot="header">
                                <b-icon icon="user-plus"></b-icon>
                                <span> Messagebird</span>
                            </template>

                            <div class="box">

                                <div class="columns is-mobile">
                                    <div class="column is-2"></div>
                                    <div class="column is-8">

                                        <!--<hr>-->

                                        <label class="label-field">
                                            Messagebird SMS Balance
                                        </label>
                                        <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
                                        <span>{{ messagebird.str }}</span>

                                    </div>
                                    <div class="column is-2"></div>
                                </div>

                            </div>

                        </b-tab-item>

                        <b-tab-item>

                            <template slot="header">
                                <b-icon icon="envelope"></b-icon>
                                <span> Test email integration</span>
                            </template>

                            <div class="box">

                                <div class="columns is-mobile">
                                    <div class="column is-2"></div>
                                    <div class="column is-8">

                                        <!--<hr>-->

                                        <div class="box">
                                            <b-field label="Send test email to:">
                                                <b-input v-model="email.testEmailRecipient" ref="email" type="email" minlength="4" maxlength="128" size="is-medium" required></b-input>
                                            </b-field>
                                        </div>

                                    </div>
                                    <div class="column is-2"></div>
                                </div>

                                <div class="has-text-centered full-width">
                                    <button class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}" :disabled="! email.testEmailRecipient" @click="sendTestEmail">Send test email</button>
                                </div>

                            </div>

                        </b-tab-item>

                        <b-tab-item>

                            <template slot="header">
                                <b-icon icon="money-check"></b-icon>
                                <span> Mollie/Moneybird</span>
                            </template>

                            <div class="box">

                                <div class="columns is-mobile">
                                    <div class="column is-2"></div>
                                    <div class="column is-8">


                                        <label class="label-field">
                                            Mollie Api test (PSP)
                                        </label>

                                        <p v-show="mollie.status !== null">
                                            status online: <b-tag size="is-medium" :type="mollie.status ? 'is-success' : 'is-danger'">{{ mollie.status ? 'ACTIVE' : 'ERROR' }}</b-tag>
                                        </p>
                                        <br>

                                        <button class="button is-info" :loading="loading" @click="testMollie">Test Mollie Api</button>

                                        <hr>

                                        <label class="label-field">
                                            Moneybird Api test (invoicing)
                                        </label>

                                        <p v-show="moneybird.status !== null">
                                            status online: <b-tag size="is-medium" :type="moneybird.status ? 'is-success' : 'is-danger'">{{ moneybird.status ? 'ACTIVE' : 'ERROR' }}</b-tag>
                                        </p>
                                        <br>

                                        <button class="button is-info" :loading="loading" @click="testMoneybird">Test Moneybird Api</button>

                                        <b-loading :is-full-page="false" :active.sync="loading"></b-loading>


                                    </div>
                                    <div class="column is-2"></div>
                                </div>

                            </div>

                        </b-tab-item>

                        <b-tab-item>

                            <template slot="header">
                                <b-icon icon="envelope"></b-icon>
                                <span> Verify phone number</span>
                            </template>

                            <div class="box">

                                <div class="columns is-mobile">
                                    <div class="column is-2"></div>
                                    <div class="column is-8">

                                        <!--<hr>-->
                                        <div class="box">
                                            <b-field label="Verify phone number:">
                                                <b-input v-model="validate.phone" ref="phone" type="phone" minlength="6" maxlength="64" size="is-medium" required></b-input>
                                            </b-field>
                                        </div>

                                        <span v-if="validate.result.parsed">Parsed IE164 format: <code>{{ validate.result.parsed }}</code></span><br>
                                        <span v-if="validate.result.isValidMobile !== null">
                                            Is valid mobile phone number:
                                            <b-tag size="is-medium" :type="validate.result.isValidMobile ? 'is-success' : 'is-danger'">{{ validate.result.isValidMobile ? 'YES' : 'NO' }}</b-tag>
                                            <div>{{ validate.result.geoDescription }}</div>
                                            <div>{{ validate.result.timezones }}</div>
                                        </span>
                                    </div>
                                    <div class="column is-2"></div>
                                </div>

                                <div class="has-text-centered full-width">
                                    <button class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}" :disabled="! validate.phone" @click="validatePhoneNumber">Verify phone number</button>
                                </div>

                            </div>

                        </b-tab-item>

                    </b-tabs>

                </section>

            </div>
            <div class="column is-1"></div>
        </div>

        <br>


    </section>
</template>

<script>

    export default {
        data() {
            return {
                data: [],
                messagebird: {
                    str: '...',
                    balance: {
                        amount: '',
                        payment: '',
                        type: ''
                    }
                },
                mollie: {
                    status: null
                },
                moneybird: {
                    status: null
                },
                email: {
                    testEmailRecipient: ''
                },
                validate:  {
                    result: {
                        phone: '',
                        parsed: null,
                        isMobile: null,
                        isValid: null,
                        isValidMobile: null,
                        timezones: [],
                        geoDescription: null
                    }
                },
                loading: true,

                submitting: false,
                hideView: true
            }
        },
        methods: {
            /*
             * Load async data
             */
            loadAsyncData() {

                this.$store.dispatch('fetchSystemInfo', {}).then((data) => {

                    let result = data.result;

                    this.messagebird = result.messagebird;
                    this.messagebird.str = result.messagebird.balance.amount + ' ' + result.messagebird.balance.type + ' (' + result.messagebird.balance.payment + ')' ;

                    this.loading = false;
                    this.hideView = false;
                })
                    .catch((error) => {
                        this.loading = false;
                        throw error
                    });

            },
            sendTestEmail() {

                this.submitting = true;

                const params = {
                    recipient: this.email.testEmailRecipient,
                };

                this.$store.dispatch('sendTestEmail', params).then((data) => {

                    const toast = {
                        message: `Test email sent!`,
                        type: 'is-success'
                    };
                    this.$buefy.toast.open(toast);

                    this.submitting = false;

                    // push to view
                })
                .catch((err) => {

                    this.$buefy.toast.open({
                        message: 'Error sending test email: '+err.custom.message,
                        type: 'is-danger'
                    });
                });
            },
            testMoneybird() {

                this.moneybird.status = null;
                this.loading = true;

                this.$store.dispatch('sendTestMBPing', {}).then((data) => {

                    this.moneybird.status = data.success;
                    this.submitting = false;

                    // push to view
                })
                    .catch((err) => {

                        this.$buefy.toast.open({
                            message: 'Error testing moneybird api: '+err.custom.message,
                            type: 'is-danger'
                        });
                    })
                    .finally(() => {
                        this.loading = false;
                    })

            },
            testMollie() {

                this.mollie.status = null;
                this.loading = true;

                this.$store.dispatch('sendTestMolliePing', {}).then((data) => {

                    this.mollie.status = data.success;
                    this.submitting = false;

                    // push to view
                })
                    .catch((err) => {

                        this.$buefy.toast.open({
                            message: 'Error testing mollie api: '+err.custom.message,
                            type: 'is-danger'
                        });
                    })
                    .finally(() => {
                        this.loading = false;
                    })

            },
            validatePhoneNumber() {

                this.submitting = true;

                const params = {
                    phone: this.validate.phone,
                };

                this.$store.dispatch('validatePhoneNumber', params).then((data) => {

                    // push to view

                    this.submitting = false;
                    this.validate.result = data.result;

                })
                .catch((err) => {

                    this.$buefy.toast.open({
                        message: 'Error validating phone number: '+err.custom.message,
                        type: 'is-danger'
                    });
                });
            },
            onChangeTab(idx) {
                switch(idx) {
                    case 1:
                        this.$refs.email.focus();
                        break;
                    case 2:
                        this.$refs.phone.focus();
                        break;
                }
            }
        },
        filters: {
        },

        mounted() {
            this.loadAsyncData()
        }
    }
</script>

<template>
<!--    <div v-if="daystoExpiration <= 1000">-->
    <div>
        <b-notification v-show="typeof daystoExpiration !== 'undefined'" :type="daystoExpiration <= 2 ? 'is-danger' : 'is-warning'" class="has-text-centered" aria-close-label="Close notification">
            <div class="has-text-centered">
                {{ message }} <b>{{ $moment(value) | moment("from", "now", true) }}</b>. To continue please upgrade to a paid plan.
                👉<router-link :to="{ name: 'admin-plans' }">Calculate your price here</router-link>
            </div>
        </b-notification>
    </div>
</template>

<script>

    import moment from 'moment';

    export default {
        props: {
            value: null
        },
        data: function () {
            return {
                now: new Date(),
                message: null,
                classType: null
            }
        },
        computed: {
            daystoExpiration() {
                return this.value && this.$moment(this.value).diff(this.$moment(this.now), 'days')
            }
        },
        methods: {
            update(newValue) {
                if(newValue) {

                    if(moment(newValue) > this.now) {
                        this.isExpired = false;
                        this.classType = 'is-warning';
                        this.message = 'Your trial account will expire in ';
                    } else {

                        this.isExpired = true;
                        this.classType = 'is-danger';
                        this.message = 'Your trial account has expired for ';
                    }
                } else {
                    this.isExpired = false;
                }
            }
        },

        watch: {
            // whenever question changes, this function will run
            value: function (newValue, oldValue) {
                this.update(newValue);

            }
        },
        mounted() {
            this.update(this.value);
        }
    }
</script>

<style scoped>

</style>

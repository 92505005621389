<template>
    <section>

        <transition name="fade">
            <h1>Payment</h1>
        </transition>
        <br>

        <!--<b-loading :active.sync="loading" :is-full-page="false"></b-loading>-->

        <div class="columns">
            <div class="column is-1">

            </div>
            <div class="column is-10">

                <!--<b-message title="Trial account expired :-(" type="is-danger" has-icon>-->
                    <!--We're sorry to inform you your trial account has expired. In order to re-enable your account, choose your plan below.-->
                <!--</b-message>-->

                <div class="box">
                </div>
            </div>
            <div class="column is-1"></div>
        </div>
    </section>
</template>

<script>

    export default {
        components: {
        },
        data: function() {
            return {
            }
        },
        methods: {
            /*
            * Load async data
            */
            loadAsyncData() {
            }
        },

        mounted() {
            // this.loadAsyncData();
        }
    }
</script>
<template>
    <section>
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <h1 class="is-pulled-left">Edit account</h1>
                </div>
            </div>

            <!-- Right side -->
            <div class="level-right">
            </div>

        </nav>
        <hr>
        <form @submit.prevent="">
            <div class="columns is-mobile">
                <div class="column is-1"></div>
                <div class="column is-10">
                    <section>
                        <b-tabs type="is-centered" size="is-medium" class="block" expanded>
                            <b-tab-item>

                                <template slot="header">
                                    <b-icon icon="wrench"></b-icon>
                                    <span> Basic</span>
                                </template>

                                <div class="box has-text-centered">

                                    <div class="columns">
                                        <div class="column is-2"></div>
                                        <div class="column is-8">

                                            <label class="label-field">
                                                Account name
                                            </label>

                                            <b-field>
                                                <b-input v-model="form.name" minlength="4" maxlength="128" size="is-medium" required></b-input>
                                            </b-field>

                                            <label class="label-field">
                                                Plan
                                            </label>

                                            <b-field>
                                            <b-dropdown v-model="form.plan" aria-role="list">
                                                <button class="button is-warning" slot="trigger">
                                                    <span>{{ form.plan  }}</span>
                                                    <b-icon icon="caret-down"></b-icon>
                                                </button>

                                                <b-dropdown-item aria-role="listitem" value="TRIAL">Trial</b-dropdown-item>
                                                <b-dropdown-item aria-role="listitem" value="PAY AS YOU GO">Pay as you go</b-dropdown-item>
                                                <b-dropdown-item aria-role="listitem" value="STARTER">Starter</b-dropdown-item>
                                                <b-dropdown-item aria-role="listitem" value="PRO">Pro</b-dropdown-item>
                                                <b-dropdown-item aria-role="listitem" value="PLUS">Plus</b-dropdown-item>
                                                <b-dropdown-item aria-role="listitem" value="UNLIMITED">Unlimited</b-dropdown-item>
                                            </b-dropdown>
                                            </b-field>

                                            <br><br>
                                            <label class="label-field">
                                                Notes
                                            </label>

                                            <b-field>
                                                <b-input v-model="form.notes" minlength="4" maxlength="128" size="is-medium" required></b-input>
                                            </b-field>

                                            <v-template v-if="form.plan === 'TRIAL'">
                                                <label class="label-field">
                                                    Account expiration
                                                </label>

                                                <b-switch v-model="willExpire" @input="switchExpirationToggle"></b-switch>

                                                <b-field>
                                                    <b-datepicker
                                                        v-model="form.expires_at"
                                                        placeholder="Type or select a date..."
                                                        icon="calendar-alt"
                                                        size="is-medium"
                                                        :disabled="! willExpire"
                                                        :date-formatter="dateFormatter"
                                                        :readonly="false">

                                                        <button class="button is-primary"
                                                                @click="form.expires_at = new Date()">
                                                            <b-icon icon="calendar"></b-icon>
                                                            <span>Today</span>
                                                        </button>

                                                        <button class="button is-danger"
                                                                @click="form.expires_at = null">
                                                            <b-icon icon="times-circle"></b-icon>
                                                            <span>Clear</span>
                                                        </button>
                                                    </b-datepicker>
                                                </b-field>
                                            </v-template>

                                            <v-template v-else>
                                                <label class="label-field">
                                                    Account cancellation
                                                </label>

                                                <b-switch v-model="willCancel" @input="switchCancellationToggle"></b-switch>

                                                <b-field>
                                                    <b-datepicker
                                                        v-model="form.cancel_at"
                                                        placeholder="Type or select a date..."
                                                        icon="calendar-alt"
                                                        size="is-medium"
                                                        :disabled="! willCancel"
                                                        :date-formatter="dateFormatter"
                                                        :readonly="false">

                                                        <button class="button is-primary"
                                                                @click="form.cancel_at = new Date()">
                                                            <b-icon icon="calendar"></b-icon>
                                                            <span>Today</span>
                                                        </button>

                                                        <button class="button is-danger"
                                                                @click="form.cancel_at = null">
                                                            <b-icon icon="times-circle"></b-icon>
                                                            <span>Clear</span>
                                                        </button>
                                                    </b-datepicker>
                                                </b-field>
                                            </v-template>

                                        </div>
                                        <div class="column is-2"></div>
                                    </div>

                                    <hr>

                                    <div class="has-text-centered">
                                        <button class="button is-primary is-medium" @click="update" type="button">Update</button>
                                    </div>
                                </div>

                            </b-tab-item>

                            <b-tab-item>

                                <template slot="header">
                                    <b-icon icon="cog"></b-icon>
                                    <span> Settings / Billing info</span>
                                </template>

                                <br>

<!--                                <vue-json-pretty-->
<!--                                    :data="account_config"-->
<!--                                >-->
<!--                                </vue-json-pretty>-->

                                <h3>Account config</h3>
                                {{ account_config }}
                                <hr>
                                <h3>Billing subscription</h3>
                                {{ billing_subscription }}
                                <hr>
                                <h3>Billing details</h3>
                                {{ billing_details }}

                            </b-tab-item>

                            <b-tab-item>

                                <template slot="header">
                                    <b-icon icon="credit-card"></b-icon>
                                    <span> Billing subscriptions</span>
                                </template>

                                <br>

                                <b-table
                                        :data="billing_subscription"
                                        :loading="loading">

                                    <template slot-scope="props">

                                        <b-table-column field="description" label="Description">
                                            {{ props.row.description }}
                                        </b-table-column>

                                        <b-table-column field="status" label="Status">
                                            <b-tag size="is-medium" type="is-light">
                                                {{ props.row.status }}
                                            </b-tag>
                                        </b-table-column>

                                        <b-table-column field="amount" label="Amount">
                                            {{ props.row.currency }} {{ props.row.amount }}
                                        </b-table-column>

                                        <b-table-column field="interval" label="Interval">
                                            {{ props.row.interval }}
                                        </b-table-column>

                                        <b-table-column field="next_payment_date" label="Next payment date">
                                            {{ props.row.next_payment_date | moment('timezone', $global.current_tz) | moment("from", "now") }}
                                        </b-table-column>

                                        <b-table-column field="foreign_subscription_id" label="Subscription Id">
                                            <pre>{{ props.row.foreign_subscription_id }}</pre>
                                        </b-table-column>

                                        <b-table-column field="action" label="Actions" width="200" centered>
                                            <a href="#" class="action-button" @click="openUpdateBillingSubscriptionModal(props.row.foreign_subscription_id)">
                                                <b-icon icon="edit"></b-icon>
                                            </a>
                                            <a href="#" @click="cancelBillingSubscription(props.row.foreign_subscription_id)" class="action-button" title="Cancel subscription">
                                                <b-icon icon="times"></b-icon>
                                            </a>
                                        </b-table-column>
                                    </template>
                                </b-table>


                            </b-tab-item>

                            <b-tab-item>

                                <template slot="header">
                                    <b-icon icon="building"></b-icon>
                                    <span> Integrations</span>
                                </template>

                                <div class="box">

                                    <div class="columns is-mobile">
                                        <div class="column is-2"></div>
                                        <div class="column is-8">

                                            <b-field label="Unique ID">
                                                {{ form.uuid }}
                                            </b-field>

                                            <b-field label="Secret key">
                                                {{ form.secret_key }}
                                            </b-field>

                                        </div>
                                        <div class="column is-2"></div>
                                    </div>

                                    <div class="has-text-centered">
                                        <button class="button is-primary is-medium" @click="regenerateSecretKey" type="button">Regenerate key</button>
                                    </div>
                                </div>

                            </b-tab-item>

                        </b-tabs>
                    </section>
                </div>
                <div class="column is-1"></div>
            </div>

            <br>

        </form>

    </section>
</template>

<script>

    import moment from 'moment';
    import UpdateBillingSubscriptionForm from "../../helpers/updateBilingSubscriptionForm";

    export default {
        components: [
            UpdateBillingSubscriptionForm
        ],
        data() {

            return {
                data: {},
                account_config: null,
                billing_subscription: [],
                billing_details: null,
                form: {
                    account_id: this.$route.params.accountId,
                    name: '',
                    notes: '',
                    plan: '',
                    secret_key: '',
                    cancel_at: '',
                },
                willExpire: null,
                willCancel: null,

                loading: false,
                submitting: false
            }
        },
        methods: {
            /*
             * Load async data
             */
            loadAsyncData() {

                this.loading = true;

                const params = {
                    id: this.form.account_id
                };

                this.$store.dispatch('fetchAccount', params).then((data) => {

                    // update view with current campaign settings
                    const result = data.result;

                    this.form.name = result.name;
                    this.form.plan = result.plan;
                    this.form.notes = result.notes;
                    this.form.expires_at = result.expires_at;
                    this.form.cancel_at = result.cancel_at;
                    this.form.secret_key = result.secret_key;
                    this.form.uuid = result.uuid;

                    if(this.form.expires_at) {
                        this.form.expires_at = ( result.expires_at ? this.$moment.utc(result.expires_at).toDate() : null);
                        this.willExpire = true;
                    }

                    if(this.form.cancel_at) {
                        this.form.cancel_at = ( result.cancel_at ? this.$moment.utc(result.cancel_at).toDate() : null);
                        this.willCancel = true;
                    }

                    this.billing_subscription = [ result.billing_subscription ];
                    this.account_config = result.account_config;
                    this.billing_details = result.billing_details;

                })
                .catch((error) => {
                    this.data = [];
                    this.total = 0;
                    throw error
                }).finally(() => {
                    this.loading = false;
                });

            },

            prettifyJSON(str) {
                  let json = JSON.parse(str);
                  return JSON.stringify(json, undefined, 4);
            },

            update() {

                this.submitting = true;

                const params = {
                    id: this.form.account_id,
                    name: this.form.name,
                    plan: this.form.plan,
                    notes: this.form.notes,
                    expires_at: this.willExpire ? this.form.expires_at : null,
                    cancel_at: this.willCancel ? this.form.cancel_at : null
                };

                this.$store.dispatch('updateAccount', params).then((data) => {

                    // push notification
                    // const toast = {
                    //     message: `Updated contact successfully!`,
                    //     type: 'is-success'
                    // };
                    // this.$buefy.toast.open(toast);

                    // push to view
                    this.$router.go(-1);
                    // this.$router.push('/contactlists');

                })
                    .catch((err) => {

                        this.$buefy.toast.open({
                            message: 'Error saving account details: '+err.custom.message,
                            type: 'is-danger'
                        });
                    });
            },
            switchExpirationToggle(enabled) {
                if(enabled && ! this.form.expires_at) {
                    // this.form.scheduled_at_date = this.form.scheduled_at_time = new Date();
                }
            },
            switchCancellationToggle(enabled) {
                if(enabled && ! this.form.cancel_at) {
                    // this.form.scheduled_at_date = this.form.scheduled_at_time = new Date();
                }
            },
            regenerateSecretKey() {

                const params = {
                    id: this.form.account_id
                };

                this.$store.dispatch('regenerateSecretKey', params).then((data) => {

                    this.form.secret_key = data.result.new_secret_key;

                    this.$buefy.toast.open({
                        message: 'Regenerate secret key successful!',
                        type: 'is-success'
                    });

                })
                .catch((err) => {

                    this.$buefy.toast.open({
                        message: 'Error regenerating secret key: '+err,
                        type: 'is-danger'
                    });
                })
                .finally(() => {
                });
            },
            dateFormatter(date) {
                return moment(date).tz(this.$global.current_tz).format("DD/MMM/YYYY");
            },
            cancelBillingSubscription(subscription_id) {
                this.$buefy.dialog.confirm({
                    title: 'Subscription',
                    message: `Are you sure you want to <b>cancel</b> this subscription (${subscription_id})?`,
                    confirmText: 'Cancel subscription',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {

                        const params = {
                            cus_account_id: this.form.account_id, // account_id name would be overridden
                            foreign_subscription_id: subscription_id
                        }

                        this.$store.dispatch('cancelSubscription', params ).then((data) => {

                            let result = data.result;
                            this.loadAsyncData();
                        })
                        .catch((error) => {
                            throw error
                        });
                    }
                })

            },
            openUpdateBillingSubscriptionModal(subscription_id) {
                this.$buefy.modal.open({
                    parent: this,
                    component: UpdateBillingSubscriptionForm,
                    hasModalCard: true,
                    props: {
                        account_id: this.form.account_id,
                        description: this.billing_subscription[0].description,
                        amount: this.billing_subscription[0].amount,
                        foreign_subscription_id: subscription_id,
                    }
                })
            }

        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>

const CreateManualOrderForm = {
    data() {
        return {
            data: {},
            invoiceId: '',
            paymentType: 'oneoff',

            submitting: false
        }
    },
    props: [
        'campaignName'
    ],
    template: `
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Create payment from (moneybird) invoice id</p>
                        <button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>
                    </header>
                    <section class="modal-card-body">
                       <div class="columns">
                        
                            <div class="column is-1">
                                <b-icon size="is-medium" icon="list-ol"></b-icon>
                            </div>
                            <div class="column is-10">
                                <p>
                                    <b>Note. make sure to set and "save upgrade settings" on user's checkout page first before continuing!</b><br><br>Enter Moneybird Sales invoice id. This id can be found in Moneybird on invoice detail page
                                </p>
                                <hr>
                                
                                <b-field label="List Name">
                                
                                    <b-input
                                        size="is-medium"
                                        type="text"
                                        v-model.trim="invoiceId"
                                        ref="invoiceId"
                                        placeholder="eg. 249558490686687125"
                                        @focus="$event.target.select()"
                                        style="width: 500px"
                                     
                                    >
                                    </b-input>
                                   
                                </b-field>
                                
                                <div class="block">
                                    <b-radio v-model="paymentType"
                                        native-value="oneoff">
                                        One off
                                    </b-radio>
                                    <b-radio v-model="paymentType"
                                        native-value="recurring">
                                        Recurring
                                    </b-radio>
                                </div>
                                
                                <br>
                            </div>
                            <div class="column is-1">
                              
                            </div>
                        </div>
                    </section>
                    <footer class="modal-card-foot">
                        <div class="columns" style="width: 100%">
                            <div class="column is-12" style="text-align: right">
                                <button class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}" @click="handleSubmit">Create payment</button>
                                <button class="button is-medium" type="button" @click="$parent.close()">Cancel</button>
                            </div>
                        </div>
                    </footer>
                </div>

        `,
    methods: {
        handleSubmit() {

            this.submitting = true;
            // Send data to the server or update your stores and such.

            const params = {
                id: this.invoiceId,
                payment_type: this.paymentType
            };

            this.$store.dispatch('createPaymentFromInvoiceId', params).then((data) => {

                this.$buefy.toast.open({
                    message: 'Order payment created',
                    type: 'is-success'
                });

                this.$emit('success');

            })
            .catch((err) => {

                this.$buefy.toast.open({
                    message: 'Error occurred while creating payment: '+this.$utils.getHumanErrorMessage(err),
                    type: 'is-danger',
                    duration: 10000
                });

                this.$emit('failed');

            })
            .finally(() => {
                this.submitting = false;
                this.$parent.close();
            });

        },

    },
    mounted() {
        this.$refs.invoiceId.focus();
    }
}

export default CreateManualOrderForm;

<template>

    <div>

        <b-tooltip :label="label">
            <b-tag v-if="!changeable" :size="size" class="state" :type="type">{{ label }}</b-tag>
        </b-tooltip>

        <b-dropdown v-if="changeable && value" v-model="value">
            <button :class="'button dropdown-state ' + type" slot="trigger">
                <span>{{ label }}</span>
                <b-icon icon="caret-down"></b-icon>
            </button>

            <b-dropdown-item @click="changeState" value="ACTIVE">Active</b-dropdown-item>
            <b-dropdown-item @click="changeState" value="PENDING_APPROVAL">Pending approval</b-dropdown-item>
            <b-dropdown-item @click="changeState" value="DECLINED">Declined</b-dropdown-item>
            <b-dropdown-item @click="changeState" value="INACTIVE">Inactive</b-dropdown-item>

        </b-dropdown>

    </div>

</template>

<script>
    export default {
        props: {
            value: { type: String },
            changeable: { type: Boolean, default: false },
            size: { type: String, default: 'is-medium' }
        },
        data: function () {
            return {
                type: 'is-info',
                label: ''
            }
        },
        methods: {
            changeState() {

                let state = this.value;

                switch(state) {
                    case 'ACTIVE':

                        this.type = 'is-success';
                        this.label = 'Active';
                        break;
                    case 'PENDING_APPROVAL':

                        this.type = 'is-warning';
                        this.label = 'Pending approval';
                        break;
                    case 'INACTIVE':

                        this.type = 'is-dark';
                        this.label = 'Inactive';
                        break;
                    case 'DECLINED':

                        this.type = 'is-danger';
                        this.label = 'Declined';
                        break;

                    case 'EXPIRED':

                        this.type = 'is-danger';
                        this.label = 'Expired';
                        break;
                }

                if(this.value)
                    this.$emit('input', this.value);
            }
        },
        watch: {
            value() {
                this.changeState();
            }
        },
        mounted() {
            this.changeState();
        }
    }
</script>

<style scoped>

</style>
<template>
    <section>
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <h1 class="is-pulled-left">Edit channel</h1>
                </div>
            </div>

            <!-- Right side -->
            <div class="level-right">
            </div>

        </nav>
        <hr>

        <form @submit.prevent="">

            <b-loading :active.sync="loading"></b-loading>

            <transition name="fade">
                <div v-if="!loading">
                    <div class="columns is-mobile">
                        <div class="column is-1"></div>
                        <div class="column is-10">
                            <section>
                                <b-tabs size="is-medium" class="block" v-model="activeTab" expanded>
                                    <b-tab-item>

                                        <template slot="header">
                                            <b-icon icon="user-plus"></b-icon>
                                            <span> General</span>
                                        </template>

                                        <div class="box">

                                            <div class="columns is-mobile">
                                                <div class="column is-2"></div>
                                                <div class="column is-8">
                                                    <!--<hr>-->

                                                    <label class="label-field">
                                                        Channel status
                                                    </label>

                                                    <channel-display-tag :channel="channel" :detailed="true"></channel-display-tag>

                                                    <v-template v-show="channel.enabled && ! channel.is_online">

                                                        <br><br>
<!--                                                            <strong>Could not connect to your WhatsApp phone.</strong><br>-->

                                                            <v-template v-if="channel.err_code === 'channel_conflict'">
                                                                <b-message type="is-danger">

                                                                    <p>
                                                                    Our systems have detected you have logged into WhatsApp Web. Textpop requires exclusive access to WhatsApp Web to function properly. Reset the connection by clicking the button below. This could take up to <u>1-3 minutes</u> for the connection to be restored.
                                                                </p>
                                                                    <br>
                                                                    <p>
                                                                        <button class="button is-danger" style="display: block" @click.prevent="resetChannel"><b-icon icon="sync" style="margin-right:10px"></b-icon> Reconnect</button>
                                                                    </p>
                                                                </b-message>
                                                            </v-template>
                                                            <v-template v-else>
                                                                <b-message type="is-info">

                                                                    <p>
                                                                        Scan the <b>WhatsApp Web QR code</b> to connect your phone number.
                                                                    </p>
    <br>
                                                                    <button class="button is-info" style="display: block" @click.prevent="scanQRCodeModal">Scan & Connect</button>
                                                                </b-message>
                                                            </v-template>

                                                    </v-template>

                                                    <br><br>

                                                    <label v-if="form.type === 'SMS'" class="label-field">
                                                        Originator id
                                                    </label>

                                                    <label v-else class="label-field">
                                                        Channel name
                                                    </label>

                                                    <b-field>
                                                        <b-input size="is-medium" maxlength="64" v-model="form.name" required></b-input>
                                                    </b-field>

                                                    <label class="label-field is-hidden">
                                                        Channel shortcode
                                                    </label>

                                                    <b-field class="is-hidden">
                                                        <b-input size="is-medium" maxlength="16" v-on:input="transformShortcode" v-model="form.short_code"></b-input>
                                                    </b-field>

                                                    <div v-show="form.type !== 'SMS'">
                                                        <label class="label-field">
                                                            Channel Enabled
                                                        </label>

                                                        <b-switch v-model="form.enabled" true-value="1" false-value="0"></b-switch>
                                                    </div>

                                                </div>
                                                <div class="column is-2"></div>
                                            </div>

                                            <div class="has-text-centered">
                                                <button class="button is-light is-medium" @click="update" type="button" v-bind:class="{'is-loading': submitting}">Update</button>
                                            </div>
                                        </div>

                                    </b-tab-item>

                                    <b-tab-item :visible="! is_shared && form.type === 'WHATSAPP'">

                                        <template slot="header">
                                            <b-icon icon="user-plus"></b-icon>
                                            <span>Subscribe Messages</span>
                                        </template>

                                        <b-message :closable="false" type="is-info" has-icon>
                                            Find below the messages that are automatically sent to users while subscribing or unsubscribing from the channel.
                                        </b-message>

                                        <div class="box">

                                            <div class="columns is-mobile">
                                                <div class="column is-2"></div>
                                                <div class="column is-8">

                                                    <!--<hr>-->

                                                    <label class="label-field">
                                                        User subscribes to channel <code>(subscribe_success)</code>
                                                    </label>

                                                    <b-field>
                                                        <b-input type="textarea"
                                                                 v-model="form.messages.subscribe_success.body"
                                                                 size="is-medium"
                                                                 rows="3"
                                                                 class="textarea-primary"
                                                                 placeholder="">
                                                        </b-input>
                                                    </b-field>

                                                    <label class="label-field">
                                                        User unsubscribes from channel <code>(unsubscribe_success)</code>
                                                    </label>

                                                    <b-field>
                                                        <b-input type="textarea"
                                                                 v-model="form.messages.unsubscribe_success.body"
                                                                 size="is-medium"
                                                                 rows="3"
                                                                 class="textarea-primary"
                                                                 placeholder="">
                                                        </b-input>
                                                    </b-field>

                                                    <label class="label-field">
                                                        User is already subscribed to channel <code>(already_subscribed)</code>
                                                    </label>

                                                    <b-field>
                                                        <b-input type="textarea"
                                                                 v-model="form.messages.already_subscribed.body"
                                                                 size="is-medium"
                                                                 rows="3"
                                                                 class="textarea-primary"
                                                                 placeholder="">
                                                        </b-input>
                                                    </b-field>

                                                    <label class="label-field">
                                                        User receives invitation to subscribe via SMS <code>subscribe_request_sms</code>
                                                    </label>

                                                    <b-field>
                                                        <b-input type="textarea"
                                                                 v-model="form.messages.subscribe_request_sms.body"
                                                                 size="is-medium"
                                                                 rows="3"
                                                                 class="textarea-primary"
                                                                 placeholder="">
                                                        </b-input>
                                                    </b-field>

                                                    <label class="label-field">
                                                        User receives invitation to subscribe via Whatsapp <code>subscribe_request_wa</code>
                                                    </label>

                                                    <b-field>
                                                        <b-input type="textarea"
                                                                 v-model="form.messages.subscribe_request_wa.body"
                                                                 size="is-medium"
                                                                 rows="3"
                                                                 class="textarea-primary"
                                                                 placeholder="">
                                                        </b-input>
                                                    </b-field>

                                                </div>
                                                <div class="column is-2"></div>
                                            </div>

                                            <div class="has-text-centered">
                                                <button class="button is-primary is-medium" @click="update" type="button" v-bind:class="{'is-loading': submitting}">Update</button>
                                            </div>
                                        </div>

                                    </b-tab-item>

                                    <b-tab-item :visible="! is_shared && $store.getters.userProfile && ($store.getters.userProfile.role === 'SUPER' || $store.getters.impersonateUserId)">
                                        <template slot="header">
                                            <b-icon icon="user-plus"></b-icon>
                                            <span> Superadmin settings</span>
                                        </template>

                                        <div class="box">

                                            <div class="columns is-mobile">
                                                <div class="column is-2"></div>

                                                <div class="column is-8">

                                                    <!--<span>{{ is_online ? 'ONLINE' : 'OFFLINE' }}</span>-->
                                                    <!--<span>{{ err_code ? '(' + err_code + ')' : '' }}</span>-->

                                                    <hr>

                                                    <div class="label-field">Connector settings</div>

                                                    <b-field>
                                                        <b-input type="textarea"
                                                                 v-model="form.connector_data"
                                                                 size="is-medium"
                                                                 rows="6"
                                                                 class="textarea-primary"
                                                                 placeholder="">
                                                        </b-input>
                                                    </b-field>

                                                    <hr>

                                                    <div class="label-field">Connector info</div>
                                                    Last updated at: {{ channel.updated_at | moment('timezone', $global.current_tz) }} which is {{ channel.updated_at | moment('timezone', $global.current_tz) | moment("from", "now") }}

                                                    <vue-json-pretty
                                                            :data="vendor_data"
                                                            >
                                                    </vue-json-pretty>

                                                    <hr>

                                                    <div class="has-text-centered">
                                                        <button class="button is-primary is-medium" @click="update" type="button">Update</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab-item>

                                </b-tabs>

                            </section>
                        </div>
                        <div class="column is-1"></div>
                    </div>

                    <br>
                </div>
            </transition>

        </form>

    </section>
</template>

<script>

    import VueJsonPretty from 'vue-json-pretty';
    import ScanQRCodeModal from '../helpers/scanQRCode';

    export default {
        components: {
            VueJsonPretty
        },
        data() {
            return {
                id: this.$route.params.channelId,
                modal: this.$route.params.modal || null,
                data: {},
                form: {
                    name: '',
                    short_code: '',
                    enabled: null,
                    type: null,
                    connector_data: {},
                    messages: {
                        subscribe_success: {
                            body: null
                        },
                        unsubscribe_success: {
                            body: null
                        },
                        already_subscribed: {
                            body: null
                        },
                        subscribe_request_wa: {
                            body: null
                        },
                        subscribe_request_sms: {
                            body: null
                        }
                    }

                },
                is_online: null,
                err_code: null,
                vendor_data: null,

                is_shared: null,

                qr_code_image: null,
                qr_code_generated_at: null,

                submitting: false,
                loading: true,
                channel: null,

                activeTab: 0,
                freshDataInterval: 6000
            }
        },
        computed: {
        },
        methods: {
            /*
             * Load async data
             */
            loadAsyncData() {

                this.loading = true;

                // this.loading = true;

                const params = {
                    id: this.id
                };

                this.$store.dispatch('fetchChannel', params).then((data) => {

                    // update view with current campaign settings
                    let result = data.result;

                    this.channel = result;

                    this.form.name = result.name;
                    this.form.short_code = result.short_code;
                    this.form.type = result.type;

                    this.form.origin = result.origin;
                    this.form.enabled = result.enabled;
                    this.form.connector_data = result.connector_data;

                    this.is_online = result.is_online;
                    this.err_code = result.err_code;

                    const messages = JSON.parse(result.messages);
                    if(Object.keys(messages).length > 0 && messages.constructor === Object) {
                        this.form.messages = JSON.parse(result.messages);
                    }

                    this.qr_code_image = result.qr_code_image;
                    this.qr_code_generated_at = result.qr_code_generated_at;
                    this.is_shared = result.is_shared;

                    this.vendor_data = result.vendor_data ? JSON.parse(result.vendor_data) : {};

                    try {
                        this.vendor_data.instanceInfo = JSON.parse(this.vendor_data.instanceInfo);
                    } catch(e) {
                        this.vendor_data.instanceInfo = {};
                    }

                })
                .catch((error) => {

                    this.$buefy.toast.open({
                        message: 'The requested channel could not be found!',
                        type: 'is-danger'
                    });

                    // this.$router.push({ name: 'channels' });

                    this.data = [];
                    this.total = 0;
                    throw error
                })
                .finally(() => {
                    this.submitting = false;
                    this.loading = false;
                });

            },
            transformShortcode() {
                this.form.short_code = this.form.short_code.replace(/\s/g, '').toUpperCase();
            },

            scanQRCodeModal() {

                this.$buefy.modal.open({
                    parent: this,
                    component: ScanQRCodeModal,
                    props: {
                        channelId: this.id,
                        channelOrigin: this.channel.origin
                    },
                    events: {
                        success: (data) => {
                            this.loadAsyncData();
                        }
                    },
                    hasModalCard: true
                })
            },

            /*
            * Handle remove a channel
            */
            resetChannel() {

                const params = {
                    id: this.id
                };

                this.$store.dispatch('resetChannel', params).then((data) => {

                    this.$buefy.toast.open({
                        message: 'Re-enable connection requested successfully. It could take up from 1 to 3 minutes before the channel connection is restored.',
                        type: 'is-info'
                    });

                    let result = data.result;
                    this.$router.push({ name: 'channels' });

                    // this.loadAsyncData();
                }).catch((error) => {
                    throw error
                });

            },

            update() {

                this.submitting = true;

                const params = this.form;
                params.id = this.id;

                // transform messages
                params.messages = JSON.stringify(this.form.messages);

                this.$store.dispatch('updateChannel', params).then((data) => {

                // push to view
                this.$router.go(-1);
                // this.$router.push('/contactlists');

                })
                .catch((err) => {

                    this.$buefy.toast.open({
                        message: 'Error saving channel details: '+err,
                        type: 'is-danger'
                    });
                })
                .finally(() => {
                    this.submitting = false;
                });

            }
        },
        beforeUpdate() {

        },
        mounted() {
            this.loadAsyncData();

            if(this.modal === 'SCAN_QR_MODAL') {

                setTimeout(() => {
                    this.scanQRCodeModal();
                },1000);
            }
        }
    }
</script>

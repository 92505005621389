<template>
    <div>
        <label class="label-field" v-if="label">
            {{ label }}
        </label>

        <!--<b-loading :active.sync="loading" :is-full-page="false"></b-loading>-->

        <p>
            <b-dropdown v-show="!loading" :disabled="channels.length === 0 || disabled" type="is-primary" v-model="origin" @change="$emit('origin')" class="modal-dropdown-content" loading>

                <button class="button is-info is-medium" slot="trigger">
                    <span v-if="channels.length > 0">{{ channel_dropdown_lbl }}</span>
                    <span v-else>
                            No channels found<br>
                        </span>
                    <b-icon icon="caret-down"></b-icon>
                </button>

                <b-dropdown-item v-for="obj in channels" :key="id" :disabled="! allowClickInactiveChannels && !obj.is_online" @click="handleChannelItemClick(obj)" style="padding-right: 18px">
                    <div style="width:100%">
                        {{obj.name}} <b-tag v-if="obj.type === 'WHATSAPP'" style="margin-left: 13px" type="is-light">+{{obj.origin}}</b-tag>
                    </div>
                    <div>
                        <b-tag v-if="obj.is_online" class="is-pulled-right" type="is-success">ONLINE</b-tag>
                        <b-tag v-else class="is-pulled-right" type="is-danger">ERROR</b-tag>
                    </div>
                </b-dropdown-item>

            </b-dropdown>

            <b-dropdown v-show="loading" disabled type="is-primary" class="modal-dropdown-content" loading>

                <!--<b-loading :is-full-page="true" :active.sync="loading"></b-loading>-->
                <button class="button is-info is-medium" slot="trigger">
                    <button-spinner></button-spinner>
                    <!--<span style="margin-left:16px" class="loader"></span>-->
                </button>

            </b-dropdown>

        </p>
    </div>
</template>

<script>
    export default {
        props: {
            value: { type: Number },
            label: { type: String, default: '' },
            phone: { type: String, default: '' },
            allowClickInactiveChannels: { type: Boolean, default: false },
        },
        data: function () {
            return {
                channels: [

                ],
                channel_dropdown_lbl: 'Choose channel',
                loading: true,
                origin : null,
                id: null,
                disabled : false
            }
        },
        methods: {
            handleChannelItemClick(obj) {

                if(obj.type === 'WHATSAPP') {
                    this.channel_dropdown_lbl = obj.name;
                } else if(obj.type === 'SMS') {
                    this.channel_dropdown_lbl = `${obj.name} (+${obj.origin})`;
                }

                this.$emit('change', obj);
                this.$emit('input', obj.id);
            },

            loadAsyncData() {

                const params = [
                    'enabled=1'
                ].join('&');
                this.$store.dispatch('fetchChannels', params ).then((data) => {

                    let channels = data.result.data;

                    let selectedItemFound = false;

                    for (let i = 0; i < channels.length ; i++) {
                        channels[i].ddIndex = i;
                        this.channels.push(channels[i]);

                        // check if channel_id is passed to component
                        if(!selectedItemFound && this.value) {

                            if( channels[i].id === this.value &&
                                channels[i].enabled &&
                                channels[i].is_online) {

                                this.$emit('input', channels[i].id);

                                this.handleChannelItemClick({ name: channels[i].name, id: channels[i].id, origin: channels[i].origin });

                                selectedItemFound = true;

                            }

                        } else {

                            // if not get the first available/online channel
                            if( channels[i].enabled &&
                                channels[i].is_online) {

                                selectedItemFound = true;

                                this.handleChannelItemClick({ name: channels[i].name, id: channels[i].id, origin: channels[i].origin });
                            }
                        }

                    }


                })
                    .catch((error) => {
                        this.data = [];
                        this.total = 0;
                        throw error
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>

<style scoped>

</style>
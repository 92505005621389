<template>
    <div style="display: inline-block" v-if="channel">
        <!--<router-link :to="{ name: 'channel-edit', params: {channelId: channel.id} }" class="no-underline">-->

            <b-taglist attached>
                <b-tag v-if="showName && channel.name" type="is-light" size="is-medium" style="min-width: 160px; max-width:160px; overflow: hidden;">
                    {{ channel.name }}
                </b-tag>

                <b-tooltip size="is-large" type="is-light" :label="channel.name">
                    <b-tag v-if="showOrigin && channel.origin" type="is-light" size="is-large" style="min-width: 120px; max-width:120px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                        <span v-if="channel.type === 'WHATSAPP'" style="font-size:14px">+{{channel.origin}}</span>
                        <span v-else-if="channel.type === 'SMS'" style="font-size:14px">{{channel.name}}</span>
                    </b-tag>
                </b-tooltip>

                <template v-if="showStatus">
                    <b-tooltip size="is-large" :type="is-light"  style="margin-top:-3px !important" :label="setTooltipLabel">
                        <router-link tag="span" class="clickable" :to="{ name: 'channel-edit', params: {channelId: channel.id, modal: (channel.err_code === 'scanning_qr_code_required' ? 'SCAN_QR_MODAL' : null) } }">

                            <b-tag :type="tagType" style="overflow: hidden;" size="is-medium">
                                <span v-if="! channel.enabled">
                                    <b-icon icon="stop-circle"></b-icon>
                                </span>
                                <span v-else-if="channel.is_online">
                                    <b-icon icon="check"></b-icon>
                                </span>
                                <span v-else-if="channel.err_code === 'scanning_qr_code_required'">
                                    <b-icon icon="qrcode"></b-icon>
                                </span>
                                <span v-else-if="! channel.is_online">
                                        <b-icon icon="times"></b-icon>
                                </span>
                            </b-tag>
                        </router-link>

                        <!--<b-tag :type="! channel.enabled ? 'is-black' : channel.is_online ? 'is-success' : 'is-danger'" size="is-medium">-->
                        <!--{{ setTooltipLabel }}-->
                        <!--</b-tag>-->

                    </b-tooltip>
                    <b-tag :type="tagType" v-if="showBattery && batteryPct && this.channel.enabled" size="is-medium" style="min-width: 80px; max-width:120px; overflow: hidden;">{{ batteryPct }}%&nbsp;&nbsp;<b-icon pack="fas" :icon="getBatteryIcon" style="display: inline-block; padding-left: 2px;"></b-icon>&nbsp;&nbsp;
                    </b-tag>
                    <b-tag :type="tagType" v-if="detailed" size="is-medium" style="min-width: 120px; overflow: hidden;">
                        {{ setTooltipLabel }}
                    </b-tag>
                </template>
            </b-taglist>

        <!--</router-link>-->
    </div>
</template>

<script>
    export default {
        props: {
            channel: Object,
            detailed: { type: Boolean, default: false },
            showBattery: { type: Boolean, default: false },
            showName: { type: Boolean, default: false },
            showOrigin: { type: Boolean, default: true },
            showStatus: { type: Boolean, default: true }
        },
        data: function () {
            return {
                // channel: this.channel,
                vendor_data: {},
                instanceInfo: {},
                batteryPct: null
            }
        },
        computed: {
            tagType() {
                if(this.channel.is_online && this.channel.enabled) {
                    return 'is-success';
                } else if(this.channel.err_code === 'scanning_qr_code_required') {
                    return 'is-warning';
                } else if(! this.channel.enabled) {
                    return 'is-black';
                } else {
                    return 'is-danger';
                }
            },
            setTooltipLabel() {
                if(this.channel.is_demo) {
                    return 'Channel is for demo purposes only'
                } else if (! this.channel.enabled) {
                    return 'Channel is disabled. Re-enable the channel to use the channel';
                } else if(this.channel.err_code === 'scanning_qr_code_required') {
                    return 'Scanning QR code required'
                } else if (this.channel.is_online) {
                    return 'Channel is online and active';
                } else if (!this.channel.is_online) {
                    return this.channel.err_code || 'Channel is offline'
                }
            },
            getBatteryIcon() {
                if(typeof this.instanceInfo.plugged !== 'undefined' && this.instanceInfo.plugged) {
                    return 'plug'
                } else if (this.batteryPct > 98) {
                    return 'battery-full'
                } else if(this.batteryPct > 75) {
                    return 'battery-three-quarters'
                } else if(this.batteryPct >= 50) {
                    return 'battery-half'
                } else if(this.batteryPct > 25) {
                    return 'battery-quarter'
                } else {
                    return 'battery-empty'
                }
            }
        },
        methods: {
        },
        mounted() {

            if(this.channel) {
                this.vendor_data = this.channel.vendor_data ? JSON.parse(this.channel.vendor_data) : {};
                this.instanceInfo = this.vendor_data.instanceInfo ? JSON.parse(this.vendor_data.instanceInfo) : {};
                this.batteryPct = this.instanceInfo.battery;
            }

            // this.batteryPct = 80;
        }
    }
</script>

<style scoped>

</style>

<template>
    <div>
        <div class="box has-text-centered">
            <div class="columns">
                <div class="column is-1"></div>
                <div class="column is-10">
                    <div class="">
                        <b-dropdown class="modal-dropdown-content" loading>
                            <button class="button is-info is-medium" slot="trigger">
                                <span>Create from template...</span>
                                <b-icon icon="caret-down"></b-icon>
                            </button>

                            <b-dropdown-item @click="showTemplateGallery()">
                                Select from gallery...
                            </b-dropdown-item>

                            <b-dropdown-item separator></b-dropdown-item>

                            <template v-if="templates.length > 0">
                                <b-dropdown-item v-for="obj in templates" :key="id" @click="handleTemplateItemClick(obj)">
                                    <div class="media" style="display: contents">
                                        <div class="media-content">
                                            <span>{{obj.name}}</span>
                                        </div>
                                        <attachment-icon v-if="obj.attachment" class-name="media-right" style="padding: 5px 5px 0px 5px" :attachment-type="obj.mime_type"></attachment-icon>
                                    </div>
                                </b-dropdown-item>
                            </template>

                            <template v-else>
                                <b-dropdown-item disabled>
                                    No templates configured
                                </b-dropdown-item>
                            </template>

                        </b-dropdown>
                    </div>
                </div>
            </div>
        </div>

        <div class="box has-text-centered">

            <div class="columns">
                <div class="column is-1"></div>
                <div class="column is-10">

                    <div v-if="! disableAttachments" class="box">
                        <label class="label-field">
                            Media attachment (gif, jpeg, png, mp3, mp4 or pdf)
                        </label>

                        <div v-if="loadingAttachment" class="loader" style="display: inline-block; zoom: 2"></div>

                        <b-field v-show="! value.uploadFileContent || showAdvancedSettings">

                            <b-upload v-on:input="previewFile" type="is-info"
                                      drag-drop>
                                <section class="section">
                                    <div class="content has-text-centered">
                                        <b-icon
                                                icon="upload"
                                                size="is-medium">
                                        </b-icon>
                                        <span>&nbsp;Drop your media file here or click to upload</span>
                                    </div>
                                </section>
                            </b-upload>
                        </b-field>

                        <br>

                        <a v-show="! value.uploadFileContent && !showAdvancedSettings" @click="showAdvancedSettings = true; $refs.attachment_url.focus()" href="#">advanced settings</a>
                        <div v-show="showAdvancedSettings">
                            <label class="label-field">
                                Or enter an external url:
                            </label>

                            <b-field grouped>
                                <b-input size="is-medium" maxlength="1024" ref="attachment_url" v-model="value.uploadFileContent" expanded></b-input>
                                <b-dropdown class="is-pulled-right">
                                    <button class="button is-light" slot="trigger">
                                        <span>Insert merge tag</span>
                                        <b-icon icon="caret-down"></b-icon>
                                    </button>

                                    <b-dropdown-item @click="insertIntoAttachmentUrl('[[ATTR1]]')">Custom attribute 1</b-dropdown-item>
                                    <b-dropdown-item @click="insertIntoAttachmentUrl('[[ATTR2]]')">Custom attribute 2</b-dropdown-item>
                                    <b-dropdown-item @click="insertIntoAttachmentUrl('[[ATTR3]]')">Custom attribute 3</b-dropdown-item>
                                </b-dropdown>

<!--                                <b-button type="is-info" size="is-medium" @click="setAttachmentUrl">Update</b-button>-->
                            </b-field>
                        </div>
                        <div v-show="value.uploadFileContent">
                            <span v-if="uploadFileMimeType" class="tag is-light rich-tag">
                                <audio v-on:loadstart="loadingAttachment = true" v-on:loadeddata="loadingAttachment = false" v-if="uploadFileMimeType === 'audio/mpeg' || uploadFileMimeType === 'audio/mp3'" class="imageUpload" controls v-bind:src="value.uploadFileContent" alt="previewing audio"></audio>
                                <video v-on:loadstart="loadingAttachment = true" v-on:loadeddata="loadingAttachment = false" v-else-if="uploadFileMimeType === 'video/mp4'" width="320" height="240" v-bind:src="value.uploadFileContent" controls></video>
                                <img v-on:load="loadingAttachment = false" v-else-if="uploadFileMimeType === 'image/png' || uploadFileMimeType === 'image/gif' || uploadFileMimeType === 'image/jpeg'" class="imageUpload" v-bind:src="value.uploadFileContent" alt="previewing image" />
                                <span v-else-if="uploadFileMimeType === 'application/pdf'">PDF document</span>
                                <span v-else="uploadFileMimeType === 'unknown'">Personalised attachment</span>

                                <button class="delete"
                                        type="button"
                                        v-show="value.uploadFileContent" @click="value.uploadFileContent = null">
                                </button>
                            </span>
                            <p v-show="uploadFileMimeType === 'image/gif'">
                                <br>
                                <b-message type="is-info" has-icon>Note. animated gifs are not supported. To send animated media, use mp4 video's instead.</b-message>
                            </p>
                        </div>
                    </div>

                    <div class="box">

                        <div>
                            <label class="label-field">
                                <template v-if="messageType === 'WHATSAPP'">WhatsApp Message</template>
                                <template v-else-if="messageType === 'SMS'">SMS Message</template>
                                <template v-else>Message</template>
                            </label>

                            <b-message v-show="personalPlaceHoldersUsed" title="Personalised Messages" type="is-info">
                                Your message contains one or more personal merge tags. This requires you to specify a <a href="#fallback-msg"><strong>fallback message</strong></a> when any of the personal information is missing from contact profiles
                            </b-message>

                            <b-message v-show="messageType === 'SMS' && value.message_body.length > 160" title="SMS Character limit" type="is-warning" aria-close-label="Close message">
                                When you send a SMS campaign message over 160 characters the message will be split. Large messages are segmented into 153 character segements and sent individually then rebuilt by the recipients device.
                            </b-message>

                            <div class="columns">
                                <div class="column is-12">

                                    <b-field>
                                        <b-input type="textarea"
                                                 minlength="1"
                                                 maxlength="2000"
                                                 v-model="value.message_body"
                                                 ref="contentField"
                                                 size="is-medium"
                                                 rows="6"
                                                 class="textarea-primary"
                                                 placeholder="Specify personalised message (eg. Hi [[FIRST_NAME]], ..."
                                                 @input="onMessagebodyChange"
                                        >
                                        </b-input>
                                    </b-field>

                                    <b-dropdown class="is-pulled-right">
                                        <button class="button is-light" slot="trigger">
                                            <span>Insert merge tag</span>
                                            <b-icon icon="caret-down"></b-icon>
                                        </button>

                                        <b-dropdown-item @click="insertIntoMessageBody('[[FIRST_NAME]]')">First name</b-dropdown-item>
                                        <b-dropdown-item @click="insertIntoMessageBody('[[LAST_NAME]]')">Last name</b-dropdown-item>
                                        <b-dropdown-item @click="insertIntoMessageBody('[[COMPANY]]')">Company</b-dropdown-item>
                                        <b-dropdown-item @click="insertIntoMessageBody('[[PHONE]]')">Phone</b-dropdown-item>
                                        <b-dropdown-item @click="insertIntoMessageBody('[[LINK]]')">Link</b-dropdown-item>
                                        <b-dropdown-item @click="insertIntoMessageBody('[[ATTR1]]')">Custom attribute 1</b-dropdown-item>
                                        <b-dropdown-item @click="insertIntoMessageBody('[[ATTR2]]')">Custom attribute 2</b-dropdown-item>
                                        <b-dropdown-item @click="insertIntoMessageBody('[[ATTR3]]')">Custom attribute 3</b-dropdown-item>
                                        <b-dropdown-item :separator="true"></b-dropdown-item>
                                        <b-dropdown-item @click="insertIntoMessageBody('[[*SENDER_FIRST_NAME]]')">Sender first name</b-dropdown-item>
                                        <b-dropdown-item @click="insertIntoMessageBody('[[*SENDER_LAST_NAME]]')">Sender last name</b-dropdown-item>
                                        <b-dropdown-item @click="insertIntoMessageBody('[[*SENDER_COMPANY]]')">Sender company</b-dropdown-item>
                                    </b-dropdown>

                                    <emoji-selector style="float: left" picker-style="margin-top:-140px; margin-left: 30px" @click="addEmoji"></emoji-selector>

                                </div>
                            </div>

                        </div>
                        <hr>

                        <div v-show="personalPlaceHoldersUsed">

                            <a name="fallback-msg"></a>
                            <label class="label-field">
                                Fallback message
                                <b-tooltip multilined type="is-light" label="The message that will be sent to contacts whenever at least one placeholder is not defined for a particular contact. e.g. when inserting placeholder [[LAST_NAME]] into the message, and the last name isn't set, the fallback message will be sent instead." style="vertical-align: middle;" position="is-right" >
                                    <b-icon size="is-small" type="is-dark" icon="info-circle" style="margin-left:4px;"></b-icon>
                                </b-tooltip>
                            </label>


                            <!--<sub type="is-warning" has-icon>-->
                                <!--The message below will be sent in case merge tags are not specified in contact profiles.-->
                            <!--</sub>-->

                            <b-message v-show="messageBodyGenericContainsPlaceholders" type="is-danger" has-icon>
                            The unpersonalised message below cannot contain personal merge tags e.g. [[FIRST_NAME]]
                            </b-message>

                            <!--<b-message v-show="messageBodyGenericContainsPlaceholders" type="is-danger" has-icon>-->
                                <!--The unpersonalised message below cannot contain personal merge tags e.g. [[FIRST_NAME]]-->
                            <!--</b-message>-->

                            <div class="columns">
                                <div class="column is-12">
                                    <b-field label="">
                                        <b-input type="textarea"
                                                 minlength="1"
                                                 maxlength="2000"
                                                 v-model="value.message_body_generic"
                                                 size="is-medium"
                                                 rows="6"
                                                 ref="message_body_generic"
                                                 @input="onMessagebodyGenericChange"
                                                 placeholder="Specify generic message. This message will be sent when placeholders are not found for this contact (eg. Hi there, ...)">
                                        </b-input>
                                    </b-field>

                                    <b-dropdown class="is-pulled-right">
                                        <button class="button is-light" slot="trigger">
                                            <span>Insert merge tags</span>
                                            <b-icon icon="caret-down"></b-icon>
                                        </button>

                                        <b-dropdown-item @click="value.message_body_generic += ' [[*SENDER_FIRST_NAME]]'">Sender First name</b-dropdown-item>
                                        <b-dropdown-item @click="value.message_body_generic += ' [[*SENDER_LAST_NAME]]'">Sender Last name</b-dropdown-item>
                                        <b-dropdown-item @click="value.message_body_generic += ' [[*SENDER_COMPANY]]'">Sender Company</b-dropdown-item>
                                    </b-dropdown>

                                    <emoji-selector style="float: left" picker-style="margin-top:-140px; margin-left: 30px" @click="value.message_body_generic += $event"></emoji-selector>

                                </div>
                            </div>
                        </div>
                    </div>

                    <br>
                </div>
                <div class="column is-1"></div>
            </div>

        </div>
    </div>
</template>

<script>

    import EmojiSelector from '../common/emoji-selector.vue';
    import TemplateGallery from '../../helpers/templateGallery';

    export default {
        components: {
            EmojiSelector
        },
        props: {
            value: {
                type: Object,
            },
            messageType: {
                type: String,
                default: 'WHATSAPP'
            },
            disableAttachments: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                message_body: 'Hi [[FIRST_NAME]], How are you today? Check out our new website! https://bit.ly/2NdFkSi Cheers [[*SENDER_FIRST_NAME]]',
                message_body_generic: 'Hi there, How are you today? Check out our new website! https://bit.ly/2NdFkSi Cheers [[*SENDER_FIRST_NAME]]',
                uploadFileContent: null,
                uploadFileMimeType: null,

                uploadMaxFileSizeInKb: 15000,
                templates: [],
                template_dropdown_lbl: 'Select',
                template_id: null,

                id: null,
                search: null,

                personalPlaceHoldersUsed: false,
                messageBodyGenericContainsPlaceholders: false,

                loading: true,
                loadingAttachment: false,

                // attachment_type: null, // local, remote, custom ([[ATTR1]])

                showAdvancedSettings: false
            }
        },
        watch: {
            // whenever question changes, this function will run
            value: function (newValue, oldValue) {

                this.message_body = newValue.message_body;
                this.message_body_generic = newValue.message_body_generic;
                this.uploadFileContent = newValue.uploadFileContent;
                this.uploadFileMimeType = newValue.uploadFileMimeType;

                this.template_id = newValue.template_id;
                this.onMessagebodyChange(newValue.message_body);

            }
        },
        computed: {
            getMaxFileSize() {
                return this.uploadMaxFileSizeInKb * 1000;
            }
        },
        methods: {

            focus: function () {
                this.$refs.message_body_generic.focus();
            },

            setAttachmentUrl() {
                // this.attachment_url = this.attachment_url.trim();
                // this.value.uploadFileContent = this.attachment_url;
                // this.uploadFileMimeType = this.$utils.getMimeTypeOfUrl(this.attachment_url);
            },

            insertIntoMessageBody(m) {
                this.value.message_body += ' '+m;
                this.onMessagebodyChange(this.value.message_body);
            },

            insertIntoAttachmentUrl(m) {
                if(! this.value.uploadFileContent) {
                    this.value.uploadFileContent = '';
                }
                this.value.uploadFileContent += ' '+m;
            },

            addEmoji(emoji) {
                this.value.message_body += emoji;
                this.$refs.contentField.focus();
            },

            onMessagebodyChange(s) {

                const wasPersonalised = this.personalPlaceHoldersUsed;
                const isPersonalised = this.containsPersonalisedFields(s);

                if(! wasPersonalised && isPersonalised) {
                    // was not personalised previously, but now it is.. -> remove any personal placeholders
                    // this.value.message_body_generic = this.message_body_generic = this.removePersonalPlaceholders(s);
                }

                // if not personalised, set the main message for generic (fallback) message as well (for housekeeping and unforeseen wrong messages being sent)
                if(! isPersonalised) {
                    this.value.message_body_generic = this.removePersonalPlaceholders(s);
                }

                this.personalPlaceHoldersUsed = isPersonalised;

                this.$emit('changePersonalised', isPersonalised);
            },

            onMessagebodyGenericChange(s) {

                this.messageBodyGenericContainsPlaceholders = this.containsPersonalisedFields(s)

            },

            async getBase64ImageFromUrl(imageUrl) {
                var res = await fetch(imageUrl);
                var blob = await res.blob();

                return new Promise((resolve, reject) => {
                    var reader  = new FileReader();
                    reader.addEventListener("load", function () {
                        resolve(reader.result);
                    }, false);

                    reader.onerror = () => {
                        return reject(this);
                    };

                    reader.readAsDataURL(blob);
                })
            },

            loadAsyncData() {

                const params = [
                    'is_public=true',
                    'type=MAIN'
                ].join('&');

                this.$store.dispatch('fetchTemplates', params).then((data) => {

                    let template = data.result.data;

                    if(template.length) {

                        for (let i = 0; i < template.length; i++) {
                            this.templates.push(template[i]);
                            this.templates[i].ddIndex = i;
                        }
                    }

                })
                    .catch((error) => {
                        this.data = [];
                        this.total = 0;
                        throw error
                    })
                    .finally(() => {
                        this.loading = false;
                    });

            },

            handleTemplateItemClick(obj) {

                this.template_dropdown_lbl = obj.name;
                this.template_id = obj.id;

                // update message fields
                this.value.message_body = obj.message_body;
                this.value.message_body_generic = obj.message_body_generic;

                // convert image to base64
                if(obj.attachment) {
                    this.getBase64ImageFromUrl(obj.attachment)
                        .then(result => {

                            this.uploadFileMimeType = obj.mime_type;
                            this.value.uploadFileContent = result;
                            // this.$emit('input', this.value);
                        })
                        .catch(err => console.error(err));
                } else {
                    this.value.uploadFileContent = null;
                    this.value.uploadFileMimeType = null;
                }

            },

            previewFile(file) {
                if (file) {

                    this.loadingAttachment = true;
                    this.attachment_url = null; // clear  external attachment url
                    this.showAdvancedSettings = false;

                    const reader = new FileReader();

                    reader.onload = (e) => {

                        this.loadingAttachment = false;

                        // limit size
                        if(e.total > this.getMaxFileSize) {

                            this.$buefy.dialog.alert({
                                title: 'Invalid file upload',
                                message: `  <b>Uploaded file size is too large (${this.getMaxFileSize} kb).</b><br><br>Please reduce the size or choose a different video or image with a smaller file size.`,
                                type: 'is-danger',
                                hasIcon: true,
                                icon: 'times-circle',
                                iconPack: 'fa'
                            });

                            file = null;
                            return false;
                        }

                        let[mime] = e.target.result.split(';');
                        mime = mime.replace('data:','');

                        this.uploadFileMimeType = mime;

                        switch(mime) {
                            case 'image/png':
                            case 'image/jpeg':
                            case 'image/gif':
                            case 'video/mp4':
                            case 'audio/mp3':
                            case 'audio/mpeg':

                            case 'application/pdf':

                                this.value.uploadFileContent = e.target.result;
                                return true;
                                break;
                            default:

                                this.$buefy.dialog.alert({
                                    title: 'Invalid file upload',
                                    message: 'Only png, gif, jpeg, pdf, mp3 or mp4 files can be inserted as an attachment!',
                                    type: 'is-danger',
                                    hasIcon: true,
                                    icon: 'times-circle',
                                    iconPack: 'fa'
                                });

                                return false;
                                break;
                        }


                    };

                    reader.readAsDataURL(file);
                }

                // required to set files to null otherwise uploading another file will not work
                file = null;

            },

            showTemplateGallery() {
                this.$buefy.modal.open({
                    parent: this,
                    component: TemplateGallery,
                    hasModalCard: true,
                    width: '900px'
                })
            },

            setTemplateFields(fields) {

                this.value.message_body = fields.message_body;
                this.value.message_body_generic = fields.message_body_generic;
                this.value.uploadFileContent = fields.attachment;

            },

            containsPersonalisedFields(str) {
                const regex = /\[\[(?!\*)([^}]\S+)\]\]/;

                let m;

                if ((m = regex.exec(str)) !== null) {
                    return true;
                } else {
                    return false;
                }

            },

            removePersonalPlaceholders(msg) {
                const regex = /\[\[(?!\*)([^}]*)\]\]/g;
                return msg.replace(regex, "");
            }

        },
        mounted() {

            if(this.value.uploadFileContent) {
                this.loadingAttachment = true;
            }

            setTimeout(() => {

                if(this.value.uploadFileContent) {
                    if(this.value.uploadFileContent.indexOf('data:') > -1) {
                        // local
                        const [mime] = this.value.uploadFileContent.split(';');
                        this.uploadFileMimeType = mime.substr(5);

                        // console.log(mime);
                    } else {
                        // is absolute external url or custom

                        this.uploadFileMimeType = this.$utils.getMimeTypeOfUrl(this.value.uploadFileContent);

                        if(this.uploadFileMimeType === 'unknown') {
                            this.showAdvancedSettings = true;
                        } else {

                        }
                        // this.attachment_url = this.value.uploadFileContent;

                        // this.uploadFileMimeType = mimeType;
                    }

                }
                this.onMessagebodyChange(this.value.message_body);

            },1000);

            this.loadAsyncData()
        }
    }
</script>

<style scoped>

</style>

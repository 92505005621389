<template>
    <section>
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <div>
                        <h1>Billing</h1>
                    </div>
                </div>
            </div>
        </nav>

        <form @submit.prevent="">

            <b-loading :active.sync="loading"></b-loading>

            <transition name="fade">
                <div class="columns is-mobile">
                    <!--<div class="column is-1"></div>-->
                    <div class="column is-12">
                        <section>
                            <b-tabs v-model="tabIndex" type="is-centered" size="is-medium" class="block" expanded>
                                <b-tab-item>

                                    <template slot="header">
                                        <b-icon icon="columns"></b-icon>
                                        <span> Overview</span>
                                    </template>

                                    <div class="box has-text-centered">
                                        <p style="font-weight:800">Your {{ $global.product_name }} account is currently in plan: <b-tag size="is-small">{{ $store.state.userProfile.account.plan }}.</b-tag></p>
                                        <p v-if="$store.state.userProfile.account.account_config.total_contact_limit > -1" style="font-weight:400">Contact volume limit is: <b-tag type="is-info">{{ $store.state.userProfile.account.account_config.total_contact_limit }}</b-tag></p>
                                        <p v-if="$store.state.userProfile.account.account_config.sms_enabled" style="font-weight:400">
                                            SMS credits left: <b-tag type="is-info">{{ $store.state.userProfile.account.sms_credits_left }}</b-tag>
<!--                                            <a href="#" class="is-small" style="margin-left: 20px" @click="upgradeCredits"><b>Upgrade credits</b></a>-->
                                        </p>
                                        <p v-if="$store.state.userProfile.account.plan === 'PAY AS YOU GO' && $store.state.userProfile.account.account_config.wa_enabled" style="font-weight:400">
                                            WhatsApp credits left: <b-tag type="is-info">{{ $store.state.userProfile.account.wa_credits_left }}</b-tag>
                                            <button v-show="" class="button is-light is-small" style="margin-left: 20px" @click="upgradeCredits"><b>Upgrade credits</b></button>
                                        </p>
                                        <p v-else-if="$store.state.userProfile.account.plan !== 'PAY AS YOU GO' && $store.state.userProfile.account.account_config.wa_enabled" style="font-weight:400">
                                            WhatsApp credits left: <b-tag type="is-info">{{ $store.state.userProfile.account.wa_credits_left }}</b-tag>
                                        </p>
                                        <br>
                                        <button v-show="$store.state.userProfile.account.plan === 'PLUS' || $store.state.userProfile.account.plan === 'PAY AS YOU GO'" class="button is-info" style="margin-left: 20px" @click="upgradeCredits"><b>Upgrade plan</b></button>

                                        <template v-if="$store.state.userProfile.account.plan === 'TRIAL'">
                                            <div class="error-color">
                                                Account will expire {{ $store.state.userProfile.account.expires_at | moment('timezone', $global.current_tz) | moment("from", "now") }}
                                            </div>
                                            <hr>
                                            <p>
                                                Make sure to upgrade to a paid plan to use {{ $global.product_name }} after the trial expires.
                                            </p>
                                            <br>
                                            <router-link :to="{ name: 'admin-plans'}" tag="span">
                                                <button class="button is-info">View payment plans</button>
                                            </router-link>
                                        </template>
                                        <template v-else-if="$store.state.userProfile.account.plan === 'STARTER'">
                                            <p>
                                                <b>Currently it is not possible to upgrade to Pro plan.</b>
                                            </p><br>
                                            Please contact us via chat button below to upgrade.
                                        </template>
                                    </div>

                                    <br>
                                    <p class="has-text-centered">
                                        <a href="#" @click="tabIndex=tabs.ORDER_HISTORY">Click here to view order status</a>
                                    </p>

                                </b-tab-item>

                                <b-tab-item>

                                    <template slot="header">
                                        <b-icon icon="history"></b-icon>
                                        <span> Order history</span>
                                    </template>

                                    <div class="box">
                                        <billing-order-table></billing-order-table>
                                    </div>

                                </b-tab-item>

                                <b-tab-item>

                                    <template slot="header">
                                        <b-icon icon="info-circle"></b-icon>
                                        Billing address
                                        <!--<span v-if="addressValid"> Billing address</span>-->
                                        <!--<span v-else class="error-color"> Billing address <b-icon size="is-small" icon="exclamation-triangle"></b-icon> </span>-->
                                    </template>

                                    <div class="box">
                                        <div class="columns">
                                            <div class="column is-2"></div>
                                            <div class="column col-8">
                                                <billing-details-form @valid="addressValid = $event" @updated="$utils.scrollToTop"></billing-details-form>
                                            </div>
                                            <div class="column is-2"></div>
                                        </div>
                                    </div>

                                </b-tab-item>

                                <!--<b-tab-item>-->

                                    <!--<template slot="header">-->
                                        <!--<b-icon icon="credit-card"></b-icon>-->
                                        <!--<span> Payment methods</span>-->
                                    <!--</template>-->

                                    <!--<div class="box">-->
                                        <!--<billing-payment-method-form></billing-payment-method-form>-->
                                    <!--</div>-->

                                <!--</b-tab-item>-->

                            </b-tabs>

                        </section>
                    </div>
                    <!--<div class="column is-1"></div>-->
                </div>
            </transition>

            <br>

        </form>

    </section>
</template>

<script>

    import billingOrderTable from './common/billing-order-table';
    import BillingDetailsForm from './common/billing-details-form';
    import BillingPaymentMethodForm from './common/billing-payment-method-form';
    import RequestUpgradeSMSLimitModal from "../helpers/requestUpgradeSMSLimitModal";

    export default {
        components: {
            billingOrderTable, BillingDetailsForm, BillingPaymentMethodForm, RequestUpgradeSMSLimitModal
        },
        data() {
            return {
                data: [],

                tabs: {
                    OVERVIEW: 0,
                    ORDER_HISTORY: 1,
                    BILLING_DETAILS: 2
                },

                tabIndex: this.$route.params.tabIndex || 0,

                addressValid: true,
                loading: true,
            }
        },

        methods: {
          requestUpgradeSMSLimitModal() {

            this.$buefy.modal.open({
              parent: this,
              component: RequestUpgradeSMSLimitModal,
              hasModalCard: true
            })

          },
            upgradeCredits() {
                this.$router.push({ name: 'admin-checkout', query: { tabIndex: 1 } });
            }

        },

        mounted() {

            if(this.$route.query.tab) {
                switch (this.$route.query.tab) {
                    case 'order_history':
                        this.tabIndex = this.tabs.ORDER_HISTORY;
                        break;
                    case 'billing_address':
                        this.tabIndex = this.tabs.BILLING_DETAILS;
                        break;
                }
            }

            this.loading = false;

            // this.loadAsyncData()
        }
    }
</script>

<template>

    <form @submit.prevent="handleSubmit">

        <b-loading :active.sync="loading"></b-loading>
        <div v-if="form">

            <h3 style="font-size: 22px">Billing details</h3>
            <hr>
            <br>

            <b-field label="Company / Organization (*)">
                <b-input size="is-medium" maxlength="64" v-model="form.company_name" required></b-input>
            </b-field>

            <b-field label="Billing address (*)">
                <b-input size="is-medium" maxlength="64" v-model="form.street1" required></b-input>
            </b-field>

            <b-field label="Billing address 2">
                <b-input size="is-medium" maxlength="64" v-model="form.street2"></b-input>
            </b-field>

            <b-field label="Billing city (*)">
                <b-input size="is-medium" maxlength="64" v-model="form.city" required></b-input>
            </b-field>

            <b-field label="Billing state / Province / Region (*)">
                <b-input size="is-medium" maxlength="64" v-model="form.state" required></b-input>
            </b-field>

            <b-field label="Billing postal code (*)">
                <b-input size="is-medium" maxlength="16" v-model="form.zipcode" required></b-input>
            </b-field>

            <b-field label="Country">
                <country-selector v-model="form.country_code"></country-selector>
            </b-field>

            <br>
            <b-field label="VAT number (include country code, e.g. NL012345678B01) (*)">
                <b-input size="is-medium" maxlength="64" v-model="form.vat_id"></b-input>
            </b-field>
            <div class="has-text-centered">
                <button class="button is-primary is-medium" role="submit" type="submit" v-bind:class="{'is-loading': submitting}">Update billing details</button>
            </div>
        </div>

    </form>

</template>

<script>

    import CountrySelector from './country-selector';

    export default {
        components: {
            CountrySelector
        },
        data: function () {
            return {
                form: null,
                valid: false,

                submitting: false,
                loading: true
            }
        },
        methods: {

            handleSubmit() {

                this.submitting = true;

                let params = this.form;

                // params.country_code = this.form.country_code;
                // params.country = this.countryObj.label;

                this.$store.dispatch('updateBillingAddress', params).then((data) => {

                    this.$buefy.toast.open({
                        message: 'Billing address updated!',
                        type: 'is-success'
                    });

                    this.$emit('input', this.form);
                    this.$emit('valid', true);
                    this.$emit('submitClicked', true);

                    this.valid = true;

                    // reload data to process charge_dutch_vat field
                    this.loadAsyncData();

                    this.$utils.scrollToTop();

                })
                .catch((err) => {

                    this.$buefy.toast.open({
                        message: this.$utils.getHumanErrorMessage(err),
                        type: 'is-danger',
                        duration: 6000
                    });
                })
                .finally(() => {
                    this.submitting = false;
                });

            },
            loadAsyncData() {

                const params = {};

                this.$store.dispatch('fetchBillingAddress', params).then((data) => {

                    this.form = data.result;

                    // this.countryObj = {
                    //     id: data.result.country_code,
                    //     label: data.result.country
                    // };

                    // emit if charge_dutch_vat is true in db
                    this.$emit('charge-vat', data.result.charge_vat);

                    this.loading = false;
                    this.show = true;
                    this.$emit('input', data.result);

                    this.valid = data.result.is_valid;
                    this.$emit('valid', data.result.is_valid);


                })
                .catch((error) => {

                    this.$buefy.toast.open({
                        message: this.$utils.getHumanErrorMessage(err),
                        type: 'is-danger',
                        duration: 6000
                    });

                    // throw error
                })
                .finally(() => {
                    this.loading = false;
                });
            }

        },

        mounted() {
            this.loadAsyncData();
        }
    }
</script>

<style scoped>
</style>

<template>
    <section>
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <h1 v-if="! loading">
                        <template v-if="total">
                            Lists ({{ total }})
                        </template>
                        <template v-else>
                            &nbsp;Lists
                        </template>
                    </h1>
                    <h1 v-else>
                        &nbsp;
                    </h1>
                </div>
            </div>

            <!-- Right side -->
            <div class="level-right">
                <button class="button is-medium is-info"
                        @click="createContactListModal()" variant="primary">
                    <b-icon icon="plus"></b-icon><span>Create contact list</span>
                </button>
            </div>

        </nav>
        <hr>

        <b-dropdown :disabled="checkedRows.length === 0" class="is-hidden-mobile">
            <button class="button is-light is-medium" slot="trigger">
                <span>Bulk action</span>
                <b-icon icon="caret-down"></b-icon>
            </button>

            <b-dropdown-item value="delete" @click="doBulkAction('delete')">
                Delete
            </b-dropdown-item>

        </b-dropdown>

        <b-field class="search-input-field">
            <b-input v-model="search" icon="search" ref="search" type="search" v-bind:class="{ 'has-value': search}" @input="searchFilter" placeholder="Search list..." size="is-medium"></b-input>
        </b-field>

        <hr id="tour-lists-overview">

        <b-table
                v-show="data"

                :data="data"
                :loading="loading"

                paginated
                backend-pagination
                :total="total"
                :per-page="perPage"
                @page-change="onPageChange"

                backend-sorting
                :default-sort-direction="defaultSortOrder"
                :default-sort="[sortField, sortOrder]"
                @sort="onSort"

                :is-row-checkable="(row) => row.id !== 3"
                :checked-rows.sync="checkedRows"
                checkable
        >

            <template slot-scope="props">


                <b-table-column field="warning" label="" width="50" sortable numeric>

                    <span v-if="! props.row.channel.enabled">
                        <b-tooltip :label="`The channel ${props.row.channel.name} (${props.row.channel.origin}) of the selected contact list is disabled. This means no messages can be sent to this contact list until the channel has been enabled via the channel settings`"
                                   position="is-right" type="is-black" multilined>
                            <b-icon icon="exclamation-triangle" position="is-top" type="is-black"></b-icon>
                        </b-tooltip>
                    </span>

                    <span v-else-if="! props.row.channel.is_online">
                        <b-tooltip :label="`The channel ${props.row.channel.name} (${props.row.channel.origin}) of the selected contact list is currently unavailable.`"
                                   position="is-right" type="is-danger" multilined>
                            <b-icon icon="exclamation-triangle" position="is-top" type="is-danger"></b-icon>
                        </b-tooltip>
                    </span>
                </b-table-column>

                <!--<b-table-column field="id" label="ID" sortable numeric>-->
                       <!-- CL-{{ props.row.id }}-->
                <!--</b-table-column>-->

                <!--<b-table-column field="name" label="Channel" sortable>-->
                    <!--<b-tag :type="props.row.channel.is_online ? 'is-success' : 'is-danger'" rounded="true">{{ props.row.channel.is_online ? 'ONLINE' : 'OFFLINE' }}</b-tag>-->
                <!--</b-table-column>-->

                <b-table-column field="name" label="Name" width="300" sortable>
                    <!--<router-link :to="{ name: 'contactlist-edit', params: {listId: props.row.id} }">-->
                        {{ props.row.name }}

                    <b-tooltip v-show="props.row.subscribe_template && props.row.subscribe_template.enabled" size="is-medium" type="is-light" :label="`Welcome message on subscription enabled`" style="vertical-align: middle; margin-bottom: 2px">
                        <b-icon icon="reply" size="is-small" :type="is-dark" style="opacity: 0.2"></b-icon>
                    </b-tooltip>

                    <b-tooltip v-show="props.row.type === 'CONTACT'" size="is-medium" type="is-light" :label="`All contact forms for channel ${props.row.channel.origin} will be automatically added to this list`" style="vertical-align: middle; margin-bottom: 2px">
                        <b-icon icon="info-circle" size="is-small" type="is-info"></b-icon>
                    </b-tooltip>
                    <!--</router-link>-->
                </b-table-column>

                <b-table-column field="contacts" label="Contacts">
                    <router-link :to="{ name: 'contacts-by-listId', params: {listId: props.row.id} }" style="vertical-align: sub" title="View contacts">
                        <b-icon icon="search" type="is-info"></b-icon>
                    </router-link>

                    <router-link :to="{ name: 'contacts-by-listId', params: {listId: props.row.id} }">
                        {{ props.row.contacts_count }}
                    </router-link>
                </b-table-column>

                <b-table-column field="channel" label="Channel linked">
                    <channel-display-tag :channel="props.row.channel"></channel-display-tag>
                </b-table-column>

                <b-table-column field="updated_at" label="Updated" sortable>
                    {{ props.row.updated_at | moment('timezone', $global.current_tz) | moment("from", "now") }}
                </b-table-column>

                <b-table-column field="action" label="Actions" centered>

                    <template v-show="props.row.type === 'REGULAR'">
                        <router-link :to="{ name: 'contactlist-edit', params: {listId: props.row.id} }" class="action-button" v-bind:class="{ invisible: props.row.type !== 'REGULAR' }" title="Edit contact list">
                            <b-icon icon="edit"></b-icon>
                        </router-link>
                    </template>

                    <b-dropdown type="is-small">
                        <button class="button action-button is-small is-white" slot="trigger">
                            <b-icon icon="ellipsis-v"></b-icon>
                        </button>

                        <b-dropdown-item title="View">
                            <router-link :to="{ name: 'contacts-by-listId', params: {listId: props.row.id} }" style="vertical-align: sub" title="View contacts">
                                View contacts
                            </router-link>
                        </b-dropdown-item>

                        <b-dropdown-item title="Import">
                            <router-link :to="{ name: 'contactlist-edit', params: {listId: props.row.id, tabIndex: 0} }">
                                Import contacts
                            </router-link>
                        </b-dropdown-item>

                        <b-dropdown-item separator></b-dropdown-item>

                        <b-dropdown-item @click="duplicateContactList(props.row.id)" title="Duplicate contact list">
                            Duplicate
                        </b-dropdown-item>

                        <b-dropdown-item v-if="props.row.type === 'REGULAR'" @click="deleteContactList(props.row.id)" title="Delete contact list">
                            Delete
                        </b-dropdown-item>

                        <b-dropdown-item separator></b-dropdown-item>

                        <b-dropdown-item @click="createContactSubscribeLinkModal(props.row)" title="Get subscribe link">
                            Create subscribe link
                        </b-dropdown-item>

                        <b-dropdown-item title="Edit welcome template">
                            <template v-if="props.row.on_subscribe_template_id">
                                <router-link :to="{ name: 'template-edit', params: {templateId: props.row.on_subscribe_template_id} }" title="Edit subscribe template">
                                    Edit subscribe template
                                </router-link>
                            </template>
                            <template v-else-if="props.row.type === 'REGULAR'">
                                <a href="#" @click="createTemplate('ON_SUBSCRIBE',props.row.id,props.row.name)">Create subscribe template</a>
                            </template>
                        </b-dropdown-item>

                    </b-dropdown>

                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section" v-show="! loading">
                    <div class="content has-text-grey has-text-centered">
                        <p class="is-large"><b>No contact lists found.</b></p>
                        <p><a href="#" @click="createContactListModal()">Create a list</a> now and start adding contacts.</p>
                    </div>
                </section>
            </template>

        </b-table>
    </section>
</template>

<script>

    import CreateContactListView from '../helpers/createContactListForm';
    import CreateContactSubscribeCodeForm from "../helpers/createContactSubscribeQRCode";

    export default {
        data() {
            return {
                data: [],
                checkedRows: [],
                total: 0,
                loading: true,
                sortField: 'updated_at',
                sortOrder: 'desc',
                defaultSortOrder: 'asc',
                page: 1,
                perPage: 20,
                search: '',
            }
        },
        methods: {
            delay(fn, waitDelay) {
                if (this.globalTimeout != null) {
                    clearTimeout(this.globalTimeout);
                }
                this.globalTimeout = setTimeout(function() {
                    this.globalTimeout = null;

                    fn();

                }, waitDelay);
            },
            createContactListModal() {
                this.$buefy.modal.open({
                    parent: this,
                    component: CreateContactListView,
                    hasModalCard: true
                })
            },
            createTemplate(type, listId, name) {
                type = type || 'MAIN'

                this.submitting = true;

                const params = {
                    name: name,
                    list_id: listId,
                    type: type,
                    is_public: true,
                    message_body: `Welcome to ${name}!`,
                    message_body_generic: `Welcome to ${name}!`
                };

                this.$store.dispatch('createTemplate', params).then((data) => {

                    if (data.success) {
                        this.$buefy.toast.open({
                            message: 'Your new template is created!',
                            type: 'is-success'
                        });

                        const newTemplateId = data.result.id;

                        this.$store.dispatch('updateContactList', { id: listId, on_subscribe_template_id: newTemplateId }).then((data) => {

                            // push notification
                            const toast = {
                                message: `Updated contact list successfully!`,
                                type: 'is-success'
                            };
                            this.$buefy.toast.open(toast);

                            this.$router.push(`/templates/edit/${newTemplateId}`);

                        })
                        .catch((err) => {

                            this.$buefy.toast.open({
                                message: 'Error saving contact list details: '+err.custom.message,
                                type: 'is-danger'
                            });
                        });

                    } else {

                    }
                });

            },
            doBulkAction(action) {

                let campaign_ids = [];
                for(let i=0; i<this.checkedRows.length; i++) {
                    campaign_ids.push(this.checkedRows[i].id);
                }

                if(action === 'delete') {
                    this.deleteContactLists(campaign_ids);
                }
            },

            /*
             * Duplicate a contact list
            */
            duplicateContactList(id) {

                this.$buefy.dialog.confirm({
                    title: 'Duplicate',
                    message: 'Are you sure you want to <b>duplicate</b> this contact list?',
                    confirmText: 'Duplicate',
                    type: 'is-info',
                    hasIcon: true,
                    onConfirm: () => {

                        const params = {
                            id: id
                        };

                        this.$store.dispatch('duplicateContactList', params).then((data) => {
                            let result = data.result;
                            this.loadAsyncData();
                        })
                            .catch((error) => {
                                throw error
                            });
                    }
                })
            },

            /*
            * Handle remove a contact list
            */
            deleteContactList(id) {

                const params = {
                    id: id
                };

                this.$buefy.dialog.confirm({
                    title: 'Delete',
                    message: 'Are you sure you want to <b>delete</b> this contact list? This action cannot be undone.',
                    confirmText: 'Delete contact list',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('deleteContactList', params).then((data) => {

                            let result = data.result;
                            this.loadAsyncData();
                        })
                            .catch((error) => {
                                throw error
                            });
                    }
                })
            },

            /*
             * Remove templates
            */
            deleteContactLists(ids) {

                const params = {
                    ids: ids
                };

                this.$buefy.dialog.confirm({
                    title: 'Delete',
                    message: 'Are you sure you want to <b>delete</b> these contact lists? This action cannot be undone.',
                    confirmText: 'Delete contact lists',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('deleteContactLists', params).then((data) => {

                            this.checkedRows = [];
                            let result = data.result;

                            if( result.err_object.ids.length === 0 ) {

                                this.$buefy.toast.open({
                                    message: 'Contact list(s) removed successfully!',
                                    type: 'is-success',
                                    duration: 4000
                                });

                            } else {

                                const ids = result.err_object.ids.join(', CL-')

                                this.$buefy.toast.open({
                                    message: 'One or multiple contact lists couldn\'t be removed! (CL-'+ids+')<br><small>Contact lists can only be removed for assigned campaign that are finished!</small>',
                                    type: 'is-warning',
                                    duration: 5000
                                });

                            }

                            this.loadAsyncData();

                        })
                            .catch((error) => {
                                throw error
                            });
                    },
                    onCancel: () => {
                    }
                })
            },

            searchFilter() {

                var that = this;
                this.delay(function() { that.loadAsyncData() }, this.$global.search_delay);
            },

            createContactSubscribeLinkModal(list) {

                // get message
                const inviteText = JSON.parse(list.channel.messages).subscribe_request_wa.body;

                this.$buefy.modal.open({
                    parent: this,
                    component: CreateContactSubscribeCodeForm,
                    props: {
                        accountUuid: this.$store.getters.userProfile.account.uuid,
                        listId: list.id,
                        listUuid: list.uuid,
                        listName: list.name,
                        inviteText: inviteText
                    },
                    events: {
                        success: (data) => {
                            this.loadAsyncData();
                        }
                    },
                    hasModalCard: true
                })
            },

            /*
             * Load async data
             */
            loadAsyncData() {

                const params = [
                    `orderBy=${this.sortField}`,
                    `sort=${this.sortOrder}`,
                    `page=${this.page}`,
                    `search=${this.search}`,
                    // `type=REGULAR`
                ].join('&');

                this.loading = true;

                this.$store.dispatch('fetchContactLists', params).then((data) => {

                    let result = data.result;

                    this.data = [];
                    let currentTotal = result.total;
                    if (result.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000
                    }

                    this.total = currentTotal;

                    result.data.forEach((item) => {
                        this.data.push(item)
                    })
                    this.loading = false
                })
                .catch((error) => {
                    this.data = [];
                    this.total = 0;
                    this.loading = false;
                    throw error
                });

            },
            /*
             * Handle page-change event
             */
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            /*
             * Handle sort event
             */
            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
            }
        },
        filters: {
            /**
             * Filter to truncate string, accepts a length parameter
             */
            truncate(value, length) {
                return value.length > length
                    ? value.substr(0, length) + '...'
                    : value
            }
        },
        mounted() {
            this.loadAsyncData();
            this.$refs.search.focus();
        }
    }
</script>

const RequestChannelForm = {
    data() {
        return {
            data: {},
            form: {
                name: `My ${this.$store.getters.userProfile.account.name} Channel`,
                origin: '',
            },

            submitting: false

        }
    },
    mounted() {
        this.$refs.channelOrigin.focus();
    },
    props: [
        'channelName'
    ],
    template: `
            <form @submit.prevent="handleSubmit">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Request new channel</p>
                        <button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>
                    </header>
                    <section class="modal-card-body">
                       <div class="columns">
                        
                            <div class="column is-1">
                                <b-icon size="is-medium" icon="list-ol"></b-icon>
                            </div>
                            <div class="column is-10">
                                <p>
                                Submit your request below to connect your company's WhatsApp number and phone to {{ this.$global.product_name }} and we will get back to you shortly.
                                </p>
                                <hr>
                                <b-field label="Name for your channel">
                                    <b-input
                                        size="is-medium"
                                        type="text"
                                        :value="form.name"
                                        v-model.trim="form.name"
                                        ref="channelName"
                                        placeholder="eg. My channel name"
                                        required
                                    >
                                    </b-input>
                                </b-field>
                                <br>
                                <b-field label="Company's WhatsApp number">
                                    <b-input
                                        size="is-medium"
                                        type="text"
                                        :value="form.origin"
                                        v-model.trim="form.origin"
                                        ref="channelOrigin"
                                        placeholder="+31612345678"
                                        minlength="7" 
                                        maxlength="32"
                                        required
                                    >
                                    </b-input>
                                </b-field>
                                <br>
                                <b-notification type="is-warning" :closable="false">
                                    <div class="has-text-centered">
                                    
                                        <b-field label="">
                                            <b-checkbox required true-value="1" false-value="0">
                                                I have downloaded and read the <a :href="'/assets/guides/'+this.$global.product_name+'WA-Connector-Guidelines.pdf'" target="_tp-website">WA-Connector Guidelines</a>.
                                            </b-checkbox>
                                        </b-field>
                                    
                                                                            
                                    </div>
                                </b-notification>
                            </div>
                            <div class="column is-1">
                              
                            </div>
                        </div>
                    </section>
                    
                    <footer class="modal-card-foot">
                        <div class="columns" style="width: 100%">
                            <div class="column is-12" style="text-align: right">
                                <button class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}">Submit</button>
                                <button class="button is-medium" type="button" @click="$parent.close()">Cancel</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </form>
        `,
    methods: {
        handleSubmit() {

            this.submitting = true;

            // Send data to the server or update your stores and such.
            const params = {
                name: this.form.name,
                origin: this.form.origin
            }

            this.$store.dispatch('requestChannel', params).then((data) => {

                this.$parent.close();
                if(this.$store.state.account.plan === 'TRIAL') {

                    setTimeout(() => {

                        this.$buefy.dialog.confirm({
                            title: 'New channel request',
                            message: `Thanks for submitting a new channel request. To activate your channel please upgrade to a paid plan.`,
                            cancelText: 'Cancel',
                            confirmText: 'View upgrade plans',
                            type: 'is-success',
                            onConfirm: () => {
                                this.$router.push({ name: 'admin-plans' });
                            }
                        })
                    }, 1000);

                } else {

                    this.$buefy.dialog.alert({
                        title: 'New channel request',
                        message: `<b>Thank you for requesting a new channel for ${params.origin}.</b><br><br>We will notify when your channel is processed and ready for activation!<br><br>It takes approximately 1 business day to process your request.`,
                        type: 'is-info',
                        hasIcon: true,
                        icon: 'thumbs-up',
                        iconPack: 'fa'
                    })

                    // this.$buefy.toast.open({
                    //     message: `Thank you for requesting a new channel for ${params.origin}. We will notify when your channel is processed and ready for activation! It takes approximately 1 business day `,
                    //     type: 'is-success',
                    //     duration: 5000
                    // });

                }

                // this.$router.push(`/channels/edit/${data.result.id}`);

            }).catch((err) => {
                this.$buefy.toast.open({
                    message: 'Error occurred while registering new channel: '+this.$utils.getHumanErrorMessage(err),
                    type: 'is-danger',
                    duration: 5000
                });
                // handle error
            })
                .finally(() => {
                    this.submitting = false;
                });
        }
    }
}

export default RequestChannelForm;


import 'babel-polyfill';

import Vue from 'vue';
import VueRouter from 'vue-router';

import App from './components/App.vue';
import mainStore from './store/mainStore';

import routes from './routes/mainRoutes';
import Utils from './utils/global';

// buefy
import Buefy from 'buefy';
// import 'buefy/dist/buefy.css';

// external libraries
// import { EmojiPickerPlugin } from 'vue-emoji-picker'

import moment from 'moment-timezone';
import Vue2Filters from 'vue2-filters';
import VueClipboard from 'vue-clipboard2';
import VueMoment from 'vue-moment';
import VueTour from 'vue-tour';
import VueScrollTo from 'vue-scrollto';
import VueTelInput from 'vue-tel-input'

require('vue-tour/dist/vue-tour.css');

// import fontawesome from '@fortawesome/fontawesome'

import Testing from './components/common/testing.vue'

// import VS2 from 'vue-script2'

import MessagePreview from './components/common/message-preview.vue'
import ChannelSelector from './components/common/channel-selector.vue'
import ChannelDisplayTag from './components/common/channel-display-tag.vue'
import ContactListSelector from './components/common/contactlist-selector.vue'
import PasswordField from './components/common/password-field.vue'
import RoleTag from './components/common/role-tag.vue'
import StateTag from './components/common/state-tag.vue'
import AvatarImage from './components/common/avatar-image.vue'
import BrandLogo from './components/common/brand-logo.vue'
// import AttachmentTypeIcon from './components/common/attachment-type-icon.vue'

// import multiReplaceAll from 'multi-replaceall';

import replaceIt from './helpers/replace'

// Vue.component('testing', Testing );
Vue.use(Utils);

Vue.component('message-preview', MessagePreview);
Vue.component('channel-selector', ChannelSelector);
Vue.component('channel-display-tag', ChannelDisplayTag);
Vue.component('contactlist-selector', ContactListSelector);
Vue.component('password-field', PasswordField);
Vue.component('role-tag', RoleTag);
Vue.component('state-tag', StateTag);
Vue.component('avatar-image', AvatarImage);
Vue.component('brand-logo', BrandLogo);
// Vue.component('attachment-type-icon', AttachmentTypeIcon);

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/css/fontawesome.css";

Vue.use(Buefy, {
    defaultIconPack: 'fas',
    defaultToastDuration: 3500,
    defaultTooltipType: 'is-light',
    defaultTooltipAnimated: true
    // defaultTooltipSize: 'is-large'
});

Vue.use(VueClipboard);
Vue.use(VueMoment, {
    moment
});

// Vue.use(EmojiPickerPlugin);
Vue.use(Vue2Filters) ;
Vue.use(VueRouter);
Vue.use(VueTour);
Vue.use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
});
Vue.use(VueTelInput);

/* Global vars */
Vue.prototype.$global = {
    hostname: window.location.hostname,
    product_name: window.location.hostname.split('.')[1].capitalize(),
    product_homepage: 'https://'+window.location.hostname.split('.')[1]+'.'+window.location.hostname.split('.')[2],

    date_format: 'MMMM Do, YYYY',
    datetime_format: 'MMMM Do, YYYY @ HH:mm',
    current_tz: moment.tz.guess(),
    search_delay: 750
};

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash
                // , offset: { x: 0, y: 10 }
            }
        }
    }
});
window.events = new Vue();

// import 'buefy/dist/buefy.min.css'

// Define a new component
Vue.component('button-spinner', {
    props: {
        label: {
            type: String,
            default: null
        }
    },
    template: `<div><span v-if="label" style="margin-right: 10px">{{ label }}</span><span style="float:right" class="loader"></span></div>`
});

Vue.component('dynamic-icon', {
    props: {
        value: {
            type: String,
            default: null
        },
        successIcon: {
            type: String,
            default: null
        },
        failedIcon: {
            type: String,
            default: null
        }
    },
    template: `
    <span>
            <b-icon v-show="value" :icon="successIcon"></b-icon>
            <b-icon v-show="! value" :icon="failedIcon"></b-icon>
    </span>
`
});

Vue.component('attachment-icon', {
    props: {
        attachmentType: {
            type: String,
            default: null
        },
        className: {
            type: String,
            default: ''
        },
        styleName: {
            type: String,
            default: ''
        },
    },
    template: `
    <span :class="className" :style="styleName">
            <b-icon class="has-text-grey-light" size="is-small" v-show="attachmentType === 'image/png' || attachmentType === 'image/gif' || attachmentType === 'image/jpeg'" icon="image"></b-icon>
            <b-icon class="has-text-grey-light" size="is-small" v-show="attachmentType === 'audio/mp3' || attachmentType === 'audio/mpeg'" icon="volume-up"></b-icon>
            <b-icon class="has-text-grey-light" size="is-small" v-show="attachmentType === 'video/mp4'" icon="video"></b-icon>
            <b-icon class="has-text-grey-light" size="is-small" v-show="attachmentType === 'application/pdf'" icon="file-pdf"></b-icon>
            <b-icon class="has-text-grey-light" size="is-small" v-show="attachmentType === 'unknown'" icon="paperclip"></b-icon>
    </span>
`
});

new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store: mainStore,
    created() {
        Vue.prototype.$global.current_tz = moment.tz.guess();

        // alert(moment.tz.guess());
        this.$moment.tz.setDefault('UTC');

        if(this.$store.getters.isLoggedIn) {
            this.$store.dispatch('getUserProfile', {}).then((data) => {
                // localStorage.setItem('userProfile', data.result);
            }).catch((err) => {
                console.log(err);
            });
        }
    }
});

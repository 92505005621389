const CreateImportConfirmationForm = {
    data() {
        return {
            data: {},
            form: {
                isOptIn: false,
                optInMessage: 'Hi! You have requested to subscribe to [[*SENDER_COMPANY]] channel. Click link: [[*SENDER_OPTIN_LINK]]'
            },

            channels: [],

            submitting: false

        }
    },
    props: [
        ''
    ],
    template: `
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Import</p>
                        <button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>
                    </header>
                    <section class="modal-card-body">
                       <div class="columns">
                        
                            <div class="column is-1">
                                <b-icon size="is-medium" icon="list-ol"></b-icon>
                            </div>
                            <div class="column is-10">
                                <b-message type="is-warning">
                                    All contacts will be imported into the contact list with the Opt state set to 'unknown'. If the contact already exist in the contact list, it will be ignored.
                                </b-message>
                                <hr>
                                
                                <div class="field is-hidden">
                                    <b-switch v-model="form.isOptIn">
                                        Send Opt-in request via SMS
                                    </b-switch>
                                </div>
                                
                                <div class="field" v-show="form.isOptIn">
                                    <label class="label-field">Opt-in Message</label>

                                    <b-field>
                                        <b-input type="textarea"
                                                 minlength="5"
                                                 maxlength="160"
                                                 v-model="form.optInMessage"
                                                 size="is-medium"
                                                 rows="3"
                                                 class="textarea-primary"
                                                 placeholder="Type an Opt-in message...">
                                        </b-input>
                                    </b-field>
                                </div>
                                
                            </div>
                            <div class="column is-1">
                              
                            </div>
                        </div>
                    </section>
                    <footer class="modal-card-foot">
                        <div class="columns" style="width: 100%">
                            <div class="column is-12" style="text-align: right">
                                <button class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}" @click="handleSubmit">Import</button>
                                <button class="button is-medium" type="button" @click="$parent.close()">Cancel</button>
                            </div>
                        </div>
                    </footer>
                    
                </div>

        `,
    methods: {

        handleSubmit() {

            this.submitting = true;

            this.$emit('onSubmit', this.form);

            // Send data to the server or update your stores and such.
            // const params = {
            //     name: this.form.name,
            //     channel_id: this.form.channel_id
            // }
            //
            // this.$store.dispatch('createContactList', params).then((data) => {
            //
            //     this.$buefy.toast.open({
            //         message: 'Contact list created!',
            //         type: 'is-success'
            //     });
            //
            //     // alert('hi');
                this.$parent.close();
            //
            //     this.$router.push(`/contactlists/edit/${data.result.id}`);
            //
            // }).catch((err) => {
            //     this.$buefy.toast.open({
            //         message: 'Error occurred while creating contact list: '+err,
            //         type: 'is-danger'
            //     });
            //     // handle error
            // })
            // .finally(() => {
            //     this.submitting = false;
            // });
        },

    },
    mounted() {
    }

}

export default CreateImportConfirmationForm;

<template>
    <section>
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <h1 class="is-pulled-left">Edit contact</h1>
                </div>
            </div>

            <!-- Right side -->
            <div class="level-right">
            </div>

        </nav>
        <hr>

        <form @submit.prevent="">

            <b-loading :active.sync="loading"></b-loading>

            <div class="columns is-mobile">
                <div class="column is-1"></div>
                <div class="column is-10">
                    <section>
                        <b-tabs size="is-medium" class="block" v-show="!loading" expanded>
                                <b-tab-item>

                                    <template slot="header">
                                        <b-icon icon="user-plus"></b-icon>
                                        <span> Contact Fields</span>
                                    </template>

                                    <div class="box">

                                        <div class="columns is-mobile">
                                            <div class="column is-2"></div>
                                            <div class="column is-8">

                                                <div class="field">
                                                    <label class="label">Manually change Opt in state</label>
                                                </div>
                                                <b-dropdown v-model="form.opt_state">
                                                    <!--class="button is-danger is-medium"-->
                                                    <button :class="optStyle" class="button" style="width:180px" slot="trigger">
                                                <span v-if="form.opt_state === 'OPT-IN'">
                                                    Opt-in
                                                </span>
                                                        <span v-else-if="form.opt_state === 'OPT-OUT'">
                                                    Opt-out
                                                </span>
                                                        <span v-else-if="form.opt_state === 'OPT-UNKNOWN'">
                                                    Opt-unknown
                                                </span>
                                                        <b-icon icon="caret-down"></b-icon>
                                                    </button>

                                                    <b-dropdown-item value="OPT-IN">Opt-in</b-dropdown-item>
                                                    <b-dropdown-item value="OPT-OUT">Opt-out</b-dropdown-item>
                                                    <b-dropdown-item value="OPT-UNKNOWN">Opt-unknown</b-dropdown-item>
                                                </b-dropdown>

                                                <hr>
                                                <label class="label-field">
                                                    First name
                                                </label>

                                                <b-field>
                                                    <b-input size="is-medium" maxlength="64" v-model="form.first_name"></b-input>
                                                </b-field>

                                                <label class="label-field">
                                                    Last name
                                                </label>

                                                <b-field>
                                                    <b-input size="is-medium" maxlength="64" v-model="form.last_name"></b-input>
                                                </b-field>

                                                <!--<label class="label-field">-->
                                                    <!--Phone-->
                                                <!--</label>-->

                                                <!--<b-field>-->
                                                    <!--<b-input size="is-medium" maxlength="255" v-model="form.phone" required></b-input>-->
                                                <!--</b-field>-->

                                                <label class="label-field">
                                                    Company
                                                </label>

                                                <b-field>
                                                    <b-input size="is-medium" maxlength="128" v-model="form.company"></b-input>
                                                </b-field>

                                                <label class="label-field">
                                                    Profile picture url
                                                </label>

                                                <div v-if="form.thumb_url" class="contact-avatar is-pulled-left">
                                                    <img :src="form.thumb_url">
                                                </div>

                                                <b-field>
                                                    <b-input size="is-medium" maxlength="512" v-model="form.thumb_url"></b-input>
                                                </b-field>

                                                <label class="label-field">
                                                    Email
                                                </label>

                                                <b-field>
                                                    <b-input type="email" message="This email is invalid" size="is-medium" v-model="form.email"></b-input>
                                                </b-field>

                                                <br>
                                                <hr>
                                                <label class="label-field">
                                                    Link
                                                </label>

                                                <b-field>
                                                    <b-input size="is-medium" maxlength="1024" v-model="form.link"></b-input>
                                                </b-field>

                                                <label class="label-field">
                                                    Custom attribute 1
                                                </label>

                                                <b-field>
                                                    <b-input size="is-medium" maxlength="2000" v-model="form.attr_1" type="textarea"></b-input>
                                                </b-field>

                                                <label class="label-field">
                                                    Custom attribute 2
                                                </label>

                                                <b-field>
                                                    <b-input size="is-medium" maxlength="2000" v-model="form.attr_2" type="textarea"></b-input>
                                                </b-field>

                                                <label class="label-field">
                                                    Custom attribute 3
                                                </label>

                                                <b-field>
                                                    <b-input size="is-medium" maxlength="2000" v-model="form.attr_3" type="textarea"></b-input>
                                                </b-field>

                                                <label class="label-field">
                                                    Internal notes
                                                </label>

                                                <b-field>
                                                    <b-input size="is-medium" maxlength="4096" v-model="form.notes" type="textarea"></b-input>
                                                </b-field>

                                            </div>
                                            <div class="column is-2"></div>
                                        </div>

                                        <div class="has-text-centered">
                                            <button class="button is-primary is-medium" @click="update" type="button" v-bind:class="{'is-loading': submitting}">Update</button>
                                        </div>
                                    </div>

                                </b-tab-item>

                        </b-tabs>

                    </section>
                </div>
                <div class="column is-1"></div>
            </div>

            <br>

        </form>

    </section>
</template>

<script>
    export default {
        data() {
            return {
                data: {
                    id: this.$route.params.contactId
                },
                form: {
                    opt_state: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    company: '',
                    thumb_url: '',
                    link: '',
                    attr_1: '',
                    attr_2: '',
                    attr_3: '',
                    notes: ''
                },
                submitting: false,
                loading: true
            }
        },
        computed: {
            optStyle() {
                let cls = '';

                switch(this.form.opt_state) {
                    case 'OPT-IN':
                        cls='is-light';
                        break;
                    case 'OPT-OUT':
                        cls='is-danger';
                        break;
                    case 'OPT-UNKNOWN':
                        cls='is-warning';
                        break;
                }
                return cls;

            }
        },
        methods: {
            /*
             * Load async data
             */
            loadAsyncData() {

                this.loading = true;

                const params = {
                    id: this.data.id
                };

                this.$store.dispatch('fetchContact', params).then((data) => {

                    // update view with current campaign settings
                    let result = data.result;

                    this.form = {
                        opt_state: result.contact_entity.opt_state,
                        first_name: result.first_name,
                        last_name: result.last_name,
                        email: result.email,
                        phone: result.contact_entity.endpoint,
                        company: result.company,
                        thumb_url: result.thumb_url,
                        link: result.link,
                        attr_1: result.attr_1,
                        attr_2: result.attr_2,
                        attr_3: result.attr_3,
                        notes: result.notes
                    };

                    this.contacts_count = result.contacts_count;

                })
                .catch((error) => {
                    this.data = [];
                    this.total = 0;
                    this.loading = false;
                    throw error
                })
                .finally(() => {
                    this.submitting = false;
                    this.loading = false;
                });

            },

            update() {

                this.submitting = true;

                const params = this.form;
                params.id = this.data.id;

                this.$store.dispatch('updateContact', params).then((data) => {

                // push notification
                const toast = {
                    message: `Updated contact successfully!`,
                    type: 'is-success'
                };
                this.$buefy.toast.open(toast);

                // push to view
                this.$router.go(-1);
                // this.$router.push('/contactlists');

                })
                .catch((err) => {

                    this.$buefy.toast.open({
                        message: 'An error has occurred! '+this.$utils.getHumanErrorMessage(err),
                        type: 'is-danger',
                        duration: 5000
                    });
                })
                .finally(() => {
                    this.submitting = false;
                });

            }
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>

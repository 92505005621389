const CreateChannelForm = {
    data() {
        return {
            data: {},
            form: {
                name: 'New channel',
                type: 'WHATSAPP',
                whatsapp_type: 'FLEX',
                origin: '',
                vendor: 'IMS',
                base_uri: 'https://ims1.textpop.io'
            },

            givenConsent: false,
            submitting: false

        }
    },
    mounted() {
        this.$refs.channelOrigin.focus();
    },
    props: [ 'channelName', 'source'],
    template: `
            <form @submit.prevent="handleSubmit">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Create a channel</p>
                        <button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>
                    </header>
                    <section class="modal-card-body">
                       <div class="columns">

                            <div class="column is-1">
                                <b-icon size="is-medium" icon="list-ol"></b-icon>
                            </div>
                            <div class="column is-10">

                              <template v-if="source && source === 'onboarding-test-message-sent'">
                                <h1 class="has-text-centered">
                                    Awesome! 😎<br>
                                    Now, let's connect your phone!
                                </h1>
                                <hr>
                                <p>
                                  To send messages originating from your <b>own number</b>, enter your WhatsApp phone number below:
                                </p>
                              </template>
                              <template v-else-if="source && source === 'onboarding-test-message-skipped'">
                                <p>
                                  To send messages originating from your <b>own number</b>, enter your WhatsApp phone number below:
                                </p>
                              </template>
                              <template v-else>
                                <p>
                                  Add your WhatsApp company phone number below:
                                </p>
                                <hr>
                              </template>
                                {{ $store.getters.userProfile.role }}
                                <b-field v-show="$store.getters.userProfile.role === 'SUPER'" label="Vendor">
                                    <b-dropdown aria-role="list">
                                        <button class="button is-primary" slot="trigger" @click.native.stop>
                                            <span>{{ form.vendor }}</span>
                                            <b-icon icon="caret-down"></b-icon>
                                        </button>

                                        <b-dropdown-item aria-role="listitem" @click="form.vendor = 'IMS'">IMS</b-dropdown-item>
                                        <b-dropdown-item aria-role="listitem" @click="form.vendor = 'CASENGO'">Casengo</b-dropdown-item>
                                    </b-dropdown>
                                </b-field>
                                <b-field v-show="$store.getters.userProfile.role === 'SUPER' || $store.getters.impersonateUserId" label="Vendor host">
                                    <b-dropdown aria-role="list">
                                        <button class="button is-light" slot="trigger" @click.native.stop>
                                            <span>{{ form.base_uri }}</span>
                                            <b-icon icon="caret-down"></b-icon>
                                        </button>

                                        <b-dropdown-item aria-role="listitem" @click="form.base_uri = 'https://ims1.textpop.io'">ims1.textpop.io</b-dropdown-item>
                                        <b-dropdown-item aria-role="listitem" @click="form.base_uri = 'https://dev.imsserver.io'">dev.imsserver.io</b-dropdown-item>
                                        <b-dropdown-item aria-role="listitem" @click="form.base_uri = 'https://app.grasp.work'">app.grasp.work</b-dropdown-item>
                                        <b-dropdown-item aria-role="listitem" @click="form.base_uri = 'https://app.getgrasp.com'">app.getgrasp.com</b-dropdown-item>
                                        <b-dropdown-item aria-role="listitem" @click="form.base_uri = 'https://ims1.whatsappier.com'">ims1.whatsappier.com</b-dropdown-item>
                                    </b-dropdown>
                                </b-field>
                                <b-field v-show="$store.getters.userProfile.role === 'SUPER'"  label="Channel Name (superadmin only)">
                                    <b-input
                                        size="is-medium"
                                        type="text"
                                        :value="form.name"
                                        v-model.trim="form.name"
                                        ref="channelName"
                                        placeholder="eg. My channel name"
                                        required
                                    >
                                    </b-input>
                                </b-field>
                                <br>
                                <b-field label="Phonenumber to connect">
                                    <b-input
                                        size="is-medium"
                                        type="text"
                                        :value="form.origin"
                                        v-model.trim="form.origin"
                                        ref="channelOrigin"
                                        placeholder="+31612345678"
                                        minlength="7"
                                        maxlength="32"
                                        required
                                    >
                                    </b-input>
                                </b-field>

                                <b-field v-show="$store.getters.userProfile.role === 'SUPER' || $store.getters.impersonateUserId"  label="Channel type (superadmin only)">
                                    <br>
                                    <br>
                                    <b-radio v-model="form.type" native-value="WHATSAPP">
                                        WhatsApp
                                    </b-radio>
                                    <b-radio v-model="form.type" native-value="SMS" disabled>
                                        SMS (not implemented) {{ form.type}}
                                    </b-radio>
                                </b-field>
                                <b-field v-show="$store.getters.userProfile.role === 'SUPER' || $store.getters.impersonateUserId" label="Channel type (superadmin only)">
                                  <p>
                                    <b-radio v-model="form.whatsapp_type" native-value="FLEX">
                                        Flex
                                    </b-radio>
                                    <b-radio v-model="form.whatsapp_type" native-value="NORMAL">
                                        Normal
                                    </b-radio>
                                  </p>
                                </b-field>

                                <b-notification type="is-warning" :closable="false">
                                    <div class="has-text-centered">

                                        <b-field label="">
                                            <b-checkbox required v-model="givenConsent" true-value="1" false-value="0">
                                                I have downloaded and read the <a :href="'/assets/guides/'+$global.product_name+'-WA-Connector-Guidelines.pdf'" target="_tp-website">WA-Connector Guidelines</a>.
                                            </b-checkbox>
                                        </b-field>

                                    </div>
                                </b-notification>

                            </div>
                            <div class="column is-1">

                            </div>
                        </div>
                    </section>

                    <footer class="modal-card-foot">
                        <div class="columns" style="width: 100%">
                            <div class="column is-12" style="text-align: right">
                                <button class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}" :disabled="! givenConsent || form.origin.length < 8">Create channel</button>
                                <button class="button is-medium" type="button" @click="$parent.close()">Do later</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </form>
        `,
    computed: {

    },
    methods: {

        handleSubmit() {

            this.submitting = true;

            // Send data to the server or update your stores and such.
            const params = {
                name: this.form.name,
                type: this.form.type,
                whatsapp_type: this.form.whatsapp_type,
                origin: this.form.origin,
                vendor: this.form.vendor,
                base_uri: this.form.base_uri,
                enabled: 1
            };

            this.$store.dispatch('createChannel', params).then((data) => {

                this.$buefy.toast.open({
                    message: 'New channel created!',
                    type: 'is-success'
                });

                this.$parent.close();
                this.$router.push({ name: 'channel-edit', params: { channelId: data.result.id, modal: 'SCAN_QR_MODAL' } });

            }).catch((err) => {
                this.$buefy.toast.open({
                    message: 'Error occurred while creating channel: '+this.$utils.getHumanErrorMessage(err),
                    type: 'is-danger'
                });
                // handle error
            })
                .finally(() => {
                    this.submitting = false;
                });
        }
    }

}

export default CreateChannelForm;

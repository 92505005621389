<template>
    <div class="msg-item">
        <div v-show="originatorId" class="msg-header has-text-left">
            <b>{{ originatorId }}</b>
        </div>

        <div class="msg-inner msg-in">

            <template v-if="checkDynamicAttachment || attachment">

                <div v-if="checkDynamicAttachment && attachment.endsWith('.pdf')" style="margin: 6px">
                    <a :href="attachment" target="_preview"><b-tag type="is-info"><b-icon icon="file-pdf"></b-icon> PDF file</b-tag></a>
                </div>

                <div v-else-if="checkDynamicAttachment" style="margin: 6px">
                    <b-tag type="is-light"><a :href="attachment" target="_preview">{{ attachment }}</a></b-tag>
                </div>

                <div v-else-if="mediaType && mediaType === 'audio'">
                    <audio controls :src="attachment"></audio>
                </div>

                <div v-else-if="(mediaType && mediaType === 'image' || attachment.startsWith('data:image/jpeg') || attachment.startsWith('data:image/png') || attachment.startsWith('data:image/gif'))" class="msg-attachment">
                    <img class="previewImageUpload" :src="attachment"/>
                </div>

                <div v-else-if="mediaType && mediaType === 'video' || attachment.startsWith('data:video/mp4')" class="msg-attachment">
                    <video class="previewImageUpload" style="width:100%" v-bind:src="attachment" controls></video>
                </div>

                <div v-else-if="mediaType && mediaType === 'pdf'" class="msg-attachment">
                    <b-tag type="is-info"><b-icon icon="file-pdf"></b-icon> PDF document</b-tag>
                </div>

                <div v-else-if="mediaType && mediaType === 'unknown'" class="msg-attachment">
                    <b-tag type="is-info">Document</b-tag>
                </div>

            </template>

            <div class="msg-body">
                <div class="msg-content">
                           <span dir="ltr">
                              <div>
                                  <slot></slot>
                              </div>
                           </span>
                </div>
                <div class="msg-footer">
                    <div class="msg-ts"><span>{{ new Date().getHours() + ':' + (new Date().getMinutes()<10?'0':'') + new Date().getMinutes() }}</span></div>
                </div>
            </div>
            <span></span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            attachment: {
                type: String,
                default: null
            },
            originatorId: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                mediaType: null
            }
        },
        watch: {
            // whenever question changes, this function will run
            attachment: function (newValue, oldValue) {

                this.attachment = newValue;
                this.setMediaType();
            }
        },
        computed: {
            checkDynamicAttachment() {
                if(this.attachment && this.attachment.indexOf('data:') === -1 && this.$utils.getMimeTypeOfUrl(this.attachment) === 'unknown') {
                    return true;
                }
            },
        },
        methods: {
            setMediaType() {

                let mime = null;

                // check if embedded url
                // if(this.attachment && this.attachment.indexOf(';') > -1) {
                //     [mime] = this.attachment.split(';');
                // }

                if(this.attachment) {
                    if( this.attachment.endsWith('.mp4') || mime === 'data:video/mp4') {
                        this.mediaType = 'video';
                    } else if( this.attachment.endsWith('.mp3') || mime === 'data:audio/mpeg') {
                        this.mediaType = 'audio';
                    } else if( this.attachment.endsWith('.gif') ||
                        this.attachment.endsWith('.jpg') ||
                        this.attachment.endsWith('.png') ||
                        mime === 'image/gif' ||
                        mime === 'image/png' ||
                        mime === 'image/jpeg') {
                        this.mediaType = 'image';
                    } else if( this.attachment.endsWith('pdf')) {
                        this.mediaType = 'pdf';
                    } else {
                        this.mediaType = 'unknown';
                    }
                } else {
                    this.mediaType = null;
                }
            }
        },
        mounted() {

            // check mime
            this.setMediaType();

        }
    }
</script>

<style scoped>

</style>

import replacePlaceholders from '../helpers/replace';


const TemplateGallery = {
    data() {
        return {
            data: {},
            form: {
                name: ''
            },
            templates: [],
            message_body: '',
            message_body_generic: '',
            attachment: null,

            templateList: [],

            loading: true,
            loadingTemplate: true,
            firstTemplateSeen: false
        }
    },
    mounted() {
        this.loadAsyncData();
    },
    props: [
    ],
    template: `

            <form @submit.prevent="handleSubmit">

                <div class="modal-card" style="width: auto">

                    <header class="modal-card-head">
                        <p class="modal-card-title">Template gallery</p>
                        <button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>
                    </header>

                    <section class="modal-card-body">
                        <!--<p>-->
                            <!--Create new template to be used as a starting point for a template-->
                        <!--</p>-->

                        <div class="columns">
                            <div class="column is-6">
                                <span><b>Select gallery</b></span>
                                
                                <!--<b-loading :active.sync="loading"></b-loading>-->
                                
                                 <b-field style="margin-top:10px">
                                    <b-select class="select-custom gallery-select"
                                        ref="selectTemplate"
                                        native-size="6"
                                        v-model="templateList">
                                        <option v-for="obj in templates" :key="obj.id" :value="obj.id">
                                            {{obj.name}}
                                        </option>
                                    </b-select>
                                </b-field>
                            </div>
                            <div class="column is-6">
                                <div style="min-height: 400px">
                                    <b>Preview</b>
                                    <b-loading :is-full-page="false" :active.sync="loadingTemplate"></b-loading>
                                    <message-preview v-show="firstTemplateSeen" :attachment="attachment" style="margin-top:10px"><span v-html="replace(message_body,true)"></span></message-preview>
                                </div>
                            </div>
                            <br>
                        </div>
                    </section>
                    
                    <footer class="modal-card-foot">
                        <div class="columns" style="width: 100%">
                            <div class="column is-12" style="text-align: right">
                                <button class="button is-primary is-medium">Use template</button>
                                <button class="button is-medium" type="button" @click="$parent.close()">Close</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </form>
           
        `,
    watch: {
        // whenever template selection changes, this function will run
        templateList: function (newIndex, oldIndex) {

            // newIndex starts with 1 as first element, so deduct 1 to get the correct index of templates
            const id = this.templates[newIndex - 1].id ;

            const params = {
                id: id,
                urlParams: [
                    'isGlobal=true'
                ].join('&')
            };

            this.loadingTemplate = true;

            this.$store.dispatch('fetchTemplate', params).then((data) => {

                this.message_body = data.result.message_body
                this.message_body_generic = data.result.message_body_generic
                // this.attachment = data.result.attachment

                if (data.result.attachment) {
                    this.getBase64ImageFromUrl(data.result.attachment)
                        .then(result => {
                            this.attachment = result;
                        })
                } else {
                    this.attachment = null;
                }

                this.firstTemplateSeen = true;

            })
                .catch((error) => {
                    this.data = [];
                    throw error
                })
                .finally(() => {
                    this.loadingTemplate = false;
                    this.loading = false;
                });


        }
    },
    methods: {
        replace(msg, personalised) {
            return replacePlaceholders(msg, personalised);
        },
        loadAsyncData() {
            const params = [
                'isGlobal=true',
                'type=MAIN'
            ].join('&');

            this.$store.dispatch('fetchTemplates', params).then((data) => {

                this.templates = data.result.data;
                this.templateList = 1;

                this.$refs.selectTemplate.focus();

            })
            .catch((error) => {
                this.data = [];
                throw error
            })
            .finally(() => {
                this.loading = false;
            });
        },
        async getBase64ImageFromUrl(imageUrl) {
            var res = await fetch(imageUrl);
            var blob = await res.blob();

            return new Promise((resolve, reject) => {
                var reader  = new FileReader();
                reader.addEventListener("load", function () {
                    resolve(reader.result);
                }, false);

                reader.onerror = () => {
                    return reject(this);
                };
                reader.readAsDataURL(blob);
            })
        },
        handleSubmit() {

            this.$buefy.dialog.confirm({
                title: 'Use template',
                cancelText: 'Cancel',
                message: 'Are you sure you want to use this template text and <b>overwrite</b> the existing one?<br><br><u>This action cannot be undone.</u>',
                type: 'is-primary',
                icon: 'paper-plane',
                hasIcon: true,
                onConfirm: () => {

                    this.$parent.close();

                    this.$parent.$parent.setTemplateFields({
                        message_body: this.message_body,
                        message_body_generic: this.message_body_generic,
                        attachment: this.attachment
                    });

                }
            })

        }
    }
};

export default TemplateGallery;

const CreateCouponCodeForm = {
    data() {
        return {
            data: {},
            form: {
                name: 'Coupon code',
            },

            submitting: false
        }
    },
    mounted() {
        this.$refs.code.focus();
    },
    props: [
        'campaignName'
    ],
    watch: {
    },
    template: `
            <form @submit.prevent="handleSubmit">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Create coupon code</p>
                        <!--<button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>-->
                    </header>
                    <section class="modal-card-body">
                    <div class="columns">

                        <div class="column is-1">
                            <b-icon size="is-medium" icon="bullhorn"></b-icon>
                        </div>
                        <div class="column is-10">
                            <p>
                            Create a coupon code to discount customers
                            </p>
                            <hr>
                            <b-field label="Coupon Code">
                                <b-input
                                    size="is-medium"
                                    type="text"
                                    v-model.trim="form.code"
                                    @input="v => { form.code = v.toUpperCase()}"
                                    ref="code"
                                    placeholder="eg. SAVE10"
                                    required
                                >
                                </b-input>
                            </b-field>
                            <br>
                        </div>
                        <div class="column is-1">
                        </div>
                    </div>
                    </section>
                    <footer class="modal-card-foot">
                        <div class="columns" style="width: 100%">
                            <div class="column is-12" style="text-align: right">
                                <button class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}">Create campaign</button>
                                <button class="button is-medium" type="button" @click="$parent.close()">Cancel</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </form>
        `,
    methods: {
        handleSubmit() {
            // Send data to the server or update your stores and such.

            this.submitting = true;

            const params = {
                code: this.form.code
            }

            this.$store.dispatch('createCouponCode', params).then((data) => {

                this.$buefy.toast.open({
                    message: 'Coupon code created successfully',
                    type: 'is-success'
                });

                this.$parent.close();
                this.$router.push({ name: 'admin-coupon-codes-edit', params: { couponCodeId: data.result.id } });

            }).catch((err) => {
                this.$buefy.toast.open({
                    message: 'Error occurred while creating coupon code: '+err,
                    type: 'is-danger'
                });
                // handle error
            });
        }
    }
}

export default CreateCouponCodeForm;

<template>
    <section>
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <h1 class="is-pulled-left">{{ form.name }}
                        <a v-show="form.name" href="#" @click="renameList">
                            <b-icon icon="pencil-alt" size="is-small" style="margin-left:14px"></b-icon>
                        </a>
                    </h1>
                </div>
            </div>

            <!-- Right side -->
            <div class="level-right">
            </div>

        </nav>
        <hr>
        <form @submit.prevent="">

            <b-loading :active.sync="loading"></b-loading>

            <div class="columns is-mobile">
                <div class="column is-1"></div>
                <div class="column is-10">
                    <section>
                        <b-tabs v-show="! loading" type="is-centered" size="is-medium" class="block" v-model="tabIndex" expanded>

                                <b-tab-item>

                                    <template slot="header">
                                        <b-icon icon="user-plus"></b-icon>
                                        <span> Import CSV</span>
                                    </template>

                                    <div class="box">
                                        <div class="has-text-centered">
                                            <b-message type="is-info">
                                                <b>Note:</b> Only internationally formatted mobile phone numbers (E.164) can be imported (e.g. +31612345678)
                                            </b-message>
                                            <label class="label">File upload (comma separated csv or text file only)</label>
                                            <p>
                                                <sub class="text-lightgrey"><b>Expected column order:</b> * Mobile phone number, First name, Last name, Company, Email, Avatar url, Link, Custom attr 1, Custom attr 2, Custom attr 3</sub>
                                            </p>
                                            <p>
                                                <sub class="text-lightgrey"><b>* Mobile phone number is required, all other fields are optional</b></sub>
                                            </p>
                                            <br>
                                            <b-field class="file" v-bind:class="{'is-loading': uploadingFile}">
                                                <b-upload v-model="form.file" type="is-info">
                                                    <a class="button is-light is-medium">
                                                        <b-icon icon="upload"></b-icon>
                                                        <span>Upload CSV file</span>
                                                    </a>
                                                </b-upload>
                                                <div style="margin-top:10px">

                                                    <p>
                                                        <span class="file-name"
                                                              v-if="form.file">{{ form.file.name }}
                                                        </span>
                                                    </p>
                                                </div>
                                            </b-field>

                                            <p style="margin-top:70px">
                                                <button class="button is-info is-medium" @click="importFile" type="button" v-show="form.file" v-bind:class="{'is-loading': importingFile}">Import</button>
                                            </p>

                                        </div>
                                    </div>

                                </b-tab-item>

                                <b-tab-item>

                                    <template slot="header">
                                        <b-icon icon="user-plus"></b-icon>
                                        <span> Import from text</span>
                                    </template>

                                    <div class="box has-text-centered">
                                        <b-message type="is-info">
                                            <b>Note:</b> Only internationally formatted mobile phone numbers (E.164) can be imported (e.g. +31612345678)
                                        </b-message>
                                        <label class="label">Add contact fields below in the following order, separated by comma (,) or semicolon (;). Separate contact by a new line.</label>
                                        <p>
                                            <sub class="text-lightgrey"><b>Expected column order:</b> * Mobile phone number, First name, Last name, Company, Email, Avatar url, Link, Custom attr 1, Custom attr 2, Custom attr 3</sub>
                                        </p>
                                        <p>
                                            <sub class="text-lightgrey"><b>* Mobile phone number is required, all other fields are optional</b></sub>
                                        </p>
                                        <br>
                                        <b-field>
                                            <b-input type="textarea"
                                                     ref="text_import"
                                                     minlength="10"
                                                     maxlength="50000"
                                                     size="is-medium"
                                                     rows="10"
                                                     v-model="form.text_import"
                                                     placeholder="Example: +31612345678, John, Doe, Doe Company, john@doe.com">
                                            </b-input>
                                        </b-field>
                                        <hr>
                                        <p>
                                            <button :disabled="form.text_import.length === 0" class="button is-info is-medium" @click="importTextfield" type="button" v-bind:class="{'is-loading': submitting}">Import</button>
                                        </p>
                                    </div>

                                </b-tab-item>

                                <b-tab-item>

                                    <template slot="header">
                                        <b-icon icon="wrench"></b-icon>
                                        <span> Channel</span>
                                    </template>

                                    <div class="box has-text-centered">

                                        <b-field label="Current channel:">
                                            <channel-display-tag :channel="form.channel"></channel-display-tag>
                                        </b-field>

                                        <br>
                                    </div>

                                    <div class="box has-text-centered">

                                        <b-message type="is-danger">
                                            <b>Important note:</b> Every contact list is assigned to a channel (phone number) which keeps track of opt-in/out states. This means if you change the channel, it will reset the opt-in states of all contacts to unknown, if the opt state of contact's phone number and channel pair isn't already set.
                                        </b-message>

                                        <button class="button is-medium is-danger"
                                                @click="changeChannel" variant="primary">
                                            <b-icon icon="exchange-alt"></b-icon><span>Change channel</span>
                                        </button>
                                    </div>

                                    <div class="box is-hidden">

                                        <b-field label="Send Opt-in message on subscribe">
                                            <b-checkbox true-value="1" false-value="0" v-model="form.send_optin_msg_on_subscribe">
                                            </b-checkbox>
                                        </b-field>

                                        <template v-if="form.optin_template_id">
                                            <router-link :to="{ name: 'template-edit', params: {templateId: form.optin_template_id} }">
                                                Edit Opt-In message
                                            </router-link>
                                        </template>

                                    </div>

                                    <hr>

                                </b-tab-item>

                                <b-tab-item>

                                    <template slot="header">
                                        <b-icon icon="code"></b-icon>
                                        <span> Advanced</span>
                                    </template>

                                    <div class="box">
                                        <b-field label="list_id code (for integrations)">
                                            <b-input v-model="form.uuid" minlength="4" maxlength="128" size="is-medium" required disabled></b-input>
                                        </b-field>
                                    </div>

                                </b-tab-item>
<!--                            </div>-->

                        </b-tabs>
                    </section>
                </div>
                <div class="column is-1"></div>
            </div>

            <br>

        </form>

    </section>
</template>

<script>
    import CreateImportConfirmationForm from '../helpers/createImportConfirmationForm';
    import ChangeChannelForm from '../helpers/changeChannelForm';

    export default {
        data() {
            return {
                data: {
                    childDataLoaded: false
                },
                form: {
                    id: this.$route.params.listId,
                    file: null,
                    name: '',
                    text_import: '',
                    send_optin_msg_on_subscribe: 0,
                    optin_template_id: null,

                    channel_id : null,
                    origin: null,

                    is_optin: null,
                    optin_message: null,

                    uuid: null
                },

                channels: [],
                channel_dropdown_lbl: 'Select',

                contacts_count: null,
                tabIndex: parseInt(this.$route.params.tabIndex) || 0,

                importingFile: false,
                submitting: false,
                loading: true
            }
        },

        watch: {
            // whenever changes, this function will run
            tabIndex: function (newTab, oldTab) {
                if(newTab === 1) {
                    this.$refs.text_import.focus();
                }
            }
        },

        methods: {
            /*
             * Load async data
             */
            loadAsyncData() {

                this.loading = true;

                let params = {
                    id: this.form.id
                };

                this.$store.dispatch('fetchContactList', params).then((data) => {

                    // update view with current campaign settings
                    const result = data.result;

                    this.form.name = result.name;
                    this.form.channel = result.channel;
                    this.form.channel_id = result.channel_id;
                    this.form.origin = result.channel.origin;
                    this.form.uuid = result.uuid;

                    this.channel_dropdown_lbl = result.channel.origin;

                    this.form.optin_template_id = result.optin_template_id;
                    this.contacts_count = result.contacts_count;

                    this.childDataLoaded = true;

                })
                .catch((error) => {
                    this.data = [];
                    this.total = 0;
                    throw error
                })
                .finally(() => {
                    this.loading = false;
                });

            },

            setOrigin(obj) {
                this.form.origin = obj.origin;
                this.form.channel_id = obj.id;
            },

            renameList() {
                this.$buefy.dialog.prompt({
                    message: `Rename contact list to:`,
                    inputAttrs: {
                        placeholder: 'e.g. Newsletter subscribers',
                        maxlength: 64,
                        value: this.form.name
                    },
                    onConfirm: (value) => {
                        this.form.name = value;
                        this.update();

                    }
                })
            },

            update() {

                this.submitting = true;

                const params = {
                    id: this.form.id,
                    name: this.form.name,

                    channel_id: this.form.channel_id,
                    origin: this.form.origin,

                    send_optin_msg_on_subscribe: this.form.send_optin_msg_on_subscribe
                };

                this.$store.dispatch('updateContactList', params).then((data) => {

                    // push notification
                    const toast = {
                        message: `Updated contact successfully!`,
                        type: 'is-success'
                    };
                    this.$buefy.toast.open(toast);

                    // push to view
                    this.$router.go(-1);
                    // this.$router.push('/contactlists');

                })
                    .catch((err) => {

                        this.$buefy.toast.open({
                            message: 'Error saving contact details: '+err.custom.message,
                            type: 'is-danger'
                        });
                    });
            },

            importContactList(type) {


                let formData = new FormData();

                /*
                    Add the form data we need to submit
                */
                if(type === 'file') {
                    this.importingFile = true;

                    formData.append('file', this.form.file);
                } else if(type === 'text') {
                    formData.append('text_import',this.form.text_import);
                }


                // add fields
                formData.append('id', this.form.id);
                formData.append('is_optin', this.form.is_optin);
                formData.append('optin_message', this.form.optin_message);

                //
                this.$store.dispatch('importContactList', { id: this.form.id, formData: formData }).then((data) => {

                    let toast = {};

                    const result = data.result;

                    const contactAddedLbl = this.$options.filters.pluralize(result.added, 'contact');
                    const contactDuplicateLbl = this.$options.filters.pluralize(result.already_exists_in_list, 'duplicate');

                    if(result.added > 0 && result.entries_provided === (result.added + result.already_exists_in_list) ) {


                        if(result.already_exists_in_list === 0) {
                            toast = {
                                message: `Added ${result.added} ${contactAddedLbl} successfully!`,
                                type: 'is-success'
                            };
                        } else {
                            toast = {
                                message: `Added ${result.added} ${contactAddedLbl} successfully! (found ${result.already_exists_in_list} ${contactDuplicateLbl})`,
                                type: 'is-success',
                                duration: 4000
                            };
                        }

                    } else if(result.added > 0 && result.entries_provided !== (result.added + result.already_exists_in_list) ) {

                        toast = {
                            message: `Added ${result.added} ${contactAddedLbl} but failed for ${result.invalid_entries} entries`,
                            type: 'is-warning',
                            duration: 4000
                        };

                    } else if(result.added === 0 && result.contacts_updated > 0) {

                        toast = {
                            message: `${result.contacts_updated} contact(s) updated`,
                            type: 'is-success',
                            duration: 4000
                        };

                    } else if(result.added === 0 || result.entries_provided === result.invalid_entries || result.entries_provided === result.already_exists_in_list) {
``
                        const msg_appendix = result.invalid_entries ? 'invalid entries ('+result.invalid_entries+')' : 'already in list ('+result.already_exists_in_list+')';

                        toast = {
                            message: `No contacts could be added or entries already exist in database. Make sure to order the columns correctly (phone_number,first_name,last_name ...) (${msg_appendix})`,
                            type: 'is-danger',
                            duration: 4000
                        };
                    }

                    this.$buefy.toast.open(toast);


                    if(result.is_optin && result.optin_messages_sent > 0) {
                        // set second toast when optin messages are sent
                        toast = {
                            message: `Opt-in SMS message is sent to ${result.optin_messages_sent} contact(s)`,
                            type: 'is-info',
                            duration: 3000
                        };

                        this.$buefy.toast.open(toast);
                    }

                    this.$router.push({name: 'contacts-by-listId', params: { listId: this.form.id } });

                }).catch((err) => {
                    this.$buefy.toast.open({
                        message: 'Error occurred while updating contact list: '+err.custom.message,
                        type: 'is-danger'
                    });
                    // handle error
                }).finally(() => {
                    this.importingFile = false;
                });
            },

            changeChannel() {
                this.$buefy.modal.open({
                    parent: this,
                    component: ChangeChannelForm,
                    props: {
                        currentChannelId: this.form.channel_id
                        // isOptIn: this.form.isOptIn,
                        // optInMessage: this.form.optInMessage
                    },
                    events: {
                        onSubmit: (data) => {
                            // this.form.isOptIn = data.isOptIn;
                            // this.form.optInMessage = data.optInMessage;
                            //
                            // console.log(this.form.optInMessage);
                            // this.importContactList('file')
                        }
                    },
                    hasModalCard: true
                })
            },

            importFile() {
                // this.$buefy.dialog.confirm({
                //     title: 'Import',
                //     message: 'To continue import, make sure all contacts have given permission to reach them (opt-in)',
                //     confirmText: 'Import',
                //     type: 'is-danger',
                //     hasIcon: true,
                //     onConfirm: () => {
                //         this.importContactList('file')
                //     }
                // })

                this.importingFile = true;

                this.$buefy.modal.open({
                    parent: this,
                    component: CreateImportConfirmationForm,
                    props: {
                        isOptIn: this.form.isOptIn,
                        optInMessage: this.form.optInMessage
                    },
                    events: {
                        onSubmit: (data) => {
                            this.form.isOptIn = data.isOptIn;
                            this.form.optInMessage = data.optInMessage;

                            this.importContactList('file')
                        }
                    },
                    hasModalCard: true
                })
            },

            importTextfield() {

                // Send data to the server or update your stores and such.
                // let params = this.form;

                // this.$buefy.dialog.confirm({
                //     title: 'Import',
                //     message: 'To continue import, make sure all contacts have given permission to reach them (opt-in)',
                //     confirmText: 'Import',
                //     type: 'is-danger',
                //     hasIcon: true,
                //     onConfirm: () => {
                //         this.importContactList('text')
                //     }
                // })

                this.$buefy.modal.open({
                    parent: this,
                    component: CreateImportConfirmationForm,
                    props: {
                        isOptIn: this.form.is_optin,
                        optInMessage: this.form.optin_message
                    },
                    events: {
                        onSubmit: (data) => {
                            this.form.is_optin = data.isOptIn ? 1 : 0;
                            this.form.optin_message = data.optInMessage;

                            this.importContactList('text')
                        }
                    },
                    hasModalCard: true
                })

            },

            handleChannelItemClick(obj) {

                this.form.channel_id = obj.id;
                this.form.origin = obj.origin;
                this.channel_dropdown_lbl = obj.origin;

            }
        },
        mounted() {

            this.loadAsyncData()
        }
    }
</script>

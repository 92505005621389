const CreateContactListForm = {
    data() {
        return {
            data: {},
            form: {
                name: 'My prospect list',
                channel_id : null,
                origin: null
            },

            channels: [],

            channel_dropdown_lbl: 'Select...',

            submitting: false

        }
    },
    props: [
        'campaignName'
    ],
    template: `
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Create a contact list</p>
                        <!--<button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>-->
                    </header>
                    <section class="modal-card-body">
                       <div class="columns">
                        
                            <div class="column is-1">
                                <b-icon size="is-medium" icon="list-ol"></b-icon>
                            </div>
                            <div class="column is-10">
                                <p>
                                    Create a new list of contacts to be used for campaign management
                                </p>
                                <hr>
                                
                                <b-field label="List Name">
                                
                                    <b-input
                                        size="is-medium"
                                        type="text"
                                        :value="form.name"
                                        v-model.trim="form.name"
                                        ref="contactListName"
                                        placeholder="eg. My prospect list"
                                        @focus="$event.target.select()"
                                    >
                                    </b-input>
                                   
                                </b-field>
                                
                                <br>
                                <label class="label">List channel</label>
                                <channel-selector :allowClickInactiveChannels="true" v-on:change="setOrigin"></channel-selector>
                                
                                <div style="margin-bottom:100px"></div>
                                <br>
                            </div>
                            <div class="column is-1">
                              
                            </div>
                        </div>
                    </section>
                    <footer class="modal-card-foot">
                        <div class="columns" style="width: 100%">
                            <div class="column is-12" style="text-align: right">
                                <button class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}" :disabled="! form.name || ! form.channel_id" @click="handleSubmit">Create contact list</button>
                                <button class="button is-medium" type="button" @click="$parent.close()">Cancel</button>
                            </div>
                        </div>
                    </footer>
                </div>

        `,
    methods: {
        setOrigin(obj) {
            this.form.origin = obj.origin;
            this.form.channel_id = obj.id;
        },
        /*
             * Load async data
             */
        loadAsyncData() {

            const params = [
                'enabled=1'
            ].join('&');

            this.$store.dispatch('fetchChannels', params ).then((data) => {

                let channels = data.result.data;

                for (let i = 0; i < channels.length ; i++) {
                    channels[i].ddIndex = i;
                    this.channels.push(channels[i]);
                }

            })
            .catch((error) => {
                this.data = [];
                throw error
            });

        },
        handleSubmit() {

            this.submitting = true;

            // Send data to the server or update your stores and such.
            const params = {
                name: this.form.name,
                channel_id: this.form.channel_id
            }

            this.$store.dispatch('createContactList', params).then((data) => {

                this.$buefy.toast.open({
                    message: 'Contact list created!',
                    type: 'is-success'
                });

                // alert('hi');
                this.$parent.close();

                this.$router.push(`/contactlists/edit/${data.result.id}`);

            }).catch((err) => {
                this.$buefy.toast.open({
                    message: 'Error occurred while creating contact list: '+err,
                    type: 'is-danger'
                });
                // handle error
            })
            .finally(() => {
                this.submitting = false;
            });
        },

        handleChannelItemClick(obj) {

            this.form.channel_id = obj.id;
            this.form.origin = obj.origin;
            this.channel_dropdown_lbl = obj.origin;
        }
    },
    mounted() {
        this.loadAsyncData();
        this.$refs.contactListName.focus();
    }
}

export default CreateContactListForm;

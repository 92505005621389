const ScanQRCodeForm = {
    data() {
        return {
            data: {},

            qr_code_generated_at: null,
            qr_code_image: null,

            // is_waiting_for_qr_validation: false,

            loading: true,
            refreshDataInterval: 10000,

            timeOutHandler: null,
            refreshEnabled: true,

            noWacCounter: 0
        }
    },
    props: [
        'channelId',
        'channelOrigin'
    ],
    template: `
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Scan QR Code</p>
                        <button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>
                    </header>

                    <!-- qr loading --->

                    <section class="modal-card-body" v-show="loading">
                        <div class="columns">

                            <div class="column is-1">
                                <b-icon size="is-medium" icon="qrcode"></b-icon>
                            </div>
                            <div class="column is-10 has-text-centered">
                                <p>
                                    Loading QR code to connect this number, please hang on..
                                    <br><br><br><br><br>

                                </p>
                                <b-loading :active.sync="loading" :is-full-page="false"></b-loading>

                            </div>

                        </div>
                    </section>


                    <!-- qr loaded -->
                    <section class="modal-card-body" v-show="! loading">
                       <div class="columns">

                            <div class="column is-1">
                                <b-icon size="is-medium" icon="qrcode"></b-icon>
                            </div>
                            <div class="column is-10 has-text-centered">
                                <p>
                                    Scan the QR code below with your WhatsApp enabled phone.
                                    <br>
                                    <span style="font-size: 12px">Make sure to scan the QR code with phone number

                                        <b-tag type="is-success" class="phone" size="is-small">
                                            <b-icon pack="fab" size="is-small" icon="whatsapp" style="margin-left: 0px"></b-icon>
                                            <span style="vertical-align: text-top;"><b>+{{ channelOrigin }}</b></span>
                                        </b-tag>
                                    </span>
                                </p>
                                <hr>

<!--                                <div v-if="is_waiting_for_qr_validation">-->
<!--                                    <b-notification-->
<!--                                        type="is-warning"-->
<!--                                        :closable="false"-->
<!--                                        role="alert">-->
<!--                                        Validating QR code...-->
<!--                                    </b-notification>-->
<!--                                </div>-->

                                <div v-if="! loading && qr_code_image">
                                    <img :src="qr_code_image">
                                    <br><br>
                                    <span style="font-size: 12px">Note. It could take up to <b>1 or 2 minutes</b> after scanning to connect your phone!</span>

<!--                                    <span style="font-size: 12px">QR Code image last updated {{ qr_code_generated_at | moment('timezone', $global.current_tz) | moment("from", "now") }}</span>-->
                                </div>


                                <b-notification
                                    v-if="! loading && ! qr_code_image"
                                    type="is-danger"
                                    :closable="false"
                                    role="alert">
                                    Your phone number cannot be connected at this moment. Our operational team has received this message and will get in touch with you shortly!
                                </b-notification>

                                <br>
                            </div>
                            <div class="column is-1">

                            </div>

                        </div>
                    </section>
                    <footer class="modal-card-foot">
                        <div class="columns" style="width: 100%">
                            <div class="column is-12" style="text-align: right">
                                <button class="button is-medium" type="button" @click="$parent.close()">Close</button>
                            </div>
                        </div>
                    </footer>
                </div>

        `,
    computed: {

    },
    methods: {
        loadAsyncData() {

            const params = {
                id: this.channelId
            };

            this.$store.dispatch('fetchQRCode', params).then((data) => {

                let result = data.result;

                if(typeof result.msg !== 'undefined' && result.msg === 'already_online') {

                    this.$emit('success');
                    this.$parent.close();

                    this.$buefy.toast.open({
                        message: 'Your phone is successfully connected!',
                        type: 'is-success'
                    });

                    this.disableRefresher();

                    return;
                } else if(result.msg === 'waiting_for_qr') {
                    // this.is_waiting_for_qr_validation = true;

                    return;
                } else if(result.msg === 'no_available_wac_found') {

                    this.noWacCounter++;

                    if(this.noWacCounter >= 5) {

                        this.$buefy.toast.open({
                            message: 'Your number could not be connected at the moment. Our team is notified and will contact with further instructions. We are sorry for the inconvenience.',
                            type: 'is-danger',
                            duration: 8000
                        });

                        this.disableRefresher();

                        this.$parent.close();

                    } else {
                        this.$buefy.toast.open({
                            message: 'There is currently no free slot available to connect your phone. Retrying in 5 seconds...',
                            type: 'is-warning'
                        });
                    }

                    return;

                }

                // if (! result.qr_code_valid) {
                //     qr expired
                    // this.qr_code_image = null;
                    // this.refreshEnabled = true;
                    //
                    // this.$store.dispatch('sendEvent', { type: 'customerCannotScanQRCode' }).then(() => {});
                    //
                    // console.log(this.checkQRCodeValidity(result.qr_code_generated_at));
                // } else {

                    this.qr_code_image = result.qr_code_image;
                    this.qr_code_generated_at = result.qr_code_generated_at;

                    if(! this.qr_code_image) {
                        // scan success

                        // Your phone is successfully linked.
                        //todo: toast below disabled, because it gives negative positives
                        // this.$buefy.toast.open({
                        //     message: 'Your phone is successfully connected!',
                        //     type: 'is-info'
                        // });

                        this.disableRefresher();

                        this.$emit('success');
                        this.$parent.close();


                    } else {
                        // this.refreshEnabled = true;
                        this.loading = false;
                    }

            })
                .catch((error) => {
                    throw error
                })
                .finally(() => {
                });

        },

        disableRefresher() {
            this.loading = false;

            this.refreshEnabled = false;
            clearTimeout(this.timeOutHandler);

        },
        refreshData() {

            if(this.refreshEnabled) {

                this.timeOutHandler = setTimeout(() => {

                    this.loadAsyncData();
                    this.refreshData();

                }, this.refreshDataInterval);

            }
        }

    },

    created() {

    },
    mounted() {
        this.noWacCounter = 0;

        this.loadAsyncData();
        this.refreshData();
    }
}

export default ScanQRCodeForm;

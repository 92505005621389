const CreateUserForm = {
    data() {
        return {
            data: {},
            form: {
                password: '',
                first_name: '',
                last_name: '',
                email: ''
            },

            submitting: false
        }
    },
    mounted() {
        this.$refs.userFirstName.focus();
    },
    props: [
        'name'
    ],
    template: `
            <form @submit.prevent="handleSubmit">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Create a user</p>
                        <button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>
                    </header>
                    <section class="modal-card-body">
                    <div class="columns">
                        <div class="column is-1">
                            <b-icon size="is-medium" icon="bullhorn"></b-icon>
                        </div>
                        <div class="column is-10">
                            
                            <b-field label="First Name">
                                <b-input
                                    size="is-medium"
                                    type="text"
                                    :value="form.first_name"
                                    v-model.trim="form.first_name"
                                    ref="userFirstName"
                                    style="width: 500px"
                                    required
                                >
                                </b-input>
                            </b-field>
                            
                            <b-field label="Last Name (optional)">
                                <b-input
                                    size="is-medium"
                                    type="text"
                                    :value="form.last_name"
                                    v-model.trim="form.last_name"
                                    ref="userLastName"
                                    style="width: 500px"
                                >
                                </b-input>
                            </b-field>
                            
                            <b-field label="Email (User Name)">
                                <b-input
                                    size="is-medium"
                                    type="text"
                                    :value="form.email"
                                    v-model.trim="form.email"
                                    ref="userEmail"
                                    style="width: 500px"
                                    required
                                >
                                </b-input>
                            </b-field>
                            
                            <b-field label="Password">
                                <password-field v-model="form.password" size="12" auto="true" characters="a-z,A-Z,0-9"></password-field>
                            </b-field>
                            
                            <br>
                        </div>
                        <div class="column is-1">
                        </div>
                    </div>
                    </section>
                    <footer class="modal-card-foot">
                    
                        <div class="columns" style="width: 100%">
                            <div class="column is-12" style="text-align: right">
                                <button class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}">Create User</button>
                                <button class="button is-medium" type="button" @click="$parent.close()">Cancel</button>
                            </div>
                        </div>
                    
                    </footer>
                </div>
            </form>
        `,
    methods: {
        handleSubmit() {
            // Send data to the server or update your stores and such.

            this.submitting = true;

            const params = this.form;

            this.$store.dispatch('createUser', params).then((data) => {

                this.$buefy.toast.open({
                    message: 'Added a new user account!',
                    type: 'is-success'
                });

                this.$parent.close();

                this.$router.push(`/admin/user/edit/${data.result.id}`);

            }).catch((err) => {
                this.$buefy.toast.open({
                    message: 'Error occurred while creating user account: '+err,
                    type: 'is-danger'
                });
                // handle error
            });
        }
    }
}

export default CreateUserForm;

<template>
    <section>
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <div>
                        <h1>Create contact widget code</h1>
                    </div>
                </div>
            </div>

        </nav>

        <form @submit.prevent="">

            <!--<b-loading :active.sync="loading"></b-loading>-->

            <transition name="fade">
                <div v-if="!loading">

                    <div class="columns is-mobile">
                        <div class="column is-1"></div>
                        <div class="column is-10">
                            <section>
                                <b-tabs type="is-centered" size="is-medium" class="block" v-model="tabIndex" v-on:change="changeTab" expanded>
                                        <b-tab-item>

                                            <template slot="header">
                                                <b-icon icon="address-book"></b-icon>
                                                <span>1. Select list</span>
                                            </template>

                                            <div class="box">
                                                <div class="columns">
                                                    <div class="column is-2"></div>
                                                    <div class="column is-8">
                                                        <p>
                                                            Follow the steps to add a contact widget icon in the bottom right for your website to allow your website visitors to contact you through WhatsApp.
                                                        </p>
                                                    </div>
                                                    <div class="column is-2"></div>
                                                </div>
                                            </div>

                                            <div class="box has-text-centered">
                                                <contactlist-selector v-model="list" type="CONTACT"></contactlist-selector>
                                            </div>

                                            <hr>
                                            <div>
                                                <p>
                                                    <button class="button is-primary is-medium is-pulled-right" :disabled="! list.id" @click="tabIndex=1" type="button">Next</button>
                                                </p>
                                                <br>
                                            </div>

                                        </b-tab-item>

                                        <b-tab-item :disabled="! list.id">
                                            <template slot="header">
                                                <b-icon icon="wrench"></b-icon>
                                                <span>2. Customize</span>
                                            </template>

                                            <div class="box">
                                                <div class="columns">
                                                    <div class="column is-2"></div>
                                                    <div class="column is-8">

                                                        <label class="label-field">
                                                            Mobile behavior
                                                        </label>

                                                        <b-checkbox v-model="form.start_wa_chat_on_mobile">
                                                            When opening widget <b>on mobile device</b>, directly open <i>WhatsApp</i> and start chat with following phone number:
                                                        </b-checkbox>

                                                        <br><br>

                                                        <b-field>
                                                            <b-input :disabled="! form.start_wa_chat_on_mobile" size="is-medium" maxlength="64" v-model="list.channel.origin" required></b-input>
                                                        </b-field>

                                                        <label class="label-field">
                                                            Customization
                                                        </label>

                                                        <p>
                                                            If you want to make changes to the default widget settings, select the checkbox below
                                                        </p>
                                                        <br>

                                                        <b-checkbox v-model="isCustomised">
                                                            Customize widget
                                                        </b-checkbox>

                                                    </div>
                                                    <div class="column is-2"></div>
                                                </div>
                                            </div>

                                            <div class="box" v-show="isCustomised">

                                                <div class="columns is-mobile">
                                                    <div class="column is-2"></div>
                                                    <div class="column is-8">

                                                        <div v-show="isCustomised">
                                                            <p>
                                                                Click the <b>"Preview widget"</b> to view the changes reflected in the widget.
                                                            </p>
                                                            <p>
                                                                When you are satisfied with the result, click the "Generate script" button.
                                                            </p>
                                                        </div>
                                                        <hr>

                                                        <hr>
                                                        <label class="label-field">
                                                            Welcome Title
                                                        </label>

                                                        <b-field>
                                                            <b-input size="is-medium" maxlength="64" v-model="form.welcome_title" required></b-input>
                                                        </b-field>

                                                        <label class="label-field">
                                                                Widget Subtitle
                                                        </label>

                                                        <b-field>
                                                            <b-input size="is-medium" type="textarea" maxlength="300" v-model="form.welcome_subtitle" required></b-input>
                                                        </b-field>

                                                        <label class="label-field">
                                                            Contact Form Title
                                                        </label>

                                                        <b-field>
                                                            <b-input size="is-medium" type="textarea" maxlength="300" v-model="form.contact_form_title" required></b-input>
                                                        </b-field>

                                                        <label class="label-field">
                                                            Contact Form Subtitle
                                                        </label>

                                                        <b-field>
                                                            <b-input size="is-medium" type="textarea" maxlength="300" v-model="form.contact_form_subtitle" required></b-input>
                                                        </b-field>

                                                        <label class="label-field">
                                                            Agent Avatar Image Url
                                                        </label>

                                                        <b-field>
                                                            <b-input size="is-medium" type="textarea" maxlength="300" v-model="form.agent_avatar" required></b-input>
                                                        </b-field>

                                                        <hr>

                                                        <label class="label-field">
                                                            Name field (placeholder)
                                                        </label>

                                                        <b-field>
                                                            <b-input size="is-medium" maxlength="64" v-model="form.name_placeholder" required></b-input>
                                                        </b-field>

                                                        <label class="label-field">
                                                            Phone field (placeholder)
                                                        </label>

                                                        <b-field>
                                                            <b-input size="is-medium" maxlength="64" v-model="form.phone_placeholder" required></b-input>
                                                        </b-field>

                                                        <label class="label-field">
                                                            Message field (placeholder)
                                                        </label>

                                                        <b-field>
                                                            <b-input size="is-medium" maxlength="64" v-model="form.message_placeholder" required></b-input>
                                                        </b-field>

                                                        <label class="label-field">
                                                            Submit button
                                                        </label>

                                                        <b-field>
                                                            <b-input size="is-medium" maxlength="64" v-model="form.submit_button" required></b-input>
                                                        </b-field>

                                                    </div>
                                                    <div class="column is-1"></div>
                                                </div>
                                            </div>

                                            <div>
                                                <p>
                                                    <button class="button is-light is-medium is-pulled-left" @click="tabIndex=0">Back</button>&nbsp;
                                                </p>
                                            </div>

                                            <div class="field is-grouped is-pulled-right">
                                                <button class="button is-medium" @click="injectWidget">Preview widget</button>
                                                <button class="button is-info is-medium" style="margin-left:6px" @click="tabIndex=2" type="button">Generate script</button>&nbsp;
                                            </div>

                                        </b-tab-item>


                                    <b-tab-item :disabled="! list.id">

                                        <template slot="header">
                                            <b-icon icon="code"></b-icon>
                                            <span>3. Generate</span>
                                        </template>

                                        <div class="box">

                                            <div class="columns is-mobile">
                                                <div class="column is-1"></div>
                                                <div class="column is-10">

                                                    <label class="label-field">
                                                        Generated script
                                                    </label>

                                                    <p>
                                                        To embed the widget on your website, copy and paste the following script code in the source code of your website.
                                                    </p>
                                                    <div class="field">
                                                        <div class="control">
                                                                <textarea rows="7" class="textarea is-info code-block" type="text" placeholder="Info textarea" disabled><!-- START Widget -->{{ widget.code }}
<!-- END Widget --></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="has-text-centered">
                                                        <button class="button is-primary is-medium" v-clipboard:copy="widget.code" @click="$dialog.alert({ title: 'Info', message: 'Successfully copied widget script to clipboard.',confirmText: 'OK'})" type="button">Copy to clipboard</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="column is-1"></div>
                                        </div>

                                        <div>
                                            <p>
                                                <button class="button is-light is-medium is-pulled-left" @click="tabIndex=1">Back</button>&nbsp;
                                            </p>
                                        </div>

                                    </b-tab-item>

                                </b-tabs>

                            </section>
                        </div>
                        <div class="column is-1"></div>
                    </div>

                    <br>
                </div>
            </transition>

        </form>

    </section>
</template>

<script>

    export default {
        data() {
            return {
                data: [],
                form: {
                    welcome_title: 'Hi there 👋',
                    welcome_subtitle: 'Ask us anything, or share your feedback',
                    contact_form_title: 'Start a Whatsapp conversation',
                    contact_form_subtitle: 'Our team typically replies within 15 minutes through WhatsApp',
                    agent_avatar: this.$global.hostname+'/widget/embed/assets/images/avatars/mk.png',
                    name_placeholder: 'Your name',
                    phone_placeholder: '(e.g. +31 612 345 678)',
                    message_placeholder: 'Your message',
                    submit_button: 'Start a conversation',
                    wa_contact: '',
                    start_wa_chat_on_mobile: false,
                },

                list : {
                    id: null,
                    name: null,
                    uuid: null,
                    contacts_count: null,
                    channel: {
                        send_campaign_recipient_limit: null,
                        origin: null
                    }
                },

                widget: {
                    code: ''
                },

                isCustomised: false,

                contactList: {},

                contactLists: [],
                contactlist_dropdown_lbl: 'Select',

                tabIndex: 0,

                loading: true

            }
        },
        computed: {

        },
        methods: {
            injectWidget() {

                if(window.__tp) {
                    window.__tp.unload();
                }

                window.__tp = window.__tp || {};

                window.__tp.app_uri = this.$utils.getCurrentUri();
                window.__tp.account = 'd8545b875f10a0fa30ced171d6c047f5';
                window.__tp.list = '5c000f0daba86';

                let varsString = '';

                if(this.isCustomised) {
                    window.__tp.vars = {
                        welcome_title: this.form.welcome_title,
                        welcome_subtitle: this.form.welcome_subtitle,
                        contact_form_title: this.form.contact_form_title,
                        contact_form_subtitle: this.form.contact_form_subtitle,
                        agent_avatars: [this.form.agent_avatar],
                        name_placeholder:  this.form.name_placeholder,
                        phone_placeholder:  this.form.phone_placeholder,
                        message_placeholder:  this.form.message_placeholder,
                        submit_button:  this.form.submit_button
                    }
                }

                var tp = document.createElement('script'); tp.id='pname-widget-script'; tp.type = 'text/javascript';
                tp.src = window.__tp.app_uri + '/widget/clientContact.min.js?'+Math.random();
                tp.onload = function() {
                    setTimeout(() => {
                        window.__tp.triggerEvent('event:widget-open');
                    },600);
                }

                var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(tp, s);


                },

            cacheBusterString() {
                const dateObj = new Date();
                const month = dateObj.getUTCMonth() + 1; //months from 1-12
                const day = dateObj.getUTCDate();
                const year = dateObj.getUTCFullYear();
                const hours = dateObj.getUTCHours();

                return year + month + day + hours;
            },
            changeTab(idx) {
                if(idx === 2) {
                    // preview
                    this.generateScript();
                }
            },
            generateScript() {
                let varsString = '';

                if(this.isCustomised) {
                    varsString = `
window.__tp.vars = {
    start_wa_chat_on_mobile: ${this.form.start_wa_chat_on_mobile},
    wa_contact: '${this.form.start_wa_chat_on_mobile ? this.list.channel.origin : ''}',
    welcome_title: '${this.form.welcome_title}',
    welcome_subtitle: '${this.form.welcome_subtitle}',
    contact_form_title: '${this.form.contact_form_title}',
    contact_form_subtitle: '${this.form.contact_form_subtitle}',
    agent_avatars: ['${this.form.agent_avatar}'],
    name_placeholder: '${this.form.name_placeholder}',
    phone_placeholder: '${this.form.phone_placeholder}',
    message_placeholder: '${this.form.message_placeholder}',
    submit_button: '${this.form.submit_button}',
    };
                    `;
                } else {
                  varsString = `
window.__tp.vars = {
    start_wa_chat_on_mobile: ${this.form.start_wa_chat_on_mobile},
    wa_contact: '${this.form.start_wa_chat_on_mobile ? this.list.channel.origin : ''}',
    };
                    `;

                }

                this.widget.code = `
<script type="text/javascript">
window.__tp = window.__tp || {};
(function() {
    window.__tp.app_uri = '${this.$utils.getCurrentUri()}';
    window.__tp.account = '${this.$store.getters.userProfile.account.uuid}';
    window.__tp.list = '${this.list.uuid}';
    ${varsString}
    var tp = document.createElement('script'); tp.type = 'text/javascript';
    tp.src = window.__tp.app_uri + '/widget/clientContact.min.js?';
    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(tp, s);

})();
${'<'}/script${'>'}`;
            }

        },
        mounted() {
            this.loading = false;
        }
    }
</script>

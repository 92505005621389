<template>

    <form @submit.prevent="">
        <div class="is-hidden">{{ usage }}</div> <!-- required -->
        <b-loading :active.sync="loading"></b-loading>
        <div v-if="! loading">
            <!--<p style="font-size: 20px">-->
                <!--<b-icon icon="address-book" style="margin-right:14px; vertical-align: text-top"></b-icon>You have currently <u>{{ contactsInAccount | currency('', 0, { thousandsSeparator: ',' }) }} {{ contactsInAccount | pluralize('contact') }}</u> in your account.-->
                <!--&lt;!&ndash;<span>This costs you {{ contactsInAccount | currency('€',0) }}/month.</span>&ndash;&gt;-->
            <!--</p>-->
            <!--<hr>-->
            <div v-show="isPayAsYouGo">
                <div class="box" style="margin-bottom: 20px">
                    <small>
                        <i>The Pay-As-You-Go Plan is a flexible alternative to a monthly plan.</i>
                        Instead of monthly recurring payments you buy credits as you go. All credits are valid for six months. We'll update you one month prior to the expiration.
                    </small>
                    <br>
                    <b-checkbox v-model="WACreditsEnabled" @input="toggleWACreditsSwitch"><b>Add WhatsApp credits</b></b-checkbox>

                    <div class="box" style="margin-top: 20px">

                        <b-table v-if="usage.pricingChannelCredits" :data="usage.pricingChannelCredits.dynamic_wa" @click="clickWaUsageTableRow" :selected.sync="waAmountInput">
                            <template slot-scope="props">
                                <b-table-column field="" label="AMOUNT">
                                    {{ props.row.amount | currency('', 0, { thousandsSeparator: ',' }) }}
                                </b-table-column>

                                <b-table-column field="" label="PRICE" centered>
                                    {{ (props.row.amount * props.row.price) | currency('€', 0, { thousandsSeparator: ',' }) }}
                                </b-table-column>

                            </template>
                        </b-table>

                        <p style="margin-top:30px">
                            <b-notification :closable="false" style="text-align:left !important">
                                Exceeding the monthly quota of each volume tier will automatically upgrade your account to the next volume tier.
                            </b-notification>
                        </p>
                    </div>

<!--                        <div v-show="WACreditsEnabled">-->
<!--                            <br>-->
<!--                            <label class="label-field">How many WhatsApp credits you need?</label>-->
<!--                            <small>-->
<!--                                <i>Minimum of 200 credits. One credit allows you to send one Whatsapp message to a contact."</i>-->
<!--                            </small>-->
<!--                            <b-field grouped>-->
<!--                                <b-input size="is-medium" ref="waAmountInput" v-model="waAmountInput" @input="updateCredits" placeholder="WhatsApp amount" type="number" :min="minWhatsappAmount"></b-input>-->
<!--                            </b-field>-->
<!--                        </div>-->
                </div>
            </div>

            <!-- recurring model -->
            <div v-if="! isPayAsYouGo && usage.pricingChannelCredits" class="box" style="margin-top: 20px">

                <b-table :data="usage.pricingChannelCredits.recurring_wa" @click="clickWaRecurringUsageTableRow" :selected.sync="whatsappCountTierLimit">
                    <template slot-scope="props">
                        <b-table-column field="" label="VOLUME (MESSAGES /MO)">
                            <template v-if="props.row.amount > 0">
                                {{ props.row.amount | currency('', 0, { thousandsSeparator: ',' }) }}
                            </template>
                            <template v-else-if="props.row.amount < 0">
                                Unlimited
                            </template>
                            <template v-else-if="props.row.amount === 0">
                                Keep existing plan
                            </template>
                        </b-table-column>

                        <b-table-column field="" label="MONTHLY FEE" centered>
                            {{ (schedule === 'MONTHLY' ? props.row.monthly_plan_price : props.row.yearly_plan_price) | currency('€', 0, { thousandsSeparator: ',' }) }}
                        </b-table-column>

                    </template>
                </b-table>

<!--                        <p style="margin-top:30px">-->
<!--                            <b-notification :closable="false" style="text-align:left !important">-->
<!--                                Exceeding the monthly quota of each volume tier will automatically upgrade your account to the next volume tier.-->
<!--                            </b-notification>-->
<!--                        </p>-->
            </div>

            <div class="box">
                <b-checkbox v-model="SMSCreditsEnabled" @input="toggleSMSCreditsSwitch"><b>Add SMS credits (optional)</b></b-checkbox>
                <div v-show="SMSCreditsEnabled">
                    <br>
                    <label class="label-field">
                        How many SMS credits you need? (optional)
                        <b-tooltip multilined type="is-info" :label="plan.short_desc" style="vertical-align: middle;" position="is-right" >
                            <b-icon size="is-small" type="is-dark" icon="info-circle" style="margin-left: 6px">
                                In order to send SMS campaigns, credits are required. Each outbound message costs 1 credit.
                            </b-icon>
                        </b-tooltip>
                    </label>
                    <small>
                        <i>Minimum of 200 credits. One credit allows you to send one SMS message to a contact.</i>
                    </small>
                    <b-field grouped>
                        <b-input size="is-medium" ref="smsAmountInput" v-model="smsAmountInput" @input="updateCredits" placeholder="SMS amount" type="number" :min="minSMSAmount"></b-input>
                    </b-field>
                </div>
            </div>

        </div>

<!--            <div v-if="! isPayAsYouGo && showContactUsageForm">-->
<!--                <hr>-->
<!--                <div class="box">-->
<!--                    <h3>Your audience</h3>-->
<!--                    <br>-->
<!--                    <label class="label-field">-->
<!--                        How many WhatsApp subscribers/contacts (phone numbers) do you need?-->
<!--&lt;!&ndash;                        <b-tooltip multilined type="is-info" :label="''" style="vertical-align: middle;" position="is-right" >&ndash;&gt;-->
<!--&lt;!&ndash;                            <b-icon size="is-small" type="is-dark" icon="info-circle" style="margin-left: 6px">&ndash;&gt;-->
<!--&lt;!&ndash;                            </b-icon>&ndash;&gt;-->
<!--&lt;!&ndash;                        </b-tooltip>&ndash;&gt;-->
<!--                    </label>-->
<!--                    <b-field grouped>-->
<!--                        <b-input size="is-medium" ref="contactAmountInput" v-model="contactAmountInput" placeholder="Contact amount" expanded></b-input>-->
<!--                        <p class="control">-->
<!--                            <button :disabled="contactAmountInput == usage.contacts.amount" class="button is-primary is-medium" @click="updateAmount" type="number" min="1000">Calculate</button>-->
<!--                        </p>-->
<!--                    </b-field>-->
<!--                    <div v-if="usage.contacts.amount"><i>Currently you have {{ contactsInAccount | currency('', 0, { thousandsSeparator: ',' }) }} {{ contactsInAccount | pluralize('contact') }} in your account. Your price up to {{ contactAmountInput }} contacts will be {{ getUsagePrice | currency('€',0) }}/month</i></div>-->

<!--                </div>-->

<!-- deprecated contact volume -->
<!--                <div class="box">-->
<!--                    <b-table v-if="usage.pricingTable" :data="usage.pricingTable" @click="clickUsageTableRow" :selected.sync="selected">-->
<!--                        <template slot-scope="props">-->

<!--                            <b-table-column field="total_contacts" label="TOTAL CONTACTS">-->
<!--                                {{ props.row.total_contacts.from }} - {{ props.row.total_contacts.to }}-->
<!--                            </b-table-column>-->

<!--                            <b-table-column field="wa_sent_msg_count" label="WHATSAPP MESSAGE LIMIT" centered>-->
<!--                                {{ props.row.wa_sent_msg_limit_monthly > 0 ? props.row.wa_sent_msg_limit_monthly : 'Unlimited' }}-->
<!--                            </b-table-column>-->

<!--                            <b-table-column v-show="usage.schedule === 'MONTHLY'" field="monthly_cost_eur" label="MONTHLY COST" numeric>-->
<!--                                € {{ props.row.monthly_cost_eur }}-->
<!--                            </b-table-column>-->

<!--                            <b-table-column v-show="usage.schedule === 'YEARLY'" field="monthly_cost_eur_per_year" label="MONTHLY COST" numeric>-->
<!--                                € {{ props.row.monthly_cost_eur_per_year }}-->
<!--                            </b-table-column>-->

<!--                        </template>-->
<!--                    </b-table>-->

<!--                    <p style="margin-top:30px">-->
<!--                        <b-notification :closable="false" style="text-align:left !important">-->
<!--                            Exceeding the contacts quota of each volume tier will automatically upgrade your account to the next volume tier.-->
<!--                        </b-notification>-->
<!--                    </p>-->
<!--                </div>-->


<!--            </div>-->

<!--        </div>-->

    </form>

</template>

<script>

    export default {
        // directives: { cleave },
        props: {
            value: { type: Object, default: false },
            // contactsInAccount: { type: Number, default: false },
            schedule: { type: String, default: 'MONTHLY' },
            contactsCountTierLimit: { type: Number, default: 0 }, // deprecated
            whatsappCountTierLimit: { type: Object, default: null },
            plan: { type: String, default: 'PLUS'},
            showContactUsageForm: { type: Boolean, default: true }
        },
        data: function () {
            return {
                data: [],

                rendered: false,

                masks: {
                    numeral: {
                        numeral: true,
                        numeralThousandsGroupStyle: 'thousand',
                        prefix: ''
                    },
                },

                WACreditsEnabled: true,
                SMSCreditsEnabled: false,

                contactAmountInput: null, // deprecated
                smsAmountInput: null,
                waAmountInput: null,

                selected: null, // possible deprecated
                selectedWaTierAmount: null,
                usage: this.value,
                // contactAmount: this.value,
                newContactAmount: null,

                minWhatsappAmount: 200,
                minSMSAmount: 200,

                submitting: false,
                loading: false
            }
        },
        watch: {
            // whenever changes, this function will run
            value: {
                handler(val) {
                    // this.$emit('update:contactsCountTierLimit', this.contactAmountInput);
                    this.$emit('update:whatsappCountTierLimit', this.whatsappCountTierLimit);
                },
                deep: true
            },
            schedule: {
                handler(val) {
                    // this.updateAmount();
                },
                deep: true
            }
        },
        computed: {

            getWaBulkAmount() {
                if(this.$store.state.account.billingDetails) {
                    return this.$store.state.account.billingDetails.wa.amount || null;
                } else {
                    return null;
                }
            },

            inPaidPlan() {
                return this.$store.state.account.plan !== 'TRIAL'
            },

            isPayAsYouGo() {
                return this.plan === 'PAY AS YOU GO';
            },

            getContactCount() {
              return this.usage.contacts.amount;
            },

            getUsagePrice() {

                for (let i=0; i<this.usage.pricingTable.length; i++) {
                    if(this.usage.contacts.amount >= this.usage.pricingTable[i].total_contacts.from &&
                        this.usage.contacts.amount <= this.usage.pricingTable[i].total_contacts.to) {

                        return this.usage.schedule === 'MONTHLY' ? this.usage.pricingTable[i].monthly_cost_eur : this.usage.pricingTable[i].monthly_cost_eur_per_year;
                    }
                }

                return false;
            },

            getUsagePriceYearlyDiscount() {

                for (let i=0; i<this.usage.pricingTable.length; i++) {
                    if(this.usage.contacts.amount >= this.usage.pricingTable[i].total_contacts.from &&
                        this.usage.contacts.amount <= this.usage.pricingTable[i].total_contacts.to) {

                        return this.usage.pricingTable[i].monthly_cost_eur - this.usage.pricingTable[i].monthly_cost_eur_per_year;
                    }
                }

            },

            getCurrentPricingTableRow() {

                for (let i=0; i<this.usage.pricingTable.length; i++) {
                    if(this.usage.contacts.amount >= this.usage.pricingTable[i].total_contacts.from &&
                        this.usage.contacts.amount <= this.usage.pricingTable[i].total_contacts.to) {
                        return this.usage.pricingTable[i];
                    }
                }
            }
        },
        methods: {

            toggleWACreditsSwitch() {
                // this.$refs.waAmountInput.focus();
                this.updateCredits();
            },

            toggleSMSCreditsSwitch() {
                // this.$refs.smsAmountInput.focus();
                this.updateCredits();
            },

            /** update credits */
            updateCredits() {

                if(this.SMSCreditsEnabled) {
                    this.smsAmountInput = parseInt(this.smsAmountInput);
                } else {
                    this.smsAmountInput = null;
                }

                // if(this.WACreditsEnabled) {
                //     this.waAmountInput = parseInt(this.waAmountInput);
                // } else {
                //     this.waAmountInput = null;
                // }

                this.usage.credits.sms.amount = this.smsAmountInput;
                this.usage.credits.sms.price = this.getUsageCreditsPrice('sms');

                // this.usage.credits.wa.amount = this.waAmountInput;
                // this.usage.credits.wa.price = this.getUsageCreditsPrice('wa');

            },

            getUsageCreditsPrice(chtype) {

                if(chtype === 'sms') {
                    return this.smsAmountInput * this.usage.pricingChannelCredits.sms.price;
                } else if(chtype === 'wa') {

                    // apply bulk pricing
                    for(let i=0;i<this.usage.pricingChannelCredits.dynamic_wa.length;i++) {
                        if(this.usage.pricingChannelCredits.dynamic_wa[i].amount === this.waAmountInput) {
                            return this.waAmountInput * this.usage.pricingChannelCredits.dynamic_wa[i].price;
                        }
                    }
                    return null; //error

                } else {
                    return null;
                }
            },

            getUpperLimitOfCurrentRange(value) {

                for (let i=0; i<this.usage.pricingTable.length; i++) {
                    if(value >= this.usage.pricingTable[i].total_contacts.from &&
                        value <= this.usage.pricingTable[i].total_contacts.to) {
                        return this.usage.pricingTable[i].total_contacts.to;
                    }
                }
            },

            clickUsageTableRow(row) {
                // deprecated: no contact volume pricing

                this.usage.contacts.amount = row.total_contacts.to;
                this.contactAmountInput = row.total_contacts.to;

                // this.updateAmount()

            },
            clickWaUsageTableRow(row) {

                // for pay as you go model
                this.waAmountInput = row;
                // this.updateCredits();
                // this.selectedWaTierAmount = row;
                this.$emit('update:whatsappCredits', {
                    amount: row.amount,
                    price: row.price * row.amount
                });
            },
            clickWaRecurringUsageTableRow(row) {
                // for recurring model

                // console.log(row.amount);
                // console.log(this.getWaBulkAmount);
                //
                // if(row.amount <= this.getWaBulkAmount) {
                // }

                this.whatsappCountTierLimit = row;
                this.$emit('update:whatsappCountTierLimit', this.whatsappCountTierLimit);
            },
            updateWaAmount() {
            },
            // updateAmount() {
            //
            //     if(isNaN(this.contactAmountInput)) {
            //         this.contactAmountInput = this.contactsInAccount;
            //
            //         this.$buefy.toast.open({
            //             message: `Please fill in the estimated number of contacts`,
            //             type: 'is-danger'
            //         });
            //
            //         this.$refs.contactAmountInput.focus();
            //
            //     }
            //
            //     if(this.usage.contacts.amount >= this.contactsInAccount) {
            //
            //         this.usage.contacts.amount = this.contactAmountInput;
            //
            //         this.usage.contacts.price = this.getUsagePrice;
            //
            //         if(this.usage.contacts.price === null) {
            //             this.showCustomPricingModal();
            //         }
            //
            //         this.usage.contacts.discountYearly = this.getUsagePriceYearlyDiscount;
            //
            //         // this.$emit('input', { contacts: { amount: this.usage.contacts.amount, price: this.usage.contacts.price }});
            //
            //         // select table row
            //        this.selected = this.getCurrentPricingTableRow;
            //
            //     } else {
            //
            //         this.$buefy.toast.open({
            //             message: `New contact amount cannot be lower than the existing amount of contacts for your account`,
            //             type: 'is-danger'
            //         });
            //     }
            // },

            showCustomPricingModal() {

                // this.$buefy.dialog.alert({
                //     title: 'Custom plan',
                //     message: `Need to reach out to more than 25,000 subscribers? Please contact us via chat for a custom usage plan.`,
                //     conformText: 'Okay',
                //     type: 'is-success',
                //     onConfirm: () => {
                //         // this.contactAmountInput = 25000;
                //         // this.updateAmount();
                //     }
                // })
            },
            loadAsyncData() {

                // console.log(this.usage);
                this.$store.dispatch('fetchUsageRates', {}).then((data) => {
                    // console.log(JSON.parse(data.result.contact_json));

                    this.usage.pricingTable = JSON.parse(data.result.contact_json);
                    this.usage.pricingChannelCredits = JSON.parse(data.result.channel_json);

                    this.usage.contacts.price = this.getUsagePrice;

                    // automatically show upper limit value in input
                    this.contactAmountInput = this.getUpperLimitOfCurrentRange(this.contactsInAccount);

                    // automatically select row in pricing table
                    this.selected = this.getCurrentPricingTableRow;

                    // recurring pricing
                    this.usage.pricingChannelCredits.recurring_wa = this.usage.pricingChannelCredits.recurring_wa.filter((obj) => {
                        return obj.amount > this.getWaBulkAmount || obj.amount === -1;
                    });

                    // add 'no change' option when account is already in paid plan and wants to add SMS credits without upgrading the recurring bulk size
                    if(this.inPaidPlan) {
                        this.usage.pricingChannelCredits.recurring_wa.unshift({ amount: 0, "yearly_plan_price": 0, "monthly_plan_price": 0});
                    }

                    this.whatsappCountTierLimit = this.usage.pricingChannelCredits.recurring_wa[0];
                    this.$emit('update:whatsappCountTierLimit', this.whatsappCountTierLimit);

                    // pay as you go pricing
                    // this.waAmountInput = this.usage.pricingChannelCredits.dynamic_wa[0];
                    this.clickWaUsageTableRow(this.usage.pricingChannelCredits.dynamic_wa[0]);

                })
                    .catch((error) => {
                        throw error
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }

        },

        beforeCreate() {
        },
        mounted() {

            this.loadAsyncData();
        },
    }
</script>

<style scoped>
</style>

<template>
    <section>
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <div>
                        <h1>Account & User Profile</h1>
                    </div>
                </div>
            </div>

        </nav>

        <form @submit.prevent="">

            <b-loading :active.sync="loading"></b-loading>

            <transition name="fade">
                <div v-if="show" class="columns is-mobile">
                    <div class="column is-1"></div>
                    <div class="column is-10">
                        <section>
                            <b-tabs v-model="tabIndex" type="is-centered" size="is-medium" class="block" expanded>
                                <b-tab-item>

                                    <template slot="header">
                                        <b-icon icon="user"></b-icon>
                                        <span> User</span>
                                    </template>

                                    <div class="box">

                                        <div class="columns is-mobile">
                                            <div class="column is-2"></div>
                                            <div class="column is-8">

                                                <b-field label="First name">
                                                    <b-input size="is-medium" maxlength="64" v-model="form.first_name" required></b-input>
                                                </b-field>

                                                <b-field label="Last name">
                                                    <b-input size="is-medium" maxlength="64" v-model="form.last_name" required></b-input>
                                                </b-field>

                                                <b-field label="Mobile phone number">
                                                    <b-input size="is-medium" maxlength="32" v-model="form.phone" required></b-input>
                                                </b-field>

                                                <b-field label="Email">
                                                    <b-input type="email" message="This email is invalid" size="is-medium" v-model="form.email" required></b-input>
                                                </b-field>

                                            </div>
                                            <div class="column is-2"></div>
                                        </div>

                                        <div class="has-text-centered">
                                            <button class="button is-primary is-medium" @click="updateUserProfile" type="button">Update</button>
                                        </div>
                                    </div>

                                    <div class="box">

                                        <div class="columns is-mobile">
                                            <div class="column is-2"></div>
                                            <div class="column is-8">

                                                <b-field label="Old password">
                                                    <b-input v-model="form.old_password" size="is-medium" ref="old_password" type="password"
                                                             value=""
                                                             password-reveal required>
                                                    </b-input>
                                                </b-field>


                                                <b-field label="Password">
                                                    <b-input v-model="form.password" size="is-medium" type="password"
                                                             value=""
                                                             password-reveal required>
                                                    </b-input>
                                                </b-field>

                                                <br>

                                                <b-field label="Password confirmation">
                                                    <b-input v-model="form.password_confirmation" size="is-medium" type="password"
                                                         value=""
                                                         password-reveal required>
                                                    </b-input>
                                                </b-field>

                                            </div>
                                            <div class="column is-2"></div>
                                        </div>

                                        <div class="has-text-centered">
                                            <button class="button is-primary is-medium" @click="updatePassword" type="button">Change password</button>
                                        </div>
                                    </div>

                                </b-tab-item>

                                <b-tab-item>

                                    <template slot="header">
                                        <b-icon icon="building"></b-icon>
                                        <span> Account</span>
                                    </template>

                                    <div class="box">

                                        <div class="columns is-mobile">
                                            <div class="column is-2"></div>
                                            <div class="column is-8">

                                                <b-field label="Company name">
                                                    <b-input size="is-medium" maxlength="64" v-model="form.company" required></b-input>
                                                </b-field>

                                            </div>
                                            <div class="column is-2"></div>
                                        </div>

                                        <div class="has-text-centered">
                                            <button class="button is-primary is-medium" @click="updateAccount" type="button">Update</button>
                                        </div>
                                    </div>

                                </b-tab-item>

                                <b-tab-item>

                                    <template slot="header">
                                        <b-icon icon="building"></b-icon>
                                        <span> Integrations / api</span>
                                    </template>

                                    <div class="box">

                                        <div class="columns is-mobile">
                                            <div class="column is-2"></div>
                                            <div class="column is-8">

                                                <b-field label="Api key (secret key)">
                                                    {{ form.secret_key }}
                                                </b-field>

                                            </div>
                                            <div class="column is-2"></div>
                                        </div>

                                        <!--<div class="has-text-centered">-->
                                            <!--<button class="button is-primary is-medium" @click="regenerateSecretKey" type="button">Regenerate key</button>-->
                                        <!--</div>-->
                                    </div>

                                </b-tab-item>

                            </b-tabs>

                        </section>
                    </div>
                    <div class="column is-1"></div>
                </div>
            </transition>

            <br>

        </form>

    </section>
</template>

<script>

    export default {
        data() {
            return {
                data: [],
                form: {
                    account_id: null,
                    first_name: '',
                    last_name: '',
                    email: '',

                    old_password: '',
                    password: '',
                    password_confirmation: '',

                    company: '',
                    secret_key: ''
                },

                tabs: {
                    USER: 0,
                    ACCOUNT: 1,
                    INTEGRATIONS: 2
                },

                tabIndex: this.$route.params.tabIndex || 0,

                loading: true,
                submitting: false,
                show: false
            }
        },
        methods: {
            /*
             * Load async data
             */
            loadAsyncData() {

                this.loading = true;

                const params = {
                    id: this.data.id
                };

                this.$store.dispatch('getUserProfile', params).then((data) => {

                    const result = data.result;

                    this.form = {
                        account_id: data.result.account_id,
                        first_name: data.result.first_name,
                        last_name: data.result.last_name,
                        phone: data.result.phone,
                        email: data.result.email,

                        company: data.result.account.name,
                        secret_key: data.result.account.secret_key
                    }

                    this.show = true;
                })
                .catch((error) => {
                    this.data = [];
                    this.total = 0;
                    this.loading = false;
                    throw error
                })
                .finally(() => {
                    this.loading = false;
                });

            },

            updateUserProfile() {

                this.submitting = true;

                const params = {
                    first_name: this.form.first_name,
                    last_name: this.form.last_name,
                    phone: this.form.phone,
                    email: this.form.email
                };

                this.$store.dispatch('updateUserProfile', params).then((data) => {

                    this.$buefy.toast.open({
                        message: 'User profile successfully sent!',
                        type: 'is-success'
                    });

                })
                    .catch((err) => {

                        this.$buefy.toast.open({
                            message: this.$utils.getHumanErrorMessage(err),
                            type: 'is-danger'
                        });
                    })
                    .finally(() => {
                        this.submitting = false;
                    });

            },
            updatePassword() {

                const params = {
                    old_password: this.form.old_password,
                    password: this.form.password,
                    password_confirmation: this.form.password_confirmation
                };

                this.$store.dispatch('changeUserPassword', params).then((data) => {

                    if(data.success) {
                        this.$buefy.toast.open({
                            message: 'Password updated!',
                            type: 'is-success'
                        });
                    } else {
                        this.$buefy.toast.open({
                            message: 'Error changing password: '+data.error,
                            type: 'is-danger'
                        });

                    }

                })
                .catch((err) => {

                    this.$buefy.toast.open({
                        message: 'Error changing password: '+this.$utils.getHumanErrorMessage(err),
                        type: 'is-danger'
                    });
                })
                .finally(() => {
                });

            },
            updateAccount() {

                const params = {
                    id: this.form.account_id,
                    name: this.form.company,
                };

                this.$store.dispatch('updateAccount', params).then((data) => {

                    this.$buefy.toast.open({
                        message: 'Account successfully sent!',
                        type: 'is-success'
                    });

                })
                    .catch((err) => {

                        this.$buefy.toast.open({
                            message: 'Error saving account: '+err,
                            type: 'is-danger'
                        });
                    })
                    .finally(() => {
                    });

            },

            regenerateSecretKey() {

                const params = {
                    id: this.form.account_id
                };

                this.$store.dispatch('regenerateSecretKey', params).then((data) => {

                    this.form.secret_key = data.result.new_secret_key;

                    this.$buefy.toast.open({
                        message: 'Regenerate secret key successful!',
                        type: 'is-success'
                    });

                })
                .catch((err) => {

                    this.$buefy.toast.open({
                        message: 'Error regenerating secret key: '+err,
                        type: 'is-danger'
                    });
                })
                .finally(() => {
                });
            }
        },
        mounted() {

          if(this.$route.query.tab) {
            switch (this.$route.query.tab) {
              case 'user':
                this.tabIndex = this.tabs.USER;
                break;
              case 'account':
                this.tabIndex = this.tabs.ACCOUNT;
                break;
              case 'integrations':
                this.tabIndex = this.tabs.INTEGRATIONS;
                break;
            }
          }

            this.loadAsyncData()
        }
    }
</script>

const CreateCampaignForm = {
    data() {
        return {
            data: {},
            form: {
                name: 'My onboarding campaign',
                type: 'WHATSAPP'
            },

            submitting: false
        }
    },
    mounted() {
        this.$refs.campaignName.focus();
    },
    props: [
        'campaignName'
    ],
    watch: {
        // whenever changes, this function will run
        form: {
            handler(val) {
                if(val.type === 'SMS' && ! this.$store.state.account.config.sms_enabled) {

                    this.$buefy.dialog.confirm({
                        title: '',
                        message: `SMS messaging is currently not enabled for your account. In order to enable SMS messaging, press the submit request button below.`,
                        cancelText: 'No, thanks',
                        confirmText: 'Submit request',
                        type: 'is-success',
                        onConfirm: () => {
                            this.form.type = 'WHATSAPP';
                            this.requestSMS();
                        },
                        onCancel: () => {
                            this.form.type = 'WHATSAPP';
                        }
                    })

                }
            },
            deep: true
        }
    },
    template: `
            <form @submit.prevent="handleSubmit">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Create a campaign</p>
                        <!--<button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>-->
                    </header>
                    <section class="modal-card-body">
                    <div class="columns">
                    
                        <div class="column is-1">
                            <b-icon size="is-medium" icon="bullhorn"></b-icon>
                        </div>
                        <div class="column is-10">
                            <p>
                            Keep your subscribers engaged by sharing your latest news, promoting a line of products, or announcing an event.
                            </p>
                            <hr>
                            <b-field label="Campaign name">
                                <b-input
                                    size="is-medium"
                                    type="text"
                                    :value="form.name"
                                    v-model.trim="form.name"
                                    ref="campaignName"
                                    placeholder="eg. My onboarding campaign"
                                    @focus="$event.target.select()"
                                    required
                                >
                                </b-input>
                            </b-field>
                            <br>
                            <div class="block">
                                <b-field label="Channel type"></b-field>
                                <b-radio v-model="form.type"
                                    native-value="WHATSAPP">
                                    WhatsApp
                                </b-radio>
                                <b-radio v-model="form.type"
                                    native-value="SMS">
                                    SMS
                                </b-radio>
                            </div>
                        </div>
                        <div class="column is-1">
                        </div>
                    </div>
                    </section>
                    <footer class="modal-card-foot">
                        <div class="columns" style="width: 100%">
                            <div class="column is-12" style="text-align: right">
                                <button class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}">Create campaign</button>
                                <button class="button is-medium" type="button" @click="$parent.close()">Cancel</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </form>
        `,
    methods: {
        requestSMS() {

            const params = {
                type: 'requestChannelSMS'
            };

            this.$store.dispatch('sendEvent', params).then((data) => {

                this.$buefy.toast.open({
                    message: 'Your request to enable SMS messaging has been submitted. We\'ll contact you as soon as your request has been processed.',
                    type: 'is-success'
                });

            }).catch((err) => {
                // this.$buefy.toast.open({
                //     message: 'Error occurred while creating campaign: '+err,
                //     type: 'is-danger'
                // });
            }).finally(() => {

            });

        },
        handleSubmit() {
            // Send data to the server or update your stores and such.

            this.submitting = true;

            const params = {
                name: this.form.name,
                type: this.form.type
            }

            this.$store.dispatch('createCampaign', params).then((data) => {

                this.$buefy.toast.open({
                    message: 'Great! Now start setting up your campaign..',
                    type: 'is-success'
                });

                this.$parent.close();

                this.$router.push(`/campaigns/edit/${data.result.id}`);

            }).catch((err) => {
                this.$buefy.toast.open({
                    message: 'Error occurred while creating campaign: '+err,
                    type: 'is-danger'
                });
                // handle error
            });
        }
    }
}

export default CreateCampaignForm;

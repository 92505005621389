<template>
    <section>
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <h1 v-bind:class="{'is-invisible': loading}">
<!--                        <b-icon icon="address-book" style="padding-right:20px"></b-icon>-->
                        {{ name }} ({{ total }})
                    </h1>
                </div>
            </div>

            <!-- Right side -->
            <div class="level-right">

                <b-dropdown size="is-medium">
                    <button class="button is-light is-medium" slot="trigger">
                        <span>Actions</span>
                        <b-icon icon="caret-down"></b-icon>

                        <!--                        <b-icon icon="ellipsis-v"></b-icon>-->
                    </button>

                    <b-dropdown-item v-if="filteredOnListId" title="Import contacts">
                        <router-link :to="{ name: 'contactlist-edit', params: {listId: this.listId, tabIndex: 1} }">
                            Import contacts into list
                        </router-link>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="filteredOnListId" value="" @click="downloadExport">Export current list</b-dropdown-item>
                    <b-dropdown-item v-else value="" @click="downloadExport">Export all contacts</b-dropdown-item>

                </b-dropdown>




<!--                <router-link v-if="filteredOnListId" :to="{ name: 'contactlist-edit', params: {listId: this.listId, tabIndex: 1} }" tag="button" class="button is-primary is-medium">-->
<!--                    <b-icon icon="user-plus"></b-icon><span>Import contacts</span>-->
<!--                </router-link>-->

<!--                <button class="button is-medium is-light"-->
<!--                    @click="downloadExport" variant="primary">-->
<!--                <b-icon icon="download"></b-icon><span>Download export</span>-->
<!--                </button>-->
            </div>

        </nav>
        <hr>

        <b-dropdown size="is-medium">
            <button class="button is-light is-medium" slot="trigger">
                <span>{{ perPage }} per page</span>
                <b-icon icon="caret-down"></b-icon>
            </button>

            <b-dropdown-item value="10" @click="onLimitChange(10)">10</b-dropdown-item>
            <b-dropdown-item value="25" @click="onLimitChange(25)">25</b-dropdown-item>
            <b-dropdown-item value="50" @click="onLimitChange(50)">50</b-dropdown-item>
            <b-dropdown-item value="100" @click="onLimitChange(100)">100</b-dropdown-item>
            <b-dropdown-item value="500" @click="onLimitChange(500)">500</b-dropdown-item>
        </b-dropdown>

        <b-dropdown size="is-medium">
            <button class="button is-light is-medium" v-bind:class="{ 'is-primary': filter.type !== '' }" slot="trigger">
                <span>{{ filter.label }}</span>
                <b-icon icon="caret-down"></b-icon>
            </button>

            <b-dropdown-item value="Filter: state" @click="onFilterChange('')">All</b-dropdown-item>
            <b-dropdown-item value="Opt-in" @click="onFilterChange('OPT-IN')">Opt-in</b-dropdown-item>
            <b-dropdown-item value="Opt-out" @click="onFilterChange('OPT-OUT')">Opt-out</b-dropdown-item>
            <b-dropdown-item value="Opt-unknown" @click="onFilterChange('OPT-UNKNOWN')">Opt-unknown</b-dropdown-item>
        </b-dropdown>

        <router-link v-if="filteredOnListId" tag="button" class="button is-info" :to="{name: 'contacts'}" @click="alert('test')">
            Show all contacts
        </router-link>

        <!--- BULK ACTION -->

        <button v-show="checkedRows.length > 0" class="button is-light is-medium" slot="trigger" @click="subscribeContactTolistModal">
            <span>Subscribe selected to list...</span>
        </button>

        <b-dropdown v-show="checkedRows.length > 0">
            <button class="button is-light is-medium" slot="trigger">
                <span>More bulk actions...</span>
                <b-icon icon="caret-down"></b-icon>
            </button>

            <b-dropdown-item value="opt-in" @click="doBulkAction('OPT-IN')">
                Change to "Opt in"
            </b-dropdown-item>

            <b-dropdown-item value="opt-in" @click="doBulkAction('OPT-UNKNOWN')">
                Change to "Opt unknown"
            </b-dropdown-item>

            <b-dropdown-item value="opt-out" @click="doBulkAction('OPT-OUT')">
                Change to "Opt out"
            </b-dropdown-item>

            <b-dropdown-item separator></b-dropdown-item>

            <b-dropdown-item v-if="filteredOnListId" value="unsubscribe" @click="doBulkAction('UNSUBSCRIBE')">
                Unsubscribe from list
            </b-dropdown-item>

            <b-dropdown-item v-if="! filteredOnListId" value="destroy" @click="doBulkAction('DELETE')">
                Delete
            </b-dropdown-item>

        </b-dropdown>

        <b-field class="is-pulled-right">
            <b-input v-model="search" icon="search" ref="search" type="search" v-bind:class="{ 'has-value': search}" @input="searchFilter" placeholder="Search first name, last name, phone, email address..." size="is-medium" style="width: 320px"></b-input>
        </b-field>

        <hr>

        <!--- TABLE -->

        <b-pagination
                v-show="data > 0"
                :total="total"
                :current.sync="page"
                order="is-right"
                :per-page="perPage"
                @change="onPageChange">
        </b-pagination>


        <b-table
                v-show="data"

                :data="data"
                :loading="loading"

                paginated
                backend-pagination
                :total="total"
                :per-page="perPage"
                @page-change="onPageChange"

                detailed
                detail-key="id"

                :is-row-checkable="(row) => row.state !== 'IN_PROGRESS'"
                :checked-rows.sync="checkedRows"
                checkable

                backend-sorting
                :default-sort-direction="defaultSortOrder"
                :default-sort="[sortField, sortOrder]"
                @sort="onSort">

            <template slot-scope="props">

                <b-table-column field="thumb_url" label="">
                    <div class="contact-avatar">
                        <avatar-image v-model="props.row.thumb_url"></avatar-image>
                    </div>
                </b-table-column>

                <b-table-column field="country" label=" ">
                        <b-tooltip type="is-light" :label="props.row.phone_country ? props.row.phone_country : 'Unknown'">
                            <country-flag :country="props.row.phone_country_code" style="margin-top: -18px" size="normal" />
                        </b-tooltip>
                </b-table-column>

                <b-table-column field="first_name" label="Name" sortable>
                    <router-link :to="{ name: 'contact-edit', params: {contactId: props.row.id} }">
                        {{ props.row.first_name }} {{ props.row.last_name }}
                    </router-link>
                </b-table-column>

                <b-table-column field="company" label="Company" sortable>
                    <router-link :to="{ name: 'contact-edit', params: {contactId: props.row.id} }">
                        {{ props.row.company }}
                    </router-link>
                </b-table-column>

                <b-table-column field="phone" label="Phone">
                    <b-tooltip :label="props.row.phone_country">
                        <b-tag type="is-success" class="phone" size="is-large">
                            <b-icon pack="fab" size="is-small" icon="whatsapp" style="margin-left: 0px"></b-icon>
                            <span style="vertical-align: text-top;">+{{ props.row.phone }}</span>
                        </b-tag>
                    </b-tooltip>
                </b-table-column>

                <!--<b-table-column field="total_sent_count" label="Total msg sent" centered sortable>-->
                    <!--<b-tag type="is-large" size="is-medium">{{ props.row.contact_entity.total_sent_count }}</b-tag>-->
                <!--</b-table-column>-->

                <b-table-column v-if="! filteredOnListId" field="subscribers" label="Lists #">
                    <b-tooltip :label="'Contact is subscribed to '+props.row.contact_lists.length+' list(s)'">
                        <span class="tag is-light">{{ props.row.contact_lists.length }}</span>
                    </b-tooltip>
                </b-table-column>

                <b-table-column field="opt_state" label="Opt in State" sortable centered>
                    <b-tag type="is-light" size="is-large" v-if="typeof props.row.contact_entity !== 'undefined' && props.row.contact_entity.opt_state === 'OPT-IN'">
                        Opted In
                    </b-tag>
                    <b-tag type="is-danger" size="is-large" v-if="typeof props.row.contact_entity !== 'undefined' && props.row.contact_entity.opt_state === 'OPT-OUT'">
                        Opted Out
                    </b-tag>
                    <b-tag type="is-warning" size="is-large" v-if="typeof props.row.contact_entity !== 'undefined' && props.row.contact_entity.opt_state === 'OPT-UNKNOWN'">
                        Unknown
                    </b-tag>
                </b-table-column>

                <b-table-column field="created_at" label="Created" sortable>
                    <b-tooltip :label="props.row.created_at | moment('timezone', $global.current_tz)">{{ props.row.created_at | moment('timezone', $global.current_tz) | moment("from", "now") }}</b-tooltip>
                </b-table-column>

                <b-table-column field="updated_at" label="Updated" sortable>
                    <b-tooltip :label="props.row.updated_at | moment('timezone', $global.current_tz)">{{ props.row.updated_at | moment('timezone', $global.current_tz) | moment("from", "now") }}</b-tooltip>
                </b-table-column>

                <!--<b-table-column field="tags" label="Tags">-->
                    <!--&lt;!&ndash;<my-tag v-for="tag in props.row.tags" v-bind:key="tag.id" v-bind:name="tag.name" ></my-tag>&ndash;&gt;-->
                    <!--<span class="tag">-->
                    <!--{{ props.row.tags }}-->
                    <!--</span>-->
                <!--</b-table-column>-->

                <b-table-column field="action" label="Actions" centered>

                    <router-link :to="{ name: 'contact-edit', params: {contactId: props.row.id} }" title="Edit contact" class="action-button"><b-icon icon="edit"></b-icon></router-link>

                    <a href="#" class="action-button" title="Opt-in contact" v-if="typeof props.row.contact_entity !== 'undefined'" @click="changeOptState(props.row.id, props.row.contact_entity.opt_state)">
                        <b-icon icon="toggle-off"></b-icon>
                    </a>

                    <a v-show="filteredOnListId" href="#" class="action-button" title="Unsubscribe contact from list" @click="unsubscribe(props.row.id)">
                        <b-icon icon="user-minus"></b-icon>
                    </a>

                    <a v-show="! filteredOnListId" href="#" class="action-button" title="Remove contact from list" @click="destroy(props.row.id)">
                        <b-icon icon="trash-alt"></b-icon>
                    </a>


                </b-table-column>

            </template>

            <template slot="empty">
                <transition name="fade">
                    <section class="section" v-show="!loading">
                        <div class="content has-text-grey has-text-centered">
                            <p>
                            </p>
                            <p><b>No contact found.</b></p>
                            <p v-show="filteredOnListId">Add contacts by
                                <router-link :to="{ name: 'contactlist-edit', params: {listId: this.listId, tabIndex: 0} }">
                                    <b>importing contacts</b>
                                </router-link>
                                now!
                            </p>
                        </div>
                    </section>
                </transition>
            </template>

            <!-- details -->

            <template slot="detail" slot-scope="props">
                <article v-if="props.row" class="media">
                    <figure class="media-left">
                        <p class="image is-64x64">
                            <avatar-image v-model="props.row.thumb_url"></avatar-image>

                            <!--<img v-if="! props.row.email" src="/assets/contact/avatar.png">-->
                            <!--<img v-else class="contact-avatar" :src="props.row.thumb_url">-->
                        </p>
                    </figure>
                    <div class="media-content">
                        <div class="content">

                                <template v-if="props.row.first_name || props.row.last_name">
                                    <strong>{{ props.row.first_name }} {{ props.row.last_name }}</strong>
                                </template>
                                <template v-else>
                                    <strong>Anonymous</strong>
                                </template>

                                <template v-if="props.row.company">
                                    | {{ props.row.company }}
                                </template>

                                | {{ props.row.phone_country }}
                                <!--<b-tag type="is-light">-->
                                    <!--<country-flag style="transform: scale(0.35)" :country="props.row.phone_country_code" size="small" />-->
                                <!--</b-tag>-->
<br>
                                <small v-if="props.row.contact_entity.opt_state === 'OPT-IN'">
                                    <span v-if="props.row.contact_entity.opt_state_changed_by === 'USER'">Subscribed</span>
                                    <span v-else-if="props.row.contact_entity.opt_state_changed_by === 'ADMIN'">
                                        <b-tag type="is-warning">Manually opted in by admin</b-tag>
                                    </span>
                                    {{ props.row.contact_entity.opt_state_updated_at | moment('timezone', $global.current_tz) | moment("from", "now") }}
                                </small>
                                <small v-if="props.row.contact_entity.opt_state === 'OPT-OUT'">
                                    <span v-if="props.row.contact_entity.opt_state_changed_by === 'USER'">Unsubscribed</span>
                                    <span v-else-if="props.row.contact_entity.opt_state_changed_by === 'ADMIN'">Manually opted out by admin</span>
                                    {{ props.row.contact_entity.opt_state_updated_at | moment('timezone', $global.current_tz) | moment("from", "now") }}
                                </small>

                                <small v-if="props.row.contact_entity.last_campaign_sent_at"><br><br>Last campaign message was sent to contact {{  props.row.contact_entity.last_campaign_sent_at | moment("from", "now") }}</small>

                            <div style="padding-bottom:6px">
                                <b-tag class="small" type="is-light">{{ props.row.contact_entity.total_sent_count}} messages sent</b-tag>
                            </div>

                            <div v-if="! filteredOnListId && props.row.contact_lists.length > 0">
                                    <router-link  v-for="obj in props.row.contact_lists" :key="id" :to="{ name: 'contacts-by-listId', params: {listId: obj.pivot.list_id} }" style="vertical-align: sub; color: white" title="View contacts">
                                        <b-tag class="small" type="is-info" style="margin-right: 4px">

                                        <b-icon size="is-small" icon="list" style="margin-right:3px"></b-icon>
                                            <b-tooltip type="is-dark" :label="'Subscribed to list: \''+obj.name+'\''">
                                                {{ obj.name }}
                                            </b-tooltip>
                                        </b-tag>

                                    </router-link>


                            </div>
                            <div v-else-if="! filteredOnListId && props.row.contact_lists.length === 0">
                                <b-tag class="small" type="is-warning" style="margin-right: 4px">Contact has no subscriptions</b-tag>
                            </div>

                        </div>
                    </div>
                </article>
            </template>

        </b-table>
    </section>
</template>

<script>

    import CountryFlag from 'vue-country-flag';
    import SubscribeContactToLists from '../helpers/subscribeContactToLists';
    import UpdateContactForm from '../helpers/updateContactForm';


    export default {
        components: {
            CountryFlag, SubscribeContactToLists,UpdateContactForm
        },
        data() {
            return {
                data: [],
                checkedRows: [],
                listId: this.$route.params.listId,
                filteredOnListId: !! this.$route.params.listId,
                name: 'All contacts',
                total: null,
                loading: true,
                sortField: 'updated_at',
                sortOrder: 'desc',
                defaultSortOrder: 'asc',
                page: 1,
                perPage: 25,
                filter: {
                    type: '',
                    label: 'Filter: state'
                },
                search: '',
                globalTimeout: null,

                preventReload: false
            }
        },

        methods: {

            delay(fn, waitDelay) {
                if (this.globalTimeout != null) {
                    clearTimeout(this.globalTimeout);
                }
                this.globalTimeout = setTimeout(function() {
                    this.globalTimeout = null;

                    fn();

                }, waitDelay);
            },
            /*
             * Handle dropdown filter change event
             */
            onFilterChange(filter) {

                let filterTypeLabel = '';

                if(filter === '') {
                    filterTypeLabel = 'Show all'
                } else if(filter === 'OPT-IN') {
                    filterTypeLabel = 'Opt-in';
                } else if(filter === 'OPT-OUT') {
                    filterTypeLabel = 'Opt-out';
                } else if(filter === 'OPT-UNKNOWN') {
                    filterTypeLabel = 'Opt-unknown';
                } else {
                    filterTypeLabel = 'Unknown';
                }

                this.filter.label = filterTypeLabel;

                this.filter.type = filter;
                this.loadAsyncData();
                this.$refs.search.focus();
            },

            /*
             * Handle dropdown filter change event
             */
            onLimitChange(val) {

                this.perPage = val;
                this.loadAsyncData();
                this.$refs.search.focus();
            },

            fetchContactList() {
                let params = {
                    id: this.listId
                };

                this.$store.dispatch('fetchContactList', params).then((data) => {

                    // update view with current campaign settings
                    const result = data.result;
                    this.name = `Contacts for "${result.name}"`;
                    // this.contacts_count = result.contacts_count;
                })
                    .catch((error) => {
                        this.data = [];
                        this.total = 0;
                        throw error
                    })
                    .finally(() => {
                    });
            },

            subscribeContactTolistModal() {
                this.$buefy.modal.open({
                    parent: this,
                    component: SubscribeContactToLists,
                    props: {
                          contacts: this.checkedRows
                    },
                    hasModalCard: true
                })
            },

            downloadExport() {

                let params = {
                    id: this.listId || null
                };

                this.$store.dispatch('downloadContactsExport', params).then((data) => {

                    this.$buefy.toast.open({
                        message: 'Downloading export file completed!',
                        type: 'is-info',
                        duration: 4000
                    });

                }).catch((error) => {
                    this.data = [];
                    this.total = 0;
                    this.loading = false;
                    throw error
                });
            },

            /*
             * Load async data
             */
            loadAsyncData() {

                this.checkedRows = [];
                this.loading = true;

                // Fetch contacts

                let params = [
                    `orderBy=${this.sortField}`,
                    `perPage=${this.perPage}`,
                    `sort=${this.sortOrder}`,
                    `page=${this.page}`,
                    `type=${this.filter.type}`,
                    `search=${this.search}`
                ];

                if(this.$route.params.listId) {
                    params.push(`listId=${this.$route.params.listId}`);

                    // Fetch contact list
                    this.fetchContactList();
                }


                this.$store.dispatch('fetchContacts', params.join('&')).then((data) => {

                    let result = data.result;

                    this.data = [];
                    let currentTotal = result.total;
                    if (result.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000
                    }

                    this.total = currentTotal;

                    result.data.forEach((item) => {
                        this.data.push(item)
                    });

                    this.loading = false;
                })
                .catch((error) => {
                    this.data = [];
                    this.total = 0;
                    this.loading = false;
                    throw error
                });

            },
            /*
             * Handle page-change event
             */
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            /*
             * Handle sort event
             */
            onSort(field, order) {
                // if(field === 'opt_state') {
                //     this.sortField = 'contactentity.opt_state'
                // } else {
                    this.sortField = field
                // }

                this.sortOrder = order
                this.loadAsyncData()
            },
            /*
             * Change opt-in state
             */
            changeOptState(id,val) {

                // val is current opt state
                val = val === 'OPT-IN' ? 'OPT-OUT' : 'OPT-IN';

                const params = {
                    id: id,
                    opt_state: val
                };

                this.$store.dispatch('updateContact', params).then((data) => {
                    this.loadAsyncData();

                    this.$buefy.toast.open({
                        message: 'Contact updated!',
                        type: 'is-success'
                    });
                })
                .catch((err) => {
                    this.$buefy.toast.open({
                        message: 'Error saving contact details: '+err,
                        type: 'is-danger'
                    });
                })
                .finally(() => {
                    // this.submitting = false;
                });

            },

            searchFilter() {
                var that = this;
                this.delay(function() { that.loadAsyncData() }, this.$global.search_delay);
            },

            doBulkAction(action) {

                let contact_ids = [];
                let opt_unknown_ids_count = 0;

                for(let i=0; i<this.checkedRows.length; i++) {
                    contact_ids.push(this.checkedRows[i].id);
                    if(this.checkedRows[i].contact_entity.opt_state === 'OPT-UNKNOWN') {
                      opt_unknown_ids_count++;
                    }
                }

                switch(action) {
                    case 'OPT-IN':
                    case 'OPT-OUT':
                    case 'OPT-UNKNOWN':
                        this.updateContacts(contact_ids, opt_unknown_ids_count, action);
                        break;
                    case 'UNSUBSCRIBE':
                        this.unsubscribeContacts(contact_ids);
                        break;
                    case 'DELETE':
                        this.deleteContacts(contact_ids);
                        break;
                }

            },

            unsubscribeContacts(contact_id) {

                const params = {
                    list_id: this.listId,
                    contact_id: contact_id
                }

                this.loading = true;

                this.$store.dispatch('unsubscribeContacts', params).then((data) => {

                    if(data.result.unsubscribed > 0) {

                        this.loadAsyncData();

                        setTimeout(() => {

                            const msg = data.result.unsubscribed + ' ' + this.$options.filters.pluralize(data.result.unsubscribed, 'contact') + ' unsubscribed from list';

                            this.$buefy.toast.open({
                                message: msg,
                                type: 'is-info'
                            });

                        }, 1000);

                    } else {
                        this.$buefy.toast.open({
                            message: 'No contacts unsubscribed',
                            type: 'is-warning'
                        });
                    }



                }).catch((err) => {
                    this.$buefy.toast.open({
                        message: 'Error occurred while unsubscribing contacts list: '+err,
                        type: 'is-danger'
                    });
                    // handle error
                });

            },

            deleteContacts(contact_ids) {

                const params = {
                    ids: contact_ids
                }

                this.$buefy.dialog.confirm({
                    title: 'Delete',
                    message: 'Are you sure you want to <b>delete</b> selected contacts and its list subscriptions?',
                    confirmText: 'Delete contacts',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.loading = true;

                        this.$store.dispatch('deleteContacts', params).then((data) => {

                            this.loading = false;

                            if(data.result.deleted > 0) {

                                this.loadAsyncData();

                                const msg = data.result.deleted + ' ' + this.$options.filters.pluralize(data.result.deleted, 'contact') + ' deleted';

                                this.$buefy.toast.open({
                                    message: msg,
                                    type: 'is-info'
                                });

                            } else {
                                this.$buefy.toast.open({
                                    message: 'No contacts deleted',
                                    type: 'is-warning'
                                });
                            }



                        }).catch((err) => {
                            this.$buefy.toast.open({
                                message: 'Error occurred while deleting contacts list: '+err,
                                type: 'is-danger'
                            });
                            // handle error
                            this.loading = false;

                        });
                    },
                    onCancel: () => {
                    }
                })


            },

            updateContacts(contacts, optUnknownCount, optState) {

              var self = this;

              this.$buefy.modal.open({
                parent: this,
                component: UpdateContactForm,
                hasModalCard: true,
                props: {
                  contacts: contacts,
                  optUnknownCount: optUnknownCount,
                  optState: optState
                },
                events: {
                  success: function() {
                    self.loadAsyncData();
                  }

                }
              });

              // const params = {
              //   contact_ids: contacts,
              //   opt_state: optState,
              //   skip_option_sms: true
              // }
              //
              //   this.$buefy.dialog.confirm({
              //       title: 'Update',
              //       message: 'Are you sure you want to <b>update</b> these contacts?',
              //       confirmText: 'Update contacts',
              //       type: 'is-danger',
              //       hasIcon: true,
              //       onConfirm: () => {
              //
              //           this.$store.dispatch('updateContacts', params).then((data) => {
              //
              //               this.checkedRows = [];
              //               let result = data.result;
              //
              //               if(data.result.updated) {
              //                   this.$buefy.toast.open({
              //                       message: data.result.updated + ' ' + this.$options.filters.pluralize(data.result.updated, 'contact') + ' updated.',
              //                       type: 'is-info'
              //                   });
              //
              //                   this.loadAsyncData();
              //
              //               } else {
              //                   this.$buefy.toast.open({
              //                       message: 'No contact could be updated.',
              //                       type: 'is-warning'
              //                   });
              //               }
              //
              //
              //
              //           })
              //               .catch((err) => {
              //                   this.$buefy.toast.open({
              //                       message: 'An error has occurred! '+this.$utils.getHumanErrorMessage(err),
              //                       type: 'is-danger',
              //                       duration: 5000
              //                   });
              //               });
              //       },
              //       onCancel: () => {
              //       }
              //   })
            },

            /*
             * Handle remove a contact from contact list
             */
            unsubscribe(id) {

                const params = {
                    id: id,
                    listId: this.listId
                };

                this.$buefy.dialog.confirm({
                    title: 'Remove',
                    message: 'Are you sure you want to <b>unsubsctibe</b> this contact from the list?',
                    confirmText: 'Unsubscribe contact',
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('unsubscribeContact', params).then((data) => {

                            let result = data.result;
                            this.loadAsyncData();
                        })
                            .catch((error) => {
                                throw error
                            });
                    }

                })
            },

            /*
             * Handle deleting a contact
             */
            destroy(id) {

                const params = {
                    id: id
                };

                this.$buefy.dialog.confirm({
                    title: 'Delete',
                    message: 'Are you sure you want to <b>delete</b> this contact and its subscription to lists?',
                    confirmText: 'Delete contact',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('deleteContact', params).then((data) => {

                            let result = data.result
                            this.loadAsyncData();
                        })
                        .catch((error) => {
                            throw error
                        });
                    }

                })
            }
        },
        filters: {
            /**
             * Filter to truncate string, accepts a length parameter
             */
            truncate(value, length) {
                return value.length > length
                    ? value.substr(0, length) + '...'
                    : value
            }
        },
        mounted() {
            this.loadAsyncData();
            this.$refs.search.focus();

        },
    }
</script>

<template>
    <div>
        <div class="label-field">Select a contact list</div>
        <p style="padding-top:16px">
            <b-dropdown v-model="selectedListId" :disabled="lists.length === 0" class="modal-dropdown-content" loading>

                <button class="button is-medium" v-bind:class="{ 'is-light': value, 'is-warning': !value, 'is-info': selectedListId }" slot="trigger">
                    <span v-if="lists.length > 0">{{ dropdown_lbl }}</span>
                    <span v-else>
                        <div class="loader" style="position:relative; top:8px"></div><br>
                    </span>
                    <b-icon v-if="lists.length > 0" icon="caret-down"></b-icon>
                </button>

                <b-dropdown-item v-for="obj in lists" :key="id" :value="obj" @click="handleItemClick(obj)">
                    <div style="width:100%">
                        {{obj.name}}
                    </div>

                    <div style="min-width: 140px">
                        <template v-if="obj.contacts_count > 0 && obj.contacts_count === obj.opt_in_contacts_count">
                            <b-tag type="is-light" class="is-pulled-right">
                                {{obj.contacts_count | currency('', 0, { thousandsSeparator: ',' }) }}
                            </b-tag>
                        </template>

                        <template v-else-if="obj.contacts_count > 0 && obj.contacts_count !== obj.opt_in_contacts_count">
                            <b-taglist attached class="is-pulled-right" style="margin:0 !important" :title="obj.opt_in_contacts_count+' of '+obj.contacts_count+' opt-in contacts'">
                                <b-tag type="is-light"><b-icon icon="user" size="is-small" style="margin-right: 1px"> </b-icon>{{obj.opt_in_contacts_count | currency('', 0, { thousandsSeparator: ',' })}}</b-tag>
                                <b-tag type="is-danger"><b-icon icon="user" size="is-small" style="margin-right: 1px"> </b-icon>{{obj.contacts_count - obj.opt_in_contacts_count | currency('', 0, { thousandsSeparator: ',' })}}</b-tag>
                            </b-taglist>
                        </template>

                        <template v-else>
                            <b-tag class="is-pulled-right" type="is-danger">
                                No contacts
                            </b-tag>
                        </template>
                    </div>

                </b-dropdown-item>

            </b-dropdown>

            <br>
            <template v-if="selectedListId">

                <router-link :to="{ name: 'contacts-by-listId', params: { listId: selectedListId } }" class="button is-small is-white" target="_blank">
                    <!--<b-tag type="is-white" size="is-medium">-->
                    <span class="has-text-info">View contacts in new tab</span>
                    <!--</b-tag>-->
                </router-link>
            </template>
            <br><br><br>
        </p>
    </div>
</template>

<script>
    export default {
        props: {
            value: { type: Object, default: {} },
            type: { type: String, default: 'REGULAR' }
        },
        data: function () {
            return {
                lists: [

                ],
                dropdown_lbl: 'Choose contact list',
                selectedListId: null,
                loading: true,
                id: null
            }
        },
        watch: {
            // whenever changes, this function will run
            value: function (newValue, oldValue) {

                for (let i = 0; i < this.lists.length ; i++) {

                    if(this.value && this.lists[i].id === this.value.id) {
                        this.selectedListId = this.lists[i].id;

                        this.setDropdownLabel(this.lists[i]);
                    }
                }
            }
        },
        computed: {

        },
        methods: {
            setDropdownLabel(obj) {
                this.dropdown_lbl = `${obj.name} (${obj.contacts_count})`;
            },
            handleItemClick(obj) {

                this.setDropdownLabel(obj);
                this.selectedListId = obj.id;
                this.$emit('input', {
                    id: obj.id,
                    name: obj.name,
                    uuid: obj.uuid,
                    contacts_count: obj.contacts_count,
                    opt_in_contacts_count: obj.opt_in_contacts_count,
                    channel: {
                        origin: obj.channel.origin,
                        send_campaign_recipient_limit: obj.channel.send_campaign_recipient_limit
                    }
                });
            },

            loadAsyncData() {

                const params = [
                    `enabled=1`,
                    `perPage=500`,
                    `type=${this.type}`
                ].join('&');

                this.$store.dispatch('fetchContactLists', params ).then((data) => {

                    let lists = data.result.data;

                    for (let i = 0; i < lists.length ; i++) {
                        lists[i].ddIndex = i;

                        if(lists[i].channel.enabled) {

                            this.lists.push(lists[i]);

                            if(this.value && lists[i].id === this.value.id) {
                                this.handleItemClick(lists[i]);
                            }
                        }

                    }

                })
                    .catch((error) => {
                        this.data = [];
                        this.total = 0;
                        throw error
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>

<style scoped>

    a.dropdown-item {
        padding: 10px !important;
    }

    .tag {
        margin: 0 !important;
    }
</style>

<template>
    <div>
        <transition name="fade">
            <div v-if="show" class="columns is-mobile" style="margin-top:50px">
                <div class="column is-half is-offset-one-quarter">
                    <div class="box">
                        <h1>Forgot password</h1>
                        <br>

                        <form @submit.prevent="register">

                            <b-field label="Email">
                                <b-input v-model="form.email" size="is-large" ref="email" type="email" value="" placeholder="Your Email address" required required></b-input>
                            </b-field>

                            <div class="has-text-centered" style="margin-top:40px; margin-bottom: 20px">
                                <router-link :to="{ name: 'login', params: { email: this.form.email } }" class="button is-light is-medium" style="margin:0 10px">Return to login</router-link>

                                <button class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}" type="submit">Send password link</button>

                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'RegisterView',
        data() {
            return {
                form: {
                    name: "",
                    company: "",
                    email: this.$route.query.email
                },
                show: false,
                submitting: false
            }
        },
        methods: {
            register() {

                this.submitting = true;

                this.$store.dispatch("forgotPassword", {
                    email: this.form.email,
                    name: this.form.name,
                    company: this.form.company
                }).then(() => {
                    this.submitting = false;
                    this.$router.push("/")

                    this.$buefy.toast.open({
                        message: 'Email is sent to your email address with a password reset link.',
                        type: 'is-info',
                        duration: 5000
                    });


                }).catch(() => {
                    this.submitting = false;
                    this.$buefy.toast.open({
                        message: 'Sorry, the email address is not found or invalid',
                        type: 'is-danger',
                        duration: 5000
                    });
                });
            }
        },
        mounted() {
            this.show = true;
            setTimeout(() => {
                this.$refs.email.focus();
            },250);
        }
    }
</script>

<template>
    <section>

        <b-loading :active.sync="loading" :is-full-page="true"></b-loading>

        <transition name="fade">
            <h1>Plans</h1>
        </transition>
        <br>

        <b-message v-if="show && $store.state.account.expired" title="Trial account expired :-(" size="is-medium" type="is-danger" has-icon :closable="false">
            We're sorry to inform you that the trial period has expired. In order to re-enable your account, choose your plan below.
        </b-message>

        <b-message v-else-if="show && $store.state.account.plan === 'TRIAL' && ! $store.state.expired" title="Account expiration" size="is-medium" type="is-warning" :closable="false" has-icon>
            <b>Your free trial period will expire {{ $store.state.userProfile.account.expires_at | moment('timezone', $global.current_tz) | moment("from", "now") }}.</b>
            <br><br>Please upgrade your account to start sending WhatsApp messages from your own phone number including the features listed below.
        </b-message>

        <!--<h2>Put your business on steroids.-->
            <!--{{ $global.product_name }} delivers a robust platform to easily engage with your customers </h2>-->

        <transition v-if="! loading" name="fade">

            <div>
                <section class="is-hidden-tablet">

                    <div class="plan" v-for="plan in plans">
                        <table class="table plan-matrix table-nohover is-fullwidth is-bordered">
                            <tr>
                                <td class="plan plan-type has-text-centered">
                                    <div class="plan-header">
                                        {{ plan.name }}
                                    </div>
                                    <div v-if="plan.name === 'PAY_AS_YOU_GO'" class="plan-header">
                                        29
                                    </div>
                                    <div v-else>
                                        {{ plan.payment_amount_yearly_in_eur / 12 | currency('€', { decimalDigits: 0 } ) }}
                                        <span>per account, per month</span>
                                        <p>{{ plan.payment_amount_monthly_in_eur | currency('€', { decimalDigits: 0 } ) }} if billed monthly</p>
                                    </div>

                                    <router-link :to="{ name: 'admin-checkout', query: { 'plan': plan.name } }" tag="span">
                                        <button style="margin: 20px 0" :disabled="plan.name === 'PAY AS YOU GO' || $store.state.account.plan === 'STARTER' || $store.state.account.plan === 'STARTER'" class="button is-medium is-info is-fullwidth" variant="info">
                                            Upgrade to {{ '"'+plan.name+'"' }}<br>
                                        </button>
                                    </router-link>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div style="margin-top:20px">

                                        <p style="font-size: 21px;"><b>Top features:</b></p>
                                        <br>
                                        <ul style="margin-bottom: 40px">
                                            <li v-for="feature in features[plan.name]" class="feature-item">
                                                <b-icon icon="check"></b-icon>
                                                <span v-html="feature"></span>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>

                        </table>
                    </div>

                </section>

                <section class="is-hidden-mobile">

                    <div class="box is-paddingless">

                        <table class="table plan-matrix table-nohover is-fullwidth is-bordered">
                            <thead>

                            </thead>
                            <tfoot>

                            </tfoot>
                            <tbody>
                            <tr>
                                <th v-for="plan in plans" class="plan plan-type has-text-centered">
                                    <div>
                                        <p>{{ plan.name }}</p>
                                        <p v-if="plan.name === 'PAY AS YOU GO'">
                                            {{ 29 | currency('€', { decimalDigits: 0 } ) }}
                                        </p>
                                        <p v-else>
                                            {{ plan.payment_amount_yearly_in_eur / 12 | currency('€', { decimalDigits: 0 } ) }}
                                        </p>
                                    </div>
                                </th>
                            </tr>

                            <tr>
                                <td v-for="plan in plans" class="has-text-centered" style="padding: 23px 0px;">
                                    <div v-if="plan.name === 'PAY AS YOU GO'" class="sub-text-color">
                                        <span>per account</span>
                                        <p><br></p>
                                    </div>
                                    <div v-else>
                                        <span>per account, per month</span>
                                        <p>{{ plan.payment_amount_monthly_in_eur | currency('€', { decimalDigits: 0 } ) }} if billed monthly</p>
                                    </div>

                                    <router-link :to="{ name: 'admin-checkout', query: { 'plan': plan.name } }" tag="span">
                                        <button style="margin: 20px 0" :disabled="plan.name === 'CUSTOM' || $store.state.account.plan === 'STARTER' || $store.state.account.plan === 'STARTER'" class="button is-medium is-info is-fullwidth" variant="info">
                                            Upgrade to {{ '"'+plan.name+'"' }}<br>
                                        </button>
                                    </router-link>
                                    <router-link :to="{ name: 'admin-checkout', query: { 'plan': plan.name } }">
                                    Calculate your price here
                                    </router-link>

                                </td>
                            </tr>

                            <tr>
                                <td v-for="plan in plans">
                                    <div style="margin-top:20px">

                                        <p style="font-size: 21px;"><b>Top features:</b></p>
                                        <br>
                                        <ul style="margin-bottom: 40px">
                                            <li v-for="feature in features[plan.name]" class="feature-item">
                                                <b-icon icon="check"></b-icon>
                                                <span v-html="feature"></span>
                                            </li>
<!-- deprecated contact volume pricing -->
<!--                                            <li class="feature-item">-->
<!--                                                <b-icon icon="check"></b-icon>-->
<!--                                                <span>-->
<!--                                                Contacts (-->
<!--                                                <router-link :to="{ name: 'admin-checkout', params: { tabIndex: 1 } }">-->
<!--                                                    volume pricing applies-->
<!--                                                </router-link>-->
<!--                                                )-->
<!--                                                </span>-->
<!--                                            </li>-->
                                        </ul>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td v-for="plan in plans">
                                    <router-link :to="{ name: 'admin-checkout', params: { 'plan': plan.name } }" tag="span">
                                        <button style="margin: 20px 0" :disabled="plan.name === 'CUSTOM' || $store.state.account.plan === 'STARTER' || $store.state.account.plan === 'STARTER'" class="button is-medium is-light is-fullwidth" variant="info">
                                            Calculate your price here
                                        </button>
                                    </router-link>

                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </section>
                <br>
                <div class="box" style="margin: 20px 0">
                    <p class="has-text-centered">Amounts shown do not include VAT.</p>
                </div>
            </div>
        </transition>

    </section>
</template>

<script>

    export default {
        components: {
        },
        data: function() {
            return {
                plans: {},
                features: {
                    PLUS: [
                        'Use your own (local) number & phone',
                        '500 sent messages /mo included',
                        'Extra messages paid additionally',
                        'Send up to unlimited sent messages',
                        'Unlimited api calls p/month',
                        'Up to 100k contacts',
                        // 'Blazing fast delivery (up to 1 msg /1 sec'
                    ],
                    // PRO and Starter are deprecated as of april 2020
                    // PRO: [
                    //     'Use your dedicated phone number',
                    //     'Unlimited user accounts (*)',
                    //     'Unlimited send messages (*)',
                    //     'Unlimited custom lists (*)',
                    //     'Unlimited message templates (*)',
                    //     'Unlimited api calls p/month (*)',
                    //     'Additional channel endpoints (€30)'
                    // ],
                    // STARTER: [
                    //     'Use your dedicated phone number',
                    //     'Unlimited user accounts (*)',
                    //     '2500 sent messages',
                    //     '5 message templates',
                    //     '3 custom lists',
                    //     '1000 api calls p/month',
                    // ],
                    'PAY AS YOU GO': [
                        'Use your own (local) number & phone',
                        'Pay per sent message',
                        'Up to 100k contacts',
                        'Bought credits won\'t expire',
                        // 'Fast delivery (up to 1 msg /5 sec'
                    ]
                },

                loading: true,
                show: false
            }
        },
        methods: {
            /*
            * Load async data
            */
            loadAsyncData() {

                const params = {};

                this.$store.dispatch('fetchPlans', params).then((data) => {

                    // update view with current campaign settings
                    this.plans = data.result;
                    // this.stats.campaigns = result.campaigns;
                    // this.stats.messages = result.messages;
                    this.show = true;

                })
                .catch((error) => {
                    throw error
                })
                .finally(() => {
                    this.loading = false;
                });

            },
        },

        mounted() {
            this.loadAsyncData();
        }
    }
</script>

<template>
    <section>
        <nav class="level">
            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <h1>
                        <template v-if="total">
                            Accounts ({{ total}})
                        </template>
                        <template v-else>&nbsp;
                        </template>
                    </h1>
                </div>
            </div>

            <!-- Right side -->
            <div class="level-right">

                <button class="button is-medium is-info is-pulled-right is-clearfix is-hidden-touch"
                        @click="createAccountModal()" variant="primary">
                    <b-icon icon="plus"></b-icon><span>Create account</span>
                </button>

            </div>

        </nav>
        <hr>

        <!--- FILTERS -->

        <b-dropdown class="is-fullwidth-mobile">
            <button class="button is-light is-medium is-fullwidth-mobile" v-bind:class="{ 'is-primary': filter.plans.length > 0  }" slot="trigger">
                <span>Filter: Plan</span>
                <b-icon icon="caret-down"></b-icon>
            </button>

            <b-dropdown-item custom>

                <b-checkbox v-model="filter.plans" native-value="TRIAL" @input="loadAsyncData">
                    Trial
                </b-checkbox>
                <b-checkbox v-model="filter.plans" native-value="PAY AS YOU GO" @input="loadAsyncData">
                    Pay As You Go
                </b-checkbox>
                <b-checkbox v-model="filter.plans" native-value="STARTER" @input="loadAsyncData">
                    Starter
                </b-checkbox>
                <b-checkbox v-model="filter.plans" native-value="PRO" @input="loadAsyncData">
                    Pro
                </b-checkbox>
                <b-checkbox v-model="filter.plans" native-value="PLUS" @input="loadAsyncData">
                    Plus
                </b-checkbox>
                <b-checkbox v-model="filter.plans" native-value="UNLIMITED" @input="loadAsyncData">
                    Unlimited
                </b-checkbox>

            </b-dropdown-item>

        </b-dropdown>

        <b-dropdown class="is-fullwidth-mobile">
            <button class="button is-light is-medium is-fullwidth-mobile" v-bind:class="{ 'is-primary': filter.labels.length > 0  }" slot="trigger">
                <span>Filter: Label</span>
                <b-icon icon="caret-down"></b-icon>
            </button>

            <b-dropdown-item custom>

                <b-checkbox v-model="filter.labels" native-value="TEXTPOP.IO" @input="loadAsyncData">
                    Textpop
                </b-checkbox>
                <b-checkbox v-model="filter.labels" native-value="WHATSAPPIER.COM" @input="loadAsyncData">
                    Whatsappier
                </b-checkbox>

            </b-dropdown-item>

        </b-dropdown>

        <b-dropdown>
            <button class="button is-light is-medium is-hidden-mobile" v-bind:class="{ 'is-primary': filter.states.length > 0 }" slot="trigger">
                <span>Filter: State</span>
                <b-icon icon="caret-down"></b-icon>
            </button>

            <b-dropdown-item custom>

                <b-checkbox v-model="filter.states" native-value="ACTIVE" @input="loadAsyncData">
                    Active
                </b-checkbox>
                <br>
                <b-checkbox v-model="filter.states" native-value="PENDING_APPROVAL" @input="loadAsyncData">
                    Pending approval
                </b-checkbox>
                <!--<b-checkbox v-model="filter.states" native-value="DECLINED" @input="loadAsyncData">-->
                <!--Declined-->
                <!--</b-checkbox>-->
                <b-checkbox v-model="filter.states" native-value="INACTIVE" @input="loadAsyncData">
                    Inactive
                </b-checkbox>

            </b-dropdown-item>

        </b-dropdown>

        <b-dropdown>
            <button class="button is-light is-medium is-pulled-right is-hidden-mobile" slot="trigger">
                <span>Toggle columns</span>
                <b-icon icon="caret-down"></b-icon>
            </button>

            <template v-for="col in tblCols">
                <b-dropdown-item paddingless custom>
                    <b-checkbox v-model="col.visible">
                        {{ col.label }}
                    </b-checkbox>
                </b-dropdown-item>
            </template>
        </b-dropdown>

        <b-field class="search-input-field" id="v-step-1">
            <b-input v-model="search" icon="search" ref="search" type="search" v-bind:class="{ 'has-value': search}" @input="searchFilter" placeholder="Search company, notes, user name, channel origin, payment amount..." size="is-medium"></b-input>
        </b-field>

        <hr><br>

        <b-table
                :data="data"
                :loading="loading"

                paginated
                backend-pagination
                :total="total"
                :per-page="perPage"
                @page-change="onPageChange"

                detailed
                detail-key="id"

                backend-sorting
                :default-sort-direction="defaultSortOrder"
                :default-sort="[sortField, sortOrder]"
                @sort="onSort">

            <template slot-scope="props">

                <b-table-column :visible="tblCols.name.id" field="id" label="Id" sortable>
                    <router-link :to="{ name: 'sa-account-edit', params: {accountId: props.row.id} }">
                        {{ props.row.id }}
                    </router-link>
                </b-table-column>

                <b-table-column :visible="tblCols.name.visible" field="name" label="Account" sortable>
                    <router-link :to="{ name: 'sa-account-edit', params: {accountId: props.row.id} }">
                        {{ props.row.name }}
                        <b-tag v-if="props.row.signup_hostname" type="is-light">{{ props.row.signup_hostname }}</b-tag>
                    </router-link>
                </b-table-column>

                <b-table-column :visible="tblCols.label.visible" field="label" label="Label" sortable>
<!--                    <router-link :to="{ name: 'sa-account-edit', params: {accountId: props.row.id} }">-->
                        <b-tag :type="props.row.label !== 'TEXTPOP.IO' ? 'is-dark' : 'is-info'">{{ props.row.label }}</b-tag>
<!--                    </router-link>-->
                </b-table-column>

                <b-table-column :visible="tblCols.plan.visible" field="plan" label="Plan" sortable>

                    <b-tag v-if="props.row.plan === 'TRIAL'" class="state" size="is-medium" type="is-warning">{{ props.row.plan }}</b-tag>
                    <b-tag v-else-if="props.row.plan === 'PAY AS YOU GO'" class="state" size="is-info" type="is-primary">{{ props.row.plan }}</b-tag>
                    <b-tag v-else-if="props.row.plan === 'STARTER'" class="state" size="is-medium" type="is-primary">{{ props.row.plan }}</b-tag>
                    <b-tag v-else-if="props.row.plan === 'PRO'" class="state" size="is-medium" type="is-info">{{ props.row.plan }}</b-tag>
                    <b-tag v-else-if="props.row.plan === 'PLUS'" class="state" size="is-medium" type="is-info">{{ props.row.plan }}</b-tag>
                    <b-tag v-else type="is-light" class="state" size="is-medium">{{ props.row.plan }}</b-tag>

                    <span v-if="props.row.plan === 'TRIAL'">
                        <span v-bind:class="{ 'error-color weight-bold': props.row.isExpired, 'warning-color weight-bold': props.row.expireWarning }">
                            <br>
                            Expired {{ props.row.expires_at | moment('timezone', $global.current_tz) | moment("from", "now") }}
                        </span>
                    </span>
                </b-table-column>

                <b-table-column :visible="tblCols.period.visible" field="billingDetails.payment_period" label="Billing period" sortable>

                    <template v-if="props.row.plan !== 'PAY AS YOU GO' && props.row.plan !== 'UNLIMITED'">

                        <b-tag v-if="props.row.billing_details.payment_period === 'MONTHLY'" class="state" size="is-small" type="is-danger">{{ props.row.billing_details.payment_period }}</b-tag>
                        <b-tag v-else-if="props.row.billing_details.payment_period === 'YEARLY'" class="state" size="is-small" type="is-warning">{{ props.row.billing_details.payment_period }}</b-tag>

                        <sub v-if="props.row.plan !== 'TRIAL' && props.row.plan !== 'UNLIMITED' && props.row.billing_subscription">
                            <br>
                            {{ props.row.billing_subscription.interval }}
                        </sub>
                        <sub v-else-if="props.row.plan !== 'TRIAL' && props.row.plan !== 'UNLIMITED'">
                            <!-- no subscription found -->
                            <br>
                            <span class="has-text-danger">No subscription found</span>
                        </sub>

                    </template>
                    <template v-else>
                        <b-tag>n/a</b-tag>
                    </template>

                </b-table-column>

                <b-table-column :visible="tblCols.next_billing_date.visible" field="billingDetails.current_period_ends_at" label="Next billing date" width="150" sortable>

                    <template v-if="props.row.plan !== 'PAY AS YOU GO' && props.row.plan !== 'UNLIMITED'">

                        <template v-if="props.row.plan !== 'UNLIMITED' && props.row.plan !== 'TRIAL' && props.row.billing_details">
                            <b-tooltip :label="props.row.billing_details.current_period_ends_at">
                                <span v-bind:class="{ 'error-color weight-bold': daysUntil(props.row.billing_details.current_period_ends_at) <= 2, 'warning-color weight-bold': daysUntil(props.row.billing_details.current_period_ends_at) <= 7 }">
                                {{ props.row.billing_details.current_period_ends_at | moment('timezone', $global.current_tz) | moment("from", "now") || '' }}
                                </span>
                            </b-tooltip>
                        </template>

                    </template>
                    <template v-else>
                        <b-tag>n/a</b-tag>
                    </template>
                </b-table-column>

                <b-table-column :visible="tblCols.subscription.visible" field="billingSubscription.amount" label="Auto-recurring" sortable>
                    <template v-if="props.row.plan !== 'TRIAL' && props.row.billing_subscription">
                        YES (€ {{ props.row.billing_subscription.amount }})
                    </template>
                    <template v-else-if="props.row.plan !== 'TRIAL' && ! props.row.billing_subscription">
                        <b>NO, manual billing required</b>
                    </template>
                </b-table-column>

                <b-table-column :visible="tblCols.next_recurring_amount.visible" field="account_subscription.amount" label="Next recurring amount" width="150" sortable>
                    <template v-if="props.row.plan !== 'UNLIMITED' && props.row.plan !== 'TRIAL' && props.row.billing_subscription">
                        {{ props.row.billing_subscription.currency || '' }} {{ props.row.billing_subscription.amount || '' }}
                    </template>
                </b-table-column>

                <b-table-column :visible="tblCols.channels.visible" width="200" field="channels" label="Channels">
                    <div v-for="channel in props.row.channels" style="display: block; margin-bottom:7px">
                        <!--<channel-display-tag :channel="channel"></channel-display-tag>-->
                        <channel-display-tag v-if="! channel.is_demo && ! channel.is_shared" :channel="channel" :showBattery="true" :showName="false"></channel-display-tag>
                    </div>
                </b-table-column>

                <b-table-column :visible="tblCols.campaigns_count.visible" field="campaigns_count" label="Cmpgn#" sortable>
                    {{ props.row.campaigns_count }}
                </b-table-column>

                <b-table-column :visible="tblCols.campaigns_finished_at.visible" field="campaigns.finished_at" label="Last finished" sortable>
                    <template v-if="props.row.campaigns.length">
                        {{ props.row.campaigns[0].finished_at | moment('timezone', $global.current_tz) | moment("from", "now") }} to <b>{{ props.row.campaigns[0].msg_sent }} contacts</b>
                    </template>
                </b-table-column>

                <b-table-column :visible="tblCols.expires_at.visible" field="expires_at" label="Expires" sortable>
                    <template v-if="props.row.expires_at">
                        <span v-bind:class="{ 'error-color weight-bold': props.row.isExpired, 'warning-color weight-bold': props.row.expireWarning }">
                            {{ props.row.expires_at | moment('timezone', $global.current_tz) | moment("from", "now") }}
                        </span>
                    </template>
                    <template v-else>
                        n/a
                    </template>
                </b-table-column>

                <b-table-column :visible="tblCols.created_at.visible" field="created_at" label="Created" sortable>
                    {{ props.row.created_at | moment('timezone', $global.current_tz) | moment("from", "now") }}
                </b-table-column>

                <b-table-column :visible="tblCols.usage.visible" style="opacity: 0.6" width="210" field="usage" label="Usage">
                    <b-tooltip label="total_contact_limit">
                        <b-icon icon="user" title="total_contact_limit" style="zoom: 0.7"></b-icon>{{ props.row.contacts_count }} of {{ props.row.account_config.total_contact_limit }}
                    </b-tooltip>

                    <b-tooltip label="total_list_limit">
                        <b-icon icon="list" title="total_list_limit" style="zoom: 0.7"></b-icon>{{ props.row.lists_count }} <span v-if="props.row.account_config.total_list_limit > -1"> / {{ props.row.account_config.total_list_limit }}</span>
                    </b-tooltip>

                    <b-tooltip label="template_count">
                        <b-icon icon="columns" title="template_count" style="zoom: 0.7"></b-icon>{{ props.row.template_count }} <span v-if="props.row.account_config.total_template_limit > -1"> / {{ props.row.account_config.total_template_limit }}</span>
                    </b-tooltip>

                    <b-tooltip label="wa_credits_left">
                        <b-icon icon="comments" title="wa_credits_left" style="zoom: 0.7"></b-icon>{{ props.row.wa_credits_left }}
                    </b-tooltip>

                    <b-tooltip label="sms_credits_left">
                        <b-icon icon="sms" title="sms_credits_left" style="zoom: 0.7"></b-icon>{{ props.row.sms_credits_left }}
                    </b-tooltip>

                    <b-tooltip label="api_requested_limit">
                        <b-icon icon="cogs" title="api_requested_limit" style="zoom: 0.7"></b-icon>{{ props.row.api_request_count }}
                    </b-tooltip>
                </b-table-column>

                <b-table-column :visible="tblCols.last_userlogin_at.visible" field="last_userlogin_at" label="Last login" sortable>
                    {{ props.row.last_userlogin_at | moment('timezone', $global.current_tz) | moment("from", "now") }}
                </b-table-column>

                <b-table-column :visible="tblCols.login_count.visible" width="30" field="login_count" label="Login count" sortable>
                    {{ props.row.login_count }}
                </b-table-column>

                <b-table-column :visible="tblCols.state.visible" width="30" field="state" label="State" sortable>
                    <state-tag v-model="props.row.state"></state-tag>

                    <sub v-if="props.row.state === 'ACTIVE' && props.row.cancel_at" class="error-color" :title="props.row.cancel_at"><b>Account will be cancelled {{ props.row.cancel_at | moment('timezone', $global.current_tz) | moment("from", "now") }}</b></sub>

                    <sub v-if="props.row.state === 'PENDING_APPROVAL'" class="text-lightgrey">
                        Requested {{ props.row.created_at | moment('timezone', $global.current_tz) | moment("from", "now") }}
                    </sub>
                </b-table-column>

                <b-table-column :visible="tblCols.notes.visible" field="notes" width="320" label="Notes">
                    {{ props.row.account_config.notes }}
                </b-table-column>

                <b-table-column field="action" width="135" label="" centered>

                    <a href="#" @click="impersonateUser(props.row.users[0].id)" class="action-button" title="Impersonate first user of account">
                        <b-icon icon="user"></b-icon>
                    </a>

                    <b-dropdown position="is-bottom-left">
                        <button class="button action-button" slot="trigger">
                            <b-icon icon="ellipsis-h"></b-icon>
                        </button>

                        <b-dropdown-item has-link>
                            <router-link :to="{ name: 'sa-account-edit', params: {accountId: props.row.id} }" title="Edit account">
                                Edit
                            </router-link>
                        </b-dropdown-item>

                        <b-dropdown-item v-if="props.row.state === 'PENDING_APPROVAL'" @click="approve(props.row.id)">
                            Approve
                        </b-dropdown-item>

                        <b-dropdown-item v-if="props.row.state === 'INACTIVE'" @click="enableAccount(props.row.id, true)">
                            Enable
                        </b-dropdown-item>

                        <b-dropdown-item v-if="props.row.state === 'ACTIVE'" @click="enableAccount(props.row.id, false)">
                            Disable
                        </b-dropdown-item>

                        <b-dropdown-item @click="deleteAccount(props.row.id)">
                            <span class="error-color">Delete</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="impersonateUser(props.row.users[0].id, {name: 'admin-billing', params: { tabIndex: 1 } } )">
                            Show billing history
                        </b-dropdown-item>

                        <b-dropdown-item @click="$utils.open($utils.getForeignCustomerUrl(props.row.billing_details.foreign_customer_id),'mollie')">
                            Open customer in Mollie
                        </b-dropdown-item>

                    </b-dropdown>

                </b-table-column>

            </template>

            <!-- DETAILS -->

            <template slot="detail" slot-scope="props">

                <b-table :data="props.row.users">
                    <template slot-scope="users">

                        <!--<b-table-column field="id" label="ID" sortable>-->
                        <!--USER-{{ users.row.id }}-->
                        <!--</b-table-column>-->

                        <b-table-column field="users.phone_country" label="" width="10" sortable>
                            <div style="position: relative; margin-top: -19px; ">
                                <b-tooltip type="is-info" :label="users.row.phone_country ? users.row.phone_country : 'Unknown'">
                                    <country-flag style="transform: scale(0.35)" :country="users.row.phone_country_code" size="small" />
                                </b-tooltip>
                            </div>
                        </b-table-column>

                        <b-table-column field="first_name" label="First name" sortable>
                            {{ users.row.first_name }}
                        </b-table-column>

                        <b-table-column field="last_name" label="Last name" sortable>
                            {{ users.row.last_name }}
                        </b-table-column>

                        <b-table-column field="email" label="Email" sortable>
                            {{ users.row.email }}
                        </b-table-column>

                        <b-table-column field="phone" label="Phone" sortable>
                            <b-tag type="is-light">+{{ users.row.phone }}</b-tag>
                        </b-table-column>

                        <b-table-column field="state" label="State">
                            <state-tag v-model="users.row.state"></state-tag>
                        </b-table-column>

                        <b-table-column field="login_count" label="Login count" sortable>
                            {{ users.row.login_count }}<br>
                            <b-tag type="is-warning" v-show="users.row.last_login_at">last login: {{ users.row.last_login_at | moment('timezone', $global.current_tz) | moment("from", "now") }}</b-tag>
                        </b-table-column>

                        <b-table-column field="role" label="Role" sortable>
                            <role-tag v-model="users.row.role"></role-tag>
                        </b-table-column>

                        <b-table-column field="action" width="160" label="" centered>

                            <a href="#" @click="impersonateUser(users.row.id)" class="action-button" title="Impersonate user">
                                <b-icon icon="user"></b-icon>
                            </a>

                            <router-link :to="{ name: 'sa-user-edit', params: {userId: users.row.id} }" class="action-button" title="Edit user">
                                <b-icon icon="edit"></b-icon>
                            </router-link>

                            <a href="#" @click="deleteUser(users.row.id)" class="action-button" title="Delete user">
                                <b-icon icon="trash-alt"></b-icon>
                            </a>

                        </b-table-column>

                    </template>
                </b-table>

                <div class="level-right">

                    <button class="button is-medium is-info is-pulled-right is-clearfix"
                            @click="createUserModal" variant="primary">
                        <b-icon icon="plus"></b-icon><span>Create user</span>
                    </button>

                </div>

            </template>

            <template slot="empty">
                <transition name="fade">
                    <section class="section" v-show="! loading">
                        <div class="content has-text-grey has-text-centered">
                            <p class="is-large"><b>Sorry, no account found.</b></p>
                        </div>
                    </section>
                </transition>
            </template>

        </b-table>
    </section>
</template>

<script>

    import moment from 'moment';
    import CountryFlag from 'vue-country-flag';

    import CreateAccountForm from '../../helpers/createAccountForm';
    import CreateUserForm from '../../helpers/createUserForm';

    export default {
        components: {
            CountryFlag
        },
        data() {
            return {
                data: [],
                total: null,
                loading: true,
                sortField: 'created_at',
                sortOrder: 'desc',
                defaultSortOrder: 'asc',
                page: 1,
                perPage: 10,

                search: this.$route.query.search || '',
                globalTimeout: null,
                filter: {
                    plans: [],
                    labels: [],
                    states: ['ACTIVE', 'PENDING_APPROVAL'],
                },

                tblCols: {
                    id: { label: 'Id', visible: true },
                    name: { label: 'Name', visible: true },
                    plan: { label: 'Plan', visible: true },
                    label: { label: 'Label', visible: true },
                    state: { label: 'State', visible: true },
                    period: { label: 'Period', visible: true },
                    subscription: { label: 'Subscription', visible: true },
                    next_billing_date: { label: 'Next billing date', visible: true },
                    next_recurring_amount: { label: 'Next recurring amount', visible: false },
                    // next_billing_date: { label: 'Next billing date', visible: false },
                    channels: { label: 'Channels', visible: true },
                    campaigns_count: { label: 'Campaign count', visible: false },
                    campaigns_finished_at: { label: 'Campaign finished at', visible: true },
                    last_userlogin_at: { label: 'Last user login at', visible: true },
                    login_count: { label: 'Login count', visible: true },
                    expires_at: { label: 'Expires at', visible: false },
                    created_at: { label: 'Created_at', visible: true },
                    usage: { label: 'Usage', visible: false },
                    notes: { label: 'Notes', visible: false }
                }

            }
        },
        watch: {
            // whenever changes, this function will run
            tblCols: {
                handler (newValue, oldValue) {

                    localStorage.sa_accounts_tblCols = JSON.stringify(this.tblCols);
                },
                deep: true
            },
            filter: {
                handler (newValue, oldValue) {

                    localStorage.sa_accounts_filter = JSON.stringify(this.filter);
                },
                deep: true
            }
        },
        computed: {

        },
        methods: {
            daysUntil(value) {
                return value && this.$moment(value).diff(this.$moment(new Date()), 'days')
            },
            delay(fn, waitDelay) {
                if (this.globalTimeout != null) {
                    clearTimeout(this.globalTimeout);
                }
                this.globalTimeout = setTimeout(function() {
                    this.globalTimeout = null;

                    fn();

                }, waitDelay);
            },
            /*
             * Load async data
             */
            loadAsyncData() {

                // localStorage.sa_accounts_tblCols = 'test';

                const params = [
                    `orderBy=${this.sortField}`,
                    `sort=${this.sortOrder}`,
                    `label=${this.filter.labels}`,
                    `state=${this.filter.states}`,
                    `plan=${this.filter.plans}`,
                    `search=${this.search}`,
                    `page=${this.page}`
                ].join('&');

                this.loading = true;

                this.$store.dispatch('fetchAccounts', params).then((data) => {

                    let result = data.result;

                    this.data = [];
                    let currentTotal = result.total;
                    if (result.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000
                    }

                    this.total = currentTotal;

                    result.data.forEach((item) => {

                        // check for empty values, create one if relation is missing
                        if(! item.contact_list) {
                            item.contact_list = {}
                        }

                        if(! item.template) {
                            item.template = {}
                        }

                        // overrides
                        item.isExpired = false; // or prove otherwise
                        item.expireWarning = false; // or prove otherwise

                        if(item.expires_at) {
                            const expires_at = moment(item.expires_at);
                            const now = moment();

                            if(item.state !== 'INACTIVE') {
                                if (expires_at.isBefore(now)) {
                                    item.state = 'EXPIRED';
                                    item.isExpired = true;
                                } else {
                                    const dateWarning = moment(Date.now() + 3 * 24 * 3600 * 1000);

                                    if (expires_at.isBetween(now, dateWarning)) {
                                        item.expireWarning = true;
                                    }
                                }
                            }

                        }

                        this.data.push(item)
                    })
                    this.loading = false
                })
                    .catch((error) => {
                        this.data = [];
                        this.total = 0;
                        this.loading = false;
                        throw error
                    });

            },
            createAccountModal() {
                const self = this;
                this.$buefy.modal.open({
                    parent: this,
                    component: CreateAccountForm,
                    hasModalCard: true,
                    events: {
                        success: function() {
                            self.loadAsyncData();
                        }
                    }
                })
            },
            createUserModal() {
                this.$buefy.modal.open({
                    parent: this,
                    component: CreateUserForm,
                    hasModalCard: true
                })
            },
            approve(id) {

                const params = {
                    id: id
                };

                this.$store.dispatch('approveAccount', params).then((data) => {
                    this.loadAsyncData();

                    this.$buefy.toast.open({
                        message: 'Account approved!',
                        type: 'is-success'
                    });


                }).catch((error) => { });
            },
            enableAccount(id, enable) {

                const params = {
                    id: id,
                    state: (enable ? 'ACTIVE' : 'INACTIVE')
                };

                this.$buefy.dialog.confirm({
                    title: (enable ? 'Enable' : 'Disable')+' account',
                    message: 'Are you sure you want to <b>'+(enable ? 'Enable' : 'Disable')+'</b> this account?<br><br><b>MAKE SURE TO CANCEL/RECREATE MOLLIE PAYMENT SUBSCRIPTION FOR THIS ACCOUNT!</b>',
                    confirmText: (enable ? 'Enable' : 'Disable')+' account',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('updateAccount', params).then(() => {
                            this.loadAsyncData();
                        })
                            .catch((error) => {
                                throw error
                            });
                    }
                })

            },
            deleteAccount(id) {

                const params = {
                    id: id
                };

                this.$buefy.dialog.confirm({
                    title: 'Delete',
                    message: 'Are you sure you want to <b>delete</b> this account? This action cannot be undone.',
                    confirmText: 'Delete account',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('deleteAccount', params).then(() => {
                            this.loadAsyncData();
                        })
                            .catch((error) => {
                                throw error
                            });
                    }
                })

            },

            /*
            * Impersonate user
            */
            impersonateUser(id, routerObj) {

                routerObj = routerObj || null;

                const params = {
                    id: id
                };

                this.$store.dispatch('impersonateUser', params).then((data) => {
                    this.$buefy.toast.open({
                        message: 'User has been successfully impersonated.',
                        type: 'is-warning',
                        duration: 1000
                    });

                    if(routerObj) {
                        // this.$router.push({ name: 'login', params: { auto_login: true, email: this.form.email, password: this.form.password } });
                        this.$router.push(routerObj);
                    } else {
                        this.$router.push('/dashboard');
                    }

                }).catch((error) => { });
            },

            /*
            * Handle remove a user
            */
            deleteUser(id) {

                const params = {
                    id: id
                };

                this.$buefy.dialog.confirm({
                    title: 'Delete',
                    message: 'Are you sure you want to <b>delete</b> this user? This action cannot be undone.',
                    confirmText: 'Delete user',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('deleteUser', params).then((data) => {

                            let result = data.result;
                            this.loadAsyncData();
                        })
                            .catch((error) => {
                                throw error
                            });
                    }
                })
            },

            searchFilter() {
                var that = this;
                this.delay(function() {
                    that.$router.replace({ query: { search: that.search } });

                    that.loadAsyncData()
                }, this.$global.search_delay);
            },

            /*
             * Handle page-change event
             */
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            /*
             * Handle sort event
             */
            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
            },
            /*
             * Type style in relation to the value
             */
            type(value) {
                const number = parseFloat(value)
                if (number < 6) {
                    return 'is-danger'
                } else if (number >= 6 && number < 8) {
                    return 'is-warning'
                } else if (number >= 8) {
                    return 'is-success'
                }
            }
        },
        filters: {
            /**
             * Filter to truncate string, accepts a length parameter
             */
            truncate(value, length) {
                return value.length > length
                    ? value.substr(0, length) + '...'
                    : value
            }
        },

        mounted() {
            setTimeout(() => this.$refs.search.focus(), 1000);

            if(localStorage.sa_accounts_tblCols) {
                try {
                    this.tblCols = JSON.parse(localStorage.sa_accounts_tblCols);
                    this.filter = JSON.parse(localStorage.sa_accounts_filter);
                } catch(error) {
                    // invalid tblCols in localStorage (update structure?)
                    localStorage.removeItem('sa_accounts_tblCols');
                    localStorage.removeItem('sa_accounts_filter');
                }
            }

            this.loadAsyncData();
        }
    }
</script>

<style scoped>
    .container {
        max-width: 95% !important;
        width: 95% !important;
    }
</style>

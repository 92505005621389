<template>
    <section>
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <div style="display:inline-block" v-bind:class="! loading">
                        <h1>{{ form.name }}
                            <a href="#" @click="renameCampaign">
                                <b-icon icon="pencil-alt" size="is-small" style="margin-left:14px"></b-icon>
                            </a>
                        </h1>
                    </div>
                </div>
            </div>

            <!-- Right side -->
            <div class="level-right">
            </div>

        </nav>
        <hr>

        <b-loading :active.sync="loading"></b-loading>

        <form @submit.prevent="">

            <div class="columns">
                <div class="column is-1"></div>
                <div class="column is-10">
                    <span v-if="list.opt_in_contacts_count === 0">
                        <b-message type="is-danger">
                            Selected contact list contains no opt-in contacts. Starting the campaign is disabled.
                        </b-message>
                        <br>
                    </span>
                    <span v-else-if="selectedChannel && ! selectedChannel.enabled">
                        <b-message v-show="(selectedChannel && ! selectedChannel.enabled)" type="is-danger">
                            The channel is disabled for contact list "{{ contactlist_dropdown_lbl }}". Starting the campaign is disabled.
                        </b-message>
                        <br>
                    </span>
                    <span v-else-if="selectedChannel && ! selectedChannel.is_online">
                        <b-message type="is-danger">
                            The channel is offline for contact list "{{ contactlist_dropdown_lbl }}". Starting the campaign is disabled.
                        </b-message>
                        <br>
                    </span>

                    <section>
                        <b-tabs v-show="! loading" type="is-centered" size="is-medium" class="block" v-model="tabIndex" expanded>
                                <b-tab-item>
                                    <template slot="header">
                                        <b-icon icon="pencil-alt"></b-icon>
                                        <span v-if="form.type === 'WHATSAPP'">WhatsApp Message</span>
                                        <span v-else-if="form.type === 'SMS'">SMS Message</span>
                                        <span v-else>Message</span>
                                    </template>
                                    <template-editor ref="templateEditor" :message-type="form.type" :disable-attachments="form.type === 'SMS'" v-model="template" v-on:changePersonalised="placeHoldersUsed=$event"></template-editor>
                                    <hr>

                                    <div class="field is-grouped is-pulled-right">
                                        <button class="is-pulled-right button is-light is-medium" @click="saveOnlyAndStayOnPage" v-bind:class="{'is-loading': savingDraft}">Save changes</button>
                                        <button class="button is-info is-medium is-pulled-right" style="margin-left:6px" @click="gotoTab(2)" type="button">Next</button>&nbsp;
                                    </div>
                                </b-tab-item>

                                <b-tab-item>
                                    <template slot="header">
                                        <b-icon icon="address-book"></b-icon>
                                        <span v-if="list.contacts_count !== null"> Recipients <b-tag type="is-info" style="vertical-align: middle">{{list.contacts_count}}</b-tag></span>
                                        <span v-else> Recipients </span>
                                    </template>

                                    <div class="box has-text-centered" style="min-height: 300px;">
                                        <contactlist-selector v-if="campaignDataLoaded" v-model="list"></contactlist-selector>
                                    </div>
                                    <hr>
                                    <div>
                                        <p>
                                            <button class="button is-light is-medium is-pulled-left" @click="tabIndex=0">Back</button>&nbsp;
                                        </p>
                                    </div>

                                    <div class="field is-grouped is-pulled-right">
                                        <button class="is-pulled-right button is-light is-medium" @click="saveOnlyAndStayOnPage" v-bind:class="{'is-loading': savingDraft}" :disabled="!list.id">Save changes</button>
                                        <button class="button is-info is-medium is-pulled-right" style="margin-left:6px" @click="gotoTab(3)" :disabled="!list.id" type="button">Next</button>&nbsp;
                                    </div>
                                </b-tab-item>

                                <b-tab-item>
                                    <template slot="header">
                                        <b-icon icon="dove"></b-icon>
                                        <span> Preview & Send </span>
                                    </template>

                                    <div class="box">
                                        <div class="has-text-centered">
                                            <b>Preview example</b>
                                        </div>
                                        <br>
                                        <div class="columns">
                                            <div class="column is-2"></div>
                                            <div class="column is-8">
                                                <div class="has-text-centered">
                                                    <div v-show="placeHoldersUsed" class="field">
                                                        <b-field>
                                                            <b-radio-button v-model="previewMode"
                                                                            native-value="PERSONALISED"
                                                                            type="is-info">
                                                                <b-icon icon="user-alt"></b-icon>
                                                                <span>Personalised</span>
                                                            </b-radio-button>

                                                            <b-radio-button v-model="previewMode"
                                                                            native-value="ANONYMOUS"
                                                                            type="is-light">
                                                                <b-icon icon="question"></b-icon>
                                                                <span>Anonymous</span>
                                                            </b-radio-button>

                                                        </b-field>
                                                    </div>

                                                    <template v-if="previewMode === 'PERSONALISED'">
                                                        <message-preview :originator-id="this.channelName" :attachment="this.template.uploadFileContent"><span v-html="$utils.parseWAMessage(replace(this.template.message_body,true))"></span></message-preview>
                                                    </template>

                                                    <template v-else>
                                                        <message-preview :originator-id="this.channelName" :attachment="this.template.uploadFileContent"><span v-html="$utils.parseWAMessage(replace(this.template.message_body_generic,false))"></span></message-preview>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-2"></div>
                                    </div>

                                    <!--<div class="box has-text-centered">-->
                                        <!--<channel-selector v-on:change="setOrigin"></channel-selector>-->
                                    <!--</div>-->

                                    <div class="box has-text-centered">
                                        <label class="label-field">
                                            Do you want to schedule this campaign?
                                        </label>
                                        <div class="columns">
                                            <div class="column is-4"></div>
                                            <div class="column is-4">
                                                <b-switch v-model="isScheduled" @input="scheduleSwitchChange"></b-switch>

                                                <template v-if="isScheduled">
                                                    <br><br>
                                                    <b-field>
                                                        <b-datepicker
                                                                v-model="form.scheduled_at_date"
                                                                placeholder="Type or select a date..."
                                                                icon="calendar-alt"
                                                                size="is-medium"
                                                                :disabled="! isScheduled"
                                                                :readonly="false">

                                                            <button class="button is-primary"
                                                                    @click="form.scheduled_at_date = new Date()">
                                                                <b-icon icon="calendar"></b-icon>
                                                                <span>Today</span>
                                                            </button>

                                                            <button class="button is-danger"
                                                                    @click="form.scheduled_at_date = null">
                                                                <b-icon icon="times-circle"></b-icon>
                                                                <span>Clear</span>
                                                            </button>
                                                        </b-datepicker>
                                                    </b-field>

                                                    <b-field>
                                                        <b-timepicker
                                                                v-model="form.scheduled_at_time"
                                                                placeholder="Type or select a date..."
                                                                icon="clock"
                                                                size="is-medium"
                                                                :disabled="! isScheduled"
                                                                :readonly="false">
                                                        </b-timepicker>
                                                    </b-field>
                                                </template>
                                            </div>
                                            <div class="column is-4"></div>
                                        </div>
                                    </div>

                                    <div class="box has-text-centered is-hidden">
                                        <label class="label-field">
                                           Send options
                                        </label>
                                        <div class="field">
                                            <b-checkbox size="is-medium" v-model="form.dry_run">Dry run (do not send messages)</b-checkbox>
                                        </div>
                                    </div>

                                    <div class="box has-text-centered is-hidden">
                                        <label class="label-field">
                                            Campaign name
                                        </label>
                                        <p style="margin-top:20px">
                                            <b-field>
                                                <b-input v-model="form.name" size="is-medium" required minlength="4" maxlength="128"></b-input>
                                            </b-field>
                                        </p>
                                    </div>

                                    <hr>
                                    <div>
                                        <p>
                                            <button class="button is-light is-medium is-pulled-left" @click="gotoTab(2)">Back</button>
                                            <div class="field is-grouped is-pulled-right">
                                                <button class="button is-light is-medium" @click="saveOnly" :disabled="savingDraft" v-bind:class="{'is-loading': savingDraft}">Save as draft</button>&nbsp;
                                                <button class="button is-light is-medium" @click="sendTestMessage">Send test message</button>&nbsp;

                                                <template v-if="isScheduled">
                                                    <button class="button is-primary is-medium" @click="saveAndSchedule" type="button" :disabled="savingStartCampaign" v-bind:class="{'is-loading': savingStartCampaign}">
                                                        Save & schedule
                                                    </button>
                                                </template>
                                                <template v-else>
                                                    <button id="send-campaign-btn" class="button is-primary is-medium" @click="saveAndStart" type="button" :disabled="savingStartCampaign" v-bind:class="{'is-loading': savingStartCampaign}">
                                                        <b-icon icon="paper-plane"></b-icon> <span style="margin-left:10px">Save & send campaign</span>
                                                    </button>
                                                </template>
                                            </div>
                                        </p>
                                    </div>

                                </b-tab-item>
                        </b-tabs>
                    </section>
                </div>
                <div class="column is-1"></div>
            </div>

            <br>

        </form>

    </section>
</template>

<script>

    import CreateSendTestMessageForm from '../helpers/createSendTestMessageForm';
    import TemplateEditor from './common/template-editor.vue';

    import replacePlaceholders from '../helpers/replace';

    export default {
        components: {
            TemplateEditor
        },
        data() {
            return {
                data: {},
                form: {
                    id: this.$route.params.campaignId,
                    name: '',
                    type: null,
                    // list_id: null,
                    template_id: null,
                    channel_id : 0,
                    origin: null,

                    scheduled_at_date: null,
                    scheduled_at_time: null,

                    is_public: false,
                    dry_run: false
                },

                list : {
                    id: null,
                    name: null,
                    uuid: null,
                    contacts_count: null,
                    opt_in_contacts_count: null,
                    channel: {
                        send_campaign_recipient_limit: null
                    }
                },

                template: {
                    message_body: 'Hi [[FIRST_NAME]], How are you today? Check out our new website! https://bit.ly/2NdFkSi Cheers [[*SENDER_FIRST_NAME]]',
                    message_body_generic: 'Hi there, How are you today? Check out our new website! https://bit.ly/2NdFkSi Cheers [[*SENDER_FIRST_NAME]]',
                    uploadFileMimeType: null,
                    uploadFileContent: null
                },

                search: null,
                id: null,

                connData: {},

                isScheduled: false,
                placeHoldersUsed: false,

                tabIndex: parseInt(this.$route.params.tabIndex) || 0,

                contactLists: [],
                templates: [],
                channels: [],

                savingDraft: false,
                savingStartCampaign: false,

                uploadFile: null,

                contactlist_dropdown_lbl: 'Select',
                template_dropdown_lbl: 'Select',
                channel_dropdown_lbl: 'Select',

                previewMode: 'PERSONALISED',

                selectedContactName: null,

                selectedChannel: null,
                channelName: null,

                campaignDataLoaded: false,
                loading: true,

            }
        },
        computed: {
            account() {
                return this.$store.getters.userProfile.account;
            },
        },
        methods: {
            setOrigin(obj) {
                this.form.origin = obj.origin;
                this.form.channel_id = obj.id;
            },
            async getBase64ImageFromUrl(imageUrl) {
                var res = await fetch(imageUrl);
                var blob = await res.blob();

                return new Promise((resolve, reject) => {
                    var reader  = new FileReader();
                    reader.addEventListener("load", function () {
                        resolve(reader.result);
                    }, false);

                    reader.onerror = () => {
                        return reject(this);
                    };

                    reader.readAsDataURL(blob);
                })
            },

            renameCampaign() {
                this.$buefy.dialog.prompt({
                    title: 'Rename campaign name',
                    inputAttrs: {
                        placeholder: 'e.g. Free offer campaign',
                        maxlength: 64,
                        value: this.form.name
                    },
                    onConfirm: (value) => {
                        this.form.name = value;
                    }
                })
            },

            gotoTab(index) {

                if(index === 1) {

                    if(! this.placeHoldersUsed) {

                        const personalised = this.containsPersonalisedFields();
                        this.placeHoldersUsed = personalised;

                        if (personalised) {
                            this.form.message_body_generic = this.removePlaceholders(this.form.message_body);
                            return false;
                        }
                    }
                }

                this.tabIndex = index -1; // tabindex uses offset of 0 while gototab expects the actual tab index starting from 1
            },
            containsPersonalisedFields() {
                const regex = /\[\[(?!\*)([^}]*)\]\]/;
                const str = this.form.message_body;
                let m;

                if ((m = regex.exec(str)) !== null) {
                    return true;
                }
            },
            getMinDate() {
                return new Date().getDate() - 1
            },
            removePlaceholders(msg) {

                const regex = /\[\[(?!\*)([^}]*)\]\]./g;
                return msg.replace(regex, "");
            },
            getMinDate() {
                return new Date().getDate() - 1
            },
            combineDateAndTime: function(date, time) {

                const year = date.getFullYear();
                const month = ('0' + (date.getMonth())).slice(-2); // Jan is 0, dec is 11
                const day = ('0' + date.getDate()).slice(-2);

                const hour = ('0' + (time.getHours())).slice(-2);
                const minute = ('0' + (time.getMinutes())).slice(-2);

                return new Date(year, month, day, hour, minute);
            },

            replace(msg, personalised) {
                return replacePlaceholders(msg, personalised);
            },

            /*
             * Load async data
             */
            loadAsyncData() {

                this.loading = true;

                let params = {
                    id: this.form.id
                };

                this.$store.dispatch('fetchCampaign', params).then((data) => {

                    // update view with current campaign settings
                    let result = data.result;

                    // store locally (mapping)
                    this.form.name = result.name;
                    this.form.type = result.type;
                    this.list.id = result.list_id;
                    this.form.template_id = result.template_id;
                    this.form.channel_id = result.channel_id;
                    this.form.origin = result.origin;

                    this.list.channel.send_campaign_recipient_limit = (result.channel ? result.channel.send_campaign_recipient_limit : -1); // no limit by default
                    this.channelName = (result.channel ? result.channel.name : null);

                    if(result.template) {
                        this.template.message_body = result.template.message_body;
                        this.template.message_body_generic = result.template.message_body_generic;
                        this.template_dropdown_lbl = result.template.name;
                        this.template.is_public = result.template.is_public;

                        if(result.template.attachment) {
                            this.template.uploadFileMimeType = result.template.mime_type;

                            if(result.template.attachment.indexOf('://')) {
  //                              is url
                                this.template.uploadFileContent = result.template.attachment;

                            } else {
//                                is base64
                                this.getBase64ImageFromUrl(result.template.attachment)
                                    .then(res => {
                                        this.template.uploadFileContent = res;
                                    })
                                    .catch(err => console.error(err));
                            }

                        } else {
                            this.template.uploadFileContent = null;
                        }

                    } else {
                        this.form.is_public = false;
                        this.template.uploadFileContent = null;
                    }

                    if(result.list_id) {

                        this.contactlist_dropdown_lbl = result.contact_list.name + ' (' + result.contacts_count + ')';
                        this.selectedContactName = result.contact_list.name;
                        this.selectedChannel = result.channel;

                    } else {
                        this.contactlist_dropdown_lbl = 'Choose contact list';
                    }

                    if(result.template_id) {
                        this.template_dropdown_lbl = result.template.name;
                    } else {
                        this.template_dropdown_lbl = 'Choose template';
                    }

                    this.form.name = result.name;

                    this.form.scheduled_at_date = ( result.scheduled_at ? this.$moment.utc(result.scheduled_at).toDate() : null);
                    this.form.scheduled_at_time = ( result.scheduled_at ? this.$moment.utc(result.scheduled_at).toDate() : null);

                    this.isScheduled = (result.scheduled_at ? true : false);

                    this.channel_dropdown_lbl = result.origin;

                    if(this.containsPersonalisedFields()) {
                        this.placeHoldersUsed = true;
                    }

                    this.campaignDataLoaded = true;

                    // this.data = result;
                    // this.loading = false
                })
                .catch((error) => {

                    this.$buefy.toast.open({
                        message: 'The requested campaign could not be found!',
                        type: 'is-danger'
                    });

                    this.$router.push({ name: 'campaigns' });

                    this.data = [];

                    this.total = 0;
                    this.loading = false;

                    return false;
                });

                // params = [
                //     `orderBy=updated_at`,
                //     `sort=desc`
                // ].join('&');
                //
                // this.$store.dispatch('fetchContactLists', params ).then((data) => {
                //
                //     let contactList = data.result.data;
                //
                //     for (let i = 0; i < contactList.length ; i++) {
                //         contactList[i].ddIndex = i;
                //
                //         this.contactLists.push(contactList[i]);
                //     }
                //
                // })
                // .catch((error) => {
                //     throw error
                // })
                // .finally(() => {
                //     this.loading = false;
                // });
                this.loading = false;

            },

            sendTestMessage() {

                this.$buefy.modal.open({
                    parent: this,
                    component: CreateSendTestMessageForm,
                    props: {
                        message: this.template.message_body,
                        phone: this.$store.getters.userProfile.phone,
                        channel_id: this.form.channel_id,
                        uploadFileContent: this.template.uploadFileContent
                    },
                    hasModalCard: true
                })
            },

            saveAndStart() {

                const obj = this.checkSufficientCredits();
                if(! obj.success) return;

                const recipients = this.list.contacts_count > 0 ? this.list.contacts_count : 0;

                const contactLblOptInContacts = this.$options.filters.pluralize(this.list.opt_in_contacts_count, 'contact');
                const contactLblOptOutContacts = this.$options.filters.pluralize(recipients - this.list.opt_in_contacts_count, 'contact');
                const contactLblContactsLimit = this.$options.filters.pluralize(this.list.channel.send_campaign_recipient_limit, 'contact');

                if(this.list.opt_in_contacts_count > 0) {

                    if(! this.isScheduled) {

                        let message = '';

                        // check limit
                        const typeLabel = this.form.type === 'WHATSAPP' ? 'WhatsApp' : 'SMS'

                        message = `You are about to send the <u>${typeLabel}</u> campaign to <b>${this.list.name}</b> (<b>${this.list.opt_in_contacts_count} ${contactLblOptInContacts})</b>.
                            <br><br>
                            ${this.list.opt_in_contacts_count < recipients ? '<span class="error-color"><b>Note. Message will not be sent to ' + (recipients - this.list.opt_in_contacts_count) + ' opted-out '+contactLblOptOutContacts+' !</b></span>' : ''}
                        `;

                        if(this.list.channel.send_campaign_recipient_limit > -1 && this.list.opt_in_contacts_count > this.list.channel.send_campaign_recipient_limit) {
                            message += `<br><br>
                            <span class="error-color"><b>Important:</b> this limited demo channel can only send maximum of ${contactLblContactsLimit} contacts</span>`;
                        }

                        const confirmText = 'Yes, send it now!';

                        this.$buefy.dialog.confirm({
                            title: 'Send Campaign',
                            cancelText: 'Cancel',
                            confirmText: confirmText,
                            type: 'is-primary',
                            icon: 'paper-plane',
                            hasIcon: true,
                            message: message,
                            onConfirm: () => {

                                this.savingStartCampaign = true;
                                this.update(true, true);
                            }
                        })
                    }



                } else {

                    this.$buefy.toast.open({
                        message: 'No contact list selected with at least 1 contact which opted in.',
                        type: 'is-danger'
                    });

                    setTimeout(() => {
                        this.tabIndex = 1;
                    },1000);

                }
            },

            saveAndSchedule() {

                const obj = this.checkSufficientCredits();
                if(! obj.success) return;

                const recipients = this.list.contacts_count > 0 ? this.list.contacts_count : 0;

                if(recipients > 0) {
                    this.savingStartCampaign = true;
                    this.update(true, true);

                } else {

                    this.$buefy.toast.open({
                        message: 'No contact list selected with at least 1 recipient.',
                        type: 'is-danger'
                    });

                    setTimeout(() => {
                        this.tabIndex = 1;
                    },1000);
                }


            },

            saveOnlyAndStayOnPage() {
                this.saveOnly(false);
            },
            saveOnly(redirectToOverview) {

                this.savingDraft = true;
                this.update(false, redirectToOverview);
            },

            update(startCampaignAferSaving, redirectToOverview) {

                startCampaignAferSaving = startCampaignAferSaving || false;

                // validation
                if(this.placeHoldersUsed && ! this.template.message_body_generic) {

                    this.$buefy.dialog.alert({
                        title: 'Cannot save campaign',
                        message: 'The fallback message cannot be empty. Please set this text message, in case any of the placeholders used (i.e. [[FIRST_NAME]]) is not defined for certain contacts.',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        onConfirm: () => {
                            this.tabIndex = 0;
                            this.$refs.templateEditor.focus();
                        }
                    })

                    this.savingDraft = false;
                    this.savingStartCampaign = false;

                    return;
                }

                // Send data to the server or update your stores and such.
                // let params = this.form;
                let scheduled_at = null;

                if(this.isScheduled) {
                    scheduled_at = this.combineDateAndTime(this.form.scheduled_at_date, this.form.scheduled_at_time);
                }

                const params = {
                    id: this.form.id,
                    name: this.form.name,
                    type: this.form.type,
                    list_id: this.list.id,
                    channel_id: this.form.channel_id,
                    origin: this.form.origin,

                    uploadFileContent: this.template.uploadFileContent,

                    message_body: this.template.message_body,
                    message_body_generic: this.template.message_body_generic,

                    scheduled_at: (this.isScheduled ? scheduled_at : null),

                    is_public: this.form.is_public,
                    template_id: this.form.template_id
                };

                //
                this.$store.dispatch('updateCampaign', params).then((data) => {

                    // this.$buefy.toast.open({
                    //     message: 'Campaign updated successfully!',
                    //     type: 'is-success'
                    // });

                    if(data.success) {
                        this.form.template_id = data.result.template_id || null;

                    } else {

                        // let err_msg = 'Unknown error message occurred while sending campaign';
                        // switch(data.err_code) {
                        //     case 'not_enough_credits_left':
                        //
                        //         err_msg = 'Not enough credits to send SMS campaign. Please increase your sms credit amount!';
                        //         break;
                        // }
                        //
                        // this.$buefy.toast.open({
                        //     message: err_msg,
                        //     type: 'is-danger'
                        // });

                    }

                    if(startCampaignAferSaving) {
                        this.startOrSchedule();
                    } else {

                        if(redirectToOverview) {

                            this.$router.push('/campaigns');
                        } else {

                            this.$buefy.toast.open({
                                message: 'Campaign saved!',
                                type: 'is-success'
                            });

                            this.$utils.scrollToTop();
                        }

                    }

                }).catch((err) => {

                    this.$buefy.toast.open({
                        message: 'Error occurred while saving campaign: '+this.$utils.getHumanErrorMessage(err),
                        type: 'is-danger'
                    });
                    // handle error
                }).finally(() => {
                    this.savingDraft = false;
                });
            },

            checkSufficientCredits() {
                if(this.account.plan !== 'PAY AS YOU GO') {
                    return { success: true, total: this.list.opt_in_contacts_count };
                }

                // pay as you go from here

                if(this.form.type === 'WHATSAPP' && this.list.opt_in_contacts_count > this.account.wa_credits_left) {

                    const needed = this.list.opt_in_contacts_count - this.account.wa_credits_left;

                    this.$buefy.toast.open({
                        message: 'Insufficient WhatsApp credits. To send your campaign you require '+needed+' more credit(s). Please top up your credits.',
                        type: 'is-danger',
                        duration: 5000
                    });

                    return { success: false, type: this.form.type, total: this.list.opt_in_contacts_count, needed: needed } // insufficient credits
                } else if(this.form.type === 'SMS' && this.list.opt_in_contacts_count > this.account.sms_credits_left) {

                    const needed = this.list.opt_in_contacts_count - this.account.sms_credits_left;

                    this.$buefy.toast.open({
                        message: 'Insufficient SMS credits. To send your campaign you require '+needed+' more credit(s). Please top up your credits.',
                        type: 'is-danger',
                        duration: 5000
                    });

                    return { success: false, type: this.form.type, total: this.list.opt_in_contacts_count, needed: needed } // insufficient credits
                }

                return { success: true, total: this.list.opt_in_contacts_count };
            },

            startOrSchedule() {

                let params = {
                    id: this.form.id,
                    dry_run: this.form.dry_run
                };

                if(! this.isScheduled) {

                    this.$store.dispatch('startCampaign', params).then((data) => {

                        this.$router.push('/campaigns');
                            this.$buefy.toast.open({
                                // message: `Campaign is queued for processing shortlyhas started and will start sending to ${data.result.contacts_sent} contact(s)`,
                              type: 'is-warning',
                              message: `Campaign is queued for sending and will start within 1 minute...`,
                              duration: 7000
                            });


                    }).catch((err) => {

                        let msg;
                        switch(err.response.data.data.err) {
                            case 'channel_is_offline':
                                msg = 'Channel is offline.';

                                this.$snackbar.open({
                                    message: msg,
                                    type: 'is-danger',
                                    position: 'is-top',
                                    actionText: 'More info',
                                    indefinite: true,
                                    onAction: () => {
                                        this.$utils.showChannelOfflineInfo();
                                    }
                                })

                                break;
                            case 'no_contacts':
                                msg = 'No contacts specified.';
                            default:
                                this.$buefy.toast.open({
                                    message: `Error occurred when starting campaign: ${this.$utils.getHumanErrorMessage(err)}`,
                                    type: 'is-danger',
                                    duration: 5000
                                });
                        }



                    }).finally(() => {
                        this.savingStartCampaign = false;
                    });
                } else {
                    // scheduled

                    params.scheduled_at = this.combineDateAndTime(this.form.scheduled_at_date, this.form.scheduled_at_time);

                    this.$store.dispatch('scheduleCampaign', params).then((data) => {

                        this.$router.push('/campaigns');

                        this.$buefy.toast.open({
                            message: `Campaign is scheduled!`,
                            type: 'is-warning',
                            duration: 4000
                        });

                    }).catch((err) => {

                        this.$buefy.toast.open({
                            message: `Error occurred when starting campaign: ${this.$utils.getHumanErrorMessage(err)}`,
                            type: 'is-danger'
                        })
                    }).finally(() => {
                        this.savingStartCampaign = false;
                    });
                }
            },

            scheduleSwitchChange(enabled) {
                if(enabled && ! this.form.scheduled_at_date) {
                    this.form.scheduled_at_date = this.form.scheduled_at_time = new Date();
                }
            }
        },

        mounted() {
            this.loadAsyncData();

            if(this.tabIndex === 2) {
                setTimeout(() => {
                    this.$scrollTo('#send-campaign-btn');
                },1000);
            }

        }
    }
</script>

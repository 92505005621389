<template>

    <b-select v-if="countries.length > 0" v-on:input="handleItemClick" size="is-medium" placeholder="Select a country" v-model="currentValue" expanded>
        <option v-for="item in countries" :key="item.id" :value="item.id">{{ item.label }}</option>
    </b-select>

</template>

<script>

    import Country from 'i18n-iso-countries';
    import CountryEn from 'i18n-iso-countries/langs/en.json';

    export default {
        components: {
            Country, CountryEn
        },
        props: {
            value: {
                type: String,
                default: "Select country"
            },
            isFullwidth: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                countries: [],
                loading: true,
            }
        },
        methods: {
            setLabel(id) {
                return Country.getName(id, "en");
            },

            handleItemClick(obj) {
                this.$emit('input', obj);
            },

            transformCountries(countries) {
                // transform data

                let arr = [];

                for (let key in countries) {
                    if (countries.hasOwnProperty(key)) {
                        arr.push({
                            id: key,
                            label: countries[key]
                        })
                    }
                }

                return arr;
            },

            loadAsyncData() {
                Country.registerLocale(CountryEn);
                this.countries = this.transformCountries( Country.getNames('en') );

            }
        },
        mounted() {
            this.loadAsyncData();
            this.currentValue = this.value;
        }
    }
</script>

<style scoped>

</style>
<template>
    <section>
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <div>
                        <h1>Create Subscribe Form code</h1>

                    </div>
                </div>
            </div>

        </nav>

        <form @submit.prevent="">

            <!--<b-loading :active.sync="loading"></b-loading>-->

            <transition name="fade">
                <div v-if="!loading">

                    <div class="columns is-mobile">
                        <div class="column is-1"></div>
                        <div class="column is-10">
                            <section>
                                <b-tabs type="is-centered" size="is-medium" class="block" v-model="tabIndex" v-on:change="changeTab" expanded>
                                        <b-tab-item>

                                            <template slot="header">
                                                <b-icon icon="address-book"></b-icon>
                                                <span>1. Select list</span>
                                            </template>

                                            <div class="box">
                                                <div class="columns">
                                                    <div class="column is-2"></div>
                                                    <div class="column is-8">
                                                        <p>
                                                            Follow the steps to add a subscribe form to your website to capture contacts and leads for future campaigns.
                                                        </p>
                                                    </div>
                                                    <div class="column is-2"></div>
                                                </div>
                                            </div>

                                            <div class="box has-text-centered">
                                                <contactlist-selector v-model="list"></contactlist-selector>
                                            </div>

                                            <hr>
                                            <div>
                                                <p>
                                                    <button class="button is-primary is-medium is-pulled-right" :disabled="! list.id" @click="tabIndex=1" type="button">Next</button>
                                                </p>
                                                <br>
                                            </div>

                                        </b-tab-item>

                                        <b-tab-item :disabled="! list.id">
                                            <template slot="header">
                                                <b-icon icon="wrench"></b-icon>
                                                <span>2. Configure</span>
                                            </template>

                                            <div class="box">

                                                <div class="columns is-mobile">
                                                    <div class="column is-2"></div>
                                                    <div class="column is-8">

                                                        <label class="label-field">
                                                            Widget size
                                                        </label>

                                                        <b-dropdown v-model="widget.size" class="modal-dropdown-content" loading>

                                                            <button class="button is-light is-medium" slot="trigger">
                                                                <span>{{ widget.size.label }}</span>
                                                                <b-icon icon="caret-down"></b-icon>
                                                            </button>

                                                            <b-dropdown-item @click="widget.size = { label: 'Compact (540 x 320px)', width: 540, height: 320 }">
                                                                Compact (540 x 320px)
                                                            </b-dropdown-item>

                                                            <b-dropdown-item @click="widget.size = { label: 'Medium (600 x 320px)', width: 600, height: 320 }">
                                                                Medium (600 x 320px)
                                                            </b-dropdown-item>

                                                            <b-dropdown-item @click="widget.size = { label: 'Large (700 x 340px)', width: 700, height: 340 }">
                                                                Large (700 x 340px)
                                                            </b-dropdown-item>

                                                        </b-dropdown>

                                                        <br><br>

                                                        <label class="label-field">
                                                            Icon
                                                        </label>

                                                        <img :src="widget.icon_url" style="width:48px">

                                                        <b-field>
                                                            <b-input size="is-medium" v-model="widget.icon_url"></b-input>
                                                        </b-field>

                                                        <label class="label-field">
                                                            Widget Title
                                                        </label>
                                                        <b-field>
                                                            <b-input size="is-medium" maxlength="64" v-model="widget.title" required></b-input>
                                                        </b-field>

                                                        <label class="label-field">
                                                            Widget Description
                                                        </label>
                                                        <b-field>
                                                            <b-input size="is-medium" type="textarea" maxlength="300" v-model="widget.description" required></b-input>
                                                        </b-field>

                                                        <hr>

                                                        <label class="label-field">
                                                            Phone (placeholder)
                                                        </label>
                                                        <b-field>
                                                            <b-input size="is-medium" maxlength="64" v-model="widget.phone_ph" required></b-input>
                                                        </b-field>

                                                        <label class="label-field">
                                                            First Name (placeholder)
                                                        </label>
                                                        <b-field>
                                                            <b-input size="is-medium" maxlength="64" v-model="widget.first_name_ph" required></b-input>
                                                        </b-field>

                                                        <label class="label-field">
                                                            <b-switch v-model="lastNameEnabled"></b-switch>
                                                            Last Name (placeholder)
                                                        </label>
                                                        <b-field>
                                                            <b-input :disabled="! lastNameEnabled" size="is-medium" maxlength="64" v-model="widget.last_name_ph" required></b-input>
                                                        </b-field>

                                                        <label class="label-field">
                                                            Subscribe button label
                                                        </label>
                                                        <b-field>
                                                            <b-input size="is-medium" maxlength="64" v-model="widget.subscribe_btn" required></b-input>
                                                        </b-field>

                                                        <hr>

                                                        <label class="label-field">
                                                            Thank you message (text or enter url to redirect to a custom thank you page)
                                                        </label>
                                                        <b-field>
                                                            <b-input size="is-medium" maxlength="200" v-model="widget.thank_you_msg" required></b-input>
                                                        </b-field>

                                                    </div>
                                                    <div class="column is-1"></div>
                                                </div>
                                            </div>

                                            <div>
                                                <p>
                                                    <button class="button is-light is-medium is-pulled-left" @click="tabIndex=0">Back</button>&nbsp;
                                                </p>
                                            </div>

                                            <div class="field is-grouped is-pulled-right">
                                                <button class="button is-info is-medium" style="margin-left:6px" @click="tabIndex=2" type="button">Next (preview)</button>&nbsp;
                                            </div>

                                        </b-tab-item>

                                        <b-tab-item :disabled="! list.id">

                                        <template slot="header">
                                            <b-icon icon="eye"></b-icon>
                                            <span>3. Preview</span>
                                        </template>

                                        <div class="box">
                                            <label class="label-field">
                                                Widget Preview
                                            </label>
                                            <br><br>
                                            <div style="position: relative; padding: 25px">
                                                <span v-html="widget.code"></span>
                                            </div>
                                        </div>

                                        <div>
                                            <p>
                                                <button class="button is-light is-medium is-pulled-left" @click="tabIndex=1">Back</button>&nbsp;
                                            </p>
                                        </div>

                                        <div class="field is-grouped is-pulled-right">
                                            <button class="button is-info is-medium" style="margin-left:6px" @click="tabIndex=3" type="button">Next</button>&nbsp;
                                        </div>

                                    </b-tab-item>

                                    <b-tab-item :disabled="! list.id">

                                        <template slot="header">
                                            <b-icon icon="code"></b-icon>
                                            <span>4. Generate</span>
                                        </template>

                                        <div class="box">

                                            <div class="columns is-mobile">
                                                <div class="column is-1"></div>
                                                <div class="column is-10">

                                                    <label class="label-field">
                                                        Generated script
                                                    </label>

                                                    <p>
                                                        Copy and paste the following code into your website where you want to display the subscribe widget
                                                    </p>
                                                    <div class="field">
                                                        <div class="control">
                                                                <textarea rows="5" class="textarea is-info code-block" type="text" placeholder="Info textarea">
        {{ widget.code }}
                                                                </textarea>
                                                        </div>
                                                    </div>
                                                    <div class="has-text-centered">
                                                        <button class="button is-primary is-medium" v-clipboard:copy="widget.code" @click="$dialog.alert({ title: 'Info', message: 'Successfully copied widget script to clipboard.',confirmText: 'OK'})" type="button">Copy to clipboard</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="column is-1"></div>
                                        </div>

                                    </b-tab-item>

                                </b-tabs>

                            </section>
                        </div>
                        <div class="column is-1"></div>
                    </div>

                    <br>
                </div>
            </transition>

        </form>

    </section>
</template>

<script>

    export default {
        data() {
            return {
                data: [],
                form: {
                    list_id: null,
                    list_uuid: null
                },

                list : {
                    id: null,
                    name: null,
                    uuid: null,
                    contacts_count: null,
                    channel: {
                        send_campaign_recipient_limit: null
                    }
                },

                contactList: {},

                contactLists: [],
                contactlist_dropdown_lbl: 'Select',

                tabIndex: 0,

                checkboxFieldGroup: ['phone','first_name'],

                selectedContactCount: null,

                widget: {
                    code: '',
                    icon_url: `https://${this.$global.hostname}/widget/embed/assets/images/${this.$global.product_name.toLowerCase()}_logo_v1.png`,
                    size: {
                        label: 'Medium (600 x 320px)',
                        width: 600,
                        height: 320
                    },
                    title: 'Get Free Updates',
                    description: 'Start receiving product updates, tips and news from '+this.$global.product_name+' through Whatsapp',

                    phone_ph: 'Phone number',
                    first_name_ph: 'First name (optional)',
                    last_name_ph: 'Last name (optional)',
                    subscribe_btn: 'Subscribe',
                    thank_you_msg: 'Thank you for subscription!'
                },

                lastNameEnabled: false,

                loading: true

            }
        },
        computed: {
            generateScript() {
                // this.widget.code = `<iframe width="${this.widget.size.width}" height="${this.widget.size.height}" frameborder="0" src="${this.getCurrentUri()}/widget/embed/${this.$store.getters.userProfile.account.uuid}/${this.list.uuid}?title=${encodeURIComponent(this.widget.title)}&text=${encodeURIComponent(this.widget.description)}"></iframe>`;
                this.widget.code = `<div style="overflow: hidden; padding-top: 370px"><iframe style="border: 0; height: 100%; left: 0; position: absolute; top: 0; width: 100%;" frameborder="0" src="${this.getCurrentUri()}/widget/embed/${this.$store.getters.userProfile.account.uuid}/${this.list.uuid}?icon_url=${encodeURIComponent(this.widget.icon_url)}&title=${encodeURIComponent(this.widget.title)}&text=${encodeURIComponent(this.widget.description)}&phone_ph=${encodeURIComponent(this.widget.phone_ph)}&first_name_ph=${encodeURIComponent(this.widget.first_name_ph)}&last_name_enabled=${this.lastNameEnabled}&last_name_ph=${encodeURIComponent(this.widget.last_name_ph)}&subscribe_btn=${encodeURIComponent(this.widget.subscribe_btn)}&thank_you_msg=${encodeURIComponent(this.widget.thank_you_msg)}"></iframe></div>`;
            }
        },
        methods: {
            getCurrentUri: function () {
                return window.location.protocol + '//' + window.location.host + '';
            },

            changeTab(idx) {
                // if(idx === 2) {
                    // preview
                    this.generateScript();
                // }
            }

        },
        mounted() {
            this.loading = false;
        }
    }
</script>

<template>
    <section>
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <h1 class="is-pulled-left">Edit coupon code</h1>
                </div>
            </div>

            <!-- Right side -->
            <div class="level-right">
            </div>

        </nav>
        <hr>

        <form @submit.prevent="">

            <b-loading :active.sync="loading"></b-loading>

            <div class="columns is-mobile">
                <div class="column is-1"></div>
                <div class="column is-10">
                    <section>
                        <b-tabs size="is-medium" class="block" v-show="!loading" expanded>
                                <b-tab-item>

                                    <template slot="header">
                                        <b-icon icon="user-plus"></b-icon>
                                        <span> Details</span>
                                    </template>

                                    <div class="box">

                                        <div class="columns is-mobile">
                                            <div class="column is-2"></div>
                                            <div class="column is-8">

                                                <label class="label-field">
                                                    Enabled
                                                </label>

                                                <b-field>
                                                    <b-switch v-model="form.enabled" true-value="1" false-value="0"></b-switch>
                                                </b-field>

                                                <label class="label-field">
                                                    Code
                                                </label>

                                                <b-field>
                                                    <b-input size="is-medium" minlength="4" maxlength="32" v-model="form.code"></b-input>
                                                </b-field>

                                                <label class="label-field">
                                                    Type
                                                </label>

                                                <b-field>
                                                    <b-dropdown aria-role="list">
                                                        <button class="button is-light" slot="trigger" @click.native.stop>
                                                            <span>{{ form.type }}</span>
                                                            <b-icon icon="caret-down"></b-icon>
                                                        </button>

                                                        <b-dropdown-item aria-role="listitem" @click="form.type = 'AMOUNT'">Amount</b-dropdown-item>
                                                        <b-dropdown-item aria-role="listitem" @click="form.type = 'PCT'">PCT</b-dropdown-item>
                                                    </b-dropdown>
                                                </b-field>

                                                <label class="label-field">
                                                    Discount value ({{ form.type === 'AMOUNT' ? 'Euro' : 'Percentage' }})
                                                </label>

                                                <b-field>
                                                    <b-input size="is-medium" v-model="form.val" required></b-input>
                                                </b-field>

                                                <label class="label-field">
                                                    Schedule (One off or Recurring discount)
                                                </label>

                                                <b-field>
                                                    <b-dropdown aria-role="list">
                                                        <button class="button is-light" slot="trigger" @click.native.stop>
                                                            <span>{{ form.schedule }}</span>
                                                            <b-icon icon="caret-down"></b-icon>
                                                        </button>

                                                        <b-dropdown-item aria-role="listitem" @click="form.schedule = 'ONEOFF'">ONEOFF</b-dropdown-item>
                                                        <b-dropdown-item aria-role="listitem" @click="form.schedule = 'RECURRING'">RECURRING</b-dropdown-item>
                                                    </b-dropdown>
                                                </b-field>

                                                <label class="label-field">
                                                    Enable Expiry date
                                                </label>

                                                <b-switch v-model="expiryDateSet" :true-value="true" :false-value="false" @input="expiryDateChange"></b-switch>

                                                <template v-if="expiryDateSet">
                                                    <br><br>
                                                    <b-field>
                                                        <b-datepicker
                                                            v-model="form.expiry_date"
                                                            placeholder="Type or select a date..."
                                                            icon="calendar-alt"
                                                            size="is-medium"
                                                            :disabled="! expiryDateSet"
                                                            :readonly="false">

                                                            <button class="button is-primary"
                                                                    @click="form.expiry_date = new Date()">
                                                                <b-icon icon="calendar"></b-icon>
                                                                <span>Today</span>
                                                            </button>

                                                            <button class="button is-danger"
                                                                    @click="form.expiry_date = null">
                                                                <b-icon icon="times-circle"></b-icon>
                                                                <span>Clear</span>
                                                            </button>
                                                        </b-datepicker>
                                                    </b-field>

                                                </template>

                                                <label class="label-field">
                                                    Apply to subscription plans
                                                </label>

                                                <b-switch v-model="form.eligible_for_subscription" true-value="1" false-value="0"></b-switch>

                                                <label class="label-field">
                                                    Apply to Pay As You Go plans
                                                </label>

                                                <b-switch v-model="form.eligible_for_payasyougo" true-value="1" false-value="0"></b-switch>

                                            </div>
                                            <div class="column is-2"></div>
                                        </div>

                                        <div class="has-text-centered">
                                            <button class="button is-primary is-medium" @click="update" type="button" v-bind:class="{'is-loading': submitting}">Update</button>
                                        </div>
                                    </div>

                                </b-tab-item>

                        </b-tabs>

                    </section>
                </div>
                <div class="column is-1"></div>
            </div>

            <br>

        </form>

    </section>
</template>

<script>
    export default {
        data() {
            return {
                data: {
                    id: this.$route.params.couponCodeId
                },
                form: {
                    code: '',
                    enabled: null,
                    expiry_date: null,
                    type: '',
                    val: 0,
                    eligible_for_subscription: null,
                    eligible_for_payasyougo: null
                },
                expiryDateSet: false,

                submitting: false,
                loading: true
            }
        },
        computed: {
        },
        methods: {
            /*
             * Load async data
             */
            loadAsyncData() {

                this.loading = true;

                const params = {
                    id: this.data.id
                };

                this.$store.dispatch('fetchCouponCode', params).then((data) => {

                    // update view with current campaign settings
                    let result = data.result;

                    if(result.expiry_date) this.expiryDateSet = true

                    this.form = {
                        enabled: result.enabled,
                        expiry_date: this.$moment.utc(result.expiry_date).toDate(),
                        code: result.code,
                        val: result.val,
                        type: result.type,
                        schedule: result.schedule,
                        eligible_for_subscription: result.eligible_for_subscription,
                        eligible_for_payasyougo: result.eligible_for_payasyougo
                    };

                })
                .catch((error) => {
                    this.data = [];
                    this.total = 0;
                    this.loading = false;
                    throw error
                })
                .finally(() => {
                    this.submitting = false;
                    this.loading = false;
                });

            },

            update() {

                this.submitting = true;

                const params = this.form;
                params.id = this.data.id;

                this.$store.dispatch('updateCouponCode', params).then((data) => {

                    // push notification
                    const toast = {
                        message: `Updated coupon code successfully!`,
                        type: 'is-success'
                    };
                    this.$buefy.toast.open(toast);

                    // push to view
                    this.$router.go(-1);
                })
                .catch((err) => {

                    this.$buefy.toast.open({
                        message: 'An error has occurred! '+this.$utils.getHumanErrorMessage(err),
                        type: 'is-danger',
                        duration: 5000
                    });
                })
                .finally(() => {
                    this.submitting = false;
                });

            },

            expiryDateChange(enabled) {
                if(! enabled) {
                    this.form.expiry_date = null;
                } else if(enabled && ! this.form.expiry_date) {
                    this.form.expiry_date = new Date();
                }
            }
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>

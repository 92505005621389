<template>
    <div>
        <div class="columns is-mobile" style="margin-top:50px">
            <div class="column is-half is-offset-one-quarter">
                <div class="box">
                    <h1>Create your password</h1>
                    <br>
                    <form @submit.prevent="register">
                        <b-field v-show="! $route.query.email" label="Email">
                            <b-input v-model="form.email" name="email" size="is-large" ref="email" type="email" placeholder="Your Email address" required></b-input>
                        </b-field>

                        <b-field label="Password">
                            <b-input v-model="form.password" name="password" ref="password" size="is-large" type="password" value="" placeholder="Your password here" minlength="8" required password-reveal></b-input>
                        </b-field>

                        <b-field label="Password confirmation">
                            <b-input v-model="form.password_confirmation" name="password_confirmation" ref="password_confirmation" size="is-large" type="password" value="" placeholder="Your password here" minlength="8" required password-reveal></b-input>
                        </b-field>

                        <div class="has-text-centered" style="margin-top:40px">
                            <button class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}" type="submit">Set password</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PasswordResetView',
        data() {
            return {
                form: {
                    email: this.$route.query.email.replace(' ','+'), //  make sure that email address containing a '+' will not throw error
                    password: "",
                    password_confirmation: "",
                    token: this.$route.params.token

                },
                submitting: false
            }
        },
        methods: {
            register() {

                this.submitting = true;

                this.$store.dispatch("resetPassword", {
                    email: this.form.email,
                    password: this.form.password,
                    password_confirmation: this.form.password_confirmation,
                    token: this.form.token

                }).then(() => {
                    this.submitting = false;
                    this.$router.push({ name: 'login', params: { auto_login: true, email: this.form.email, password: this.form.password } });

                    this.$buefy.toast.open({
                        message: 'Password has been set!',
                        type: 'is-info',
                        duration: 5000
                    });

                    // workaround stylesheet mess up
                    // setTimeout(() => {
                    //     window.location.href= '/login?new&email='+this.form.email;
                    // },1000);

                }).catch((err) => {
                    this.submitting = false;
                    this.$buefy.toast.open({
                        message: 'Sorry, an error occurred on password reset: "' + err.custom.message + '"',
                        type: 'is-danger',
                        duration: 5000
                    });
                });
            }
        },
        mounted() {
            if(this.form.email) {

                // this.$refs.password.focus();
            } else {
                // this.$refs.email.focus();
            }
        }
    }
</script>

import Vue from 'vue';
import Vuex from 'vuex';
// import axios from 'axios';

import { Toast } from 'buefy/dist/components/toast'

import call from '../helpers/main';
import getCustomErrorMessage from '../helpers/error';

import api from '../api';
import moment from 'moment';

import { createFlashStore } from 'vuex-flash';

const LOGIN = "LOGIN";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_REVALIDATE_SUCCESS = "LOGIN_REVALIDATE_SUCCESS";
const LOGOUT = "LOGOUT";
const IMPERSONATE = "IMPERSONATE";
const DEIMPERSONATE = "DEIMPERSONATE";
const VIEW_CHANGED = "VIEW_CHANGED";

const AUTH_PROFILE_SUCCESS = "AUTH_PROFILE_SUCCESS";
const FINISH_ONBOARDING = "FINISH_ONBOARDING";

Vue.use(Vuex);

const mainStore = new Vuex.Store({
    state: {
        auth: {
            access_token: localStorage.getItem('access_token'),
            refresh_token: localStorage.getItem('refresh_token')
        },
        account: {
            plan: null,
            state: null,
            expired: null,
            config: null,
            onboarding: (localStorage.getItem('account_onboarding') === 'true')
        },
        userProfile: localStorage.getItem('userProfile'),
        isLoggedIn: (localStorage.getItem('isLoggedIn') === 'true'),
        impersonate_user_id: localStorage.getItem('impersonate_user_id'),
        view: null
},
    plugins: [
    ],
    mutations: {
        [LOGIN] (state) {
            state.pending = true;
        },
        [LOGIN_SUCCESS] (state) {
            state.pending = false;

            localStorage.removeItem('impersonate_user_id');
            localStorage.removeItem('account_onboarding');
        },
        [AUTH_PROFILE_SUCCESS] (state, userProfile) {
            state.userProfile = userProfile;

            state.account.onboarding = (! localStorage.getItem('account_onboarding') && userProfile.account.login_count === 1);
            state.account.state = userProfile.account.state;
            state.account.config = userProfile.account.account_config;
            state.account.expired = (moment(userProfile.account.expires_at) < moment());
            state.account.plan = userProfile.account.plan;
            state.account.uuid = userProfile.account.uuid;

            state.account.billingDetails = JSON.parse(userProfile.account.billing_details.current_cycle_credits_json) || null;

            state.isLoggedIn = true;

            //FS identification
            try {
                // fullstory installed and it's not impersonating

                if (typeof FS !== 'undefined' && ! state.impersonate_user_id) {
                    // This is an example script - don't forget to change it!
                    FS.identify(userProfile.account.name + '_' + userProfile.email, {
                        displayName: userProfile.account.name + ' - ' + userProfile.first_name,
                        email: userProfile.email,
                        // TODO: Add your own custom user variables here, details at
                        // http://help.fullstory.com/develop-js/setuservars
                        // planreviewsWritten_int: 14
                    });
                }
            } catch(error) { console.log('FS identify error') }

        },
        [LOGIN_REVALIDATE_SUCCESS](state) {
            state.isLoggedIn = true;
        },
        [LOGOUT](state) {
            state.isLoggedIn = false;

            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('userProfile');

            state.userProfile = null;

            state.auth = {
                access_token: null,
                refresh_token: null
            };
        },
        [IMPERSONATE](state, id) {
            state.impersonate_user_id = id;
        },
        [DEIMPERSONATE](state) {
            state.impersonate_user_id = null;
        },
        [FINISH_ONBOARDING](state) {
            state.account_onboarding = false;
        },
        [VIEW_CHANGED](state, params) {
            state.view = params;
        }
    },
    getters: {
        userProfile: state => {
            return state.userProfile
        },
        isLoggedIn: state => {
            return state.isLoggedIn
        },
        isExpired: state => {
            return state.account.expired
        },
        accessToken: state => {
            return state.auth.access_token
        },
        refreshToken: state => {
            return state.auth.refresh_token
        },
        impersonateUserId: state => {
            return state.impersonate_user_id || null
        },
        enableAccountOnboarding: state => {
            return state.account.onboarding;
        },
        currentView: state => {
            return state.view || null
        },
        inTrial: state => {
            return state.account.plan === 'TRIAL' || null
        }
    },
    actions: {

        login({ commit }, data) {

            commit(LOGIN); // show spinner

            return new Promise((resolve, reject) => {

                call('post', `${api.login}`, data)
                    .then((response) => {
                        // handle response
                        const result = response.data.data.result;

                        localStorage.setItem("isLoggedIn", true);
                        localStorage.setItem('id', result.id);
                        localStorage.setItem("access_token", result.oath.access_token);
                        localStorage.setItem("refresh_token", result.oath.refresh_token);

                        this.state.auth.access_token = result.oath.access_token;
                        this.state.auth.refresh_token = result.oath.refresh_token;

                        // commit(AUTH_PROFILE_SUCCESS, response.data.data.result);
                        commit(LOGIN_SUCCESS);

                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },
        logout({ commit }) {

            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");

            commit(LOGOUT);
        },

        finishOnboarding({ commit }) {

            commit(FINISH_ONBOARDING);
            localStorage.setItem("account_onboarding", false);
        },
        forgotPassword({}, data) {

            return new Promise((resolve, reject) => {

                call('post', `${api.forgotPassword}`, data)
                    .then((response) => {
                        // handle response

                        resolve();
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        resetPassword({}, data) {

            return new Promise((resolve, reject) => {

                call('post', `${api.resetPassword}`, data)
                    .then((response) => {
                        // handle response
                        resolve();

                    }).catch((err) => {
                    // handle error
                    reject({
                        obj: err,
                        custom: getCustomErrorMessage(err)
                    });
                })

            })
        },

        refreshToken({ commit }, data) {

            return new Promise((resolve, reject) => {

                data.token = state.refresh_token;

                call('post', `${api.refreshToken}`, data)
                    .then((response) => {
                        // handle response
                        localStorage.setItem("isLoggedIn", true);
                        localStorage.setItem("access_token", response.data.data.result.oath.access_token);
                        localStorage.setItem("refresh_token", response.data.data.result.oath.refresh_token);
                        commit(LOGIN_REVALIDATE_SUCCESS);

                        resolve();
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        unauthorized({ commit, dispatch }) {

            //todo: trigger other action instead instead of reimplementing it twice
            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");

            Toast.open({
                message: 'Your user session has expired! Redirecting to login screen...',
                type: 'is-danger',
                duration: 4000
            });

            commit(LOGOUT);

            setTimeout(() => {
                location.href = '/login';
            },1500);

        },

        register({}, data) {

            return new Promise((resolve, reject) => {

                call('post', `${api.register}`, data)
                    .then((response) => {
                        // handle response

                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        activateAccount({}, data) {

            return new Promise((resolve, reject) => {

                call('post', `${api.activate}`, data)
                    .then((response) => {
                        // handle response

                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        /** super admin */
        fetchAccounts({}, params) {
            return new Promise((resolve, reject) => {

                call('get', `${api.sa.account}?${params}`, {})
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        createAccount({}, data) {

            return new Promise((resolve, reject) => {

                call('post', `${api.sa.account}`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        fetchAccount({}, params) {
            return new Promise((resolve, reject) => {

                call('get', `${api.sa.account}/${params.id}`, {})
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        approveAccount({}, data) {

            return new Promise((resolve, reject) => {

                call('put', `${api.sa.account}/${data.id}/approve`, data)
                    .then((response) => {
                        // handle response

                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        updateAccount({}, data) {

            return new Promise((resolve, reject) => {

                call('put', `${api.sa.account}/${data.id}`, data)
                    .then((response) => {
                        // handle response

                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject({
                        obj: err,
                        custom: getCustomErrorMessage(err)
                    });
                })

            })
        },

        updateSubscription({}, data) {

            return new Promise((resolve, reject) => {

                call('put', `${api.admin.billingSubscription}`, data)
                    .then((response) => {
                        // handle response

                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject({
                        obj: err,
                        custom: getCustomErrorMessage(err)
                    });
                })

            })
        },

        cancelSubscription({}, data) {

            return new Promise((resolve, reject) => {

                call('delete', `${api.admin.billingSubscription}`, data)
                    .then((response) => {
                        // handle response

                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject({
                        obj: err,
                        custom: getCustomErrorMessage(err)
                    });
                })

            })
        },

        fetchUser({}, params) {
            return new Promise((resolve, reject) => {

                call('get',`${api.user}/${params.id}`, {})
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        createUser({}, data) {

            return new Promise((resolve, reject) => {

                call('post', `${api.user}`, data)
                    .then((response) => {
                        // handle response

                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject({
                        obj: err,
                        custom: getCustomErrorMessage(err)
                    });
                })

            })
        },

        updateUser({}, data) {

            return new Promise((resolve, reject) => {

                call('put', `${api.user}/${data.id}`, data)
                    .then((response) => {
                        // handle response

                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject({
                        obj: err,
                        custom: getCustomErrorMessage(err)
                    });
                })

            })
        },

        deleteUser({}, params) {

            return new Promise((resolve, reject) => {

                call('delete', `${api.user}/${params.id}`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        impersonateUser({ commit, dispatch }, params) {

            commit(IMPERSONATE, params.id); // show spinner

            localStorage.setItem("impersonate_user_id", parseInt(params.id));
            dispatch('getUserProfile');

        },

        deImpersonateUser({ commit, dispatch }) {

            commit(DEIMPERSONATE);

            localStorage.removeItem("impersonate_user_id");
            dispatch('getUserProfile');

        },

        regenerateSecretKey({}, data) {

            return new Promise((resolve, reject) => {

                call('put', `${api.sa.account}/${data.id}/regenerateSecretKey`, data)
                    .then((response) => {
                        // handle response

                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        deleteAccount({}, data) {

            return new Promise((resolve, reject) => {

                call('delete', `${api.sa.account}/${data.id}`, data)
                    .then((response) => {
                        // handle response

                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        fetchSystemInfo({}, params) {
            return new Promise((resolve, reject) => {

                call('get', `${api.sa.system}/getInfo`, {})
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        sendTestEmail({}, data) {
            return new Promise((resolve, reject) => {

                call('post', `${api.sa.system}/sendTestEmail`, data)
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        sendTestMolliePing({}, data) { // send mollie api test (check existence and if api authentication status)
            return new Promise((resolve, reject) => {

                call('get', `${api.sa.system}/sendTestMolliePing`, data)
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        sendTestMBPing({}, data) { // send moneybird api test (check existence and if api authentication status
            return new Promise((resolve, reject) => {

                call('post', `${api.sa.system}/sendTestMBPing`, data)
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        validatePhoneNumber({}, data) {
            return new Promise((resolve, reject) => {

                call('get', `${api.sa.system}/validatePhoneNumber/${data.phone}`, data)
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        createPaymentFromInvoiceId({}, params) {
            return new Promise((resolve, reject) => {

                call('post', `${api.sa.createPaymentFromInvoiceId}/${params.id}`, params)
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        /** campaign */

        fetchCampaigns({}, params) {
            return new Promise((resolve, reject) => {

                call('get', `${api.campaign}?${params}`, {})
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },
        fetchCampaign({}, params) {
            return new Promise((resolve, reject) => {

                call('get', `${api.campaign}/${params.id}`, {})
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        createCampaign({}, data) {

            return new Promise((resolve, reject) => {

                call('post', `${api.campaign}`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        updateCampaign({}, data) {

            return new Promise((resolve, reject) => {
                call('put', `${api.campaign}/${data.id}`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error

                    reject(err);

                })
            })
        },

        startCampaign({}, data) {

            return new Promise((resolve, reject) => {

                call('put', `${api.campaign}/`+data.id+`/start`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((response,err) => {
                    // handle error
                    reject(response);
                })
            })
        },

        scheduleCampaign({}, data) {

            return new Promise((resolve, reject) => {

                call('put', `${api.campaign}/`+data.id+`/schedule`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((response,err) => {
                    // handle error
                    reject(response);
                })
            })
        },

        duplicateCampaign({}, data) {

            return new Promise((resolve, reject) => {

                call('put', `${api.campaign}/${data.id}/duplicate`, data).then((response) => {
                    // handle response
                    // console.log(response.data.data);
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        cancelCampaign({}, data) {

            return new Promise((resolve, reject) => {

                call('put', `${api.campaign}/${data.id}/cancel`, data).then((response) => {
                    // handle response
                    // console.log(response.data.data);
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        archiveCampaign({}, params) {

            return new Promise((resolve, reject) => {

                call('put', `${api.campaign}/${params.id}/archive`, {
                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        deleteCampaign({}, params) {

            return new Promise((resolve, reject) => {

                call('delete', `${api.campaign}/${params.id}`, {
                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        deleteCampaigns({}, params) {

            return new Promise((resolve, reject) => {

                const ids = params.ids.join(',');

                call('delete', `${api.campaign}/${ids}`, {
                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        createChannel({}, data) {

            return new Promise((resolve, reject) => {

                call('post', `${api.channel}`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        requestChannel({}, data) {

            return new Promise((resolve, reject) => {

                call('post', `${api.channel}/request`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        // connectChannel({}, data) {
        //
        //     return new Promise((resolve, reject) => {
        //
        //         call('post', `${api.channel}/connect`, data).then((response) => {
        //             resolve(response.data.data);
        //         }).catch((err) => {
        //             // handle error
        //             reject(err);
        //         })
        //     })
        // },

        requestUpgradeSMSLimit({}, data) {

            return new Promise((resolve, reject) => {

                call('post', `${api.channel}/requestUpgradeSMSLimit`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        upgradeSMSLimit({}, data) {

            return new Promise((resolve, reject) => {

                call('post', `${api.channel}/upgradeSMSLimit`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        fetchChannels({}, params) {

            return new Promise((resolve, reject) => {

                call('get', `${api.channel}?${params}`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        fetchChannel({}, params) {

            return new Promise((resolve, reject) => {

                call('get', `${api.channel}/${params.id}`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        fetchQRCode({}, params) {

            return new Promise((resolve, reject) => {

                call('get', `${api.channel}/${params.id}/getQRCode`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        updateChannel({}, data) {

            return new Promise((resolve, reject) => {

                call('put', `${api.channel}/${data.id}`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        changeChannel({}, data) {

            return new Promise((resolve, reject) => {

                call('post', `${api.contactList}/${data.id}/changeChannel`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        resetChannel({}, params) {

            return new Promise((resolve, reject) => {

                call('post', `${api.channel}/${params.id}/reset`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        deleteChannel({}, params) {

            return new Promise((resolve, reject) => {

                call('delete', `${api.channel}/${params.id}`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        fetchContact({}, params) {

            return new Promise((resolve, reject) => {

                call('get', `${api.contact}/${params.id}`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        fetchContacts({}, params) {

            return new Promise((resolve, reject) => {

                call('get', `${api.contact}?${params}`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        downloadContactsExport({}, params) {

            return new Promise((resolve, reject) => {

                call('get', `${api.exportContacts}/${params.id}`, {
                    download: true,
                    downloadFileName: 'export-contacts.csv'
                }).then((response) => {
                    // handle response
                    resolve();
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        updateContact({}, data) {

            return new Promise((resolve, reject) => {

                call('put', `${api.contact}/${data.id}`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        updateContacts({}, data) {

            return new Promise((resolve, reject) => {

                call('put', `${api.contact}`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        subscribeContacts({}, params) {

            return new Promise((resolve, reject) => {

                call('post', `${api.contactList}/${params.list_id}/subscribeContact`, {
                    contact_id: params.contact_id
                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        unsubscribeContacts({}, params) {

            return new Promise((resolve, reject) => {

                call('post', `${api.contactList}/${params.list_id}/unsubscribeContact`, {
                    list_id: params.list_id,
                    contact_id: params.contact_id
                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        unsubscribeContact({}, params) {

            return new Promise((resolve, reject) => {

                call('put', `${api.contact}/${params.id}/unsubscribe/${params.listId}`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        deleteContact({}, params) {

            return new Promise((resolve, reject) => {

                call('delete', `${api.contact}/${params.id}`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        deleteContacts({}, params) {

            return new Promise((resolve, reject) => {

                const ids = params.ids.join(',');

                call('delete', `${api.contact}/${ids}`, {
                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        fetchContactLists({}, params) {

            return new Promise((resolve, reject) => {

                call('get', `${api.contactList}?${params}`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        fetchContactList({}, params) {

            return new Promise((resolve, reject) => {

                call('get', `${api.contactList}/${params.id}`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        createContactList({}, data) {

            return new Promise((resolve, reject) => {

                call('post', `${api.contactList}`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        updateContactList({}, data) {

            return new Promise((resolve, reject) => {

                call('put', `${api.contactList}/${data.id}`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject({
                        obj: err,
                        custom: getCustomErrorMessage(err)
                    });
                })
            })
        },

        importContactList({}, data) {

            return new Promise((resolve, reject) => {

                call('post', `${api.contactList}/${data.id}/import`, data.formData).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject({
                        obj: err,
                        custom: getCustomErrorMessage(err)
                    });
                })
            })
        },

        duplicateContactList({}, params) {

            return new Promise((resolve, reject) => {

                call('post', `${api.contactList}/${params.id}/duplicate`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        deleteContactList({}, params) {

            return new Promise((resolve, reject) => {

                call('delete', `${api.contactList}/${params.id}`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        deleteContactLists({}, params) {

            return new Promise((resolve, reject) => {

                const ids = params.ids.join(',');

                call('delete', `${api.contactList}/${ids}`, {
                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        fetchEventLogs({}, params) {

            return new Promise((resolve, reject) => {

                call('get', `${api.eventLog}?${params}`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        fetchTemplates({}, params) {

            return new Promise((resolve, reject) => {

                call('get', `${api.template}?${params}`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        fetchTemplate({}, params) {

            return new Promise((resolve, reject) => {

                call('get', `${api.template}/${params.id}?${params.urlParams}`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        createTemplate({}, data) {

            return new Promise((resolve, reject) => {

                call('post', `${api.template}`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        updateTemplate({}, data) {

            return new Promise((resolve, reject) => {

                call('put', `${api.template}/${data.id}`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        deleteTemplate({}, params) {

            return new Promise((resolve, reject) => {

                call('delete', `${api.template}/${params.id}`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        deleteTemplates({}, params) {

            return new Promise((resolve, reject) => {

                const ids = params.ids.join(',');

                call('delete', `${api.template}/${ids}`, {
                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        sendTestMessage({}, data) {

            return new Promise((resolve, reject) => {

                call('post', `${api.message}`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        fetchStats({}, params) {

            return new Promise((resolve, reject) => {

                call('get', `${api.stats}/campaign`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        getUserProfile({ commit }, params) {

            return new Promise((resolve, reject) => {

                let id = localStorage.getItem('id');

                if(this.state.impersonate_user_id) {
                    id = this.state.impersonate_user_id;
                }

                call('get', `${api.user}/${id}`, {
                }).then((response) => {
                    const profile = response.data.data.result;

                    commit(AUTH_PROFILE_SUCCESS, profile);
                    resolve(response.data.data);

                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        updateUserProfile({ }, params) {

            return new Promise((resolve, reject) => {

                const id = localStorage.getItem('id');

                call('put', `${api.user}/${id}`, params).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        changeUserPassword({ }, params) {

            return new Promise((resolve, reject) => {

                const id = localStorage.getItem('id');

                call('put', `${api.user}/${id}/changeUserPassword`, params).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        getSubscribeLists({ }, params) {

            return new Promise((resolve, reject) => {

                call('get', `${api.dashboard}/getTPSubscriptions`, params).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        setSubscribeList({ }, params) {

            return new Promise((resolve, reject) => {

                call('put', `${api.dashboard}/setTPSubscription`, params).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })
            })
        },

        setView({ commit }, params ) {
            commit(VIEW_CHANGED, params);
        },

        fetchPlans({}) {
            return new Promise((resolve, reject) => {

                call('get', `${api.admin.plan}`, {})
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle errorsaveUpgradeSettings
                    reject(err);
                })

            })
        },

        fetchUsageRates({}) {
            return new Promise((resolve, reject) => {

                call('get', `${api.admin.billingUsageRates}`, {})
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        fetchBillingInfo({}) {
            return new Promise((resolve, reject) => {

                call('get', `${api.admin.billingInfo}`, {})
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },
        fetchbillingOrder({}, params) {
            return new Promise((resolve, reject) => {

                call('get', `${api.admin.billingOrder}`, params)
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },
        deleteBillingOrder({}, params) {
            return new Promise((resolve, reject) => {

                call('delete', `${api.admin.billingOrder}/${params.id}`, {})
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },
        downloadInvoice({}, params) {

            return new Promise((resolve, reject) => {

                call('get', `${api.admin.billingOrderExport}/${params.id}`, {
                    download: true,
                    downloadFileName: `invoice-${params.id}.pdf`
                }).then((response) => {
                    // handle response
                    resolve();
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },
        fetchBillingAddress({}) {
            return new Promise((resolve, reject) => {

                call('get', `${api.admin.billingAddress}`, {})
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },
        fetchOrder({}, params) {
            return new Promise((resolve, reject) => {

                call('get', `${api.admin.billingOrder}/${params.id}`, {})
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        updateBillingAddress({}, params) {
            return new Promise((resolve, reject) => {

                call('put', `${api.admin.billingAddress}`, params)
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        submitOrder({}, params) {
            return new Promise((resolve, reject) => {

                call('post', `${api.admin.billingOrder}`, params)
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        makePayment({}, params) {
            return new Promise((resolve, reject) => {

                call('put', `${api.admin.billingOrder}/${params.id}/makePayment`, params)
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        saveUpgradeSettings({}, params) {
            return new Promise((resolve, reject) => {

                call('put', `${api.admin.billingSaveUpgradeSettings}`, params)
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        fetchCouponCode({}, params) {

            return new Promise((resolve, reject) => {

                call('get', `${api.admin.billingCouponCode}/${params.id}`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        fetchCouponCodes({}, params) {

            return new Promise((resolve, reject) => {

                call('get', `${api.admin.billingCouponCode}`, {

                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        checkCouponCode({}, params) {
            return new Promise((resolve, reject) => {

                call('post', `${api.admin.billingCouponCode}/check`, params)
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        createCouponCode({}, params) {
            return new Promise((resolve, reject) => {

                call('post', `${api.admin.billingCouponCode}`, params)
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        updateCouponCode({}, data) {

            return new Promise((resolve, reject) => {

                call('put', `${api.admin.billingCouponCode}/${data.id}`, data).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject({
                        obj: err,
                        custom: getCustomErrorMessage(err)
                    });
                })
            })
        },

        deleteCouponCode({}, params) {

            return new Promise((resolve, reject) => {

                call('delete', `${api.admin.billingCouponCode}/${params.id}`, {
                }).then((response) => {
                    // handle response
                    resolve(response.data.data);
                }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

        sendEvent({}, params) {
            return new Promise((resolve, reject) => {

                call('post', `${api.event}`, params)
                    .then((response) => {
                        // handle response
                        resolve(response.data.data);
                    }).catch((err) => {
                    // handle error
                    reject(err);
                })

            })
        },

    }

});

export default mainStore;


const ChangeChannelForm = {
    data() {
        return {
            data: {
            },
            form: {
                id: this.$route.params.listId,
                origin: null,
                channel_id: null
            },

            submitting: false,
            loading: true

        }
    },
    props: [
        'currentChannelId'
    ],
    template: `
            <form @submit.prevent="">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Change channel</p>

                    </header>
                    <section class="modal-card-body">
                       <div class="columns">
                        
                            <div class="column is-1">
                                <!--<b-icon size="is-medium" icon="list-ol"></b-icon>-->
                            </div>
                            <div class="column is-10">
                                <b-message type="is-danger">
                           <b>Important: Changing the channel could reset the opt-in state of the contacts in this contact list.</b>
                        </b-message>
                                
                                <channel-selector :allowClickInactiveChannels="true" v-bind:channel-id="form.channel_id" v-on:change="setOrigin"></channel-selector>
                                <br>
                                <br>
                                <br>
                                <br>
                                <br>
                                <br>
                            </div>
                            <div class="column is-1">
                              
                            </div>
                        </div>
                    </section>
                    <footer class="modal-card-foot">
                    <div class="col-md-12 text-center">
                        <button class="button is-medium" type="button" @click="$parent.close()">Cancel</button>
                        <button class="button is-danger is-medium" v-bind:class="{'is-loading': submitting}" @click="handleSubmit" :disabled="! form.channel_id || currentChannelId === form.channel_id">Update</button>
                        </div>
                    </footer>
                </div>
            </form>
        `,
    methods: {
        setOrigin(obj) {
            this.form.origin = obj.origin;
            this.form.channel_id = obj.id;
        },
        handleSubmit() {

            this.submitting = true;

            // Send data to the server or update your stores and such.

            let params = {
                id: this.form.id,
                channel_id: this.form.channel_id
            };

            this.$store.dispatch('changeChannel', params).then((data) => {

                this.$buefy.toast.open({
                    message: 'Changed channel successfully',
                    type: 'is-success'
                });

                // alert('hi');
                this.$parent.close();

                this.$router.push(`/contactlists`);

            }).catch((err) => {
                this.$buefy.toast.open({
                    message: 'Error occurred while changing channel: '+err,
                    type: 'is-danger'
                });
                // handle error
            })
            .finally(() => {
                this.submitting = false;
            });
        }
    }, mounted() {
    }
}

export default ChangeChannelForm;

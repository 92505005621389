<template>

    <form @submit.prevent="handleSubmit">
        <b-loading :active.sync="loading"></b-loading>
        <div v-if="show" class="columns is-mobile">
            <div class="column is-2"></div>
            <div class="column is-8">

                <div class="box cc-list">
                    <div class="field">
                        <b-radio v-model="paymentMethod"
                                 native-value="CREDITCARD">
                            Credit card
                        </b-radio>
                    </div>

                    <div class="field">
                        <b-radio v-model="paymentMethod"
                                 native-value="PAYPAL">
                            Paypal
                        </b-radio>
                    </div>

                    <div class="field">
                        <b-radio v-model="paymentMethod"
                                 native-value="IDEAL">
                            Ideal
                        </b-radio>
                    </div>
                </div>


                <!--<div class="has-text-centered">-->
                    <!--<button class="button is-primary is-medium" type="submit" v-bind:class="{'is-loading': submitting}">Update details</button>-->
                <!--</div>-->

            </div>
            <div class="column is-2"></div>
        </div>


    </form>

</template>

<script>

    export default {
        props: ['value'],
        data: function () {
            return {
                paymentMethod: this.value,
                submitting: false,
                loading: false,
                show: true
            }
        },
        watch: {
            // whenever changes, this function will run
            paymentMethod: function (newValue, oldValue) {

                this.$emit('input', newValue);
            }
        },
        methods: {

            handleSubmit() {

                this.submitting = true;

                // let params = this.form;
                //
                // params.country_code = this.countryObj.id;
                // params.country = this.countryObj.label;
                //
                // this.$store.dispatch('updateBillingDetails', params).then((data) => {
                //
                //     this.$buefy.toast.open({
                //         message: 'Billing details saved!',
                //         type: 'is-success'
                //     });
                //
                // })
                // .catch((err) => {
                //
                //     this.$buefy.toast.open({
                //         message: this.$utils.getHumanErrorMessage(err),
                //         type: 'is-danger'
                //     });
                // })
                // .finally(() => {
                //     this.submitting = false;
                // });

            },
            loadAsyncData() {

                const params = {};

                // this.$store.dispatch('fetchBillingDetails', params).then((data) => {
                //
                //     this.form = data.result;
                //
                //     this.countryObj = {
                //         id: data.result.country_code,
                //         label: data.result.country
                //     };
                //
                //     this.loading = false;
                //     this.show = true;
                // })
                //     .catch((error) => {
                //         throw error
                //     })
                //     .finally(() => {
                //         this.loading = false;
                //     });
            }

        },

        mounted() {
            this.loadAsyncData();
        }
    }
</script>

<style scoped>
    .cc-list .field {
        padding: 12px;
    }
</style>

const AccountExpiredModal = {
    components: {
    },
    data() {
        return {
            tabIndex: 0
        }
    },
    props: [
    ],
    template: `
        <div>
               
            <form @submit.prevent="handleSubmit">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Trial account expired</p>
                    </header>
                    <section class="modal-card-body">
                        <b-tabs v-model="tabIndex" type="is-boxed" :animated="false" class="modal-tabs-invisible">
                            <b-tab-item>
                                <template slot="header"  style="visibility: hidden">
                                    <!--<b-icon icon="information-outline"></b-icon>-->
                                    <!--<span> Issues <b-tag rounded> 3 </b-tag> </span>-->
                                </template>
                                <div class="columns">
                        
                                    <div class="column is-1">
                                    </div>
                                    
                                    <div class="column is-10">
                                        <b>Your {{ this.$global.product_name }} trial period has expired.</b><br><br>
                                        To keep using {{ this.$global.product_name }}, upgrade now by clicking the button below.

                                    </div>
                                    
                                    <div class="column is-1">
                                    </div>
                                </div>    
                                <hr>
                                <div class="columns" style="width: 100%">
                                    <div class="column is-12 has-text-centered">
                                        <button class="button is-medium is-info" type="button" @click="upgrade">View plans</button>
                                    </div>
                                </div>
                                
                            </b-tab-item>
                            
                           
                        </b-tabs>
                    
                       
                    </section>
                    <footer class="modal-card-foot">
                    </footer>
                </div>
            </form>
        </div>
        `,
    methods: {
        upgrade() {
            this.$emit('close');
            this.$parent.close();
        }
    },
    mounted() {
    }
}

export default AccountExpiredModal;

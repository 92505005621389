const UpgradeSMSLimitModal = {
    data() {
        return {
            data: {},
            form: {
                amount: 5000
            },

            submitting: false

        }
    },
    mounted() {
        this.$refs.creditBalance.focus();
    },
    props: [
        'channelName',
        'creditBalance'
    ],
    template: `
            <form @submit.prevent="handleSubmit">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Upgrade SMS credits</p>
                        <button class="delete" aria-label="close" role="button" type="button" @click="$parent.close"></button>
                    </header>
                    <section class="modal-card-body">
                       <div class="columns">
                        
                            <div class="column is-1">
                                <b-icon size="is-medium" icon="list-ol"></b-icon>
                            </div>
                            <div class="column is-10">
                                <p>{{ creditBalance }}
                                Upgrade SMS balance.
                                </p>
                                <hr>
                                <b-field horizontal label="Credits amount">
                                    <b-input
                                        size="is-medium"
                                        type="text"
                                        :value="creditBalance"
                                        v-model.trim="creditBalance"
                                        ref="creditBalance"
                                        placeholder="eg. 5000"
                                        style="width: 200px"
                                        required
                                    >
                                    </b-input>
                                </b-field>
                                <br>
                               
                            </div>
                            <div class="column is-1">
                              
                            </div>
                        </div>
                    </section>
                    
                    <footer class="modal-card-foot">
                        <div class="columns" style="width: 100%">
                            <div class="column is-12" style="text-align: right">
                                <button class="button is-primary is-medium" v-bind:class="{'is-loading': submitting}">Upgrade</button>
                                <button class="button is-medium" type="button" @click="$parent.close()">Cancel</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </form>
        `,
    methods: {
        handleSubmit() {

            this.submitting = true;

            // Send data to the server or update your stores and such.
            const params = {
                amount: this.creditBalance,
            }

            this.$store.dispatch('upgradeSMSLimit', params).then((data) => {

                this.$parent.close();

                this.$buefy.dialog.alert({
                    title: 'Request',
                    message: `<b>SMS balance Upgraded.`,
                    type: 'is-info',
                    hasIcon: true,
                    icon: 'thumbs-up',
                    iconPack: 'fa'
                })

                this.$emit('success');

            }).catch((err) => {
                this.$buefy.toast.open({
                    message: 'Error occurred while requesting upgrade of SMS balance: '+this.$utils.getHumanErrorMessage(err),
                    type: 'is-danger'
                });
                // handle error

            })
                .finally(() => {
                    this.submitting = false;
                });
        }
    }
}

export default UpgradeSMSLimitModal;

<template>

    <div :class="value ? '' : 'no-image'">
        <img v-bind:src="value ? value : '/assets/contact/avatar.png'">
    </div>

</template>

<script>
    export default {
        props: {
            value: { type: String },
        },
        data: function () {
            return {
            }
        },
        methods: {

        },

        mounted() {
            // this.imageUrl = src;
            // if(! this.src || this.src === null) {
                // this.src = '/assets/contact/avatar.png';
            // }
        }
    }
</script>

<style scoped>

    .no-image {
        opacity: 0.2;
    }

</style>
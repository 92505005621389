
const getHumanErrorMessage = function (err) {

    const errorObj  = err.response.data.data.errors;
    const errMsgObj = errorObj[Object.keys(errorObj)[0]];

    return { message : errMsgObj, errorArray : errorObj };
}

export default getHumanErrorMessage;

<template>
    <div>
        <transition name="fade">
            <div class="columns is-mobile" style="margin-top:50px">
                <b-loading :active.sync="loading"></b-loading>

                <div v-if="show" class="column is-half is-offset-one-quarter">
                    <div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'ActivateAccountView',
        data() {
            return {
                accountId: this.$route.params.accountId,
                code: this.$route.params.code,

                errors: {
                },

                message: 'Activating account...',
                loading: false,
                show: false
            }
        },
        methods: {
            /*
            * Load async data
            */
            loadAsyncData() {

                this.loading = true;

                const params = {
                    account_id: this.accountId,
                    activation_code: this.code
                };

                this.$store.dispatch('activateAccount', params).then((data) => {
                    if(data.success) {
                        this.$buefy.toast.open({
                            message: 'Your account is almost ready. Now set your password to log in...',
                            type: 'is-success'
                        });

                        this.$router.push('/password/reset/'+data.result.token+'?email='+data.result.email);

                        // hard redirect to password reset
                        // setTimeout(() => {
                        //     location.href='/password/reset/'+data.result.token+'?email='+data.result.email;
                        // },2500);
                    } else {
                    }

                    this.show = true;
                })
                .catch((error) => {
                    const data = error.response.data.data;

                    console.log(error.response.data);
                    this.$buefy.toast.open({
                        message: `${data.error}.<br>For questions, contact us using the chat widget below.`,
                        type: 'is-danger'
                    });

                    setTimeout(() => {
                        location.href='/password/reset/'+data.result.token+'?email='+data.result.email;
                    },1000);

                    throw error
                })
                .finally(() => {
                    this.loading = false;
                });

            },
        },
        mounted() {
            this.loadAsyncData();

        }
    }
</script>

<template>
    <section>
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item" id="tour-channels">
                    <h1>
                        <template v-if="total">
                            Channels ({{ total}})
                        </template>
                        <template v-else>
                            Channels
                        </template>
                    </h1>
                </div>
            </div>

            <!-- Right side -->
            <div class="level-right">
                <button v-if="$store.getters.userProfile.account.account_config.sms_enabled" class="button is-medium is-info"
                        @click="requestUpgradeSMSLimitModal()" variant="primary">
                    <b-icon pack="fas" size="is-small" icon="coins"></b-icon><span>Upgrade SMS limit</span>
                </button>&nbsp;
<!--                <button id="tour-channels-request-channel" class="button is-medium is-light"-->
<!--                        @click="requestChannelModal()" variant="primary">-->
<!--                    <b-icon pack="fab" size="is-small" icon="whatsapp"></b-icon><span>Connect your phone number</span>-->
<!--                </button>-->
<!--                <button id="tour-channels-request-channel2" class="button is-medium is-light"-->
<!--                        @click="connectChannelModal()" variant="primary">-->
<!--                    <b-icon pack="fab" size="is-small" icon="whatsapp"></b-icon><span>Connect your phone number (beta)</span>-->
<!--                </button>-->
<!--                <button class="button is-medium is-warning" style="margin-left:10px" v-if="$store.getters.userProfile && ($store.getters.userProfile.role === 'SUPER' || $store.getters.impersonateUserId)"-->
                <button class="button is-medium is-warning" style="margin-left:10px" @click="createChannelModal()" variant="primary">
                    <b-icon icon="plus" size="is-small"></b-icon><span>Connect channel</span>
                </button>
            </div>

        </nav>
        <hr>

        <br><br>

        <b-table
                :data="data"
                :loading="loading"

                paginated
                backend-pagination
                :total="total"
                :per-page="perPage"
                @page-change="onPageChange"

                backend-sorting
                :default-sort-direction="defaultSortOrder"
                :default-sort="[sortField, sortOrder]"

                @sort="onSort">

            <template slot-scope="props">
                <!--<b-table-column field="id" label="ID" width="80" sortable numeric>-->
                    <!--<router-link :to="{ name: 'channel-edit', params: {channelId: props.row.id} }">-->
                        <!--CH-{{ props.row.id }}-->
                    <!--</router-link>-->
                <!--</b-table-column>-->

                <b-table-column field="origin" label="Type" width="30" sortable>
                    <b-tooltip v-if="props.row.type === 'WHATSAPP'" label="WhatsApp">
                        <b-icon pack="fab" icon="whatsapp"></b-icon>
                    </b-tooltip>

                    <b-tooltip v-if="props.row.type === 'SMS'" label="SMS">
                        <b-icon icon="comment"></b-icon>
                    </b-tooltip>
                </b-table-column>

                <b-table-column field="name" label="Name" sortable>
                    <router-link :to="{ name: 'channel-edit', params: {channelId: props.row.id} }">
                        {{ props.row.name }}
                    </router-link>

                    <template v-if="props.row.is_demo">
                        <b-tooltip type="is-warning" multilined label="This channel is for demo purposes only. Do not communicate this number to your customers. It's limited in functionality and the number might change overtime.">
                            <b-tag type="is-light" rounded><strong>Demo phone number!</strong></b-tag>
                        </b-tooltip>
                    </template>

                    <template v-if="props.row.type === 'SMS'" && this.$store.getters.userProfile>
                        <b-tag :type="getCreditsLeftType($store.getters.userProfile.account.sms_credits_left)" rounded>{{ $store.getters.userProfile.account.sms_credits_left }} SMS {{ $store.getters.userProfile.account.sms_credits_left | pluralize('credit') }} left</b-tag>
                    </template>

                </b-table-column>

                <b-table-column field="origin" label="Channel" width="430" sortable>
                    <channel-display-tag :channel="props.row" :show-origin="true" :showStatus="props.row.type === 'WHATSAPP' ? true : false" :show-battery="props.row.type === 'WHATSAPP' ? true : false"></channel-display-tag>
                </b-table-column>

                <!--<b-table-column field="type" label="Type" sortable>-->
                    <!--<template v-if="props.row.type === 'WHATSAPP'">-->
                        <!--<b-icon pack="fab" size="is-large" type="is-primary" icon="whatsapp"></b-icon>-->
                    <!--</template>-->
                    <!--<template v-else>-->
                        <!--Unknown-->
                    <!--</template>-->
                <!--</b-table-column>-->

                <b-table-column field="updated_at" width="220" label="Last status checked" sortable>
                    {{ props.row.updated_at | moment('timezone', $global.current_tz) | moment("from", "now") }}
                </b-table-column>

                <b-table-column field="action" width="160" label="" centered>
                    <a v-if="props.row.err_code === 'channel_conflict'" @click="resetChannel(props.row.id)" href="#" class="action-danger-button" title="Reset channel">
                        <b-icon icon="sync"></b-icon>
                    </a>

                    <a v-if="props.row.type === 'SMS' && $store.getters.userProfile && ($store.getters.userProfile.role === 'SUPER' || $store.getters.impersonateUserId)" href="#" class="action-button" @click="upgradeSMSLimitModal">
                        <b-icon icon="coins"></b-icon>
                    </a>

                    <router-link class="action-button" :to="{ name: 'channel-edit', params: {channelId: props.row.id} }">
                        <b-icon icon="edit"></b-icon>
                    </router-link>

                    <a href="#" class="action-button" @click="deleteChannel(props.row.id)" title="remove/disconnect channel">
                        <b-icon icon="times-circle"></b-icon>
                    </a>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section" v-show="!loading">
                    <div class="content has-text-grey has-text-centered">
                        <p class="is-large"><b>No channels found.</b></p>
                    </div>
                </section>
            </template>

        </b-table>
    </section>
</template>

<script>

    import CreateChannelView from '../helpers/createChannelForm';
    import ConnectChannelView from '../helpers/connectChannelForm';
    import RequestChannelView from '../helpers/requestChannelForm';
    import RequestUpgradeSMSLimitModal from "../helpers/requestUpgradeSMSLimitModal";
    import UpgradeSMSLimitModal from "../helpers/upgradeSMSLimitModal";

    export default {
        data() {
            return {
                data: [],
                total: 0,
                loading: false,
                sortField: 'id',
                sortOrder: 'asc',
                defaultSortOrder: 'asc',
                page: 1,
                perPage: 20,
                search: '',

                freshDataInterval: 20 * 1000,
                silentRefreshData: false
            }
        },
        methods: {

            getCreditsLeftType(value) {
                // const value = this.$store.getters.userProfile.account.sms_credits_left;
                if(value > 20) {
                  return 'is-info';
                } else if(value > 0) {
                  return 'is-warning';
                } else {
                  return 'is-danger';
                }
            },

            /*
            * Handle remove a channel
            */
            resetChannel(id) {

                const params = {
                    id: id
                };

                this.$buefy.dialog.confirm({
                    title: 'Re-enable channel',
                    message: 'Are you sure you want to <b>re-enable</b> this channel?',
                    confirmText: 'Re-enable channel',
                    type: 'is-danger',
                    hasIcon: true,
                    size: 'is-medium',
                    onConfirm: () => {
                        this.$store.dispatch('resetChannel', params).then((data) => {

                            this.$buefy.toast.open({
                                message: 'Re-enable connection requested successfully. It could take up from 1 to 3 minutes before the channel connection is restored.',
                                type: 'is-info'
                            });

                            let result = data.result;
                            this.loadAsyncData();
                        }).catch((error) => {
                            throw error
                        });
                    }
                })
            },

            /*
            * Handle remove a channel
            */
            deleteChannel(id) {

                const params = {
                    id: id
                };

                this.$buefy.dialog.confirm({
                    title: 'Delete',
                    message: 'Are you sure you want to <b>disconnect and remove</b> this channel?<br><br>ALL CONTACT LISTS ATTACHED TO THIS CHANNEL WILL BE DELETED AS WELL!<br><br><b>This action cannot be undone.</b>',
                    confirmText: 'Delete',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('deleteChannel', params).then((data) => {

                            let result = data.result;
                            this.loadAsyncData();
                        })
                            .catch((error) => {
                                throw error
                            });
                    }
                })
            },
            /*
             * Load async data
             */
            loadAsyncData() {
                const params = [
                    'is_public=true',
                    `orderBy=${this.sortField}`,
                    `sort=${this.sortOrder}`,
                    `page=${this.page}`
                    // `type=${this.filter.type}`
                ].join('&');

                this.loading = ! this.silentRefreshData;

                this.$store.dispatch('fetchChannels', params).then((data) => {

                    this.silentRefreshData = false;

                    let result = data.result;

                    this.data = [];
                    let currentTotal = result.total;
                    if (result.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000
                    }

                    this.total = currentTotal;

                    result.data.forEach((item) => {
                        this.data.push(item)
                    })
                    this.loading = false
                })
                .catch((error) => {
                    this.data = [];
                    this.total = 0;
                    this.loading = false;
                    throw error
                });

            },
            requestChannelModal() {

                this.$buefy.modal.open({
                    parent: this,
                    component: RequestChannelView,
                    hasModalCard: true
                })
            },
            connectChannelModal() {

                this.$buefy.modal.open({
                    parent: this,
                    component: ConnectChannelView,
                    hasModalCard: true
                })
            },
            requestUpgradeSMSLimitModal() {

                this.$buefy.modal.open({
                    parent: this,
                    component: RequestUpgradeSMSLimitModal,
                    hasModalCard: true
                })
            },
            upgradeSMSLimitModal() {

              var self = this;

                this.$buefy.modal.open({
                    parent: this,
                    component: UpgradeSMSLimitModal,
                    hasModalCard: true,
                    props: {
                        creditBalance: this.$store.getters.userProfile.account.sms_credits_left,
                    },
                    events: {
                        success: function() {
                          setTimeout(() => {
                            location.reload();
                          },1000);
                        },
                        failed: function() {
                        }
                    }
                })
            },
            createChannelModal() {

                this.$buefy.modal.open({
                    parent: this,
                    component: CreateChannelView,
                    hasModalCard: true
                })
            },

            /*
             * Handle dropdown filter change event
             */
            onFilterChange(filter) {

                // let filterTypeLabel = '';
                //
                // if(filter === 'MAIN') {
                //     filterTypeLabel = 'Template messages'
                // } else if(filter === 'OPT-IN') {
                //     filterTypeLabel = 'Opt-in';
                // } else if(filter === 'OPT-OUT') {
                //     filterTypeLabel = 'Opt-out';
                // } else {
                //     filterTypeLabel = 'Unknown';
                // }
                //
                // this.filter.label = filterTypeLabel;
                //
                // this.filter.type = filter;
                this.loadAsyncData()
            },
            /*
             * Handle page-change event
             */
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            /*
             * Handle sort event
             */
            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
            },
            refreshData() {
                setTimeout(() => {
                    this.silentRefreshData = true;
                    this.loadAsyncData();
                    this.refreshData();
                },this.freshDataInterval);
            }
        },
        filters: {
            /**
             * Filter to truncate string, accepts a length parameter
             */
            truncate(value, length) {
                return value.length > length
                    ? value.substr(0, length) + '...'
                    : value
            }
        },
        mounted() {

            this.loadAsyncData();
            this.refreshData();

            if(this.$route.params.requestUpgradeSMS) {
                this.requestUpgradeSMSLimitModal();
            }

        }
    }
</script>

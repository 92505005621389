import AccountSettingsView from '../components/AccountSettingsView.vue'
import ActivateAccountView from '../components/ActivateAccountView.vue'

import AdminCheckoutView from '../components/AdminCheckoutView.vue'
import AdminBillingView from '../components/AdminBillingView.vue'
import AdminOrderDetailView from '../components/AdminOrderDetailView.vue'
import AdminPlanView from '../components/AdminPlanView.vue'
import AdminPaymentReturnView from '../components/AdminPaymentReturnView.vue'

import CampaignView from '../components/CampaignView.vue'
import CampaignEditView from '../components/CampaignEditView.vue'
import ChannelView from '../components/ChannelView.vue'
import ChannelEditView from '../components/ChannelEditView.vue'
import ContactView from '../components/ContactView.vue'
import ContactEditView from '../components/ContactEditView.vue'
import ContactListEditView from '../components/ContactListEditView.vue'
import ContactListView from '../components/ContactListView.vue'
import CouponCodeView from '../components/CouponCodeView.vue'
import CouponCodeEditView from '../components/CouponCodeEditView.vue'
import DashboardView from '../components/DashboardView.vue'
import EventLogView from '../components/EventLogView.vue'
import LoginView from '../components/LoginView.vue'
import ForgotPasswordView from '../components/ForgotPasswordView.vue'
import PasswordResetView from '../components/PasswordResetView.vue'
import RegisterView from '../components/RegisterView.vue'
import Store from '../store/mainStore'
import SendSingleMessage from "../components/SendSingleMessage.vue"
import TemplateView from '../components/TemplateView.vue'
import TemplateEditView from '../components/TemplateEditView.vue'
import TestView from '../components/TestView.vue'
import GenerateSubscribeFormView from '../components/GenerateSubscribeFormView.vue'
import GenerateContactWidgetView from '../components/GenerateContactWidgetView.vue'

// superadmin
import AccountView from '../components/admin/AccountView.vue'
import AccountEditView from '../components/admin/AccountEditView.vue'
import SystemView from '../components/admin/SystemView.vue'
import UserEditView from '../components/admin/UserEditView.vue'

const ifNotAuthenticated = (to, from, next) => {

    if (! Store.getters.isLoggedIn) {

        if (Store.getters.isExpired && to.name !== 'login') {

            next({ name: 'admin-plans' });
        } else {

            Store.dispatch('setView', { name: to.name });
            next();
        }

        return
    }

    Store.dispatch('setView', { name: 'login' });
    next({ name: 'login' });
}

const ifAuthenticated = (to, from, next) => {

    if (Store.getters.isLoggedIn) {

        if (Store.getters.isExpired && (
            to.name !== 'admin-billing' &&
            to.name !== 'admin-plans' &&
            to.name !== 'admin-checkout' &&
            to.name !== 'admin-order-detail' &&
            to.name !== 'login'
        )) {
            next({ name: 'admin-plans'});

        } else {

            Store.dispatch('setView', { name: to.name });
            next()
        }

        return
    }

    Store.dispatch('setView', { name: 'login' });
    next({ name: 'login', params: { redir_path: to.path, redir_query: to.query } })
}

const mainRoutes = [
    { name: 'login',                    path: '/login', component: LoginView, beforeEnter: ifNotAuthenticated },
    { name: 'signup',                   path: '/signup', component: RegisterView, beforeEnter: ifNotAuthenticated },
    { name: 'forgot-password',          path: '/forgot-password', component: ForgotPasswordView, beforeEnter: ifNotAuthenticated },
    { name: 'password-reset',           path: '/password/reset/:token', component: PasswordResetView },
    { name: 'activate',                 path: '/activate/:accountId/:code', component: ActivateAccountView },

    { name: 'admin-checkout',           path: '/admin/checkout', component: AdminCheckoutView, beforeEnter: ifAuthenticated },
    { name: 'admin-coupon-codes',       path: '/coupon-codes/', component: CouponCodeView, beforeEnter: ifAuthenticated },
    { name: 'admin-coupon-codes-edit',  path: '/coupon-codes/:couponCodeId', component: CouponCodeEditView, beforeEnter: ifAuthenticated },
    { name: 'admin-billing',            path: '/admin/billing', component: AdminBillingView, beforeEnter: ifAuthenticated },
    { name: 'admin-plans',              path: '/admin/plans', component: AdminPlanView, beforeEnter: ifAuthenticated },
    { name: 'admin-payment-return',     path: '/admin/payment/return', component: AdminPaymentReturnView, beforeEnter: ifAuthenticated },
    { name: 'admin-order-detail',       path: '/admin/order/:orderId', component: AdminOrderDetailView, beforeEnter: ifAuthenticated },

    { name: 'root',                     path: '/', redirect: { name: 'dashboard', beforeEnter: ifAuthenticated } },
    { name: 'campaigns',                path: '/campaigns', component: CampaignView, beforeEnter: ifAuthenticated },
    { name: 'campaign-edit',            path: '/campaigns/edit/:campaignId', component: CampaignEditView, beforeEnter: ifAuthenticated },
    { name: 'channels',                 path: '/channels', component: ChannelView, beforeEnter: ifAuthenticated },
    { name: 'channel-edit',             path: '/channels/edit/:channelId', component: ChannelEditView, beforeEnter: ifAuthenticated },
    { name: 'contacts-by-listId',       path: '/contacts/list/:listId', component: ContactView, beforeEnter: ifAuthenticated },
    { name: 'contacts',                 path: '/contacts', component: ContactView, beforeEnter: ifAuthenticated },
    { name: 'contact-edit',             path: '/contacts/edit/:contactId', component: ContactEditView, beforeEnter: ifAuthenticated },
    { name: 'contactlists',             path: '/contactlists', component: ContactListView, beforeEnter: ifAuthenticated },
    { name: 'contactlist-edit',         path: '/contactlists/edit/:listId', component: ContactListEditView, beforeEnter: ifAuthenticated },
    { name: 'dashboard',                path: '/dashboard', component: DashboardView, beforeEnter: ifAuthenticated },
    { name: 'event-log',                path: '/event-logs', component: EventLogView, beforeEnter: ifAuthenticated },
    { name: 'account-settings',         path: '/account/settings', component: AccountSettingsView, beforeEnter: ifAuthenticated },

    { name: 'generate-subscribe-form',  path: '/embed/subscribeForm', component: GenerateSubscribeFormView, beforeEnter: ifAuthenticated },
    { name: 'generate-contact-widget',  path: '/embed/contactWidget', component: GenerateContactWidgetView, beforeEnter: ifAuthenticated },

    { name: 'sendSingleMessage',        path: '/sendSingleMessage', component: SendSingleMessage, beforeEnter: ifAuthenticated },
    { name: 'templates',                path: '/templates', component: TemplateView,beforeEnter: ifAuthenticated },
    { name: 'template-edit',            path: '/templates/edit/:templateId', component: TemplateEditView, beforeEnter: ifAuthenticated },
    { name: 'test',                     path: '/test', component: TestView, beforeEnter: ifAuthenticated },

    { name: 'sa-accounts',              path: '/sa/accounts', component: AccountView, beforeEnter: ifAuthenticated },
    { name: 'sa-account-edit',          path: '/sa/account/edit/:accountId', component: AccountEditView, beforeEnter: ifAuthenticated },
    { name: 'sa-user-edit',             path: '/sa/user/edit/:userId', component: UserEditView, beforeEnter: ifAuthenticated },
    { name: 'sa-system',                path: '/sa/system', component: SystemView, beforeEnter: ifAuthenticated },

    // { name: '404',                      path: '*', redirect: { name: 'root', beforeEnter: ifAuthenticated } }

];

export default mainRoutes;

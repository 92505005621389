<template>
    <section>
        <nav class="level">

            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <h1 v-if="! loading">
                        <template v-if="total">
                            Coupon Codes ({{ total }})
                        </template>
                        <template v-else>
                            &nbsp;Coupon Codes
                        </template>
                    </h1>
                    <h1 v-else>
                        &nbsp;
                    </h1>
                </div>
            </div>

            <!-- Right side -->
            <div class="level-right">
                <button class="button is-medium is-info"
                        @click="createCouponCodeModal()" variant="primary">
                    <b-icon icon="plus"></b-icon><span>Create coupon code</span>
                </button>
            </div>

        </nav>
        <hr>

        <b-table
                v-show="data"

                :data="data"
                :loading="loading"

                paginated
                backend-pagination
                :total="total"
                :per-page="perPage"
                @page-change="onPageChange"

                backend-sorting
                :default-sort-direction="defaultSortOrder"
                :default-sort="[sortField, sortOrder]"
                @sort="onSort">

<!--                :is-row-checkable="(row) => row.id !== 3"-->
<!--                :checked-rows.sync="checkedRows"-->
<!--                checkable-->
<!--        >-->

            <template slot-scope="props">

                <b-table-column field="code" label="Code" width="300" sortable>
                    <b>{{ props.row.code }}</b>
                    <template v-if="isExpired(props.row.expiry_date)">
                            <b-notification type="is-danger"
                                            :closable="false"
                                            role="alert">
                                Expired {{ getExpiredInDays(props.row.expiry_date) * -1 }} {{ getExpiredInDays(props.row.expiry_date) | pluralize('day') }} ago
                            </b-notification>
                    </template>
                    <template v-else-if="getExpiredInDays(props.row.expiry_date) >= 0">
                        <b-notification type="is-success"
                                        :closable="false"
                                        role="alert">
                            Expires in {{ getExpiredInDays(props.row.expiry_date) }} {{ getExpiredInDays(props.row.expiry_date) | pluralize('day') }} ago
                        </b-notification>
                    </template>
                </b-table-column>

                <b-table-column field="enabled" label="Enabled" sortable>
                    <b-tag v-if="props.row.enabled && ! isExpired(props.row.expiry_date)" type="is-primary" size="is-small">
                        <b-icon icon="check"></b-icon>
                    </b-tag>
                    <b-tag v-else type="is-danger">
                        <b-icon icon="times"></b-icon>
                    </b-tag>
                </b-table-column>

                <b-table-column field="schedule" label="Schedule" sortable>
                    <b-tag v-if="props.row.schedule === 'ONEOFF'" type="is-light" size="is-small">
                        {{ props.row.schedule }}
                    </b-tag>
                    <b-tag v-else type="is-danger">
                        {{ props.row.schedule }}
                    </b-tag>
                </b-table-column>

                <b-table-column field="val" label="Discount Value" sortable>
                    <b>
                        <div v-if="props.row.type === 'AMOUNT'">
                            {{ props.row.val | currency('€', { decimalDigits: 0 } ) }}
                        </div>
                        <div v-if="props.row.type === 'PCT'">
                            {{ props.row.val }}%
                        </div>
                    </b>
                </b-table-column>

                <b-table-column field="eligible_for_subscription" label="Applies to Subscription" sortable>
<!--                    <b-tag :type="props.row.eligible_for_subscription ? 'is-success' : 'is-danger'">-->
                        {{ !! props.row.eligible_for_subscription ? 'Y' : 'N' }}
<!--                    </b-tag>-->
                </b-table-column>

                <b-table-column field="eligible_for_payasyougo" label="Applies to Pay-as-you-go" sortable>
<!--                    <b-tag :type="props.row.eligible_for_payasyougo ? 'is-success' : 'is-danger'">-->
                        {{ !! props.row.eligible_for_payasyougo ? 'Y' : 'N' }}
<!--                    </b-tag>-->
                </b-table-column>

                <b-table-column field="used_count" label="Used" sortable>
                    {{ props.row.used_count | currency('', 0, { thousandsSeparator: ',' })}}
                </b-table-column>

                <b-table-column field="updated_at" label="Updated" sortable>
                    {{ props.row.updated_at | moment('timezone', $global.current_tz) | moment("from", "now") }}
                </b-table-column>

                <b-table-column field="action" label="Actions" centered>
                    <template>
                        <router-link :to="{ name: 'admin-coupon-codes-edit', params: {couponCodeId: props.row.id} }" class="action-button" title="Edit coupon code">
                            <b-icon icon="edit"></b-icon>
                        </router-link>

                        <a href="#" class="action-button" title="Remove coupon code from list" @click="deleteCouponCode(props.row.id)">
                            <b-icon icon="trash-alt"></b-icon>
                        </a>
                    </template>

                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section" v-show="! loading">
                    <div class="content has-text-grey has-text-centered">
                        <p class="is-large"><b>No coupon codes found.</b></p>
<!--                        <p><a href="#" @click="createContactListModal()">Create a list</a> now and start adding contacts.</p>-->
                    </div>
                </section>
            </template>

        </b-table>
    </section>
</template>

<script>

    import CreateCouponCodeForm from '../helpers/createCouponCodeForm';

    export default {
        data() {
            return {
                data: [],
                checkedRows: [],
                total: 0,
                loading: true,
                sortField: 'updated_at',
                sortOrder: 'desc',
                defaultSortOrder: 'asc',
                page: 1,
                perPage: 20,
                search: '',
            }
        },
        computed: {
        },
        methods: {
            isExpired(d) {

                const dParsed = this.$moment.utc(d);
                return dParsed.diff(this.$moment(new Date()), 'seconds') < 0;
            },
            getExpiredInDays(d) {

                const dParsed = this.$moment.utc(d);
                return dParsed.diff(this.$moment(new Date()), 'days');
            },
            delay(fn, waitDelay) {
                if (this.globalTimeout != null) {
                    clearTimeout(this.globalTimeout);
                }
                this.globalTimeout = setTimeout(function() {
                    this.globalTimeout = null;

                    fn();

                }, waitDelay);
            },
            createCouponCodeModal() {
                this.$buefy.modal.open({
                    parent: this,
                    component: CreateCouponCodeForm,
                    hasModalCard: true
                })
            },

            /*
            * Handle remove a contact list
            */
            deleteCouponCode(id) {

                const params = {
                    id: id
                };

                this.$buefy.dialog.confirm({
                    title: 'Delete',
                    message: 'Are you sure you want to <b>delete</b> this coupon code? This action cannot be undone.',
                    confirmText: 'Delete coupon code',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('deleteCouponCode', params).then((data) => {

                            let result = data.result;
                            this.loadAsyncData();
                        })
                            .catch((error) => {
                                throw error
                            });
                    }
                })
            },

            /*
             * Load async data
             */
            loadAsyncData() {

                const params = [
                ].join('&');

                this.loading = true;

                this.$store.dispatch('fetchCouponCodes', params).then((data) => {

                    let result = data.result;

                    this.data = [];
                    let currentTotal = result.total;
                    if (result.total / this.perPage > 1000) {
                        currentTotal = this.perPage * 1000
                    }

                    this.total = currentTotal;

                    result.data.forEach((item) => {
                        this.data.push(item)
                    })
                    this.loading = false
                })
                .catch((error) => {
                    this.data = [];
                    this.total = 0;
                    this.loading = false;
                    throw error
                });

            },
            /*
             * Handle page-change event
             */
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            /*
             * Handle sort event
             */
            onSort(field, order) {
                this.sortField = field
                this.sortOrder = order
                this.loadAsyncData()
            }
        },
        filters: {
            /**
             * Filter to truncate string, accepts a length parameter
             */
            truncate(value, length) {
                return value.length > length
                    ? value.substr(0, length) + '...'
                    : value
            }
        },
        mounted() {
            this.loadAsyncData();
        }
    }
</script>
